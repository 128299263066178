<template>
    <div class="h-100 d-flex flex-column">
        <b-navbar class="text-white" variant="primary">
            <b-navbar-nav><back-button></back-button></b-navbar-nav>
            <b-navbar-nav class="ml-auto mr-auto">
                <h5 class="ml-4 mt-2 text-nowrap">
                    {{ title }}
                </h5>
            </b-navbar-nav>
            <b-navbar-nav></b-navbar-nav>
        </b-navbar>
        <iframe class="m-0 p-0 w-100 h-100" style="" :src="link"></iframe>
    </div>
</template>

<script>
    import api from "../util/api";
    import BackButton from "@/components/BackButton";

    export default {
        name: "ExernalLinkBrowser",
        components: {BackButton},
        props: ['link', 'title'],
        data: function() {
            return {
            }
        },
        async mounted() {
        }
    }
</script>

<style scoped>

</style>
