<template>
    <div class="btn-toolbar justify-content-between">
        <div class="pt-1" style="font-size: large">
            <i class="fas fa-chevron-left" @click="moveStep(-1)"></i>
            <span class="px-4">{{ periodLabel() }}</span>
            <i class="fas fa-chevron-right" @click="moveStep(1)"></i>
        </div>
        <div class="btn-group">
            <button class="btn btn-outline-secondary"
                    :class="{active: unit == u}"
                    @click="setUnit(u)"
                    v-for="u in ['week', 'month', 'year']"
                    :key="u">{{ labels[u] }}</button>
        </div>
    </div>
</template>

<script>
    import moment from "moment";

    export default {
        name: "PeriodSelector",
        props: {
            initialStep: {type: Number, default: 0},
            initialUnit: {type: String, default: 'week'},
            weekOffset: {type: Number, default: 0},
        },
        data() {
            return {
                labels: {
                    week: '주',
                    month: '월',
                    year: '연',
                },
                step: this.initialStep,
                unit: this.initialUnit,
            }
        },
        computed: {
            moment() {
                if (this.unit == 'half') {
                    // TODO
                } else {
                    return moment().add(this.step, this.unit + 's')
                }
                return null
            },
            begin() {
                if (this.unit == 'week') {
                    return moment(this.moment).day(0).add(this.weekOffset, 'day')
                } else if (this.unit == 'month') {
                    return moment(this.moment).date(1)
                } else if (this.unit == 'year') {
                    return moment(this.moment).month(0).date(1)
                }
                return null
            },
            end() {
                return moment(this.begin).add(1, this.unit + 's').subtract(1, 'days')
            }
        },
        mounted() {
            this.notifyChange()
        },
        methods: {
            notifyChange: function () {
                this.$emit('change', {
                    unit: this.unit,
                    step: this.step,
                    begin: this.begin,
                    end: this.end
                })
            },
            moveStep(diff) {
                if (this.step == 0 && diff > 0) {
                    return
                }
                this.step += diff
                this.notifyChange()
            },
            setUnit(unit) {
                this.unit = unit
                this.step = 0
                this.notifyChange()
            },
            periodLabel() {
                if (this.unit == 'week') {
                    if (this.step == 0) {
                        return '이번 주'
                    } else {
                        return (- this.step) + '주 전'
                    }
                } else if (this.unit == 'month') {
                    return (this.moment.month() + 1) + '월'
                } else if (this.unit == 'year') {
                    return this.moment.year() + '년'
                }
            },
        }
    }
</script>

<style scoped>

</style>
