import api from "./api";
import querystring from "querystring";

export default {
    name: "AccountMixin",
    data: () => ({
        bankAccounts: [],
        accounts: [],
    }),
    computed: {
        accountTypes() {
            return Object.fromEntries(this.globals.accounts.map(a => [a.type, a]))
        },
        scrapingSources() {
            return Object.fromEntries(this.globals.scraping.map(a => [a.source, a]))
        },
    },
    methods: {
        async removeAccount(account, type, resource=null) {
            if (!account.valid || confirm(`연동을 해제하면 ${type} 데이터 업데이트가 중단됩니다. 연동해제 하시겠습니까?`)) {
                await api.delete(`robotax/${resource || 'account'}/${account.id}/`)
                await this.pollAccounts()
            }
        },
        async retryAccount(type) {
            await api.post('robotax/scrapingtask/start/', {
                '사업자': this.globals.trader.robotax_id,
                'sources': [type],
                'retry_invalid': true,
            })
            this.$bvToast.toast(`${type} 계정 연동 확인 중입니다. 이 작업은 몇 분 걸리며, 완료되면 알림을 보내드립니다.`)
        },
        async setBankAccountPassword(account, accountPassword, password) {
            let res = await api.post(`robotax/bankaccount/${account.id}/set_password/`, {
                account_password: accountPassword,
                password: password
            })
            await this.poll(account.type)
            await this.reload()
            Object.assign(account, res.data)
        },
        async refreshBankAccount(account) {
            await api.post(`robotax/bankaccount/${account.id}/sync/`, {})
            await this.pollScraping(account.type)
            await this.reload()
        },
        pollAccounts() {
            let path = 'robotax/account/?' + querystring.stringify({
                사업자: this.globals.trader.robotax_id,
            })
            if (!this.globals.polling[path]) {
                this.$set(this.globals.polling, path, {counter: 0, timer: null})
            }
            api.get(path).then(res => {
                this.globals.accounts = res.data.results

                if (this.globals.polling[path].counter > 20) {
                    delete this.globals.polling[path]
                } else if (Object.values(this.globals.accounts).every(a => a.valid != null)) {
                    delete this.globals.polling[path]
                    if (this.accountsUpdated) {
                        this.accountsUpdated()
                    }
                } else {
                    if (this._isDestroyed) { return }
                    this.globals.polling[path].timer = setTimeout(this.pollAccounts, 3000)
                    this.globals.polling[path].counter ++
                }
            })
        },
        pollScraping(...sources) {
            let path = `robotax/scrapingtask/latest/?` + querystring.stringify({
                사업자: this.globals.trader.robotax_id,
                source: sources
            })
            if (!this.globals.polling[path]) {
                this.$set(this.globals.polling, path, {counter: 0, timer: null})
            }
            api.get(path).then(res => {
                this.globals.scraping = res.data

                if (this.globals.polling[path].counter > 20) {
                    delete this.globals.polling[path]
                } else if (this.globals.scraping.every(t => t.status != 'running' && t.status != 'ready')) {
                    delete this.globals.polling[path]
                    if (this.scrapingUpdated) {
                        this.scrapingUpdated()
                    }
                } else {
                    if (this._isDestroyed) { return }
                    this.globals.polling[path].timer = setTimeout(() => this.pollScraping(...sources), 3000)
                    this.globals.polling[path].counter ++
                }
            })
        },
        startScraping(...sources) {
            api.post(`robotax/scrapingtask/start/`, {
                사업자: this.globals.trader.robotax_id,
                sources: sources,
                retry_invalid: true,
            }).then(res => {
                setTimeout(() => this.pollScraping(...sources), 500)
            })
        },
    }
}
