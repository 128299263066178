<template>
    <div>
        <nav class="navbar navbar-light titlebar">
            <back-button></back-button>
            <h5 class="mt-2">
                영세 사업자 무료이용 쿠폰 신청
            </h5>
            <span></span>
        </nav>
        <div class="card mx-3 mb-3">
            <div class="card-body">
                <p>키퍼에서 영세 사업자를 지원하기 위해 무료이용 쿠폰을 드립니다. 다음과 같은 조건이 만족하면 쿠폰을 신청할 수 있습니다.</p>
                <table class="table">
                    <tbody>
                        <tr>
                            <th>사업자구분</th>
                            <td>개인사업자</td>
                        </tr>
                        <tr>
                            <th>최근 6개월 매출</th>
                            <td>1천만원 이하</td>
                        </tr>
                        <tr>
                            <th>요금제</th>
                            <td>정식버전</td>
                        </tr>
                    </tbody>
                </table>
                <p>
                    쿠폰을 다 쓰고 나서도 또 신청할 수 있기 때문에 위의 조건만 유지된다면
                    <strong>키퍼를 계속 무료로 사용</strong>할 수 있습니다.
                </p>
                <div v-if="globals.trader.plan == '정식버전'">
                    <button class="btn btn-block btn-outline-primary" @click="requestCoupon">무료이용 쿠폰 신청하기</button>
                </div>
                <p class="alert alert-danger" v-else>
                    쿠폰을 신청하기 전에 <strong>정식버전을 먼저 등록</strong>해주세요. 정식버전 결제수단을 등록하시더라도 무료이용 쿠폰을 발급받으면 결제수단으로
                    실제 비용이 지출되지 않고 계속 무료로 사용하실 수 있어요.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import BackButton from "@/components/BackButton";
    import {api} from "@/util/api";
    export default {
        name: "SmallBizSupport",
        components: {BackButton},
        methods: {
            async requestCoupon() {
                await api.post('coupon/request/', {
                    사업자: this.globals.trader.id
                })
                alert('쿠폰 신청이 완료되었습니다.')
            }
        }
    }
</script>

<style scoped>

</style>