<template>
    <b-modal id="대표자-주민등록번호-modal" title="대표자 주민등록번호 입력" ok-only ok-title="닫기">
        <p>종합소득세 신고를 하려면 대표자 주민등록번호가 필요합니다. 입력하신 주민등록번호는 안전하게 보관됩니다.</p>
        <div class="text-center">
            <b-input-group>
                <b-input-group-prepend is-text>{{ 사업자.대표자주민등록번호.substring(0, 6) }}</b-input-group-prepend>
                <b-input-group-prepend is-text>-</b-input-group-prepend>
                <b-input size="" type="text" maxlength="7" pattern="[0-9]*" inputmode="numeric" placeholder="뒷자리 7자리 입력"
                         @focus="$event.target.select()" v-model="대표자주민등록번호_뒷자리"></b-input>
                <b-input-group-append>
                    <button class="btn btn-outline-primary" @click="updateTaxPayerID">변경</button>
                </b-input-group-append>
            </b-input-group>
        </div>
        <p class="text-success" v-if="valid">유효한 주민등록번호입니다.</p>
        <p class="text-danger" v-else>주민등록번호가 유효하지 않습니다.</p>
    </b-modal>
</template>
<script>
    import {api} from "@/util/api";

    export default {
        name: 'RegistrationNumberModal',
        props: {
            valid: {},
            사업자: {}
        },
        data() {
            return {
                대표자주민등록번호_뒷자리: ''
            }
        },
        mounted() {
            this.대표자주민등록번호_뒷자리 = this.사업자.대표자주민등록번호.substring(6)
        },
        methods: {
            async updateTaxPayerID() {
                Object.assign(this.사업자, (await api.patch(`robotax/사업자/${this.사업자.id}/`, {
                    '대표자주민등록번호': this.사업자.대표자주민등록번호.substring(0, 6) + this.대표자주민등록번호_뒷자리,
                })).data)
                this.$emit('update')
                // let trader = await api.patch(`사업자/${this.globals.trader.id}/`, {
                //     '대표자주민등록번호': 사업자.납세자ID
                // })
                // saveGlobal('trader', trader.data)
            },
        }
    }
</script>