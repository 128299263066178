<template>
    <b-modal id="자가신고-확인-modal" title="자가신고 확인"
             ok-title="동의" cancel-title="거절"
             @ok="$emit('ok')"
             @cancel="$emit('cancel')">
        <p class="">
            <i class="fas fa-exclamation-triangle"></i>
            키퍼의 신고 기능은 세무사의 신고대리가 아니라 고객님이 홈택스에 <strong>자가신고</strong>하는 과정을 자동화하고 도와주는 편의 기능이며,
            <strong>키퍼는 세금신고의 내용에 대해 책임지지 않습니다.</strong> 그러므로 키퍼를 통해 신고하시기 전에 내역보기에서 신고서의 내역을 상세히 확인하시기 바랍니다.
        </p>
        <p>위의 내용에 동의하시면 키퍼를 통해 신고하실 수 있습니다. 동의하시겠습니까?</p>
    </b-modal>
</template>
<script>
    export default {
        name: 'DisclaimerModal',
        props: {
            updateChecklist: {}
        }
    }
</script>