import axios from 'axios'


const baseDomain = process.env.NODE_ENV === 'production' ? '' : location.origin.replace(/808[0-9]/, '8000')
const baseURL = `${baseDomain}/opapi`

const opapi = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken'
})
export default opapi


export const robotaxapi = axios.create({
    baseURL: `${baseDomain}/api/robotax`,
    withCredentials: true,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken'
})
