<template>
    <div class="h-100 d-flex flex-column">
        <nav class="navbar navbar-light titlebar">
            <back-button></back-button>
            <h5 class="mt-2">카드매출내역</h5>
            <span class="text-underline" v-b-toggle.help-여신금융협회>
                <i class="fas fa-info-circle"></i> 도움말
            </span>
        </nav>
        <div class="container-fluid h-100 overflow-auto">
            <b-collapse class="mt-1 alert alert-light" id="help-여신금융협회" title="여신금융협회 안내" ok-only ok-title="닫기" :visible="!account">
                <p>
                    <strong>여신금융협회</strong>는 카드 단말기의 매출을 조회할 수 있는 사이트입니다.
                    아직 사회보험통합징수포털 계정이 없다면 회원가입해주세요. 키퍼에 등록하신 <strong>공동인증서</strong>로 회원가입하시면
                    키퍼에서도 연동되어 4대보험 납부내역을 확인하실 수 있어요.
                </p>
                <p>등록하신 계정 정보에 오류가 있는 경우 연결해제를 하고 다시 등록해보세요.</p>
            </b-collapse>
            <scraping-account-panel source="여신금융협회" :account="accountTypes.여신금융협회"></scraping-account-panel>
            <div class="row">
                <div class="col-md-6 col-lg-5 p-0">
                    <div class="card m-3">
                        <div class="card-header">
                            <period-selector initial-unit="week" @change="reload"></period-selector>
                        </div>
                        <div class="card-body">
                            <div>
                                <canvas id="chart-cardsales" style="width: 100%;"></canvas>
                            </div>
                        </div>
                    </div>

                    <div class="card m-3" v-if="depositExpected">
                        <div class="card-header">
                            입금 예정
                        </div>
                        <div class="card-body">
                            <table class="table">
                                <thead>
                                    <tr class="text-center">
                                        <th>입금예정일</th>
                                        <th>입금액</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="depositExpectedDates.size == 0"><td colspan="2" class="text-center">입금예정금액이 없습니다.</td></tr>
                                    <router-link :to="{query: date == moment().format('YYYY-MM-DD') ? null : {depositDate: date}}"
                                                 replace
                                                 tag="tr"
                                                 exact-active-class="table-active"
                                                 v-for="date in depositExpectedDates" :key="date">
                                        <td>{{ localDateFormat(date) }}</td>
                                        <td class="text-right">{{ moneyFormat(depositExpected.filter(d => d.지급예정일 == date).reduce((s, a) => s + a.지급금액__sum, 0)) }}</td>
                                    </router-link>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <div class="col-md-6 col-lg-7 p-0">
                    <div class="card m-3" v-if="depositExpected">
                        <div class="card-header">
                            <strong>{{ localDateFormat(depositDate) }}</strong> 카드사별 입금예정
                        </div>
                        <div class="card-body">
                            <table class="table">
                                <thead>
                                    <tr class="text-center">
                                        <th>카드사</th>
                                        <th>입금액</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="depositByCardCompany.length == 0"><td colspan="2" class="text-center">카드사별 입금예정금액이 없습니다.</td></tr>
                                    <tr v-else>
                                        <th>합계</th>
                                        <th class="text-right">{{
                                                moneyFormat(depositByCardCompany.reduce((s, a) => s + a.지급금액__sum, 0))
                                            }}</th>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr v-for="row in depositByCardCompany" :key="row.카드사명">
                                        <td>{{ row.카드사명 }}</td>
                                        <td class="text-right">{{ moneyFormat(row.지급금액__sum) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import BackButton from "@/components/BackButton";
    import api from "@/util/api";
    import Chart from "chart.js"
    import moment from "moment"
    import {getCssVar, humanizeNumberScale, range} from "@/util/vue-commons";
    import PeriodSelector from "@/components/PeriodSelector";
    import querystring from "querystring";
    import ScrapingTaskPollMixin from "@/util/ScrapingTaskPollMixin";
    import AccountMixin from "@/util/AccountMixin";
    import ScrapingAccountPanel from "@/views/connect/ScrapingAccountPanel";

    export default {
        name: "CardSalesReport",
        mixins: [ScrapingTaskPollMixin, AccountMixin],
        components: {ScrapingAccountPanel, PeriodSelector, BackButton},
        data() {
            return {
                summary: {승인금액: [], 지급금액: []},
                chart: null,
                period: null,
                depositExpected: null,
            }
        },
        computed: {
            account() {
                return this.accountTypes.여신금융협회
            },
            points: function () {
                if (!this.period) {
                    return []
                }
                if (this.period.unit == 'week') {
                    let dates = range(7).map(n => moment().add(7 * (this.period.step - 1) + n + 1, 'days'))
                    return dates.map(d => { return {
                        value: d.format('YYYY-MM-DD'),
                        label: this.localDateFormat(d, true)
                    }})
                } else if (this.period.unit == 'month') {
                    let month = moment().add(this.period.step, 'months');
                    let dates = range(month.daysInMonth()).map(n => month.date(n + 1).format('YYYY-MM-DD'))
                    return dates.map(d => { return {
                        value: d,
                        label: this.localDateFormat(d, true)
                    }})
                } else if (this.period.unit == 'year') {
                    let dates = range(moment().add(this.period.step, 'years').month() + 1).map(n => n + 1)
                    return dates.map(d => { return {value: d, label: `${d + 1}월`} })
                }
                return []
            },
            depositDate() {
                return this.$route.query.depositDate || moment().format('YYYY-MM-DD')
            },
            depositByCardCompany() {
                return this.depositExpected.filter(d => d.지급예정일 == this.depositDate)
            },
            depositExpectedDates() {
                return new Set(this.depositExpected.map(d => d.지급예정일))
            }

        },
        watch: {
        },
        async mounted() {
            this.pollAccounts()
        },
        methods: {
            accountsUpdated() {
                this.pollScraping('여신금융협회')
            },
            async scrapingUpdated() {
                await this.reload(this.period)
            },
            async reload(period) {
                this.period = period
                let 승인금액_params = this.queryParams('거래일시__date', '승인금액');
                this.summary.승인금액 = (await api.get('robotax/카드매출/aggregate/', {
                    params: 승인금액_params
                })).data

                let 지급금액_params = this.queryParams('지급예정일', '지급금액');
                this.summary.지급금액 = (await api.get('robotax/카드매출/aggregate/', {
                    params: 지급금액_params
                })).data

                if (this.chart) {
                    this.chart.destroy()
                }
                this.chart = new Chart('chart-cardsales', {
                    type: 'bar',
                    data: {
                        labels: this.points.map(p => p.label),
                        datasets: [
                            {
                                type: 'line',
                                label: '승인금액',
                                borderColor: getCssVar('primary'),
                                data: this.points.map(p => this.summary.승인금액.find(s => s[승인금액_params.groupby] == p.value) || {승인금액: 0}).map(e => e.승인금액),
                            },
                            {
                                type: 'bar',
                                label: '지급금액',
                                borderColor: getCssVar('success'),
                                backgroundColor: getCssVar('success'),
                                data: this.points.map(p => this.summary.지급금액.find(s => s[지급금액_params.groupby] == p.value) || {지급금액: 0}).map(e => e.지급금액)
                            },
                        ],
                    },
                    options: {
                        legend: {
                            position: 'top'
                        },
                        scales: {
                            yAxes: [
                                {
                                    display: true,
                                    labelString: '금액',
                                    ticks: {
                                        callback: function (value, index, values) {
                                            return humanizeNumberScale(value)
                                        }
                                    }

                                },
                            ]
                        },
                    }
                })

                this.depositExpected = (await api.get('robotax/카드매출/annotate/', {
                    params: {사업자: this.globals.trader.robotax_id, groupby_fields: ['지급예정일', '카드사명'], sum_fields: '지급금액', 지급예정일__gte: moment().format('YYYY-MM-DD')},
                    paramsSerializer: querystring.stringify
                })).data
            },
            queryParams(groupby, field) {
                let params = {
                    사업자: this.globals.trader.robotax_id,
                    groupby: this.period.unit == 'year' ? groupby + '__month' : groupby,
                    field: field,
                }
                params[groupby + '__gte'] = this.period.begin.format('YYYY-MM-DD')
                params[groupby + '__lte'] = this.period.end.format('YYYY-MM-DD')
                return params
            },
        }
    }
</script>

<style scoped>

</style>