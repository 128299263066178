import moment from "moment"
import mixpanel from "mixpanel-browser";
import Decimal from "decimal.js";
import {constants, globals} from "@/globals";
import {getNestedProperty} from "@/models";


const numberUnits = {
    '억': '100000000',
    '만': '10000'
}


export function humanizeNumberScale(value) {
    for (var unit in numberUnits) {
        if (value > numberUnits[unit]) {
            return `${Math.round(value / numberUnits[unit] * 10) / 10}${unit}`
        }
    }
    return value
}


export function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}


export function range(end) {
    return Array.from(Array(end).keys())
}


let mixpanelEnabled = globals.production;


export function trackPageView(params) {
    gtag('config', GA_APPWEB_ID, params)
    gtag('config', GA_WEB_ID, params)
    if (mixpanelEnabled) {
        mixpanel.track(params.page_title)
    }
}


export function trackEvent(event, params) {
    gtag('event', event, params)
    if (mixpanelEnabled) {
        mixpanel.track(event, params)
    }
}


export function trackUser(identifier, properties) {
    if (mixpanelEnabled) {
        mixpanel.people.set(properties)
        mixpanel.identify(identifier)
    }
}


export const VueCommonUtils = {
    computed: {
        parentRoute: function() {
            return {
                path: this.$route.path.slice(0, this.$route.path.lastIndexOf('/'))
            }
        }
    },
    created() {
        this.constants = constants
    },
    methods: {
        decimal: function(value) {
            return new Decimal(value)
        },
        enumFormat(str) {
            return str.replace('_', '')
        },
        dateFormat: function(date) {
            return moment(date).format('YYYY-MM-DD')
        },
        localDateFormat: function(date, excludeYear) {
            if (excludeYear) {
                return moment(date).format('M월 D일')
            }
            return moment(date).format('YYYY년 M월 D일')
        },
        yearMonthFormat: function(date) {
            return moment(date).format('YYYY년 M월')
        },
        humanizeDate: function(date) {
            return moment(date).fromNow()
        },
        numberFormat: function (value) {
            if (value == undefined) return '0'
            let str = new Decimal(new String(value).replace(',', '')).toDecimalPlaces(0).toString()
            let result = ''
            for (let i = 0; i < str.length; i ++) {
                if (i > 0 && i % 3 == 0) {
                    result = ',' + result
                }
                result = str.slice(str.length - i - 1, str.length - i) + result
            }
            return result.replace('-,', '-')
        },
        moneyFormat: function (value) {
            return this.numberFormat(value) + '원'
        },
        registrationNumberFormat: function (value) {
            if (!value) {
                return ''
            }
            if (value.length == 10) {
                return value.slice(0, 3) + '-' + value.slice(3, 5) + '-' + value.slice(5)
            }
            return value
        },
        choiceLabel: function(model, field, value) {
            if (!this.globals.schema) return value

            let found = this.globals.schema.definitions[model].properties[field].enum.find(e => e.split(': ')[0] == value)
            if (found)
                return found.split(': ')[1]

            return value
        },
        publicURL: function (path) {
            return process.env.BASE_URL + path
        },
        납세자IDFormat: function(number) {
            if (number.length == 10) {
                number = number.split('-').join('');
                return number.slice(0, 3) + '-' + number.slice(3, 5) + '-' + number.slice(5, 11)
            } else if (number.length == 13) {
                number = number.split('-').join('');
                return number.slice(0, 6) + '-' + number.slice(6, 13)
            }
            return number
        },
        certStatus (cert, check_valid=true) {
            if (check_valid && !cert.valid) {
                return {label: 'danger', text: cert.error}
            }
            if (moment().isAfter(moment(cert.not_after), 'day')) {
                return {label: 'danger', text: cert.error}
            }
            if (moment().isAfter(moment(cert.not_after).subtract(1, 'months'), 'day')) {
                return {label: 'warning', text: cert.error}
            }

            return null
        },
        법인명_상호_display(trader) {
            if (trader.폐업일) {
                return `${trader.법인명_상호}(폐업)`
            }
            return trader.법인명_상호
        },
        copyToClipboard (element, message) {
            let range = document.createRange();
            range.selectNode(element);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
            document.execCommand("copy");
            this.$bvToast.toast(message)
        },
        getNestedProperty: getNestedProperty,
        trackPageView: trackPageView,
        trackEvent: trackEvent
    },


}

export function getCssVar(name) {
    return getComputedStyle(document.body).getPropertyValue('--' + name);
}
