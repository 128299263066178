<template>
    <div>
        <div class="card m-3" v-if="globals.trader.사업자구분 != '법인'">
            <div class="card-body">
                <table class="table" v-if="사업자 && 사업자.owner && 종합소득세">
                    <tbody>
                        <tr @click="$router.push({name: '종합소득세 신고서', params: {id: 종합소득세.id, transition: 'push'}})" v-if="종합소득세">
                            <th>{{ 사업자.owner.법인명_상호 }} {{ period.begin.year() }}년 종합소득세</th>
                            <td class="text-right">
                                {{ moneyFormat(종합소득세.records[2].종합소득세_신고기한내납부할세액) }}
                                <i class="fas fa-chevron-right"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-else>
                    <p>개인용 공인인증서도 등록하시면 종합소득세도 실시간으로 확인하고 신고하실 수 있어요.</p>
                    <router-link class="btn btn-outline-primary btn-block" :to="{name: '공인인증서 등록'}">공인인증서 등록하러 가기 <i class="fas fa-chevron-right"></i></router-link>
                </div>
            </div>
        </div>


        <div class="card m-3" v-if="!taggingStatus">
            <div class="card-header">
                손익계산서
            </div>
            <div class="card-body text-center">
                <b-spinner variant="primary"></b-spinner>
            </div>
        </div>
        <div class="card m-3" v-else-if="tags && tagSummary">
            <div class="card-header">
                <strong class="text-underline" v-b-modal.income-statement-help>
                    손익계산서 <i class="fas fa-question-circle text-info"></i>
                </strong>

                <b-modal id="income-statement-help" title="손익계산서란?" ok-only ok-title="닫기">
                    <p>
                        <strong>손익계산서는 내가 번 돈에서 쓴 돈을 빼고 얼마가 남았는지 계산하는 회계보고서입니다.</strong>
                        손익은 크게 다섯 가지 항목으로 분류해서 다음과 같이 계산합니다.
                    </p>
                    <p>
                        <span class="text-primary">손익</span> = <span class="text-success">매출액</span>
                        + <span class="text-success">영업외수익</span>
                        - <span class="text-danger">매출원가</span>
                        - <span class="text-danger">판매비와관리비</span>
                        - <span class="text-danger">영업외비용</span>
                    </p>
                    <p>
                        키퍼는 적격증빙이 있는 거래 전부, 그리고 일부 은행입출금 거래를 인공지능으로 자동분류해서 태그를 붙여드립니다.
                        그래서 대략적인 손익을 파악할 수 있고, 고객님이 직접 태그를 더 붙이거나 조정하시면 더 정확한 손익을 알 수 있습니다.
                    </p>
                    <p>
                        만약 어떤 거래에 어떤 태그를 붙여야 할지 어려우시다면 키퍼의
                        <router-link :to="{name: '정식버전 결제'}"><strong>장부정리 도우미</strong> (월 5,500원)</router-link>를 이용하실 수 있습니다.
                        전산회계 전문가들이 직접 분류해서 태그를 붙여드리기 때문에 손익을 정확하게 알 수 있습니다.
                    </p>
                    <p>
                        장부정리 뿐 아니라 모든 회계 업무를 대행하는 <router-link :to="{name: '경리대행 안내'}"><strong>경리대행</strong> (월 10만원부터)</router-link> 서비스도 알아보세요.
                    </p>
                </b-modal>

                <a href="#untagged" class="float-right text-underline text-secondary">
                    <span class="text-danger" v-if="taggingStatus.untagged.매출 || taggingStatus.untagged.매입">
                        미분류 {{
                            ((taggingStatus.untagged.매출 || {}).id__count || 0) + ((taggingStatus.untagged.매입 || {}).id__count || 0)
                        }}건
                    </span>
                    <span class="text-warning"
                          v-if="taggingStatus.unconfirmed.매출 || taggingStatus.unconfirmed.매입 || taggingStatus.unconfirmed.입금 || taggingStatus.unconfirmed.출금">
                          미확인 {{
                              ((taggingStatus.unconfirmed.매출 || {}).id__count || 0) + ((taggingStatus.unconfirmed.매입 || {}).id__count || 0) + ((taggingStatus.unconfirmed.입금 || {}).id__count || 0) + ((taggingStatus.unconfirmed.출금 || {}).id__count || 0)
                          }}건
                    </span>
                    <i class="fas fa-arrow-down text-secondary"></i>
                </a>
            </div>
            <div class="card-body px-0">
                <table class="table w-100" style="table-layout: fixed">
                    <thead>
                        <tr>
                            <th colspan="2">장부상 손익</th>
                            <th class="text-right pr-4 d-flex justify-content-end align-items-center">{{ moneyFormat(tagSum(['매출액', '영업외수익']) - tagSum(['매출원가', '판매비와관리비', '영업외비용'])) }}</th>
                        </tr>
                        <tr>
                            <th colspan="2">매출 총이익 <small>(매출액 - 매출원가)</small></th>
                            <th class="text-right pr-4 d-flex justify-content-end align-items-center">{{ moneyFormat(tagSum(['매출액']) - tagSum(['매출원가'])) }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(data, tag) in tags">
                            <tr v-if="data.id__count && globals.TAG_CATEGORIES.손익과원가.tags.indexOf(tag.split('/')[0]) >= 0"
                                :key="tag"
                                @click="$router.push({...$route.query, name: '손익거래', query: {tag__path__contains: tag, 거래일__gte: dateFormat(period.begin), 거래일__lte: dateFormat(period.end)}})">
                                <component :is="globals.TAG_COLORS[tag] ? 'th' : 'td'" :class="'pl-' + (tag.split('/').length + 2)">{{ data.name }}</component>
                                <td :is="globals.TAG_COLORS[tag] ? 'th' : 'td'" class="text-right">{{ data.id__count }}건</td>
                                <td :is="globals.TAG_COLORS[tag] ? 'th' : 'td'" class="text-right d-flex justify-content-end align-items-center">
                                    {{ moneyFormat(data.공급가액__sum) }}
                                    <i class="fas fa-chevron-right text-muted"></i>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>

            <div class="card-header border-top" id="untagged">
                증빙종류별 미분류, 미확인 내역
<!--                <tag-guide class="m-3" :should-tag="taggingStatus.untagged.매출 || taggingStatus.untagged.매입"></tag-guide>-->
            </div>

            <div class="card-body px-0" v-if="taggingStatus">
                <div v-for="(거래종류_set, 거래종류) in {매출매입: ['매출', '매입'], 은행입출금: ['입금', '출금']}" :key="거래종류">
                    <table class="table mb-2">
                        <thead>
                        <tr>
                            <th colspan="3">
                                {{ 거래종류 }}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="key in 거래종류_set">
                            <tr v-if="taggingStatus.all[key]" :key="key"
                                @click="$router.push({name: '손익거래', query: {[거래종류]: key, 거래일__gte: dateFormat(period.begin), 거래일__lte: dateFormat(period.end)}})">
                                <th>{{ taggingStatus.all[key].매출매입 || taggingStatus.all[key].은행입출금 }}</th>
                                <th class="text-right">
                                    <i class="fas fa-check text-success"
                                       v-if="!taggingStatus.untagged[key] && !taggingStatus.unconfirmed[key]"></i>
                                    {{ taggingStatus.all[key].id__count }}건
                                </th>
                                <th class="text-right">{{ moneyFormat(taggingStatus.all[key].공급가액__sum) }}
                                    <i class="fas fa-chevron-right text-secondary"></i></th>
                            </tr>
                            <tr v-else :key="key + '-none'">
                                <td colspan="3" class="text-secondary">미확인 또는 미분류 {{key}}이 없습니다.</td>
                            </tr>
                            <tr :class="거래종류 == '매출매입' ? 'text-danger' : 'text-warning'"
                                v-if="taggingStatus.untagged[key]" :key="key + '-untagged'"
                                @click="$router.push({name: '손익거래', query: {push: true, [거래종류]: key, 거래일__gte: dateFormat(period.begin), 거래일__lte: dateFormat(period.end), tag__isnull: true}})">
                                <td>&nbsp;-&nbsp; 미분류</td>
                                <td class="text-right">{{ taggingStatus.untagged[key].id__count }}건</td>
                                <td class="text-right">{{ moneyFormat(taggingStatus.untagged[key].공급가액__sum) }}
                                    <i class="fas fa-chevron-right text-secondary"></i></td>
                            </tr>
                            <tr class="text-warning" v-if="taggingStatus.unconfirmed[key]" :key="key + '-unconfirmed'"
                                @click="$router.push({name: '손익거래', query: {push: true, [거래종류]: key, 거래일__gte: dateFormat(period.begin), 거래일__lte: dateFormat(period.end), tag__confirmed: false}})">
                                <td>&nbsp;-&nbsp; 미확인</td>
                                <td class="text-right">{{ taggingStatus.unconfirmed[key].id__count }}건</td>
                                <td class="text-right">{{ moneyFormat(taggingStatus.unconfirmed[key].공급가액__sum) }}
                                    <i class="fas fa-chevron-right text-secondary"></i></td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="alert alert-light m-3">
            <p>
                손익계산서 분류가 어려우신가요?
                <br>
                <router-link :to="{name: '정식버전 결제'}"><strong>장부정리 도우미</strong> (월 5,500원)</router-link>를 이용해보세요.
            </p>
            <p>
                회계 업무 전체를 맡기고 싶으신가요?<br>
                <router-link :to="{name: '경리대행 안내'}"><strong>경리대행</strong> (월 10만원부터)</router-link>을 이용해보세요.
            </p>
        </div>

        <div class="card m-3" v-if="소득구분별_사업소득명세_set.length">
            <div class="card-header">
                종합소득세 신고유형에 따른 소득 비교
            </div>
            <div class="card-body">
                <table class="table" v-for="사업소득명세 in 소득구분별_사업소득명세_set" :key="사업소득명세.소득구분코드">
                    <thead>
                        <tr>
                            <th colspan="2">
                                {{ globals.trader.법인명_상호 }}의 <span class="text-underline text-wrap">{{ globals.소득구분코드[사업소득명세.소득구분코드].slice(3) }}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>간편장부</th>
                            <td class="text-right">
                                {{ moneyFormat(사업소득명세.data.간편장부소득금액계산서.당해연도소득금액) }}
                            </td>
                        </tr>
                        <tr>
                            <th>단순경비율</th>
                            <td class="text-right">
                                {{ moneyFormat(사업소득명세.data.단순경비율_추계소득금액계산서.금액_단순경비율) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <span class="text-underline text-primary" v-b-modal.종합소득세-신고유형-help>
                    <i class="fas fa-info-circle"></i> 종합소득세는 소득에 따라 세율과 세금이 정해지므로 소득이 클수록 세금도 많이 나옵니다. <strong>단순경비율</strong>로 계산한 소득과 <strong>간편장부 손익계산서</strong>의 소득 차이가 크다면 필요경비에 태그를 더 붙여보세요.
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {api} from "@/util/api";
    import querystring from "querystring";
    import {loadTags} from "@/models";
    import IncomeTaxMixin from "@/util/IncomeTaxMixin";
    import TagGuide from "@/components/TagGuide";

    export default {
        name: "IncomeStatement",
        components: {TagGuide},
        props: ['period'],
        mixins: [IncomeTaxMixin],
        data() {
            return {
                사업자: null,
                종합소득세: null,
                소득구분별_사업소득명세_set: [],
                taggingStatus: null,
                tagSummary: [],
                tags: null,
            }
        },
        computed: {
        },
        watch: {
        },
        async mounted() {
            // this.사업소득명세 = (await this.globals.사업소득명세.get(this.id + '/')).object
            await this.reloadSummary()
            this.$watch('period', this.reloadSummary)
        },
        methods: {
            async reloadSummary() {
                this.tags = await loadTags()
                this.tagSummary = (await api.get('robotax/전표/annotate/', {
                    params: {
                        사업자: this.globals.trader.robotax_id,
                        거래일__gte: this.dateFormat(this.period.begin),
                        거래일__lte: this.dateFormat(this.period.end),
                        groupby_fields: ['tag__path'],
                        count_fields: ['id'],
                        sum_fields: ['공급가액'],
                        ordering: 'tag__path'
                    },
                    paramsSerializer: querystring.stringify
                })).data

                this.tagSummary.forEach(item => {
                    if (!item.tag__path) {
                        return
                    }
                    let parts = item.tag__path.split('/')
                    for (let i = 0; i < parts.length; i++) {
                        let tagData = this.tags[parts.slice(0, i + 1).join('/')]
                        tagData.id__count = (tagData.id__count || 0) + item.id__count
                        tagData.공급가액__sum = (tagData.공급가액__sum || 0) + item.공급가액__sum
                    }
                })

                this.소득구분별_사업소득명세_set = (await api.get('robotax/사업소득명세/소득구분별/', {
                    params: {
                        사업자: this.globals.trader.robotax_id,
                        과세기간시작일__year: this.period.begin.year(),
                    }
                })).data

                this.사업자 = await this.globals.사업자.getObject(this.globals.trader.robotax_id)
                if (this.사업자.owner) {
                    this.종합소득세 = (await this.globals.종합소득세.get(`now/?사업자=${this.사업자.owner.robotax_id}&today=${this.dateFormat(this.period.begin)}`)).object
                }

                let 전표Summary = (await api.get('robotax/전표/annotate/', {
                    params: {
                        사업자: this.globals.trader.robotax_id,
                        거래일__gte: this.dateFormat(this.period.begin),
                        거래일__lte: this.dateFormat(this.period.end),
                        groupby_fields: ['매출매입', '은행입출금'],
                        count_fields: ['id'],
                        sum_fields: ['공급가액'],
                        max_fields: ['거래일'],
                    },
                    paramsSerializer: querystring.stringify
                })).data
                let untagged전표Summary = (await api.get('robotax/전표/annotate/', {
                    params: {
                        사업자: this.globals.trader.robotax_id,
                        거래일__gte: this.dateFormat(this.period.begin),
                        거래일__lte: this.dateFormat(this.period.end),
                        tag__isnull: true,
                        groupby_fields: ['매출매입', '은행입출금'],
                        count_fields: ['id'],
                        sum_fields: ['공급가액'],
                        max_fields: ['거래일'],
                    },
                    paramsSerializer: querystring.stringify
                })).data

                let unconfirmed전표Summary = (await api.get('robotax/전표/annotate/', {
                    params: {
                        사업자: this.globals.trader.robotax_id,
                        거래일__gte: this.dateFormat(this.period.begin),
                        거래일__lte: this.dateFormat(this.period.end),
                        tag__isnull: false,
                        tag__confirmed: false,
                        groupby_fields: ['매출매입', '은행입출금'],
                        count_fields: ['id'],
                        sum_fields: ['공급가액'],
                        max_fields: ['거래일'],
                    },
                    paramsSerializer: querystring.stringify
                })).data

                this.taggingStatus = {all: {}, untagged: {}, unconfirmed: {}}
                전표Summary.forEach(s => {
                    this.taggingStatus.all[s.매출매입 || s.은행입출금] = s
                })
                untagged전표Summary.forEach(s => {
                    this.taggingStatus.untagged[s.매출매입 || s.은행입출금] = s
                })
                unconfirmed전표Summary.forEach(s => {
                    this.taggingStatus.unconfirmed[s.매출매입 || s.은행입출금] = s
                })
            },
            async change신고유형(신고유형) {
                Object.assign(this.사업소득명세, (await api.patch(`robotax/사업소득명세/${this.id}/`, {신고유형: 신고유형})).data)
            },
            tagSum(tags) {
                return tags.reduce((acc, value) => acc + (this.tags[value].공급가액__sum || 0), 0)
            }
        }
    }
</script>
