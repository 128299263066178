<template>
    <div>
        <nav class="navbar navbar-light titlebar pt-0">
            <span></span>
            <h5 class="mt-2">주간보고 - {{ weekLabel }}</h5>
            <span></span>
        </nav>

        <div v-if="report">
            <h5 class="text-white mx-4">{{ weekRange }}</h5>
            <div class="card mx-3">
                <div class="card-body">
                    <table class="table">
                        <tbody>
                            <tr v-for="record in sum({매출매입: '매출'})" :key="record.매입매출">
                                <th>매출</th>
                                <td>{{ record.count }}건</td>
                                <td class="text-right">{{ moneyFormat(record.공급가액 + record.부가세) }}</td>
                            </tr>
                            <tr v-for="record in report.filter(r => r.매출매입 == '매출')" :key="record.증빙종류 + record.부가세유형">
                                <td class="pl-4">{{ record.증빙종류 }} {{ record.부가세유형 }}</td>
                                <td>{{ record.count }}건</td>
                                <td class="text-right">{{ moneyFormat(record.공급가액 + record.부가세) }}</td>
                            </tr>
                            <tr v-for="record in sum({매출매입: '매입'})" :key="record.매입매출">
                                <th>매입</th>
                                <td>{{ record.count }}건</td>
                                <td class="text-right">{{ moneyFormat(record.공급가액 + record.부가세) }}</td>
                            </tr>
                            <tr v-for="record in report.filter(r => r.매출매입 == '매입')" :key="record.증빙종류 + record.부가세유형">
                                <td class="pl-4">{{ record.증빙종류 }} {{ record.부가세유형 }}</td>
                                <td>{{ record.count }}건</td>
                                <td class="text-right">{{ moneyFormat(record.공급가액 + record.부가세) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card mx-3 mt-3">
                <div class="card-body">
                    <table class="table">
                        <tbody>
                            <tr v-for="record in sum({은행입출금: '입금'})" :key="record">
                                <th>은행 입금</th>
                                <td>{{ record.count }}건</td>
                                <td class="text-right">{{ moneyFormat(record.공급가액 + record.부가세) }}</td>
                            </tr>
                            <tr v-for="record in sum({은행입출금: '출금'})" :key="record">
                                <th>은행 출금</th>
                                <td>{{ record.count }}건</td>
                                <td class="text-right">{{ moneyFormat(record.공급가액 + record.부가세) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {api} from "@/util/api";
    import moment from "moment";

    export default {
        name: "WeeklyReport",
        data() {
            return {
                report: null
            }
        },
        computed: {
            weekLabel() {
                return moment().year() + '년 ' + moment().week() + '주'
            },
            weekRange() {
                return this.localDateFormat(moment().weekday(0)) + '(월) ~ ' + this.localDateFormat(moment().weekday(6))
            }
        },
        async mounted() {
            this.report = (await api.get('report/', {
                params: {사업자: this.globals.trader.robotax_id}
            })).data
        },
        methods: {
            sum(filters) {
                let result = {
                    count: 0,
                    공급가액: 0,
                    부가세: 0
                }
                for (let record of this.report) {
                    if (Object.keys(filters).every(key => record[key] == filters[key])) {
                        result.공급가액 += record.공급가액
                        result.부가세 += record.부가세
                        result.count += record.count
                    }
                }
                return [result]
            }
        }
    }
</script>

<style scoped>

</style>