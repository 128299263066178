<template>
    <component :is="tag" class="text-center pr-0">
        <i class="fas fa-minus text-secondary" v-if="!task || !task.status"></i>
        <b-spinner class="mb-1 mr-1" small variant="light" v-else-if="task.status == 'running' || task.status == 'ready'"></b-spinner>
        <span v-else-if="statuses[task.status]">
            <i class="fas" :class="`${statuses[task.status].icon} text-${statuses[task.status].color}`"></i>
        </span>

        <span class="ml-1" v-if="task && task.end && task.updated">{{ humanizeDate(task.end) }}</span>
        <template v-if="detail && task && ['invalid', 'pending', 'error'].indexOf(task.status) >= 0">
            <br>
            <span :title="task.error" :class="'text-' + statuses[task.status].color">
                <span class="ml-1 text-danger">
                    <span v-if="task.error.length > 40">
                        자료수집 중에 오류가 발생했습니다. 잠시 후에 다시 확인해보세요.
                    </span>
                    <span v-else>
                        {{ task.error }}
                    </span>
                </span>
            </span>
        </template>
    </component>
</template>
<script>
    export default {
        name: 'StatusCell',
        props: {
            tag: {default: 'td'},
            task: {},
            account: {},
            detail: {type: Boolean, default: false}
        },
        data() {
            return {
                statuses: {
                    success: {color: 'success', icon: 'fa-check'},
                    error: {color: 'danger', icon: 'fa-exclamation-triangle'},
                    invalid: {color: 'danger', icon: 'fa-times-circle'},
                    pending: {color: 'warning', icon: 'fa-pause-circle'},
                }
            }
        }
    }
</script>
