<template>
    <div class="h-100 p-4 d-flex flex-column justify-content-between align-items-center">
        <div></div>
        <template v-if="addMode">
            <h4 class="text-white text-center">사업자 추가</h4>
            <div class="card">
                <div class="card-body">
                    추가하려는 사업자의 홈택스 계정을 연동해주세요.<br>
                    개인계정으로 연동하실 경우 대표자님의 사업장이 한 번에 모두 연동됩니다.
                </div>
            </div>
        </template>
        <template v-else>
            <h4 class="text-white text-center">안녕하세요</h4>
            <div class="card">
                <div class="card-body">
                    <span class="text-primary">인공지능 세금계산기 키퍼</span>에 오신 것을 환영합니다.
                    홈택스 계정연동을 하시면 아래 서비스를 이용하실 수 있습니다.
                    <ul class="mt-2">
                        <li>거래내역</li>
                        <li>세금 내역, 예상세금 조회</li>
                        <li>세금신고</li>
                        <li>세무회계 프리미엄 서비스</li>
                    </ul>
                </div>
            </div>
        </template>
        <div class="w-100 d-flex flex-column">
            <input class="invisible" type="file" ref="file" multiple @change="certFiles = $refs.file.files" />
            <b-button variant="outline-primary" v-b-modal.hometax-id>홈택스 ID로 연동</b-button>
            <b-button class="mt-2" variant="outline-primary" v-b-modal.hometax-cert>공인인증서로 연동</b-button>
            <b-button class="mt-2" variant="outline-primary" @click="goToNextRoute" v-if="addMode">취소</b-button>
        </div>
        <div></div>

        <b-modal id="hometax-id" title="홈택스 ID 연동" centered ok-title="연결하기" cancel-title="닫기" @ok="connectWithCredentials">
            <label class="text-primary">홈택스 ID</label>
            <b-form-input v-model="credentials.username"></b-form-input>
            <label class="text-primary">홈택스 비밀번호</label>
            <b-form-input type="password" v-model="credentials.password"></b-form-input>
        </b-modal>

        <cert-manager @success="setupTraderAndFinish"/>

        <b-modal id="traders-info" title="사업자 추가" centered :ok-only="newTraders.length > 0" cancel-title="다시 시도" ok-title="확인" @ok="goToNextRoute">
            <div v-if="newTraders.length">
                <div>아래 사업자가 새로 연결되었습니다.</div>
                <b-list-group>
                    <b-list-group-item v-for="trader in newTraders" :key="trader.id">
                        {{ trader.법인명_상호 }} ({{납세자IDFormat(trader.사업자등록번호)}})
                    </b-list-group-item>
                </b-list-group>
            </div>
            <div v-else>
                새로 추가된 사업자가 없습니다.
            </div>
        </b-modal>
    </div>
</template>

<script>
    import api from "@/util/api";
    import {saveGlobal} from "@/globals";
    import CertManager from "@/components/CertManager";

    export default {
        name: "Hometax",
        components: {CertManager},
        data: () => ({
            credentials: {
                username: '',
                password: '',
            },
            prevTraders: [],
            nextRoute: {name: '현황판'},
        }),
        computed: {
            addMode () {
                return this.$route.query.mode === 'add'
            },
            newTraders () {
                if (!this.globals.user) { return [] }
                return this.globals.user.사업자_set.filter(trader => !this.prevTraders.some(t => t.id === trader.id))
            },
        },
        mounted () {
            if (this.addMode) {
                this.nextRoute = {name: this.$route.query.next}
                return
            }

            if (!this.globals.user || !this.globals.user.사업자_set.length) {
                saveGlobal('trader', null)
                return
            }

            if (!this.globals.trader || !this.globals.user.사업자_set.some(trader => this.globals.trader.id === trader.id)) {
                saveGlobal('trader', this.globals.user.사업자_set[0])
            }

            this.goToNextRoute()
        },
        methods: {
            connectWithCredentials () {
                this.prevTraders = this.globals.user ? this.globals.user.사업자_set : []
                this.globals.loadingMessage = '홈택스에 로그인하고 있습니다...'
                api.post('user/connect/', this.credentials).then(res => {
                    saveGlobal('user', res.data)
                    if (this.addMode) {
                        this.$bvModal.show('traders-info')
                    } else {
                        saveGlobal('trader', this.globals.user.사업자_set[0])
                        this.goToNextRoute()
                    }
                })
            },
            goToNextRoute () {
                this.$router.push(this.nextRoute)
            },
            setupTraderAndFinish () {
                if (!this.globals.trader) {
                    saveGlobal('trader', this.globals.user.사업자_set[0])
                    this.goToNextRoute()
                }
            }
        }
    }
</script>

<style scoped>

</style>