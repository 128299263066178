<template>
    <div class="card">
        <div class="card-body">
            <table class="table">
                <tbody>
                    <slot></slot>
                    <tr v-if="!scrapingOnly">
                        <th>
                            <span v-if="useCert || account && account.공인인증서">공동인증서</span>
                            <span v-else>계정</span>
                        </th>
                        <td class="px-0" style="word-break: break-all">
                            <status-cell tag="span" :task="account"></status-cell>
                            <span v-if="!account">
                                미등록
                            </span>
                            <span v-else>
                                <span v-if="useCert || account && account.공인인증서">
                                    {{ account.공인인증서 ? account.공인인증서.subject : '미확인' }}
                                </span>
                                <template v-else>
                                    {{ account.username || account.type }}
                                </template>
                                <div v-if="!account.valid">
                                    {{ account.error }}
                                </div>
                            </span>
                        </td>
                        <td>
                            <router-link :to="{name: '스크래핑 계정 등록', params: {transition: 'push', id: account && account.id}, query: {type: source}}"
                                         class="btn btn-outline-primary btn-sm float-right"
                                         title="설정"
                                         v-if="!(useCert || account && account.공인인증서)">
                                <i class="fas fa-wrench"></i> <span class="d-none d-sm-inline">설정</span>
                            </router-link>
                        </td>
                    </tr>
                    <tr>
                        <th>자료수집</th>
                        <td class="px-0">
                            <status-cell tag="span" :task="account ? globals.scraping.find(s => s.params.account == account.id) : scrapingSources[source]" detail></status-cell>
                        </td>
                        <td>
                            <!-- TODO 선택된 account 하나만 리프레시할 수 있도록. -->
                            <refresh-scraping-button class="float-right" :source="source" v-if="account || scrapingSources[source]"/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
    import StatusCell from "@/views/connect/StatusCell"
    import AccountMixin from "@/util/AccountMixin";
    import RefreshScrapingButton from "@/views/connect/RefreshScrapingButton";

    export default {
        name: 'ScrapingAccountPanel',
        mixins: [AccountMixin],
        components: {RefreshScrapingButton, StatusCell},
        props: {
            source: {},
            account: {},
            useCert: {type: Boolean, default: false},
            scrapingOnly: {type: Boolean, default: false}
        }
    }
</script>
