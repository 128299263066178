<template>
    <div>
        <nav class="navbar navbar-light titlebar">
            <back-button></back-button>
            <h5 class="">
                공동인증서 관리
            </h5>
            <span></span>
        </nav>
        <div class="card mx-3">
            <div class="card-header">
                <div class="btn-group btn-group-sm">
                    <button class="btn btn-sm btn-outline-secondary" :class="{active: certFilter == 'trader'}"
                            @click="certFilter = 'trader'">현재 사업자의 인증서
                    </button>
                    <button class="btn btn-sm btn-outline-secondary" :class="{active: certFilter == 'all'}"
                            @click="certFilter = 'all'">내가 올린 인증서
                    </button>
                </div>
            </div>
            <div class="card-body">
                <table class="table" style="table-layout: fixed">
                    <tbody>
                        <tr v-for="cert in certFilter == 'trader' ? traderCerts : certs" :key="cert.id"
                            @click="$router.push({name: '공인인증서 상세', params: {id: cert.id}})">
                            <td class="d-flex justify-content-between align-items-center">
                                <div class="flex-grow-1" style="max-width: 90%">
                                    <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                                        <span class="badge badge-secondary"
                                              v-if="cert.subject_type">{{ cert.subject_type }}</span>
                                        {{ cert.subject }}
                                    </div>
                                    <div class="text-muted">
                                        <span><i class="ml-1 fas fa-exclamation-circle"
                                                 :class="`text-${certStatus(cert).label}`"
                                                 v-if="certStatus(cert)"></i></span>
                                        만료일 : {{ cert.not_after }}
                                        <template v-if="certStatus(cert)">
                                            <div :class="`text-${certStatus(cert).label}`" v-if="certStatus(cert).text">
                                                <small>{{ certStatus(cert).text }}</small></div>
                                            <div class="text-warning" style="font-size: 0.5rem"
                                                 v-else-if="certStatus(cert).label === 'warning'">공인인증서 만료일이 1개월 이내입니다.
                                                금융기관에서 재발급 후 다시 공인인증서를 추가해주세요.
                                            </div>
                                            <div class="text-danger" style="font-size: 0.5rem"
                                                 v-else-if="certStatus(cert).label === 'danger'">공인인증서가 만료되었습니다. 금융기관에서 재발급
                                                후 다시 공인인증서를 추가해주세요.
                                            </div>
                                        </template>
                                    </div>
                                    <div class="">
                                        <span class="mr-1">홈택스 사업자 <strong class="text-primary">{{ cert.사업자_set.length }}</strong>개</span>
                                        <span v-if="cert.bank_set.filter(b => !b.status).length">/ 은행 {{ cert.bank_set.filter(b => !b.status).length }}개</span>
                                    </div>
                                    <div v-if="certFilter == 'trader' && cert.owner">
                                        <span class="text-muted">소유자:</span> <social-login-info :user="cert.owner" show-email></social-login-info>
                                    </div>
                                </div>
                                <i class="fas fa-chevron-right"></i>
                            </td>
                        </tr>
                        <template v-if="certFilter == 'trader' && globals.trader && globals.trader.사업자구분 == '개인사업자'">
                            <tr v-if="certs.filter(c => c.subject_type == '개인').length == 0">
                                <td class="text-warning">
                                    <i class="fas fa-info-circle"></i> <strong>개인용</strong> 공인인증서도 등록하시면
                                    <strong>종합소득세</strong> 계산과 신고가 가능합니다.
                                </td>
                            </tr>
                            <tr v-else-if="certs.filter(c => c.subject_type == '개인사업자').length == 0">
                                <td class="text-warning">
                                    <i class="fas fa-info-circle"></i> <strong>사업자용</strong> 공인인증서도 등록하시면 <strong>사업용 은행계좌
                                    조회</strong>가 가능합니다.
                                </td>
                            </tr>
                        </template>
                        <tr>
                            <td class="text-center">
                                <router-link :to="{name: '공인인증서 등록'}" class="btn btn-link text-primary text-center"> + 공인인증서
                                    추가
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
    import api from "@/util/api";
    import querystring from "querystring";
    import BackButton from "@/components/BackButton";
    import SocialLoginInfo from "@/components/SocialLoginInfo";

    export default {
        name: 'CertList',
        components: {SocialLoginInfo, BackButton},
        props: {
        },
        data() {
            return {
                certs: [],
                traderCerts: [],
                certFilter: 'trader',
            }
        },
        computed: {
        },
        async mounted() {
            await this.reloadCert()
        },
        methods: {
            async reloadCert() {
                this.certs = (await api.get('/robotax/공인인증서/', {
                    params: {client_key: this.globals.user.id},
                    paramsSerializer: querystring.stringify
                })).data.results

                if (this.globals.trader) {
                    this.traderCerts = (await api.get('/robotax/공인인증서/', {
                        params: {사업자_set: this.globals.trader.robotax_id},
                        paramsSerializer: querystring.stringify
                    })).data.results
                } else {
                    this.traderCerts = []
                }

                for (let cert of this.traderCerts) {
                    this.$set(cert, 'owner', (await api.get(`user/${cert.client_key}/`)).data)
                }

            },
        }
    }
</script>
