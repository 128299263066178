<template>
    <div class="overflow-auto" @scroll="loadMore($event)">
        <p class="alert alert-light rounded-0 mb-0">
            국세청에 체납한 세금 내역입니다. 체납된 국세는 <strong>인터넷 뱅킹의 국세 계좌</strong>를 이용하면 손쉽게 납부할 수 있습니다.
        </p>
        <table class="table bg-white">
            <tbody>
                <tr class="text-center text-white" v-if="!docPaginator || docPaginator.loading || globals.transactions.syncing">

                    <td>
                        <b-spinner variant="light"></b-spinner>
                        <p>불러오는 중...</p>
                    </td>
                </tr>
                <tr class="" v-else-if="docPaginator.objects.length == 0">
                    <td>체납내역이 없습니다.</td>
                </tr>
                <router-link tag="tr"
                             :to="{name: '체납내역 납부안내', query: {id: doc.id, title: doc.title, accountNo: doc.data.전자납부번호, amount: doc.amount, type: '국세'}}"
                             v-for="doc in docPaginator.objects"
                             active-class="table-active"
                             :key="doc.id"
                             v-else>
                    <td>
                        <strong>{{ doc.title }}</strong>
                        <div class="text-muted">{{ localDateFormat(doc.issued) }}</div>
                        <div><strong>전자납부번호:</strong> {{ doc.data.전자납부번호 }}</div>
                    </td>
                    <td class="text-right">
                        <span class="text-danger">{{ moneyFormat(doc.amount) }}</span>
                        <i class="fas fa-chevron-right text-muted"></i>
                    </td>
                </router-link>
            </tbody>
        </table>
    </div>
</template>

<script>
    import Paginator from "@/util/paginator";
    import api from "@/util/api";
    import LoginRequired from "@/components/LoginRequired";
    import AppUpdateMixin from "@/components/AppUpdateMixin";

    export default {
        name: "HometaxNonpayments",
        mixins: [LoginRequired, AppUpdateMixin],
        data: function() {
            return {
                docPaginator: null,
            }
        },
        mounted() {
            this.reload()
        },
        methods: {
            async reload() {
                if (!this.globals.trader) {
                    return
                }

                this.docPaginator = new Paginator(api.get, 'robotax/hometaxdocument/?page_size=10&source=체납내역&status__isnull=true&사업자=' + this.globals.trader.robotax_id)
                this.docPaginator.loadNext()
            },
            loadMore: function(event) {
                if (!this.globals.trader) { return }

                if (!this.docPaginator.canLoadNext()) {
                    return
                }
                if (event.target.scrollTop + event.target.clientHeight + 100 > event.target.scrollHeight) {
                    this.docPaginator.loadNext()
                }
            }
        }
    }
</script>

<style scoped>

</style>
