<template>
    <div>
        <div class="my-2" v-if="recentUsedTags.length">
            <div class="mb-2">최근 사용한 태그 <b-button size="sm" variant="outline-primary" @click="globals.recentUsedTags = []">비우기</b-button></div>
            <transition-group enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__fadeOut">
                <b-button :variant="'outline-' + (globals.TAG_COLORS[tagPath.split('/')[0]] || 'secondary')"
                          class="mr-1 mb-1 border-dashed"
                          size="sm"
                          v-for="tagPath in recentUsedTags"
                          :key="tagPath"
                          @click="selectTag(tagPath)">
                    <b-badge :variant="(globals.TAG_COLORS[tagPath.split('/')[0]] || 'secondary')">{{ tagPath.split('/').shift() }}</b-badge> {{ tagPath.split('/').pop() }}
                </b-button>
            </transition-group>
        </div>
        <button class="btn btn-sm btn-outline-secondary mr-1"
                @click="showTagSelector({branches: null, expandedTags: null})">
            태그
            <i class="fas fa-chevron-up" v-if="tagSelectorVisible"></i>
            <i class="fas fa-chevron-down" v-else></i>
        </button>

        <transition-group enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__fadeOut">
            <tag-button :tag="tag"
                        :recommendation="transaction.recommended_tags[tag.path]"
                        v-for="tag in transaction.tag_set"
                        :key="tag.id"
                        @select="selectTag"
                        @untag="untag(tag)"
                        @remove-recommend="removeRecommend(tag)"
            ></tag-button>
        </transition-group>
        <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__fadeOut">
            <button class="btn btn-sm btn-secondary"
                    v-if="transaction.tag_set.length == 0"
                    @click="recommendTags">태그 추천</button>
        </transition>

        <b-collapse v-model="tagSelectorVisible">
            <tag-selector class="mt-2" style="background-color: #f0f0f0"
                          :excludes="constants.TAG_EXCLUDES[transaction.매출매입] || constants.TAG_EXCLUDES[transaction.은행입출금]"
                          @select="selectTag"
                          />
        </b-collapse>
    </div>
</template>
<script>
    import TagSelector from "@/views/transaction/TagSelector.vue"
    import api from "@/util/api";
    import TagButton from "@/views/transaction/TagButton";

    export default {
        name: 'TransactionTagging',
        components: {TagButton, TagSelector},
        props: {
            transaction: {},
        },
        data() {
            return {
                tagSelectorOptions: {
                    branches: [],
                    expandedTags: [],
                },
                tagSelectorVisible: false,
            }
        },
        computed: {
            recommended() {
                return Object.keys(this.transaction.recommended_tags).length > 0 && !this.transaction.recommended_tags.미분류
            },
            recentUsedTags() {
                let excludes = this.constants.TAG_EXCLUDES[this.transaction.매출매입] || this.constants.TAG_EXCLUDES[this.transaction.은행입출금]
                return this.globals.recentUsedTags.filter(t => {
                    return !this.transaction.tag_set.map(t => t.path).includes(t) && !excludes.some(e => t.startsWith(e))
                })
            }
        },
        mounted() {
            this.tagSelectorVisible = !this.recommended
        },
        methods: {
            async selectTag(tagPath) {
                Object.assign(this.transaction, (await api.post(`robotax/전표/${this.transaction.id}/tag/`, {path: tagPath})).data)
                this.$emit('tag', tagPath)
                if (!this.globals.recentUsedTags.includes(tagPath)) {
                    this.globals.recentUsedTags.push(tagPath)
                }
            },
            async untag(tag) {
                Object.assign(this.transaction, (await api.delete(`robotax/전표/${this.transaction.id}/remove_tag/`, {data: {path: tag.path}})).data)
                this.$emit('untag', tag)
            },
            async removeRecommend(tag) {
                Object.assign(this.transaction, (await api.delete(`robotax/전표/${this.transaction.id}/remove_tag/`, {data: {path: tag.path, auto_recommend: false}})).data)
                this.$emit('remove-recommend', tag)
            },
            showTagSelector(data) {
                this.tagSelectorOptions.branches = data.branches
                this.tagSelectorOptions.expandedTags = data.expandedTags
                this.tagSelectorVisible = !this.tagSelectorVisible
            },
            async recommendTags() {
                Object.assign(this.transaction, (await api.post(`robotax/전표/${this.transaction.id}/recommend_tags/`)).data)
                if (this.transaction.tag_set.length == 0) {
                    this.$bvToast.toast('추천된 태그가 없습니다.', {'variant': 'warning'})
                }
            }
        }
    }
</script>
