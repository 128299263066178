<template>
    <vue100vh :css="{height: '100rvh'}">
    <version-checker></version-checker>
    <div id="app" class="h-100 d-flex flex-column bg-primary">
        <nav class="navbar navbar-expand navbar-light bg-white pt-0 pb-0 w-100 border-top border-bottom border-primary d-none d-md-flex shadow" style="order: 0; font-size: 16px; z-index: 2" v-if="showTabs">
            <router-link class="navbar-brand" :to="{name: '현황판'}">
                <img :src="publicURL('img/icons/favicon.svg')" style="width: 32px">
                <span class="ml-1 d-none d-lg-inline">키퍼</span>
            </router-link>
            <div class="navbar-collapse">
            <status-bar class="mx-0"
                        toggle-variant="outline-secondary"
                        text-class="text-secondary"
                        v-if="!globals.smallScreen"></status-bar>

            <ul class="navbar-nav ml-auto">
                <li class="nav-item pl-1"
                    :id="route.name + '-upper'"
                    v-for="route in mainRoutes"
                    :class="route.meta.class && route.meta.class()"
                    :key="route.name">
                    <router-link :to="{name: route.name, params: {transition: ''}}" replace class="nav-link " active-class="active font-weight-bold">
                        <i class="mt-1 d-none d-lg-inline" :class="route.meta.icon" style=""></i>
                        <span class="ml-1" style="">{{ route.meta.label || route.name }}</span>
                        <b-badge variant="danger" v-if="badges[route.name]">{{ badges[route.name] }}</b-badge>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{name: '도움말', params: {transition: ''}}" replace class="nav-link " active-class="active font-weight-bold">
                        <i class="mt-1 fas fa-question-circle"></i>
                        <span class="ml-1 d-none d-lg-inline" style="">도움말</span>
                    </router-link>
                </li>
            </ul>
            </div>
        </nav>
        <div class="h-100 overflow-auto">
            <transition :name="transitionName">
                <keep-alive :include="[]">
                    <router-view />
                </keep-alive>
            </transition>
        </div>
        <nav class="navbar navbar-expand navbar-light bg-white pt-0 pb-0 w-100 border-top d-md-none" style="order: 2; font-size: 16px; min-height: 62px;" v-if="showTabs">
            <ul class="navbar-nav d-flex w-100">
                <li class="nav-item flex-grow-1 text-center"
                    :id="route.name"
                    v-for="route in mainRoutes"
                    :key="route.name"
                    :class="route.meta.class && route.meta.class()">
                    <router-link :to="{name: route.name, params: {transition: ''}}" replace class="nav-link position-relative" active-class="active font-weight-bold">
                        <b-badge variant="danger" style="position: absolute; left: 38px;" v-if="badges[route.name]">{{ badges[route.name] }}</b-badge>
                        <i class="mt-1" :class="[route.meta.icon, doAnimate(route)]" style="font-size: x-large"></i>
                        <div style="font-size: x-small">{{ route.meta.label || route.name }}</div>
                    </router-link>
                </li>
            </ul>
        </nav>
        <b-modal :visible="Boolean(globals.loadingMessage)" ok-only ok-disabled hide-header hide-footer no-close-on-backdrop centered>
            <div class="text-center">
                <b-spinner class="mb-1 ml-2" variant="primary"></b-spinner>
                <p>{{ globals.loadingMessage }}</p>
            </div>
        </b-modal>
        <b-toast id="server-error-toast" title="서버 오류" variant="danger" :visible="Boolean(globals.error)" @hidden="globals.error = null" style="white-space: pre-wrap">{{ globals.error }}</b-toast>

        <div class="common-modals">
            <b-modal id="종합소득세-신고유형-help" title="종합소득세 신고유형 안내" ok-only ok-title="닫기">
                <p>
                    종합소득세는 기본적으로 번 돈(<strong>수입금액</strong>)에서 쓴 돈(<strong>필요경비</strong>)을 빼서 나오는 <strong>소득금액</strong>에
                    세율을 적용해서 계산하는 세금인데, 신고유형에 따라 필요경비 계산법이 달라져서 소득금액과 세액도 달라질 수 있습니다.
                </p>
                <p>
                    신고유형은 크게 장부를 작성해서 신고하는 <strong>기장신고</strong>와 장부 없이 업종별 경비율로 추정 계산하는 <strong>추계신고</strong>가 있습니다.
                    기장신고를 하면 <strong>무기장가산세</strong>를 내지 않고 결손금 인정도 받을 수 있기 때문에 사업용 경비를 지출할 때 적격증빙(세금계산서, 카드매출전표, 현금영수증 등)을
                    잘 확보했다면 장부를 작성해서 신고하는 것이 유리합니다.
                </p>
                <p>
                    기장신고는 다시 <strong>복식부기</strong>와 <strong>간편장부</strong>로 나뉘는데, 복식부기는 다소 어렵기 때문에
                    복식부기 의무자는 세무사를 통해 신고하시길 권해드립니다.
                    소규모 사업자는 간편장부로 신고해도 되고, 키퍼는 현재 인공지능으로 자동 작성된 간편장부를 통해 종합소득세 신고를 할 수 있는 기능을
                    제공하고 있습니다.
                </p>
                <p>
                    추계신고도 다시 <strong>단순경비율</strong>과 <strong>기준경비율</strong>로 나뉘는데, 간편장부로 계산된 소득금액과
                    비교해볼 수 있도록 단순경비율로 계산한 소득금액도 보여드리고 있습니다. 만일 업종 평균과 비슷한 수익을 내셨다면 이 둘의 계산 금액은
                    비슷한 값이 나옵니다. 만약 간편장부로 계산한 소득금액이 훨씬 크다면 키퍼 간편장부의 태그 기능을 이용해서
                    <strong>판매비와관리비</strong>, <strong>매출원가</strong> 등의 태그를 잘 붙여서 소득금액을 줄여보세요.
                </p>
                <p>
                    물론 업종평균에 비해 실제로 높은 소득을 올리셨다면 간편장부로 정확하게 장부를 작성해도 단순경비율보다 소득이 높게 나오므로,
                    이 경우는 지나치게 소득을 낮추지 않는 게 좋습니다.
                </p>
            </b-modal>
        </div>
    </div>
    </vue100vh>
</template>

<script>
    import {silentApi} from "@/util/api";
    import VersionChecker from "@/components/VersionChecker";
    import vue100vh from 'vue-100vh';
    import StatusBar from "@/components/StatusBar";
    import * as Sentry from "@sentry/browser";

    export default {
        name: "App",
        components: {
            StatusBar,
            vue100vh, VersionChecker
        },
        data() {
            return {
                transitionName: '',
                pollingTimer: null,
                fcmToken: null
            }
        },
        computed: {
            mainRoutes() {
                return this.$router.options.routes.filter(r => r.meta && r.meta.icon)
            },
            showTabs() {
                if (!this.globals.user) {
                    return false
                }
                if (!this.globals.user.terms_agreed) {
                    return false
                }

                let HIDETAB_ROUTE_NAMES = ['공인인증서 등록', '빠른조회신청대행 동의']
                if (HIDETAB_ROUTE_NAMES.includes(this.$route.name)) {
                    return false
                }

                return true
            },
            badges() {
                return {
                    거래내역: this.globals.user.unread,
                    알림: this.globals.unfinishedTaskCount
                }
            }
        },
        async mounted() {
            window.app = this
            if (window.Android) {
                this.globals.platform = 'android'
            }
        },
        watch: {
            'globals.user': async function() {
                if (!this.globals.user) {
                    return
                }
                if (window.Android) {
                    this.fcmToken = Android.getFCMToken()
                }
                this.globals.unfinishedTaskCount = (await silentApi.get('task/unfinished_count/')).data
                if (this.globals.production) {
                    Sentry.setUser({ id: this.globals.user.id, email: this.globals.user.email, username: this.globals.trader ? this.globals.trader.법인명_상호 : null })
                }
            },
            'fcmToken': async function(fcmToken) {
                let devices = await silentApi.get(`device/?registration_id=${fcmToken}`)
                if (devices.data.count > 0) {
                    this.globals.device = devices.data.results[0]
                }

                if (this.globals.device) {
                    await silentApi.patch(`device/${this.globals.device.id}/`, {
                        user: this.globals.user.id
                    })
                } else {
                    let response = await silentApi.post(`device/`, {
                        user: this.globals.user.id, os: window.Android ? 'android' : 'ios', registration_id: fcmToken
                    })
                    this.globals.device = response.data
                }
            },
            '$route' (to, from) {
                if (!this.globals.smallScreen) {
                    this.transitionName = ''
                    return
                }

                if (to.params.transition !== undefined) {  // $router.push 에서 params.transition 으로 지정한 transition의 우선순위가 1순위
                    this.transitionName = to.params.transition
                } else if (to.path.length > from.path.length && to.path.startsWith(from.path)) {
                    this.transitionName = 'push'
                } else if (to.path.length < from.path.length && from.path.startsWith(to.path)) {
                    this.transitionName = 'back'
                } else if (from.params.transition == 'push') {
                    this.transitionName = 'back'
                } else if (to.meta.transition) {
                    this.transitionName = to.meta.transition
                } else if (from.meta.transition == 'push') {
                    this.transitionName = 'back'
                } else {
                    this.transitionName = ''
                }
            }
        },
        methods: {
            doAnimate (route) {
                if (route.name !== '알림') {
                    return
                }
                if (!this.badges[route.name]) {
                    return
                }
                return 'bell'
            }
        }
    }
</script>
<style lang="scss">
#app {
    font-family: 'Noto Sans KR', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
