<template>
    <div class="h-100 d-flex flex-column" v-if="report">
        <nav class="navbar navbar-light titlebar pt-0">
            <back-button v-if="globals.smallScreen"></back-button>
            <span v-else></span>
            <h5 class="mt-2">
                {{ report.title() }} 종합소득세
            </h5>
            <b-dropdown no-caret variant="link" toggle-class="text-white">
                <template #button-content>
                    <i class="fas fa-history"></i>
                </template>
                <b-dropdown-item v-for="r in allReports" :key="r.id" :active="r.id == id" @click="$router.replace({params: {id: r.id}})">
                    {{ r.title() }}
                </b-dropdown-item>
            </b-dropdown>
        </nav>
        <b-nav class="bg-white p-2 shadow" pills style="z-index: 1">
            <b-nav-item :to="{name: '종합소득세 신고서'}" exact exact-active-class="active" replace><strong>내역보기</strong></b-nav-item>
            <b-nav-item :to="{name: '종합소득세 신고하기'}" exact exact-active-class="active" replace><strong>신고하기</strong></b-nav-item>
            <b-nav-item :to="{name: '종합소득세 증빙자료'}" exact exact-active-class="active" replace><strong>증빙자료</strong></b-nav-item>
        </b-nav>
        <router-view></router-view>
    </div>

</template>

<script>
    import BackButton from "@/components/BackButton";
    export default {
        name: "IncomeTaxTabs",
        components: {BackButton},
        props: ['id'],
        data: function() {
            return {
                allReports: null,
                report: null,
                hometaxDocument: null,
            }
        },
        watch: {
            id(to, from_) {
                this.reload()
            }
        },
        async mounted() {
            await this.reload()
        },
        methods: {
            async reload() {
                this.report = (await this.globals.종합소득세.get(this.id + '/')).object
                this.allReports = (await this.globals.종합소득세.get('?사업자=' + this.report.사업자)).objects
            }

        }
    }
</script>

<style scoped>

</style>
