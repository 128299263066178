<template>
    <div class="container-fluid h-100">
        <div class="row h-100">
            <div class="col-sm-12 col-md-6 col-lg-5 col-xl-4 p-0 border-right h-100" v-if="!globals.smallScreen || isRoot">
                <slot></slot>
            </div>
            <div class="col-md-6 col-lg-7 col-xl-8 p-0 h-100" v-if="!globals.smallScreen || !isRoot">
                <slot name="no-child-route-view" v-if="!globals.smallScreen && isRoot"></slot>
                <router-view :class="contentClass"></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SplitView",
        props: ['contentClass'],
        data() {
            return {
                leftTransition: '',
                rightTransition: '',
            }
        },
        computed: {
            isRoot() {
                return this.$route.meta.root
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>