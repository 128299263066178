<template>
    <div class="h-100 d-flex flex-column">
        <nav class="navbar navbar-light titlebar pt-0">
            <span style="">
                <back-button v-if="globals.smallScreen"></back-button>
            </span>

            <h5 class="mt-2">
                <span v-if="$route.query.title">{{ $route.query.title }}</span>
                <small class="text-white" v-else-if="tag"><i class="fas fa-tag"></i> 태그 붙이기</small>
                <span class="text-white" v-else>거래내역</span>
            </h5>
            <span v-if="$route.query.hideMenu"></span>
            <span v-else-if="globals.trader">
                <template>
                    <router-link class="text-white" :to="{name: '거래내역 수동입력', params: {transition: 'push'}}"><i class="fas fa-plus" title="거래내역 수동입력"></i></router-link>
                </template>
            </span>
        </nav>
        <b-navbar class="pt-0 justify-content-between text-white" type="dark" variant="" v-if="globals.user">
            <b-input-group>
                <template v-slot:prepend>
                    <b-input-group-text class="bg-white" style="border-right: 0"><i class="text-primary fas fa-search"></i></b-input-group-text>
                </template>
                <b-form-input style="border-left: 0;" placeholder="" :disabled="!globals.trader" v-model="search" @input="debouncedFetch"></b-form-input>
                <b-input-group-append v-if="search">
                    <b-input-group-text @click="applyFilter({search: ''})">
                        <i class="text-primary fas fa-times"></i>
                    </b-input-group-text>
                </b-input-group-append>
            </b-input-group>
            <b-dropdown class="ml-1"
                        :toggle-class="$route.query.거래일__gte || $route.query.거래일__lte ? 'btn-outline-primary' : 'btn-outline-secondary'"
                        no-caret right>
                <template #button-content>
                    <i class="fas fa-calendar-alt" title="거래기간 선택"></i>
                </template>
                <b-dropdown-header>거래기간 선택</b-dropdown-header>
                <b-dropdown-form>
                    <strong v-if="$route.query.dateFilterLabel">{{ $route.query.dateFilterLabel }}</strong>
                    <label>
                        시작일
                        <b-input type="date" :value="$route.query.거래일__gte" @change="applyFilter({거래일__gte: $event})"></b-input>
                    </label>
                    <label>
                        종료일
                        <b-input type="date" :value="$route.query.거래일__lte" @change="applyFilter({거래일__lte: $event})"></b-input>
                    </label>
                </b-dropdown-form>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="applyFilter({거래일__gte: undefined, 거래일__lte: undefined, dateFilterLabel: undefined})"
                                 v-if="$route.query.거래일__gte || $route.query.거래일__lte">
                    기간 선택 해제
                </b-dropdown-item>
            </b-dropdown>
        </b-navbar>
        <div class="mx-3">
            <div class="btn-group pt-1 pb-2">
                <b-dropdown class=""
                            :text="stringify($route.query[filterName]) || options.label"
                            :variant="$route.query[filterName] ? 'outline-primary': 'outline-secondary'"
                            size="sm"
                            v-for="(options, filterName) in filters"
                            :key="filterName">
                    <b-dropdown-item @click="applyFilter({[filterName]: null})">전체</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item v-for="option in options.choices"
                                     :key="option"
                                     @click="applyFilter({[filterName]: option})">
                        {{ option }}
                    </b-dropdown-item>
                </b-dropdown>
                <b-dropdown class="" menu-class="overflow-auto landing-image"
                            size="sm"
                            :variant="$route.query.tag__confirmed || $route.query.tag__path__contains || $route.query.tag__isnull ? 'outline-primary': 'outline-secondary'"
                            boundary="viewport"
                            :text="tagFilterLabel">
                    <b-dropdown-item @click="applyFilter({tag__isnull: undefined, tag__path__contains: undefined, tag__confirmed: undefined})">전체</b-dropdown-item>
                    <b-dropdown-item @click="applyFilter({tag__isnull: true, tag__path__contains: undefined, tag__confirmed: undefined})">미분류</b-dropdown-item>
                    <b-dropdown-item @click="applyFilter({tag__isnull: false, tag__path__contains: undefined, tag__confirmed: false})">미확인</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <template v-for="rootTag in rootTags">
                        <b-dropdown-header :key="rootTag">
                            {{ rootTag }}
                        </b-dropdown-header>
                        <b-dropdown-item v-for="t in usedTags.filter(t => t.startsWith(rootTag))"
                                         :key="t"
                                         class=""
                                         :active="$route.query.tag__path__contains == t"
                                         link-class="text-ellipsis text-ellipsis-begin"
                                         @click="applyFilter({tag__isnull: null, tag__path__contains: t})">
                            {{ t.split('/').slice(1).join('/') }}
                        </b-dropdown-item>
                    </template>
                </b-dropdown>
            </div>
        </div>
        <div class="alert alert-success mb-0" style="border-radius: 0" v-if="tag">
            거래내역을 터치하면 <b-button :variant="'outline-' + (globals.TAG_COLORS[tag.split('/')[0]] || 'secondary')" size="sm">{{tag.split('/').pop()}}</b-button> 태그를 붙일 수 있어요.
            태그를 지우시려면 다시 터치하세요.
        </div>
        <transition enter-active-class="animate__animated animate__fadeInLeft" leave-active-class="animate__animated animate__fadeOutRight">
            <div class="alert alert-warning mb-0" style="border-radius: 0" v-if="relation">
                <code>
                    {{ localDateFormat(relation.거래일) }}
                    {{ relation.거래처명 }}
                    {{ relation.증빙종류 }} {{ relation.은행입출금 }} {{ relation.매출매입 }}
                </code> 거래의 증빙이 되는 세금계산서를 찾아서 선택하시면 연결이 됩니다.
                <button class="btn btn-sm btn-outline-secondary" @click="relate(null)">닫기</button>
            </div>
        </transition>
        <transition enter-active-class="animate__animated animate__fadeInLeft" leave-active-class="animate__animated animate__fadeOutRight">
            <div class="alert alert-light mb-0" style="border-radius: 0" v-if="globals.transactions.batchTagging.target">
                <p>
                    <strong>{{ globals.transactions.batchTagging.target.거래처명 }}</strong>의 거래내역 중
                    유사한 거래가 <strong>{{ globals.transactions.batchTagging.target.count }}개</strong> 있습니다. 확인하고 동일한 태그를 붙일까요?
                </p>
                <div>
                    <router-link class="btn btn-sm btn-outline-primary" :to="{query: similarTransactionQuery()}" active-class="active">유사한 거래내역 보기</router-link>
                    <button class="btn btn-sm btn-primary ml-1" v-b-modal:tagrule-modal>동일한 태그 붙이기</button>
                    <button class="btn btn-sm btn-outline-secondary float-right" @click="cancelSimilarTransactions">닫기</button>
                </div>

                <b-modal id="tagrule-modal" title="동일한 태그 붙이기" ok-title="적용하기" cancel-title="취소" @ok="tagSimilarTransactions" centered>
                    <p>다음 조건의 전표가 <strong>{{ globals.transactions.batchTagging.target.count }}개</strong> 더 있습니다.</p>
                    <table class="table">
                        <tbody>
                            <template v-for="(value, key) of globals.transactions.batchTagging.target">
                                <tr v-if="key != '사업자' && key != 'count'" :key="key">
                                    <th>{{ key.replace('__contains', ' (포함)') }}</th>
                                    <td>{{ value }}</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <p>
                        이 조건에 맞는 전표에 모두 <code>{{ globals.transactions.batchTagging.tagPath }}</code> 태그를 적용할까요?
                        적용하면 앞으로 새로 등록되는 거래내역의 전표에도 동일한 규칙이 적용됩니다.
                    </p>
                </b-modal>
            </div>
        </transition>

        <transaction-detail :selected="selected"
                            @begin-relating="beginRelating(selected)"
                            @tag="checkBatchTagging(selected, $event)"
                            @select-other="selectTransactionById($event)"/>

        <small class="p-2 bg-dark text-white d-flex align-items-center"
               @click="showTip = false"
               v-if="showTip && $route.query.매출매입 === '매입' && ($route.query.증빙종류 === '신용카드')">
            <div class="mr-3">
                <i class="fas fa-info-circle mr-1"></i>카드매입은 1,4,7,10월 15일경에 해당 월 이전까지의 내역을 불러옵니다.
            </div>
            <button class="float-right btn btn-sm btn-outline-primary text-nowrap">확인</button>
        </small>
        <div class="h-100 bg-white" v-if="!globals.trader">
            <induce-cert class="p-4" theme="light">
                <p>
                    <strong>홈택스</strong>에 접속 가능한 <strong>공인인증서</strong>를 등록하시면 홈택스에서 <strong>세금계산서, 현금영수증, 카드매입, 카드매출집계</strong> 내역을
                    자동으로 가져와서 손쉽게 확인하실 수 있습니다.
                    거래내역을 검색하거나 종류별로 보기도 쉬워요.
                </p>
            </induce-cert>
        </div>

        <div class="h-100 bg-white" style="overflow-y: auto"
             v-infinite-scroll="loadNext"
             infinite-scroll-distance="30"
             ref="scroll">
            <div class="bg-white">
                <div class="alert alert-info text-center m-3" v-if="!globals.transactions.paginator || loading">
                    <div class="mt-1">거래내역을 가져오는 중 입니다. <br>잠시만 기다려주세요.</div>
                    <b-spinner variant=""></b-spinner>
                </div>
                <div class="alert alert-info text-center m-3" v-if="globals.transactions.paginator && globals.transactions.paginator.isEmpty()">
                    <div class="mt-1" v-if="globals.transactions.syncing">
                        <div class="mt-1">거래내역을 가져오는 중 입니다. <br>잠시만 기다려주세요.</div>
                        <b-spinner variant=""></b-spinner>
                    </div>
                    <div v-else> {{ display증빙종류 }} {{ $route.query.부가세유형 }} {{ $route.query.매출매입 }} 거래내역이 없습니다.</div>
                </div>
                <table class="table" style="table-layout: fixed;" v-else-if="globals.transactions.paginator">
                    <transaction-item :object="object"
                                      @select="selectTransaction(object)"
                                      v-for="object in globals.transactions.paginator.objects"
                                      :key="object.id" />
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import querystring from 'querystring'
    import SearchString from 'search-string'
    import infiniteScroll from 'vue-infinite-scroll'
    import api, {silentApi} from "@/util/api";
    import Paginator from "@/util/paginator";
    import BackButton from "@/components/BackButton";
    import LoginRequired from "@/components/LoginRequired";
    import InduceCert from "@/components/InduceCert";
    import TransactionItem from "@/views/transaction/TransactionItem";
    import TransactionDetail from "@/views/transaction/TransactionDetail";

    export default {
        name: "Transaction",
        mixins: [LoginRequired],
        components: {TransactionDetail, TransactionItem, InduceCert, BackButton},
        directives: { infiniteScroll },
        props: ['evidence', 'salespurchase', 'vattype'],
        data() {
            return {
                filters: {
                    매출매입: {label: '매출매입', choices: ['매출', '매입']},
                    증빙종류: {label: '증빙별', choices: ['세금계산서', '신용카드', '현금영수증', '은행', '증빙없음',]},
                    은행입출금: {label: '은행', choices: ['입금', '출금']},
                    부가세유형: {label: '부가세', choices: ['과세', '면세', '영세']},
                },
                similarTransactions: null,
                selected: null,
                search: '',
                tag: null,
                usedTags: [],
                relation: null,
                debounce: null,
                poll: false,
                scrollY: 0,
                showTip: true,  // TODO localstorage
            }
        },
        computed: {
            filterActivated () {
                return Object.keys(this.$route.query).some(key => this.filters[key] && this.$route.query[key]) || Boolean(this.$route.query.tag__path__contains)
            },
            tagFilterLabel() {
                if (this.$route.query.tag__confirmed == false) {
                    return '미확인'
                }
                if (this.$route.query.tag__isnull) {
                    return '미분류'
                }
                return '태그'
            },
            display증빙종류 () {
                let 증빙종류 = this.$route.query.증빙종류
                if (!증빙종류) { return '' }
                else if (Array.isArray(증빙종류)) { return 증빙종류.join(', ') }

                return 증빙종류
            },
            loading () {
                return this.globals.transactions.paginator.loading
            },
            rootTags() {
                return new Set(this.usedTags.map(t => t.split('/')[0]))
            }
        },
        watch: {
            '$route': function (prev, next) {
                if (prev.name === '거래내역상세' || next.name === '거래내역상세') { return }
                this.reload()
            },
            'globals.transactions.syncing': function () {
                if (this.globals.transactions.syncing === false) {
                    this.reload()
                }
            },
            'globals.trader': function() {
                this.reload()
            },
            '현금매출입력.공급가액': function() {
                this.현금매출입력.부가세 = Math.floor(this.현금매출입력.공급가액 / 10)
            },
            '종이세금계산서.공급가액': function() {
                this.종이세금계산서.부가세 = this.종이세금계산서.부가세유형 === '과세' ? Math.floor(this.종이세금계산서.공급가액 / 10) : 0
            },
        },
        mounted () {
            this.reload()
        },
        async activated () {
            if (this.globals.transactions.forceQuery) {
                this.applyFilter(this.globals.transactions.forceQuery)
                this.globals.transactions.forceQuery = null
                return
            }
            if (this.$refs.scroll) {
                this.$refs.scroll.scrollTop = this.scrollY
            }
            if (!this.globals.transactions.paginator) {
                await this.reload()
            }
        },
        deactivated () {
            if (this.$refs.scroll) {
                this.scrollY = this.$refs.scroll.scrollTop
            }
            this.poll = false
        },
        methods: {
            async reload() {
                if (!this.globals.trader) {
                    return
                }

                this.search = this.$route.query.search || ''
                this.tag = this.$route.query.select_tag

                let query = Object.assign({
                    사업자: this.globals.trader.robotax_id,
                    ordering: '-거래일,-거래일시,-created',
                    page_size: 20,
                }, this.$route.query);

                let searchStr = SearchString.parse(this.search)
                if (searchStr.textSegments.length > 0) {
                    query.search = searchStr.textSegments.map(t => t.text).join(' ')
                } else {
                    delete query.search
                }
                let 거래처Condition = searchStr.conditionArray.find(c => c.keyword == '거래처명')
                if (거래처Condition) {
                    query.거래처명__contains = 거래처Condition.value
                }

                query.거래일__gte = this.$route.query.거래일__gte || ''
                query.거래일__lte = this.$route.query.거래일__lte || ''

                Object.keys(query).forEach(key => {
                    if (Array.isArray(query[key])) {
                        query[key + '__in'] = query[key].join(',')
                        delete query[key]
                    }
                })

                this.globals.transactions.paginator = new Paginator(api.get, 'robotax/전표/?' + querystring.stringify(query))
                this.globals.transactions.paginator.loadNext()

                this.read()

                this.poll = true

                this.usedTags = (await api.get('robotax/tag/distinct/?사업자=' + this.globals.trader.robotax_id)).data
            },
            applyFilter (query, reset=false) {
                this.$router.replace({query: reset ? query : {...this.$route.query, ...query}}).catch(() => {})
            },
            debouncedFetch (query) {
                if (this.debounce) {
                    clearTimeout(this.debounce)
                }
                this.debounce = setTimeout(() => this.applyFilter({search: query}), 1000)
            },
            loadNext () {
                if (!this.globals.trader) {
                    return
                }

                this.globals.transactions.paginator.loadNext()
            },
            read () {
                api.post(`user/${this.globals.user.id}/read_all_sync/`).then(res => {
                    this.globals.user.unread = 0
                })
            },
            selectTransaction(row) {
                if (this.relation) {
                    this.relate(row)
                    return
                }

                if (this.tag) {
                    this.toggleTag(row, this.tag)
                    return
                }

                this.selected = row
                this.$bvModal.show('tagging-modal')

                // 참고용으로 URL에 id 값을 넣지만, id 값을 준다고 해서 자동으로 선택되진 않는다.
                // this.$router.replace({params: {id: row.id}, query: this.$route.query})
            },
            hasTag(row, _tag) {
                return row.tag_set.find(tag => tag.path === _tag)
            },
            async toggleTag (row, tag) {
                if (this.hasTag(row, tag)) {
                    Object.assign(row, (await api.delete(`robotax/전표/${row.id}/remove_tag/`, {data: {path: tag}})).data)
                } else {
                    Object.assign(row, (await api.post(`robotax/전표/${row.id}/tag/`, {path: tag})).data)
                    await this.checkBatchTagging(row, tag);
                }
            },
            checkBatchTagging: async function (row, tag) {
                this.globals.transactions.batchTagging.target = (await api.get(`robotax/전표/${row.id}/similar_ones/`)).data
                this.globals.transactions.batchTagging.tagPath = tag
            },
            async tagSimilarTransactions() {
                let params = Object.assign({}, this.globals.transactions.batchTagging.target)
                delete params.count
                this.globals.loading = true
                this.globals.loadingMessage = '태그를 일괄 적용하는 중입니다...'

                let res = await api.post(`robotax/전표/tag/?` + querystring.stringify(params), {path: this.globals.transactions.batchTagging.tagPath})
                res.data.forEach(transaction => {
                    this.globals.transactions.paginator.objects.filter(t => t.id == transaction.id).map(t => {
                        Object.assign(t, transaction)
                    })
                })
                this.cancelSimilarTransactions()
            },
            similarTransactionQuery: function () {
                let {거래처명, 적요__contains, count, ...query} = this.globals.transactions.batchTagging.target
                return Object.assign(query, {
                    search: `거래처명:"${거래처명}" "${적요__contains}"`,
                    similarTransactions: true
                });
            },
            cancelSimilarTransactions() {
                this.globals.transactions.batchTagging.target = null
                if (this.$route.query.similarTransactions) {
                    this.$router.go(-1)
                }
            },
            resetTag () {
                this.$router.replace({query: {...this.$route.query, select_tag: null}})
            },
            stringify (filter) {
                if (Array.isArray(filter)) {
                    return String(filter)
                }

                return filter
            },
            async selectTransactionById(id) {
                this.selected = (await api.get(`robotax/전표/${id}/`)).data
            },
            beginRelating(transaction) {
                this.relation = transaction
                this.$bvModal.hide('tagging-modal')
                if (transaction.증빙종류 == '은행') {
                    this.$router.push({
                        query: {
                            증빙종류: '세금계산서',
                            은행입출금: undefined,
                            매출매입: this.relation.은행입출금 == '입금' ? '매출' : '매입'
                        }
                    })
                } else if (transaction.증빙종류 == '세금계산서') {
                    this.$router.push({
                        query: {
                            증빙종류: '은행',
                            매출매입: undefined,
                            은행입출금: this.relation.매출매입 == '매출' ? '입금' : '출금'
                        }
                    })
                }
            },
            async relate(transaction) {
                this.globals.loading = true
                this.globals.loadingMessage = '두 전표를 연결하고 있습니다...'

                if (transaction) {
                    this.relation = (await api.post(`robotax/전표/${this.relation.id}/relate/`, {target: transaction.id})).data
                }

                this.relation = null
                this.$router.back()
            },
        }
    }
</script>