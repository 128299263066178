<template>
    <div class="h-100 d-flex flex-column">
        <nav class="navbar navbar-light titlebar">
            <back-button></back-button>
            <h5 class="mt-2">
                카드매입
            </h5>
            <span></span>
        </nav>
        <scraping-account-panel class="mx-3 mb-3" source="카드매입" scraping-only></scraping-account-panel>
        <div class="alert alert-light mx-3">
            카드매입은 매월 15일에 이전 달의 자료가 수집됩니다.
        </div>
        <div class="h-100 bg-white overflow-auto" v-infinite-scroll="loadNext" infinite-scroll-distance="30">
            <table class="table" v-if="paginator">
                <thead>
                    <tr>
                        <th class="">거래일시</th>
                        <th class="">거래처</th>
                        <th class="">총금액</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in paginator.objects" :key="item.id">
                        <td>{{ humanizeDate(item.거래일시) }}</td>
                        <td>{{ item.가맹점_상호 }}</td>
                        <td class="text-right">{{ moneyFormat(item.총금액) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import Paginator from "@/util/paginator";
    import api from "@/util/api";
    import infiniteScroll from "vue-infinite-scroll";
    import BackButton from "@/components/BackButton";
    import querystring from "querystring";
    import ScrapingAccountPanel from "@/views/connect/ScrapingAccountPanel";
    import LoginRequired from "@/components/LoginRequired";
    import AccountMixin from "@/util/AccountMixin";

    export default {
        name: "CashReceipts",
        mixins: [LoginRequired, AccountMixin],
        components: {ScrapingAccountPanel, BackButton},
        directives: { infiniteScroll },
        data() {
            return {
                paginator: null
            }
        },
        watch: {
            'scrapingSources.카드매입.status'(newValue) {
                if (newValue == 'success') {
                    this.reload()
                }
            }
        },
        async mounted() {
            this.reload()
        },
        methods: {
            reload() {
                this.paginator = new Paginator(api.get, 'robotax/카드매입/?' + querystring.stringify({
                    사업자: this.globals.trader.robotax_id,
                    ordering: '-거래일시'
                }))
                this.paginator.loadNext()
            },
            loadNext () {
                this.paginator.loadNext()
            }
        }
    }
</script>

<style scoped>

</style>