<template>
    <div>
        <nav class="navbar navbar-light titlebar">
            <back-button></back-button>
            <h5 class="mt-2">카드매출 월별 집계</h5>
            <span>
                <refresh-scraping-button source="카드매출집계" spinner/>
            </span>
        </nav>

        <div class="card mx-3 mb-3">
            <div class="card-body">
                <canvas id="chart-cardsales" style="width: 100%; height: 200px"></canvas>
            </div>
        </div>

        <router-link tag="p" :to="{name: '카드매출내역'}" class="m-3 alert alert-light" replace>
            카드매출 집계는 홈택스에서 가져오는 자료이며, 매월 15일에 전월분의 카드매출 집계가 업데이트됩니다.
            <strong>여신금융협회 계정</strong>을 등록하시면 <strong>매일 상세한 카드매출 내역</strong>을 확인하실 수 있어요.
            <span class="text-underline">
                여신금융협회 연동하기
                <i class="fas fa-chevron-right"></i>
            </span>

        </router-link>

        <div class="bg-white">
            <table class="table">
                <thead>
                    <tr>
                        <th>월</th>
                        <th class="text-right">거래건수</th>
                        <th class="text-right">합계금액</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="obj in cardSales" :key="obj.id">
                        <td>{{ yearMonthFormat(obj.거래기간시작일) }}</td>
                        <td class="text-right">{{ obj.거래건수 }}</td>
                        <td class="text-right">{{ moneyFormat(obj.합계금액) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import BackButton from "@/components/BackButton";
    import api from "@/util/api";
    import Chart from "chart.js"
    import moment from "moment"
    import {getCssVar, humanizeNumberScale, range} from "@/util/vue-commons";
    import RefreshScrapingButton from "@/views/connect/RefreshScrapingButton";
    import AccountMixin from "@/util/AccountMixin";

    export default {
        name: "CardSalesMonthlyReport",
        mixins: [AccountMixin],
        components: {RefreshScrapingButton, BackButton},
        data() {
            return {
                cardSales: [],
                unit: '주',
                step: 0,
            }
        },
        computed: {
            points: function () {
                let dates = range(moment().add(this.step, 'years').month() + 1).map(n => n + 1)
                return dates.map(d => { return {value: d, label: `${d}월`} })
            }
        },
        async mounted() {
            await this.reload()
            this.pollScraping('카드매출집계')
        },
        methods: {
            async scrapingUpdated() {
                await this.reload()
            },
            async reload() {
                this.cardSales = (await api.get('robotax/카드매출집계/', {
                    params: {사업자: this.globals.trader.robotax_id, 거래기간시작일__gte: moment().month(0).date(1).format('YYYY-MM-DD')}
                })).data.results

                new Chart('chart-cardsales', {
                    type: 'bar',
                    data: {
                        labels: this.points.map(p => p.label),
                        datasets: [
                            {
                                type: 'line',
                                label: '금액',
                                borderColor: getCssVar('primary'),
                                data: this.cardSales.map(s => s.합계금액).reverse(),
                            },
                        ],
                    },
                    options: {
                        legend: {
                            position: 'top'
                        },
                        scales: {
                            yAxes: [
                                {
                                    display: true,
                                    labelString: '금액',
                                    ticks: {
                                        callback: function (value, index, values) {
                                            return humanizeNumberScale(value)
                                        }
                                    }

                                },
                            ]
                        },
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>