<template>
    <div>
        <nav class="navbar navbar-light titlebar">
            <back-button ref="back"></back-button>
            <h5 class="mt-2">
                부동산 임대정보 입력
            </h5>
            <span></span>
        </nav>
        <div class="card m-3">
            <div class="card-body">
                <b-form>
                    <b-form-group label="임대주택종류 (주택인 경우만 선택)">
                        <b-dropdown class="mt-2"
                                    :text="부동산임대.임대주택종류 ? 임대주택종류[부동산임대.임대주택종류] : '임대주택종류 선택'"
                                    variant="outline-primary"
                                    block
                                    style="font-size: 0.5em">
                            <b-dropdown-item @click="부동산임대.임대주택종류 = '11'">단독주택</b-dropdown-item>
                            <b-dropdown-item @click="부동산임대.임대주택종류 = '14'">다가구주택</b-dropdown-item>
                            <b-dropdown-item @click="부동산임대.임대주택종류 = '15'">아파트</b-dropdown-item>
                            <b-dropdown-item @click="부동산임대.임대주택종류 = '16'">연립주택</b-dropdown-item>
                            <b-dropdown-item @click="부동산임대.임대주택종류 = '17'">다세대주택</b-dropdown-item>
                            <b-dropdown-item @click="부동산임대.임대주택종류 = '18'">오피스텔</b-dropdown-item>
                            <b-dropdown-item @click="부동산임대.임대주택종류 = '99'">기타</b-dropdown-item>
                        </b-dropdown>
                    </b-form-group>
                    <b-form-group label="도로명 주소">
                        <b-button block variant="outline-primary" class="mt-2" @click="openAddressModal">{{ 부동산임대.도로명주소 ? 부동산임대.도로명주소.roadAddr : '주소 찾기' }}</b-button>
                    </b-form-group>
                    <b-modal id="도로명주소찾기" centered title="도로명주소 찾기" cancel-title="취소" ok-title="주소저장" :ok-disabled="!부동산임대.도로명주소">
                        <b-input type="text" placeholder="도로명, 건물명, 지번, 초성검색" @input="debouncedSearch"></b-input>
                        <div style="height: 300px" class="overflow-auto">
                            <div v-if="searching"><b-spinner variant="primary"></b-spinner></div>
                            <div v-if="!results">검색어를 입력하고 여기 나타나는 주소중 하나를 선택하세요.</div>
                            <div v-else-if="results.common.errorMessage !== '정상'">{{ results.common.errorMessage }}</div>
                            <div v-else-if="!부동산임대.도로명주소">
                                <div>아래 검색결과 중 하나를 선택해주세요</div>
                                <table class="table">
                                    <tbody>
                                    <tr v-for="result in results.juso" :key="result['roadAddr']" @click="부동산임대.도로명주소 = result">
                                        <td>{{ result.roadAddr }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </b-modal>
                    <div class="row">
                        <div class="col-6">
                            <b-input-group prepend="블록">
                                <b-input placeholder="아파트, 상가건물 등의 블록" v-model="부동산임대.블록"></b-input>
                            </b-input-group>
                        </div>
                        <div class="col-6">
                            <b-input-group prepend="동">
                                <b-input placeholder="동" v-model="부동산임대.동"></b-input>
                            </b-input-group>
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col-6">
                            <b-input-group prepend="층">
                                <b-input placeholder="층" v-model="부동산임대.층"></b-input>
                            </b-input-group>
                        </div>
                        <div class="col-6">
                            <b-input-group prepend="호">
                                <b-input placeholder="호" v-model="부동산임대.호"></b-input>
                            </b-input-group>
                        </div>
                    </div>
                    <b-form-group label="취득일자">
                        <b-form-input block type="date" class="text-right" v-model="부동산임대.임대물건취득일자" placeholder="취득일자"></b-form-input>
                    </b-form-group>
                    <b-form-group label="건물 면적">
                        <b-input-group append="㎡">
                            <b-form-input type="number" class="text-right" v-model="부동산임대.건물면적" placeholder="건물면적"></b-form-input>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="임차인 성명">
                        <b-input type="text" class="text-right" v-model="부동산임대.임차인_성명" placeholder="임차인 성명"></b-input>
                    </b-form-group>
                    <b-form-group label="임차인 주민등록번호">
                        <b-input type="text" class="text-right" v-model="부동산임대.임차인_사업자주민등록번호" placeholder="임차인 주민등록번호 (-제외)"></b-input>
                    </b-form-group>
                    <b-form-group label="임대기간 시작일">
                        <b-input type="date" class="text-right" v-model="부동산임대.임대기간_시작일" placeholder="임대기간 시작일"></b-input>
                    </b-form-group>
                    <b-form-group label="임대기간 종료일">
                        <b-input type="date" class="text-right" v-model="부동산임대.임대기간_종료일" placeholder="임대기간 종료일"></b-input>
                    </b-form-group>
                    <b-form-group label="보증금">
                        <b-input-group append="원">
                            <b-input type="number" class="text-right" v-model="부동산임대.보증금" placeholder="보증금"></b-input>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="월세">
                        <b-input-group append="원">
                            <b-input type="number" class="text-right" v-model="부동산임대.월세" placeholder="월세"></b-input>
                        </b-input-group>
                    </b-form-group>
                </b-form>
                <b-button block variant="primary" @click.prevent="updateRent(부동산임대)">저장</b-button>
                <b-button block variant="danger" v-if="부동산임대.id" @click.prevent="deleteRent(부동산임대)">삭제</b-button>
            </div>
        </div>
    </div>
</template>

<script>
    import api, {silentApi} from "@/util/api";
    import BackButton from "@/components/BackButton";

    export default {
        name: "RentDetail.vue",
        components: {BackButton},
        data: () => ({
            // TODO robotax 부동산임대 모델에서 코드 받아오도록
            임대주택종류: {
                11: '단독주택',
                14: '다가구주택',
                15: '아파트',
                16: '연립주택',
                17: '다세대주택',
                18: '오피스텔',
                99: '기타',
            },
            부동산임대: {
                임대주택종류: '',
                //TODO : 법정동코드 필드제거
                법정동코드: '-',
                도로명주소: '',
                블록: '',
                층: '',
                동: '',
                호: '',
                임대물건취득일자: '',
                건물면적: '',
                임차인_성명: '',
                임차인_주민등록번호: '',
                임차인_납세자구분: '',
                임대기간_시작일: '',
                임대기간_종료일: '',
                보증금: '',
                월세: '',
                임대상태: '임대'
            },
            searching: false,
            debounce: null,
            results: null,
        }),
        async mounted() {
            await this.reload()
        },
        methods: {
            async reload() {
                if (this.$route.query.id) {
                    this.부동산임대 = (await api.get(`robotax/부동산임대/${this.$route.query.id}`)).data
                }
            },
            updateRent(rent) {
                let method = rent.id ? silentApi.patch : silentApi.post
                let url = rent.id ? `robotax/부동산임대/${rent.id}/` : `robotax/부동산임대/`
                method(url, {...rent, 사업자: this.globals.trader.robotax_id}).then(res => {
                    this.부동산임대 = res.data
                    if (this.$route.query.id !== res.data.id) {
                        this.$router.replace({name: '부동산임대 편집', query: {id: res.data.id}})
                    }
                    alert('부동산 임대정보가 성공적으로 저장되었습니다.')
                }).catch(err => alert(`임대정보 저장에 실패하였습니다. ${Object.keys(err.response.data).join(', ')} 내용을 확인해주세요.`))
            },
            deleteRent(rent) {
                if (!confirm('임대정보를 삭제하시겠습니까?')) {
                    return
                }

                api.delete(`robotax/부동산임대/${rent.id}/`).then(() => {
                    this.$refs.back.go()
                }).catch(err => alert(err))
            },
            openAddressModal () {
                this.results = null
                this.$bvModal.show('도로명주소찾기')
            },
            resetAddress () {
                this.부동산임대.도로명주소 = null
            },
            debouncedSearch(query) {
                this.resetAddress()
                if (this.debounce) {
                    clearTimeout(this.debounce)
                }
                this.debounce = setTimeout(() => this.search(query), 1000)
            },
            search(query) {
                if (!query) { return }

                this.searching = true
                api.get(`robotax/부동산임대/도로명주소/?query=${query}`).then(res => {
                    this.results = res.data.results
                    this.searching = false
                })
            },
            extract(dict, postfix) {
                const extracted = (dict[postfix] || '').trim()
                if (!extracted) {
                    return ''
                }

                if (extracted.endsWith(postfix)) {
                    return extracted
                }

                return extracted + postfix
            },
        }
    }
</script>

<style scoped>

</style>
