<template>
    <b-tabs nav-wrapper-class="p-2">
        <b-tab :title="category" v-for="category in ['손익', '부가가치세', '재무상태']" :key="category">
            <ul class="pl-0">
                <template v-for="root in globals.TAG_CATEGORIES[category].tags">
                    <li :class="'alert-' + globals.TAG_COLORS[root]"
                        :style="'padding-left: ' + ((tagPath.match(/\//g) || []).length * 20) + 'px'"
                        style="list-style: none; font-size: large"
                        v-for="tagPath in descendants(root)"
                        :key="tagPath"
                        @click="toggle(tagPath)"
                        v-show="expanded[globals.tags[tagPath].parent] && isExtended(tagPath)">
                        <div class="px-1">
                            <i class="fas fa-minus" v-if="!globals.tags[tagPath].children_count"></i>
                            <i class="fas fa-chevron-down" v-else-if="expanded[tagPath]"></i>
                            <i class="fas fa-chevron-right" style="margin-right: 4px" v-else></i>
                            &nbsp;
                            <span v-if="globals.tags[tagPath].children_count">{{globals.tags[tagPath].name}}</span>
                            <a href="#" v-else @click.prevent="select(tagPath)">{{ globals.tags[tagPath].name }}</a>
                            <small class="ml-3 text-secondary"
                                   @click.stop
                                   v-b-popover.bottom.hover.blur="globals.tags[tagPath].description"
                                   v-if="globals.tags[tagPath].description">
                                {{ globals.tags[tagPath].description.split('.')[0] }}
                            </small>
                            <slot name="tag-submenu" :tagPath="tagPath"></slot>
                        </div>
                    </li>
                    <li :class="'alert-' + globals.TAG_COLORS[root]"
                        class="text-underline text-secondary"
                        :key="root + 'ellipsis'"
                        style="list-style: none"
                        :style="'padding-left: ' + 40 + 'px'"
                        @click="extended[root] = true"
                        v-if="expanded[root] && !excludes.includes(root) && extended[root] === false">
                        ... 모두 보기
                    </li>
                </template>
            </ul>
        </b-tab>
    </b-tabs>
</template>
<script>
    import api from "@/util/api";
    import {loadTags} from "@/models";

    export default {
        name: 'TagSelector',
        props: {
            id: {},
            requires: {type: Array},
            excludes: {type: Array, default: () => []},
            extendAll: {type: Boolean, default: false}
        },
        data() {
            return {
                tags: {},
                expanded: {'': true, 매출액: true, 매출원가: true, 판매비와관리비: true, 부가가치세: true, 감가상각자산: true},
                extended: {매출액: false, 영업외수익: false, 매출원가: false, 판매비와관리비: false, 영업외비용: false},
                frequentlyUsed: {  // https://stats.nts.go.kr/national/major_detail.asp?year=2019&catecode=A03028
                    '매출액': ['상품매출', '제품매출', '임대수입', '공사수입', '기타매출'],
                    '영업외수익': ['이자수익', '유,무형자산 처분이익', '국고보조금', '판매장려금', '기타소계/기타1'],
                    '매출원가': ['상품매입액', '재료매입액', '외주비', '경비', '경비/지급수수료', '경비/소모품비', '경비/통신비', '경비/접대비', '경비/전력비', '경비/가스,수도,유류비', '경비/운반비', '경비/보험료', '경비/운반비', '경비/차량유지비'],
                    '판매비와관리비': ['지급수수료', '소모품비', '통신비', '접대비', '기타비용', '세금과공과', '보험료', '여비교통비', '챠량유지비', '복리후생비', '임차료'],
                    '영업외비용': ['이자비용', '기부금', '유,무형자산 처분손실', '기타소계/기타1'],
                },
                rareTagsVisible: false
            }
        },
        computed: {
            visibleTags() {
                return Object.keys(this.globals.tags).filter(tag => {
                    return !this.excludes.some(exclude => tag.startsWith(exclude))
                })
            }
        },
        watch: {
        },
        async mounted() {
            await loadTags()
            if (this.extendAll) {
                Object.keys(this.extended).forEach(k => {
                    this.extended[k] = true
                })
            }
            this.excludes.forEach(e => {
                this.expanded[e] = false
            })
        },
        methods: {
            select(tagPath) {
                this.$emit('select', tagPath)
                this.$bvModal.hide(this.id)
            },
            toggle(tagPath) {
                this.$set(this.expanded, tagPath, !this.expanded[tagPath])
            },
            descendants(root) {
                return this.visibleTags.filter(t => t.startsWith(root))
            },
            isExtended(tagPath) {
                let root = tagPath.split('/')[0]
                return !this.frequentlyUsed[root]
                    || this.frequentlyUsed[root].some(t => (root + '/' + t).startsWith(tagPath))
                    || this.extended[root]
            }
        }
    }
</script>