<template>
    <div class="overflow-auto" @scroll="loadMore($event)">
        <p class="alert alert-light rounded-0 mb-0">
            국세청에서 환급 받은 내역입니다.
        </p>
        <table class="table bg-white">
            <tbody>
                <tr class="text-center" v-if="!taskPaginator || taskPaginator.loading || globals.transactions.syncing">
                    <td>
                        <b-spinner variant="light"></b-spinner>
                        <p>불러오는 중...</p>
                    </td>
                </tr>
                <tr class="" v-else-if="taskPaginator.objects.length == 0">
                    <td>환급내역이 없습니다.</td>
                </tr>
                <tr class="" v-for="task in taskPaginator.objects" :key="task.id" v-else>
                    <td class="">
                        <span>{{ task.title }}</span>
                        <div class="text-muted">{{ localDateFormat(task.issued) }}</div>
                    </td>
                    <td class="text-right"><span class="">{{ moneyFormat(task.amount) }}</span></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import LoginRequired from "@/components/LoginRequired";
    import AppUpdateMixin from "@/components/AppUpdateMixin";
    import Paginator from "@/util/paginator";
    import api from "@/util/api";

    export default {
        name: "HometaxRefunds",
        mixins: [LoginRequired, AppUpdateMixin],
        data: function() {
            return {
                taskPaginator: null,
            }
        },
        mounted() {
            this.reload()
        },
        methods: {
            async reload() {
                if (!this.globals.trader) {
                    return
                }

                this.taskPaginator = new Paginator(api.get, 'robotax/hometaxdocument/?page_size=10&source=환급금조회&사업자=' + this.globals.trader.robotax_id)
                this.taskPaginator.loadNext()
            },
            loadMore: function(event) {
                if (!this.globals.trader) { return }

                if (!this.taskPaginator.canLoadNext()) {
                    return
                }
                if (event.target.scrollTop + event.target.clientHeight + 100 > event.target.scrollHeight) {
                    this.taskPaginator.loadNext()
                }
            }
        }
    }
</script>

<style scoped>

</style>
