<template>
    <div class="h-100 overflow-auto" v-if="report && taxreport && 사업자">
        <div class="m-3">
            <button class="btn btn-block btn-light animate__animated animate__bounce" v-b-modal.how-to-report-tax-modal>키퍼로 자가신고할까요, 세무사에 맡길까요?</button>
            <b-modal id="how-to-report-tax-modal" title="종합소득세 신고 방법 안내" ok-title="닫기" ok-only>
                <p>
                    종합소득세 신고, 키퍼를 통해서 자가신고할지 세무사에게 맡길지 고민되시나요? 쉽게 판단하실 수 있게 신고 방법들을 비교해보겠습니다.
                </p>

                <table class="table">
                    <thead>
                        <tr>
                            <th>신고방법</th>
                            <th>장점</th>
                            <th>단점</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>키퍼로 자가신고</th>
                            <td>쉽고 저렴하다(1만원)</td>
                            <td>본인 책임</td>
                        </tr>
                        <tr>
                            <th>홈택스로 자가신고</th>
                            <td>무료</td>
                            <td>본인 책임, 어렵다</td>
                        </tr>
                        <tr>
                            <th>세무사에게 맡기기</th>
                            <td>높은 신뢰도, 세무사 책임</td>
                            <td>비싸다 (최저 30만원)</td>
                        </tr>
                    </tbody>
                </table>
                <p>
                    키퍼의 인공지능 세금신고는 아직 세무사보다 많이 부족하기 때문에 다음 조건을 모두 만족하는 경우에만 종합소득세 신고가 가능합니다.
                </p>
                <ul>
                    <li>1인 사업자, 간편장부 대상자</li>
                    <li>사업용 은행계좌와 신용카드를 개인용과 엄격하게 분리해서 쓴다.</li>
                    <li>대부분의 거래가 적격 증빙(세금계산서, 카드매출전표, 현금영수증)으로 이루어진다.</li>
                    <li>사업소득 이외의 소득이 없고, 개인 명의로 원천징수되는 사업소득도 없다.</li>
                </ul>
                <p>
                    위의 조건을 충족하지 못하는 경우는 세무사를 통해 신고하길 권고드립니다.
                    그리고 좀더 과감하게 절세를 하고 싶은 경우에도 간편장부로 직접 경비처리하는 것보다 세무사에게 맡기는 것이 좀더 안전합니다.
                </p>
            </b-modal>
        </div>
        <div class="alert alert-danger m-3" v-if="globals.trader.사업자구분 != '개인'">
            <p>
                <i class="fas fa-info-circle mr-1"></i>
                <strong>종합소득세</strong>는 사업자가 아니라 사업자를 보유한 <strong>대표자 개인에게 부과되는 세금</strong>입니다.
                현황판이나 설정에서 <strong>{{ globals.trader.법인명_상호 }}</strong> 대신
                <strong>{{ 사업자.법인명_상호 }}</strong> 개인을 선택하시면 신고하기 기능도 이용하실 수 있어요.
            </p>
            <button class="btn btn-block btn-outline-primary" @click="selectPerson">{{ 사업자.법인명_상호 }} 개인 현황판 보기</button>
        </div>

        <template v-else>
            <div class="card m-3 border" :class="nonFullVersionTraders.length ? 'border-danger' : 'border-success'">
                <div class="card-header border-bottom" :class="nonFullVersionTraders.length ? 'border-danger' : 'border-success'">
                    정식버전 확인
                </div>
                <div class="card-body px-0">
                    <table class="table">
                        <tbody>
                            <tr v-for="trader in 사업자.ownTraders.filter(t => !t.폐업일)" :key="trader.id" @click="goToPay(trader)">
                                <td>{{ trader.법인명_상호 }}</td>
                                <td class="text-right">
                                    <i class="fas fa-check text-success" v-if="trader.plan == '정식버전'"></i>
                                    {{ trader.plan }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p class="mx-3 text-danger" v-if="nonFullVersionTraders.length">
                        <i class="fas fa-exclamation-triangle"></i> 소유하신 사업자가 모두 키퍼 정식버전으로 등록되어 있어야 종합소득세 신고가 가능합니다.
                    </p>
                </div>
            </div>

            <div class="card m-3 border" :class="allChecklistDone ? 'border-success' : 'border-danger'">
                <div class="card-header border-bottom" :class="allChecklistDone ? 'border-success' : 'border-danger'" @click="checklistExpanded = !checklistExpanded">
                    <strong>
                        신고 전 확인사항
                    </strong>
                    <span>{{ Object.keys(checklist).filter(k => taxreport.checklist[k]).length }}</span>
                    /
                    <span>{{ Object.keys(checklist).length }}</span>
                    <div class="ml-2 badge badge-dark" v-if="allChecklistDone">완료!</div>
                    <div class="float-right">
                        <i class="fas fa-chevron-up" v-if="checklistVisible"></i>
                        <i class="fas fa-chevron-down  float-right mt-1" v-else></i>
                    </div>
                </div>
                <div class="card-body px-0">
                    <table class="table" v-show="checklistVisible">
                        <tbody>
                            <tr v-for="(item, itemName) in checklist" :key="itemName" v-b-modal="itemName.replace(/ /g, '-') + '-modal'">
                                <td>
                                    <strong v-if="taxreport.checklist[itemName]">{{ itemName }}</strong>
                                    <span v-else>{{ itemName }}</span>
                                </td>
                                <td class="py-2" style="font-size: 1.4em">
                                    <b-spinner class="ml-1 mb-1" small variant="primary" v-if="!taxreport.checklist[itemName] && item.showLoading"></b-spinner>
                                    <i class="far fa-check-circle text-success" v-else-if="taxreport.checklist[itemName]"></i>
                                    <i class="far fa-circle" v-else></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <DisclaimerModal @ok="updateChecklist('자가신고 확인', true)"
                                     @cancel="updateChecklist('자가신고 확인', false)"/>

                    <RegistrationNumberModal :사업자="사업자"
                                             :valid="taxreport.checklist['대표자 주민등록번호']"
                                             @update="updateChecklist"/>

                    <b-modal id="신고유형-및-신고-범위-확인-modal" title="신고유형 및 신고 범위 확인"
                             ok-title="동의" cancel-title="거절"
                             @ok="updateChecklist('신고유형 및 신고 범위 확인', true)"
                             @cancel="updateChecklist('신고유형 및 신고 범위 확인', false)">

                        <p>
                            키퍼의 인공지능 세금신고는 간편장부로 신고하는 것만 지원하며, 만약 복식부기 의무자라면 세무사에게 신고를 맡기시는 것을 추천합니다.
                            키퍼가 식별한 기장의무는 다음과 같습니다.
                        </p>

                        <table class="table">
                            <thead>
                                <tr>
                                    <th>사업자</th>
                                    <th>기장의무</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :class="{'text-danger': traderInfo.기장의무 == '복식부기'}" v-for="traderInfo in report.options.사업자_set" :key="traderInfo.납세자ID">
                                    <td>{{ traderInfo.법인명_상호 }}</td>
                                    <td>{{ traderInfo.기장의무 || '미상' }}</td>
                                </tr>
                            </tbody>
                        </table>

                        <p>다음의 경우는 키퍼로 신고할 수 없으니 세무사에게 맡기시길 권고합니다.</p>
                        <ul>
                            <li>복식부기 의무자</li>
                            <li>대표자 이외의 직원이 있는 경우</li>
                            <li><strong>적격 증빙(세금계산서, 카드매출전표, 현금영수증) 이외의 매출매입이 많은 경우</strong></li>
                            <li>사업용 은행계좌나 카드매입에 개인적인 지출이 많은 경우</li>
                            <li>홈택스에 사업용신용카드, 사업용계좌를 등록하지 않은 경우</li>
                        </ul>

                    </b-modal>

                    <b-modal id="은행계좌-연동-modal" title="은행계좌 연동" ok-only ok-title="닫기">
                        <p>사업자의 은행계좌가 1개 이상 연동되어 있어야 합니다.</p>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>사업자</th>
                                    <th>계좌</th>
                                    <th>입출금내역</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="traderInfo in report.options.사업자_set" :key="traderInfo.납세자ID">
                                    <td>{{ traderInfo.법인명_상호 }}</td>
                                    <td :class="{'text-danger': traderInfo.bank_accounts == 0}">{{ traderInfo.bank_accounts }}</td>
                                    <td :class="{'text-danger': traderInfo.bank_accounts == 0}">{{ traderInfo.bank_transactions }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <p v-if="!taxreport.checklist['은행계좌 연동']">
                            만약 대표자 개인용 계좌를 사업용으로 쓰고 있으시다면 현황판의 은행잔고에서 계좌를 사업자에 할당해주세요.
                            이미 은행계좌를 모두 할당하셨으면 <b-button variant="outline-primary" size="sm" @click="reload(true)">재확인</b-button>을 해보세요.
                        </p>
                    </b-modal>

                    <b-modal id="과세기간의-거래내역-수집-modal" title="과세기간의 거래내역 수집" ok-only ok-title="닫기">
                        <p>과세기간의 거래내역이 모두 수집된 이후에 종합소득세 신고가 가능합니다. 수집이 완료되면 자동으로 완료로 표시됩니다.</p>
                    </b-modal>

                    <BankAccountSelectorModal :금융기관코드="this.report.records[0].은행코드_국세환급금"
                                              :계좌번호="this.report.records[0].계좌번호_국세환급금"
                                              @select="setRefundAccount($event)"/>
                </div>
            </div>

            <div class="alert alert-warning m-3" v-if="overDeadline || report.신고시작일자.isAfter()">
                <p><span v-if="!overDeadline">아직</span> 신고기간이 아닙니다.</p>
                <p>신고기간: <strong>{{ localDateFormat(report.신고시작일자) }} ~ {{ localDateFormat(report.납기일) }}</strong></p>
            </div>

            <template  v-if="!report.신고시작일자.isAfter()">
                <div class="card m-3" v-if="!overDeadline && !report.신고일 && !report.finalized">
                    <div class="card-header">
                        종합소득세 신고하기
                    </div>
                    <div class="card-body">
                        <p>
                            종합소득세 신고는 홈택스를 이용해주시기 바랍니다.
                            그동안 키퍼에서도 종합소득세 신고 기능을 제공해왔지만 최근 홈택스의 종합소득세 신고 기능이 좋아져서 홈택스에서
                            신고하시는 것이 키퍼보다 더 정확하고 안정적이기 때문에 홈택스를 추천드립니다.
                        </p>
<!--                        <p>-->
<!--                            종합소득세 신고서 제출 비용은 <strong class="text-underline" v-b-popover.hover.bottom="'키퍼 정식버전을 이용하시면 부가가치세는 무료로 신고할 수 있고, 종합소득세는 1만 1천원이 과금됩니다. '">{{ moneyFormat(globals.종합소득세_신고비용) }}</strong>이며,-->
<!--                            신고하기를 누르면 정식버전에 등록된 결제수단을 통해 결제됩니다.</p>-->
<!--                        <p class="alert alert-danger" v-if="!allChecklistDone">신고 전 확인사항이 모두 확인되지 않았습니다. 위에서 확인사항들을 모두 읽어보시고 확인해주시면 신고를 진행할 수 있습니다.</p>-->
<!--                        <div class="m-3">-->
<!--                            <button class="btn btn-primary btn-block" :disabled="!allChecklistDone" @click="sendReport">신고하기</button>-->
<!--                        </div>-->
<!--                        <p class="text-muted">여러 번 신고하기를 눌러도 결제는 한 번만 실행됩니다.</p>-->
                    </div>
                </div>
                <div class="card m-3" v-else-if="!report.신고일 && report.finalized">
                    <div class="card-header">
                        종합소득세 신고가 키퍼에 접수되었습니다.
                    </div>
                    <div class="card-body">
                        <p>
                            <strong>종합소득세 신고가 접수되었습니다.</strong>
                            접수된 신고서는 키퍼의 신고 담당자가 한 번 더 검토한 후에 문제가 없으면 홈택스에 제출하며 1~2일 정도 소요됩니다.
                            신고가 완료되면 알림을 보내드리며, 혹시 수정이 필요한 내용이 있을 경우에도 알림을 보내드리니 키퍼에서 알림이 오면 확인 부탁드립니다.
                        </p>
                    </div>
                </div>
                <div class="card m-3" v-else-if="report.신고일">
                    <div class="card-header">
                        종합소득세 신고가 완료되었습니다.
                    </div>
                    <div class="card-body">
                        <p>
                            <strong>홈택스에 종합소득세를 정상적으로 신고했습니다.</strong>
                        </p>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
    import {api} from "@/util/api";
    import {validate주민등록번호} from "@/models";
    import {saveGlobal} from "@/globals";
    import moment from "moment";
    import RegistrationNumberModal from "@/views/taxreport/RegistrationNumberModal";
    import DisclaimerModal from "@/views/taxreport/DisclaimerModal";
    import BankAccountSelectorModal from "@/views/taxreport/BankAccountSelectorModal";

    export default {
        name: "IncomeTaxReport",
        components: {BankAccountSelectorModal, DisclaimerModal, RegistrationNumberModal},
        props: ['id'],
        data() {
            return {
                report: null,  // robotax report
                taxreport: null,  // selftax report
                사업자: null,
                대표자주민등록번호_뒷자리: '',
                hometaxDocument: null,
                checklist: {
                    '자가신고 확인': {},
                    '신고유형 및 신고 범위 확인': {},
                    '과세기간의 거래내역 수집': {showLoading: true,},
                    '은행계좌 연동': {},
                    '대표자 주민등록번호': {},
                    '환급계좌 입력': {},
                },
                checklistExpanded: true,
                공통은행코드: null,
                deadlines: {
                    '2020': moment('2021-05-31 18:00'),
                    '2021': moment('2022-05-31 18:00')
                },
            }
        },
        computed: {
            overDeadline() {
                if (!this.report) { return false }
                return moment() >= this.deadlines[this.report.과세기간_년월.substring(0, 4)]
            },
            nonFullVersionTraders() {
                return this.사업자.ownTraders.filter(t => !t.폐업일 && t.plan != '정식버전')
            },
            allChecklistDone: function() {
                return this.taxreport && this.taxreport.status !== 'DRAFT'
            },
            checklistVisible: function() {
                return this.checklistExpanded
            },
            is대표자주민등록번호Valid() {
                if (!this.사업자) {
                    return false
                }
                return validate주민등록번호(this.사업자.납세자ID.substring(0, 6) + this.대표자주민등록번호_뒷자리)
            }
        },
        watch: {
            id(to, from_) {
                this.reload()
            }
        },
        async mounted() {
            await this.reload()
            await this.globals.codes.get('bank/')
        },
        methods: {
            async reload(loaddata) {
                this.공통은행코드 = (await this.globals.codes.get('공통은행코드/')).data
                this.report = (await this.globals.종합소득세.get(this.id + '/')).object
                this.사업자 = await this.globals.사업자.getObject(this.report.사업자)
                this.대표자주민등록번호_뒷자리 = this.사업자.납세자ID.substring(6)
                this.taxreport = (await api.get(`taxreport/${this.id}/종합소득세/`)).data

                if (loaddata) {
                    Object.assign(this.report, (await api.post(`robotax/종합소득세/${this.id}/loaddata/`)).data)
                    Object.assign(this.taxreport, (await api.get(`taxreport/${this.id}/종합소득세/`)).data)
                }
            },
            updateChecklist: async function(item, value) {
                Object.assign(this.taxreport, (await api.patch(`taxreport/${this.taxreport.id}/`, {
                    checklist: {...this.taxreport.checklist, [item]: value}
                })).data)
            },
            async setRefundAccount(account) {
                api.patch(`robotax/사업자/${this.globals.trader.robotax_id}/`, {
                    예입처_은행코드: account.금융기관코드,
                    계좌번호: account.account_no,
                }).then(async () => {
                    Object.assign(this.report, (await api.post(`robotax/종합소득세/${this.report.id}/loaddata/`)).data)
                    await this.updateChecklist('환급계좌 입력', false)  // value will be overwritten by server
                }).catch(err => {
                    alert(err)
                })
            },
            goToPay(trader) {
                if (confirm(`사업자를 ${trader.법인명_상호}로 선택한 후 정식버전 안내 페이지로 이동하시겠습니까? 이동한 후에 현재 화면으로 돌아오려면 다시 ${this.globals.trader.법인명_상호} 개인을 선택하고 종합소득세를 선택하셔야 합니다.`)) {
                    saveGlobal('trader', trader)
                    this.$router.push({name: '정식버전 결제', query: {back: this.$route.fullPath}})
                }
            },
            selectPerson() {
                saveGlobal('trader', this.globals.user.사업자_set.find(t => t.robotax_id == this.report.사업자))
                this.$router.replace({name: '현황판'})
            },
            async sendReport() {
                if (!this.allChecklistDone) {
                    alert('신고 전 확인사항의 항목들을 확인해주세요.')
                    return false
                }
                Object.assign(this.report, (await api.post(`hometax/${this.report.id}/종합소득세신고/`)).data)
                await this.$bvModal.msgBoxOk('종합소득세 신고가 접수되었습니다. 키퍼의 담당자가 확인한 후 홈택스를 통해 신고하기까지 보통 3시간 정도 소요되며, 신고가 완료되면 알림을 보내드립니다. 업무시간(평일 오전 9시 ~ 오후 6시) 외에 접수된 신고는 다음 근무일 오전 중에 신고합니다.', {okTitle: '확인', centered: true, title: '종합소득세 신고 접수'})
            }
        }
    }
</script>

