<template>
    <span>
        <i class="fas fa-chevron-down"></i>
        <i class="fas fa-chevron-up"></i>
    </span>
</template>
<script>
    export default {
        name: 'collapse-indicator'
    }
</script>