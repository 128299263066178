<template>
    <div class="h-100 overflow-auto container-fluid" v-if="report && taxreport">
        <div class="row">
            <div class="col-12 p-0">
                <div class="card m-3 border border-danger" v-if="globals.trader.plan != '정식버전'">
                    <div class="card-header border-bottom border-danger">
                        정식버전 확인
                    </div>
                    <div class="card-body px-0">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td>{{ globals.trader.법인명_상호 }}</td>
                                    <td class="text-right">
                                        <i class="fas fa-check text-success" v-if="globals.trader.plan == '정식버전'"></i>
                                        {{ globals.trader.plan }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p class="mx-3 text-danger" v-if="globals.trader.plan != '정식버전'">
                            <i class="fas fa-exclamation-triangle"></i> 부가가치세 신고는 무료지만, 정식버전 사업자만 가능합니다.
                        </p>
                        <div class="p-3">
                            <router-link class="w-100 btn btn-primary" :to="{name: '정식버전 결제', query: {back: $route.fullPath}}">정식버전 등록하기</router-link>
                        </div>
                    </div>
                </div>

                <div class="card m-3">
                    <div class="card-header border-bottom">
                        <strong>부가가치세 신고 자동화 서비스</strong>
                    </div>
                    <div class="card-body" v-if="report.status.label == '신고기간 아님'">
                        <p>아직 신고기간이 아닙니다. </p>
                    </div>
                    <div class="card-body" v-else>
                        <table class="table">
                            <tbody>
                                <tr>
                                    <th>홈택스 신고납부</th>
                                    <td :class="'text-' + report.status.color">{{ report.status.label }}</td>
                                </tr>
                                <tr v-if="report.finalized">
                                    <th>접수일</th>
                                    <td>{{ localDateFormat(report.finalized) }}</td>
                                </tr>
                                <tr v-if="report.신고일">
                                    <th>신고일</th>
                                    <td>{{ localDateFormat(report.신고일) }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="text-danger" v-if="overdue">
                            부가가치세 신고 기한이 지났습니다. 기한 후 신고는 하루라도 빨리 신고하셔야 가산세누적을 줄이실 수 있습니다.
                        </div>
                        <div v-if="report.신고일">
                            <p>
                                <span>
                                    신고가 완료되었습니다.
                                    <strong class="text-danger">{{ localDateFormat(report.납기일) }}</strong>까지 납부서를 확인하시고 납부해주세요.
                                </span>
                            </p>
                        </div>
                        <div v-else-if="report.finalized">
                            <p class=" mt-3">
                                <strong>부가가치세 신고가 접수되었습니다.</strong> 홈택스에 신고가 완료되기까지 보통 3시간 정도 소요되며, 신고가 완료되면 알림을 보내드립니다. (<router-link style="text-decoration: underline" :to="{name: '설정'}">알림설정</router-link>)
                                업무시간(평일 오전 9시 ~ 오후 6시) 이외에 접수된 신고는 <strong>다음 근무일 오전</strong> 중에 신고됩니다.
                            </p>
                        </div>

                        <div v-else>
                            <p class="mt-3">
                                키퍼를 이용해서 홈택스에 자가신고를 해보세요. 신고를 접수하시면 키퍼에서 담당자가 한 번 검수한 다음 홈택스에 신고서를 올려드립니다.
                            </p>
                        </div>
                        <p class="mt-3 text-danger" v-if="!canReport">
                            <i class="fas fa-exclamation-triangle "></i> 아래의 신고 전 확인사항들을 확인해주세요.
                        </p>
                        <template v-if="report">
                            <div class="text-center m-4">
                                <button class="btn btn-block btn-primary" @click="sendReport" :disabled="!canReport"> <span v-if="overdue">기한 후 </span>신고 접수하기</button>
                            </div>
                            <p class="mt-3">신고기간 중에는 여러 번 수정해서 신고하실 수 있습니다. 태그를 고치는 등의 수정사항이 발생했을 경우 다시 신고를 접수하세요.</p>
                        </template>
                    </div>
                    <div class="card-footer border-top text-center" v-if="report.hometax">
                        <router-link class="d-block text-underline"
                                     :to="{name: '세금신고납부 상세', params: {id: report.hometax.id, transition: 'push'}}">
                            홈택스에 실제로 신고한 내역 보기 <i class="fas fa-chevron-right"></i>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="col-12 p-0">
                <div class="card border m-3" :class="canReport ? 'border-success' : 'border-danger'">
            <div class="card-header border-bottom" :class="canReport ? 'border-success' : 'border-danger'" @click="checklistExpanded = !checklistExpanded">
                <strong>신고 전 확인사항</strong>
                <span class="ml-2">
                    {{ Object.keys(checklist).filter(k => taxreport.checklist[k]).length }}
                    / {{ Object.keys(checklist).length }}
                </span>
                <span class="ml-2" v-if="canReport"><i class="fas fa-check text-success"></i> </span>
                <div class="float-right">
                    <i class="fas fa-chevron-up" v-if="checklistVisible"></i>
                    <i class="fas fa-chevron-down  float-right mt-1" v-else></i>
                </div>
            </div>
            <div class="card-body">
                <table class="table" v-show="checklistVisible">
                    <tbody>
                        <tr>
                            <td class="py-2" style="font-size: 1.4em">
                                <i class="far fa-check-circle text-success" v-if="globals.trader.plan == '정식버전'"></i>
                                <i class="far fa-circle" v-else></i>
                            </td>
                            <td>
                                <strong v-if="globals.trader.plan == '정식버전'">정식버전 확인</strong>
                                <span v-else>정식버전 확인</span>
                            </td>
                        </tr>
                        <tr v-for="(item, itemName) in listed_checklist" :key="itemName" v-b-modal="itemName.replace(/ /g, '-') + '-modal'">
                            <td class="py-2" style="font-size: 1.4em">
                                <b-spinner class="ml-1 mb-1" small variant="primary" v-if="!taxreport.checklist[itemName] && item.showLoading"></b-spinner>
                                <i class="far fa-check-circle text-success" v-else-if="taxreport.checklist[itemName]"></i>
                                <i class="far fa-circle" v-else></i>
                            </td>
                            <td>
                                <strong v-if="taxreport.checklist[itemName]">{{ itemName }}</strong>
                                <span v-else>{{ itemName }}</span>
                            </td>

                        </tr>
                    </tbody>
                </table>
                <DisclaimerModal @ok="updateChecklist('자가신고 확인', true)"
                                 @cancel="updateChecklist('자가신고 확인', false)"/>

                <RegistrationNumberModal :사업자="사업자"
                         :valid="taxreport.checklist['대표자 주민등록번호']"
                         @update="updateChecklist"/>

                <b-modal id="과세기간의-거래내역-수집-modal" title="과세기간의 거래내역 수집" ok-only ok-title="닫기" centered>
                    <p>
                        부가가치세 신고서를 완성하려면 홈택스를 비롯한 여러 금융기관에서 거래내역을 모두 동기화해야 합니다.
                        현재 진행상황은 현황판 상단 <span class="text-primary">데이터 동기화</span>를 누르시면 확인하실 수 있습니다.
                    </p>
                    <p>데이터가 모두 연동되고 나면 자동으로 완료되며, 알림을 보내드리니 잠시만 기다려주세요.</p>
                    <p>
                        동기화가 완료되고 나면 <router-link :to="{name: '부가가치세 증빙자료'}" exact exact-active-class="active" replace><strong>증빙자료</strong></router-link>
                        탭에서 확인하실 수 있습니다. 여기서 확인해보시고 모든 증빙자료가 들어온 것으로 확인되면 다음 절차를 진행해주세요.</p>

                </b-modal>
                <b-modal id="종이세금계산서-확인-modal" title="종이세금계산서 확인" centered
                         ok-title="모두 확인했어요" @ok="updateChecklist('종이세금계산서 확인', true)"
                         cancel-title="아직 확인하지 않았어요" @cancel="updateChecklist('종이세금계산서 확인', false)">
                    <p>
                        종이 세금계산서나 종이 계산서를 발급하거나 수취한 내역이 있으면 간편장부의 우상단 <i class="fas fa-plus"></i> 버튼을
                        눌러서 등록해주세요.
                    </p>
                    <p>
                        <router-link :to="{name: '거래내역',
                                query: {
                                    증빙종류: ['세금계산서'],
                                    전자세금: false,
                                    dateFilterLabel: report.title() + ' 부가가치세 기간',
                                    ...거래일Filter,
                                    push: true},
                                params: {transition: 'push'}}">
                            <button class="btn btn-outline-primary">
                                전표에서 확인하기 <i class="fas fa-chevron-right"></i>
                            </button>
                        </router-link>
                    </p>
                </b-modal>
                <b-modal id="지원되지-않는-신고-항목-확인-modal"
                         title="지원되지 않는 신고 항목 확인"
                         ok-title="확인했습니다" @ok="updateChecklist('지원되지 않는 신고 항목 확인', true)"
                         cancel-title="잘 모르겠어요" @cancel="updateChecklist('지원되지 않는 신고 항목 확인', false)">
                    <p>현재 {{ globals.appTitle }}는 다음과 같은 신고 항목을 지원하지 않습니다. 다음 중 하나에 해당하는 게 있으면 세무사에게 신고대리를 맡기셔야 합니다.</p>
                    <ul>
                        <li>일반과세자와 간이과세자 간 전환</li>
                        <li>부동산 임대 공급</li>
                        <li>대손세액 공제</li>
                        <li>수출</li>
                        <li>전자화폐 결제</li>
                        <li>중고 금 매입</li>
                        <li>내국신용장</li>
                        <li>원산지 확인서</li>
                        <li>동물진료 용역</li>
                        <li>외국인 관광객 면세물품 판매</li>
                        <li>구리 스크랩 등</li>
                        <li>관세 환급금</li>
                        <li>외국인물품 외교관면세판매</li>
                        <li>선박 운송 용역</li>
                        <li>사업 양도</li>
                        <li>외국인관광객 즉시환급 물품 판매</li>
                    </ul>
                </b-modal>
                <b-modal id="고정자산-매입내역-확인-modal" title="고정자산 매입 내역 확인"
                         ok-title="모두 확인했어요" @ok="updateChecklist('고정자산 매입내역 확인', true)"
                         cancel-title="아직 확인하지 않았어요" @cancel="updateChecklist('고정자산 매입내역 확인', false)">
                    <p>고정자산을 매입한 내역이 있으면 거래내역에서 <strong>감가상각자산</strong> 태그를 붙여주세요. 태깅을 완료하고 이 항목에 체크해주시면 감가상각자산 취득명세서를 자동으로 만들어서 신고서에 첨부해드립니다. </p>
                    <p>고정자산의 종류는 다음과 같습니다. 다음 항목 중 하나를 선택하면 거래내역에서 해당 태그를 붙일 수 있습니다.</p>
                    <ul>
                        <li class="mb-1"><router-link class="btn btn-sm" :class="'btn-outline-' + globals.TAG_COLORS['감가상각자산']" :to="{name: '거래내역', query: {select_tag: '감가상각자산/건물', 매출매입: '매입', push: true, ...거래일Filter}, params: {transition: 'push'}}">건물</router-link></li>
                        <li class="mb-1"><router-link class="btn btn-sm" :class="'btn-outline-' + globals.TAG_COLORS['감가상각자산']" :to="{name: '거래내역', query: {select_tag: '감가상각자산/구축물', 매출매입: '매입', push: true, ...거래일Filter}, params: {transition: 'push'}}">구축물</router-link></li>
                        <li class="mb-1"><router-link class="btn btn-sm" :class="'btn-outline-' + globals.TAG_COLORS['감가상각자산']" :to="{name: '거래내역', query: {select_tag: '감가상각자산/기계장치', 매출매입: '매입', push: true, ...거래일Filter}, params: {transition: 'push'}}">기계장치</router-link></li>
                        <li class="mb-1"><router-link class="btn btn-sm" :class="'btn-outline-' + globals.TAG_COLORS['감가상각자산']" :to="{name: '거래내역', query: {select_tag: '감가상각자산/차량운반구', 매출매입: '매입', push: true, ...거래일Filter}, params: {transition: 'push'}}">차량운반구</router-link></li>
                        <li class="mb-1"><router-link class="btn btn-sm" :class="'btn-outline-' + globals.TAG_COLORS['감가상각자산']" :to="{name: '거래내역', query: {select_tag: '감가상각자산/기타감가상각', 매출매입: '매입', push: true, ...거래일Filter}, params: {transition: 'push'}}">기타감가상각</router-link></li>
                    </ul>
                </b-modal>
                <b-modal id="의제매입세액-확인-modal" title="의제매입세액 확인"
                         ok-title="모두 확인했어요" @ok="updateChecklist('의제매입세액 확인', true)"
                         cancel-title="아직 확인하지 않았어요" @cancel="updateChecklist('의제매입세액 확인', false)">
                    <p>
                        <strong>의제매입세액</strong>은 농/축/수/임산물을 면세로 구입하고 원재료로 사용하여
                        부가가치세가 과세되는 재화를 제조ㆍ가공하거나 용역을 창출하는 사업자에 대하여
                        일정금액을 매입세액으로 공제하여 주는 것입니다.
                        농/축/수/임산물을 구입한 전표에 대해 의제매입세액 태그를 붙여주시면 자동으로 공제액 계산이 되고 의제매입세액 공제신고서가 작성됩니다.
                    </p>
                    <p>
                        <router-link :to="{name: '거래내역',
                                query: {
                                    매출매입: '매입',
                                    부가세유형: '면세',
                                    select_tag: '부가가치세/의제매입세액',
                                    dateFilterLabel: report.title() + ' 부가가치세 기간',
                                    ...거래일Filter,
                                    push: true},
                                params: {transition: 'push'}}">
                            <button class="btn btn-outline-primary">
                                전표에서 확인하기 <i class="fas fa-chevron-right"></i>
                            </button>
                        </router-link>
                    </p>
                </b-modal>
                <b-modal id="불공제-확인-modal"
                         title="불공제 확인"
                         ok-title="모두 확인했어요" @ok="updateChecklist('불공제 확인', true)"
                         cancel-title="아직 확인하지 않았어요" @cancel="updateChecklist('불공제 확인', false)">
                    <p>
                        키퍼에서 가져온 거래내역은 <b>기본적으로 모두 공제처리</b>되어 있습니다.
                        <b>사업과 관련없는 지출</b> 등 아래 사유에 해당하는 거래내역은 확인하여 <b>불공제처리</b>를 해주세요.
                        <b>아래 태그를 터치하면</b> 거래내역에서 바로 거래내역을 선택할 수 있어요.
                        <br>
                        <small class="mt-1 text-danger">* 아래 사유에 해당하는 데도 공제처리를 하게되면 <b>추후 불이익</b>이 발생할 수 있으니 꼭 확인해주세요!</small>
                    </p>
                    <ul>
                        <li class="mb-1"><router-link class="btn btn-sm" :class="'btn-outline-' + globals.TAG_COLORS['부가가치세']" :to="{name: '거래내역', query: {select_tag: '부가가치세/불공제사유구분/사업과 직접 관련 없는 지출', 매출매입: '매입', 부가세유형: '과세', push: true, ...거래일Filter}, params: {transition: 'push'}}">사업과 직접 관련 없는 지출</router-link></li>
                        <li class="mb-1"><router-link class="btn btn-sm" :class="'btn-outline-' + globals.TAG_COLORS['부가가치세']" :to="{name: '거래내역', query: {select_tag: '부가가치세/불공제사유구분/비영업용 소형승용차 구입, 유지 및 임차', 매출매입: '매입', 부가세유형: '과세', push: true, ...거래일Filter}, params: {transition: 'push'}}">비영업용 소형승용자동차 구입ㆍ유지 및 임차</router-link></li>
                        <li class="mb-1"><router-link class="btn btn-sm" :class="'btn-outline-' + globals.TAG_COLORS['부가가치세']" :to="{name: '거래내역', query: {select_tag: '부가가치세/불공제사유구분/접대비 및 이와 유사한 비용 관련', 매출매입: '매입', 부가세유형: '과세', push: true, ...거래일Filter}, params: {transition: 'push'}}">접대비 및 이와 유사한 비용 관련</router-link></li>
                        <li class="mb-1"><router-link class="btn btn-sm" :class="'btn-outline-' + globals.TAG_COLORS['부가가치세']" :to="{name: '거래내역', query: {select_tag: '부가가치세/불공제사유구분/면세사업 관련', 매출매입: '매입', 부가세유형: '과세', push: true, ...거래일Filter}, params: {transition: 'push'}}">면세사업등 관련</router-link></li>
                        <li class="mb-1"><router-link class="btn btn-sm" :class="'btn-outline-' + globals.TAG_COLORS['부가가치세']" :to="{name: '거래내역', query: {select_tag: '부가가치세/불공제사유구분/토지의 자본적 지출 관련', 매출매입: '매입', 부가세유형: '과세', push: true, ...거래일Filter}, params: {transition: 'push'}}">토지의 자본적 지출 관련</router-link></li>
                        <li class="mb-1"><router-link class="btn btn-sm" :class="'btn-outline-' + globals.TAG_COLORS['부가가치세']" :to="{name: '거래내역', query: {select_tag: '부가가치세/불공제사유구분/사업자등록 전 매입세액', 매출매입: '매입', 부가세유형: '과세', push: true, ...거래일Filter}, params: {transition: 'push'}}">사업자등록 전 매입세액</router-link></li>
                        <li class="mb-1"><router-link class="btn btn-sm" :class="'btn-outline-' + globals.TAG_COLORS['부가가치세']" :to="{name: '거래내역', query: {select_tag: '부가가치세/불공제사유구분/금, 구리스크랩 거래계좌 미사용 관련 매입세액', 매출매입: '매입', 부가세유형: '과세', push: true, ...거래일Filter}, params: {transition: 'push'}}">금ㆍ구리 스크랩 거래계좌 미사용 관련 매입세액</router-link></li>
                        <li class="mb-1"><router-link class="btn btn-sm" :class="'btn-outline-' + globals.TAG_COLORS['부가가치세']" :to="{name: '거래내역', query: {select_tag: '부가가치세/불공제사유구분/공통매입세액 안분계산서 분', 매출매입: '매입', 부가세유형: '과세', push: true, ...거래일Filter}, params: {transition: 'push'}}">공통매입세액 안분계산서 분</router-link></li>
                    </ul>
                </b-modal>
                <b-modal id="매입자발행세금계산서-확인-modal" title="매입자발행세금계산서 확인"
                         ok-title="모두 확인했어요" @ok="updateChecklist('매입자발행세금계산서 확인', true)"
                         cancel-title="아직 확인하지 않았어요" @cancel="updateChecklist('매입자발행세금계산서 확인', false)">
                    <p>
                        일반적으로 세금계산서는 매출이 발생했을 때 공급자가 발행합니다.
                        그러나, 이미 돈을 주고 재화나 용역을 공급 받았는데도 공급자가 세금계산서를 발행해주지 않는 경우가 있습니다.
                        이런 경우, 공급받은 자가 세금계산서 발급시기부터 3개월 이내에 “거래사실 확인 신청서”에 영수증, 무통장입금증 등 증빙서류를 첨부하여
                        신청인 관할 세무서장에 신청(거래사실 입증책임은 신청인에게 있음)하면 세금계산서를 발행할 수 있습니다.
                        이것을 <strong>매입자발행세금계산서</strong>라고 하며, 이런 경우가 있었다면 전표에 매입자발행세금계산서 태그를 붙여야 합니다.
                    </p>
                    <p>일반적인 매입 세금계산서에는 붙이면 안되니 주의하시기 부탁드립니다.</p>
                    <p>
                        <router-link :to="{
                                name: '거래내역',
                                query: {
                                    증빙종류: '세금계산서',
                                    매출매입: '매입',
                                    dateFilterLabel: report.title() + ' 부가가치세 기간',
                                    select_tag: '부가가치세/매입자발행세금계산서',
                                    ...거래일Filter,
                                    push: true},
                                params: {transition: 'push'} }">
                            <button class="btn btn-outline-primary">
                                전표에서 확인하기 <i class="fas fa-chevron-right"></i>
                            </button>
                        </router-link>
                    </p>
                </b-modal>

                <BankAccountSelectorModal :금융기관코드="report.환급계좌_은행코드"
                                          :계좌번호="report.환급계좌_계좌번호"
                                          @select="setRefundAccount($event)"/>
            </div>
        </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Decimal from "decimal.js";
    import api from "@/util/api";
    import {saveGlobal} from '@/globals'
    import moment from "moment";
    import DisclaimerModal from "@/views/taxreport/DisclaimerModal";
    import RegistrationNumberModal from "@/views/taxreport/RegistrationNumberModal";
    import BankAccountSelectorModal from "@/views/taxreport/BankAccountSelectorModal";


    export default {
        name: "VatReportConfirm",
        components: {BankAccountSelectorModal, RegistrationNumberModal, DisclaimerModal},
        props: ['id'],
        data() {
            return {
                report: null,  // robotax report
                taxreport: null,  // selftax report
                hometaxDocument: null,
                checklist: {
                    '자가신고 확인': {},
                    '지원되지 않는 신고 항목 확인': {},
                    '과세기간의 거래내역 수집': {showLoading: true,},
                    '대표자 주민등록번호': {},
                    '불공제 확인': {},
                    '종이세금계산서 확인': {},
                    '의제매입세액 확인': {},
                    '매입자발행세금계산서 확인': {},
                    '고정자산 매입내역 확인': {},
                    '환급계좌 입력': {},
                },
                checklistExpanded: true,
                대표자주민등록번호: '',
            }
        },
        computed: {
            canRefund: function() {
                return new Decimal(this.report.records[1].차감납부할세액).isNegative()
            },
            canReport: function() {
                // TODO plan확인도 checklist에 편입. 현재는 스크립트에서 처리
                return this.globals.trader.plan == '정식버전' && this.taxreport.status !== 'DRAFT'
            },
            checklistVisible: function() {
                return this.checklistExpanded
            },
            listed_checklist: function () {
                return Object.keys(this.checklist).filter(key => !this.checklist[key].unlisted).reduce((obj, key) => {
                    obj[key] = this.checklist[key]
                    return obj
                }, {})
            },
            거래일Filter: function () {
                return {
                    거래일__gte: this.report.과세기간시작일자,
                    거래일__lte: this.report.과세기간종료일자,
                }
            },
            신고구분상세코드_label: function () {
                if (!this.report) { return '' }
                const pairs = this.globals.codes.cache['부가가치세_신고구분상세코드/'].filter(c => c[0] === this.report.신고구분상세코드)
                if (!pairs.length) { return '' }
                return pairs[0][1]
            },
            overdue () {
                if (!this.report) { return false }
                return moment().isAfter(moment(this.report.납기일), 'day') && !this.report.신고일
            }
        },
        async mounted() {
            this.report = (await this.globals.부가가치세.get(this.id + '/')).object
            this.report.loaddata()
            await this.report.loadHometax()
            this.사업자 = await this.globals.사업자.getObject(this.report.사업자)

            this.taxreport = (await api.get(`taxreport/${this.id}/부가가치세/`)).data
            let documents = (await api.get(`robotax/hometaxdocument/?report_type=부가가치세&report_id=${this.report.id}`)).data
            this.hometaxDocument = documents.results.length ? documents.results[0] : null
            this.대표자주민등록번호 = this.globals.trader.대표자주민등록번호.substring(6, 13).replace('*', '')
        },
        methods: {
            sendReport: async function() {
                if (!this.canReport) {
                    gtag('event', 'vat_try_error_not_ready')
                    alert('신고 전 확인사항의 항목들을 확인해주세요.')
                    return false
                }
                Object.assign(this.report, (await api.post(`hometax/${this.report.id}/부가가치세신고/`)).data)
                this.$bvModal.msgBoxOk('부가가치세 신고가 접수되었습니다. 키퍼의 담당자가 확인한 후 홈택스를 통해 신고하기까지 보통 3시간 정도 소요되며, 신고가 완료되면 알림을 보내드립니다. 업무시간(평일 오전 9시 ~ 오후 6시) 외에 접수된 신고는 다음 근무일 오전 중에 신고합니다.', {okTitle: '확인', centered: true, title: '부가가치세 신고 접수'})
            },
            checklistRoute(itemName) {
                let item = this.checklist[itemName]
                return {
                    name: item.link.name,
                    query: {...item.link.query, ...this.거래일Filter},
                    params: {...item.link.params, report: this.report}
                }

            },
            handleRegNo: async function(bvModalEvt) {
                bvModalEvt.preventDefault()
                await this.updateRegNo()
            },
            updateRegNo: async function() {
                gtag('event', 'vat_update_registration_number')
                let robotax_trader = await api.patch(`robotax/사업자/${this.globals.trader.robotax_id}/`, {
                    '대표자주민등록번호': this.globals.trader.대표자주민등록번호.substring(0, 6) + this.대표자주민등록번호
                })
                await this.updateChecklist()
                if (!this.taxreport.checklist['대표자 주민등록번호 입력']) {
                    alert('유효한 주민등록번호가 아닙니다.')
                    return
                }

                let trader = await api.patch(`사업자/${this.globals.trader.id}/`, {
                    '대표자주민등록번호': robotax_trader.data['대표자주민등록번호']
                })
                saveGlobal('trader', trader.data)
                this.$nextTick(() => {
                    this.$bvModal.hide('대표자-주민등록번호-입력-help')
                })
            },
            updateChecklist: async function(item, value) {
                gtag('event', 'vat_update_checklist')
                Object.assign(this.taxreport, (await api.patch(`taxreport/${this.taxreport.id}/`, {
                    checklist: {...this.taxreport.checklist, [item]: value}
                })).data)
            },
            async setRefundAccount(account) {
                if (this.report.환급계좌_은행코드 && this.report.환급계좌_계좌번호
                    && this.report.환급계좌_은행코드 == account.hometax_bank_code || account.금융기관코드 && this.report.환급계좌_계좌번호 == account.account_no) {
                    this.report.환급계좌_은행코드 = null
                    this.report.환급계좌_계좌번호 = null
                } else {
                    this.report.환급계좌_은행코드 = account.hometax_bank_code || account.금융기관코드
                    this.report.환급계좌_계좌번호 = account.account_no
                }
                await this.globals.부가가치세.saveObject(this.report)

                await this.updateChecklist('환급계좌 확인', false)  // value will be overwritten by server
            }
        }

    }
</script>

<style scoped>

</style>
