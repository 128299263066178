<template>
    <div class="h-100">
        <induce-cert class="p-3" v-if="!globals.trader">
            <p>
                <strong>홈택스</strong>에 접속 가능한 <strong>공인인증서</strong>를 등록하시면 홈택스에 신고한 <strong>세금내역, 납부해야 할 내역, 환급 결정된 내역</strong> 등을 조회하실 수 있어요.
            </p>
        </induce-cert>
        <split-view v-else>
            <div class="h-100 d-flex flex-column">
                <nav class="navbar navbar-light titlebar pt-0 flex-shrink-0">
                    <back-button></back-button>
                    <h5 class="mt-2">내 세금 내역</h5>
                    <span></span>
                </nav>
                <b-nav class="bg-white p-2 shadow flex-shrink-0" pills style="z-index: 1">
                    <b-nav-item :to="{name: '세금 내역', params: {type: 'vat'}}" active-class="active" replace v-if="globals.trader.사업자구분 != '개인' && globals.robotaxTrader.면세구분 != '면세'"><strong>부가가치세</strong></b-nav-item>
                    <b-nav-item :to="{name: '세금 내역', params: {type: 'bpreport'}}" active-class="active" replace v-if="globals.trader.사업자구분 != '개인' && globals.robotaxTrader.면세구분 == '면세'"><strong>사업장현황신고</strong></b-nav-item>
                    <b-nav-item :to="{name: '세금 내역', params: {type: 'ait'}}" active-class="active" replace v-if="globals.trader.사업자구분 != '법인'"><strong>종합소득세</strong></b-nav-item>
                    <b-nav-item :to="{name: '세금 신고내역'}" active-class="active" replace><strong>신고/고지</strong></b-nav-item>
                    <b-nav-item :to="{name: '세금 납부내역'}" active-class="active" replace>납부</b-nav-item>
                    <b-nav-item :to="{name: '세금 환급내역'}" active-class="active" replace>환급</b-nav-item>
                    <b-nav-item :to="{name: '세금 체납내역'}" active-class="active" replace>체납</b-nav-item>
                </b-nav>
                <div class="flex-grow-1 overflow-auto">
                    <router-view name="sidebar"></router-view>
                </div>
            </div>
        </split-view>
    </div>
</template>

<script>
    import BackButton from "@/components/BackButton";
    import SplitView from "@/components/SplitView";
    import LoginRequired from "@/components/LoginRequired";
    export default {
        name: "TaxMenu",
        mixins: [LoginRequired],
        components: {SplitView, BackButton}
    }
</script>

<style scoped>

</style>