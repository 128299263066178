<template>
    <div class="h-100 d-flex flex-column" v-if="party">
        <nav class="navbar navbar-light titlebar shadow">
            <back-button></back-button>
            <h5 class="mt-2">{{ party.거래처명 }}</h5>
            <span></span>
        </nav>

        <div class="flex-grow-1 overflow-auto">
            <div class="card m-3">
                <div class="card-body">
                    <table class="table">
                        <transition-group tag="tbody" enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__fadeOut">
                            <tr v-for="(field, name) in fields" :key="name" v-show="editMode || field.showOnTop">
                                <th style="width: 120px">{{ field.label || name }}</th>
                                <td class="p-2" style="max-width: 0">
                                    <input class="form-control" v-model="party[name]" :disabled="!editMode">
                                </td>
                            </tr>
                        </transition-group>
                    </table>
                </div>
                <div class="card-footer">
                    <button class="btn btn-outline-info" @click="editMode = true" v-if="!editMode">정보 더보기 / 수정</button>
                    <template v-else>
                        <button class="btn btn-outline-info" @click="editMode = false" v-if="id">취소</button>
                        <button class="ml-1 btn btn-success" @click="save">저장</button>
                    </template>
                    <button class="btn btn-outline-primary float-right" v-b-modal:invoice-select-modal v-if="id">세금계산서 발급</button>
                    <b-modal id="invoice-select-modal" title="세금계산서 발급" ok-only ok-title="취소" scrollable>
                        <p>
                            <strong>{{ party.거래처명 }}</strong>에게 세금계산서를 발급합니다.
                            이미 발급한 세금계산서가 있을 경우 그 중에서 선택하면 해당 세금계산서를 복사해서 발급할 수 있습니다.
                            신규로 발급하려면 거래처의 <strong>사업자등록번호</strong>와 <strong>상호(또는 법인명)</strong>가 정확하게 입력되어 있어야 합니다.
                        </p>
                        <p>
                            세금계산서 종류는 일반 세금계산서, 일반 계산서만 가능하고, 영세율, 수입 등은 지원하지 않습니다.
                        </p>
                        <p class="alert alert-warning">세금계산서 발급기능은 현재 베타버전으로 출시되어 당분간 무료로 제공됩니다.</p>
                        <router-link class="btn btn-primary"
                                     :to="{name: '세금계산서 상세', params: {id: 0}, query: {공급받는자: party.id, mode: 'issue'}}" v-if="party.사업자구분">
                            신규 발급
                        </router-link>
                        <b-button variant="primary" disabled v-else>신규 발급</b-button>
                        <button class="ml-2 btn btn-success" v-b-toggle.invoice-clone>기존 세금계산서 복사 발급</button>
                        <b-collapse id="invoice-clone">
                            <h6 class="mt-2">세금계산서 선택</h6>
                            <table class="table table-hover" v-if="invoicePaginator">
                                <tbody>
                                    <router-link tag="tr"
                                                 :to="{name: '세금계산서 상세', params: {id: invoice.id}, query: {mode: 'issue'}}"
                                                 v-for="invoice in invoicePaginator.objects"
                                                 :key="invoice.id">
                                        <td>
                                            <div>
                                                <span class="">{{ localDateFormat(invoice.data.TaxInvoiceDocument.IssueDateTime) }}</span>
                                                <span class="float-right text-monospace">{{ moneyFormat(invoice.data.TaxInvoiceTradeSettlement.SpecifiedMonetarySummation.ChargeTotalAmount) }}</span>
                                            </div>
                                            <div>
                                                <span class="badge" :class="{'badge-success': invoice.매출매입 == '매출', 'badge-danger': invoice.매출매입 == '매입'}">
                                                    {{ invoice.매출매입 }}
                                                </span>
                                                {{ invoice.data.TaxInvoiceTradeLineItem[0].NameText }}
                                                <span class="badge badge-light" v-if="!invoice.전송일자">홈택스 미확인</span>
                                            </div>
                                        </td>

                                    </router-link>
                                </tbody>
                            </table>
                        </b-collapse>
                    </b-modal>
                </div>
            </div>
            <div class="card m-3" v-if="similar_ones">
                <div class="card-header">
                    이 거래처의 별칭
                    <!-- TODO 별칭 도움말 -->
                </div>
                <div class="card-body">
                    <div>
                        <ul class="list-group mb-2">
                            <li class="list-group-item" v-for="alias in party.거래처별명_set" :key="alias.id">
                                {{ alias.별명 }}
                                <button class="btn btn-sm btn-outline-warning float-right" @click="separate(alias)" v-if="alias.별명 != party.거래처명">연결해제</button>
                            </li>
                        </ul>
                    </div>
                </div>
                <template v-if="similar_ones.length">
                    <div class="card-header">
                        <h6>비슷한 이름의 거래처</h6>
                    </div>
                    <div class="card-body">
                        <p>아래 거래처들은 동일한 거래처일 가능성이 높은 거래처들입니다. 이 거래처들을 연결하면 하나의 거래처로 거래기록을 정리해볼 수 있습니다.</p>
                        <ul class="list-group">
                            <li class="list-group-item" v-for="one in similar_ones" :key="one.id">
                                {{ one.거래처명 }}
                                <button class="btn btn-sm btn-outline-primary float-right" @click="merge(one)">연결</button>
                            </li>
                        </ul>
                    </div>
                </template>
            </div>

            <div class="card m-3" v-if="summary">
                <div class="card-header">
                    <router-link :to="{name: '거래처별 거래', query: {push: true, 거래처__거래처명: party.거래처명}}">
                        거래요약
                        <span class="text-secondary">(전체 기간)</span>
                        <i class="fas fa-chevron-right ml-1"></i>
                    </router-link>
                </div>
                <div class="card-body px-0">
                    <table class="table">
                        <tbody>
                            <router-link :to="{name: '거래처별 거래', query: {push: true, 증빙종류: item.증빙종류, 은행입출금: item.은행입출금, 매출매입: item.매출매입, 거래처__거래처명: party.거래처명}}"
                                         tag="tr"
                                         exact-active-class="table-active"
                                         v-for="item in summary" :key="item.증빙종류 + item.매출매입 + item.은행입출금">
                                <td>{{ item.증빙종류 }} {{ item.은행입출금 }} {{ item.매출매입 }}</td>
                                <td>{{ item.id__count }}건</td>
                                <td class="text-right">{{ moneyFormat(Number(item.공급가액__sum) + Number(item.부가세__sum)) }} <i class="fas fa-chevron-right"></i></td>
                            </router-link>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card m-3" v-if="invoicePaginator && invoicePaginator.objects.length">
                <div class="card-header">
                    최근 세금계산서
                </div>
                <div class="card-body">
                    <table class="table table-hover">
                        <tbody>
                            <router-link tag="tr"
                                         :to="{name: '세금계산서 상세', params: {id: invoice.id}}"
                                         v-for="invoice in invoicePaginator.objects"
                                         :key="invoice.id">
                                <td>
                                    <div>
                                        <span class="">{{ localDateFormat(invoice.data.TaxInvoiceDocument.IssueDateTime) }}</span>
                                        <span class="float-right text-monospace">{{ moneyFormat(invoice.data.TaxInvoiceTradeSettlement.SpecifiedMonetarySummation.ChargeTotalAmount) }}</span>
                                    </div>
                                    <div>
                                        <span class="badge" :class="{'badge-success': invoice.매출매입 == '매출', 'badge-danger': invoice.매출매입 == '매입'}">
                                            {{ invoice.매출매입 }}
                                        </span>
                                        {{ invoice.data.TaxInvoiceTradeLineItem[0].NameText }}
                                        <span class="badge badge-light" v-if="!invoice.전송일자">홈택스 미확인</span>
                                    </div>
                                </td>

                            </router-link>
                        </tbody>
                    </table>
                </div>
            </div>
            <br>
        </div>
    </div>
</template>

<script>
    import Paginator from "@/util/paginator";
    import api from "@/util/api";
    import querystring from "querystring";
    import BackButton from "@/components/BackButton";

    export default {
        name: "PartyDetail",
        components: {BackButton},
        props: ['id'],
        data() {
            return {
                party: null,
                summary: null,
                similar_ones: null,
                invoicePaginator: null,
                editMode: false,
                fields: {
                    거래처명: {},
                    거래처사업자주민번호: {label: '사업자번호', showOnTop: true},
                    대표자명: {showOnTop: true},
                    주소: {},
                    업태: {},
                    업종: {},
                    담당부서명: {},
                    담당자명: {},
                    담당자전화번호: {showOnTop: true},
                    이메일: {showOnTop: true},
                }
            }
        },
        watch: {
            $route() {
                this.reload()
            }
        },
        async mounted() {
            await this.reload();
        },
        methods: {
            async merge(target) {
                await api.put(`robotax/거래처/${this.id}/merge/`, {target: target.id})
                await this.reload()
            },
            async separate(target) {
                await api.put(`robotax/거래처/${this.id}/separate/`, {target: target.id})
                await this.reload()
            },
            reload: async function () {
                if (this.id) {
                    this.party = (await api.get(`robotax/거래처/${this.id}/`)).data
                } else {
                    this.party = Object.fromEntries(Object.keys(this.fields).map(name => [name, '']))
                    this.party.사업자 = this.globals.trader.robotax_id
                    this.editMode = true
                    return
                }

                this.summary = (await api.get('robotax/전표/annotate/?' + querystring.stringify({
                    사업자: this.globals.trader.robotax_id,
                    거래처__거래처명: this.party.거래처명,
                    // 거래일__gte: this.dateFormat(this.period.begin),
                    // 거래일__lte: this.dateFormat(this.period.end),
                    groupby_fields: ['증빙종류', '매출매입', '은행입출금'],
                    count_fields: ['id'],
                    sum_fields: ['공급가액', '부가세'],
                    ordering: '-공급가액__sum',
                }))).data

                this.similar_ones = (await api.get(`robotax/거래처/${this.party.id}/similar_ones/`)).data
                this.invoicePaginator = new Paginator(api.get, `robotax/세금계산서/?` + querystring.stringify({
                    사업자: this.globals.trader.robotax_id,
                    거래처: this.party.거래처사업자주민번호,
                    ordering: '-작성일자'
                }))
                this.invoicePaginator.loadNext()
            },
            async save() {
                let data = Object.assign({}, this.party)
                delete data.거래처별명_set
                this.party = (await api.post('robotax/거래처/', this.party)).data
                this.editMode = false
                await this.$router.replace({params: {id: this.party.id}})
            }
        }
    }
</script>

<style scoped>

</style>
