import {api, silentApi} from "./util/api";
import moment from "moment";
import {globals} from "./globals";

function getAPIClient(silent) {
    return silent ? silentApi : api
}


export class 사업자 {
    /**
     * 로보택스의 사업자를 다룬다.
     * @param data
     */
    constructor(data) {
        Object.assign(this, data)
    }

    /**
     * selftax의 사업자에서 대표자를 찾는다.
     * @returns {Object}
     */
    get owner() {
        if (!globals.user) {
            return null
        }

        if (this.사업자구분 == '개인') {
            return globals.user.사업자_set.filter(t => t.robotax_id == this.id)[0]
        } else {
            return globals.user.사업자_set.filter(t => this.대표자_set.indexOf(t.robotax_id) >= 0)[0]
        }
    }

    /**
     * 연결된 selftax trader를 리턴한다.
     */
    get trader() {
        return globals.user.사업자_set.filter(t => t.robotax_id == this.id)
    }

    get ownTraders() {
        return globals.user.사업자_set.filter(t => this.사업자_set.indexOf(t.robotax_id) >= 0)
    }

    async 대표자_사업자_set() {
        if (this.사업자구분 == '개인') {
            return [this].concat((await api.get(`robotax/사업자/?대표자_set=${this.id}`)).data.results)
        } else if (this.대표자_set.length) {
            let 대표자 = (await api.get(`robotax/사업자/${this.대표자_set[0]}/`)).data
            return [대표자].concat((await api.get(`robotax/사업자/?대표자_set=${this.대표자_set[0]}`)).data.results)
        } else {
            return [this]
        }
    }
}


export class TaxReport {

    constructor(report) {
        Object.assign(this, report)
        if (this.records[0].서식명 == 'v1_1_신고서_Head_레코드') {
            this.세금 = '부가가치세'
        } else if (this.records[0].서식명 == 'A1_종합소득세_과세표준확정신고_HEADER') {
            this.세금 = '종합소득세'
        }

        this.hometax = null
    }

    filter(서식명) {
        return this.records.filter(r => r.서식명 == 서식명)
    }

    get(서식명) {
        let result = this.filter(서식명)
        if (result.length) return result[0]
        return null
    }

    loaddata(silent=false) {
        getAPIClient(silent).post(`robotax/${this.세금}/${this.id}/loaddata/`).then(res => {
            Object.assign(this, res.data)
        })
    }

    async loadHometax() {
        this.hometax = (await api.get(`robotax/hometaxdocument/?report_type=${this.세금}&report_id=${this.id}`)).data.results[0]
    }

    get needsAttention() {
        // loadHometax가 먼저 호출되어야 한다.
        // 홈택스에 신고된 내역이 없거나, 이번 달에 신고한 경우에 보여준다.
        return !this.hometax || moment().isSame(moment(this.hometax.issued), 'month')
    }

    get status() {
        this.reportBegin = moment(this.신고시작일자)
        this.reportEnd = moment(this.납기일)

        if (this.hometax) {
            return globals.TAX_STATUSES[this.hometax.tax_status]
        }

        let today = moment()

        if (today.isBefore(this.reportBegin, 'day')) {
            return {color: 'muted', label: '신고기간 아님', description: '아직 신고기간이 아닙니다.'}
        }
        if (this.finalized) {
            return {color: 'warning', label: '신고 접수됨', description: '신고가 접수되었으며 키퍼 담당자가 검토 중입니다.'}
        }
        if (today.isAfter(this.reportEnd, 'day')) {
            return {color: 'danger', label: '납기일 경과', description: '신고납부기한이 지났습니다. 무신고, 체납 가산세는 시간이 지날수록 커지니, 오늘 바로 신고해보세요.'}
        }

        return {color: 'danger', label: '신고납부기간', description: '현재 신고납부기간입니다. 기한이 지나기 전에 신고하세요.'}
    }
}


export class 부가가치세 extends TaxReport {

    constructor(report) {
        super(report);
    }

    title() {
        return `${this.과세기간_년기_월.slice(0, 4)}년 ${this.과세기간_년기_월[5]}기 ${this.신고구분코드 == '01' ? '확정' : '예정'}`
    }

    amount() {
        return this.records[1].차감납부할세액 || this.records[1].실차감납부할세액
    }

    isNotSameAsHometax() {
        return this.hometax && this.hometax.data.납부서 && this.hometax.data.금액 != this.amount()
    }

    get 신고시작일자() {
        return moment(this.과세기간종료일자).add(1, 'day')
    }

    screens() {
        return {
            '부가가치세 신고서': {
                v1_2_일반과세자_신고서_레코드: {
                    fields: [
                        {
                            label: '과세표준, 매출세액',
                            name: '산출세액',
                            sumOf: [
                                {label: '세금계산서 발급분', name: '매출과세세금계산서발급세액', detail: '매출 세금계산서/계산서 합계표'},
                                {label: '매입자발행 세금계산서', name: '매출과세매입자발행세금계산서세액'},
                                {label: '카드/현금영수증', name: '매출과세카드현금발행세액', detail: '신용카드, 현금영수증 매출'},
                                {label: '기타', name: '매출과세기타세액', detail: '현금매출명세서'},
                                {label: '영세율 세금계산서', name: '매출영세율세금계산서발급세액'},
                                {label: '영세율 기타', name: '매출영세율기타세액'},
                                {label: '예정신고 누락분', name: '매출예정누락합계세액'},
                                {label: '대손세액 가감', name: '매출대손세액가감세액'},
                            ]
                        },
                        {
                            label: '매입세액',
                            name: '차감합계세액',
                            sumOf: [
                                {label: '세금계산서 일반매입', name: '매입세금계산서수취일반세액', detail: '매입 세금계산서 합계표'},
                                {label: '수출기업 수입분 납부유예', name: '수출기업_수입_납부유예'},
                                {label: '고정자산 매입', name: '매입세금계산서수취고정자산세액', detail: '매입 세금계산서 합계표'},
                                {label: '예정신고 누락분', name: '매입예정누락합계세액'},
                                {label: '매입자발행 세금계산서', name: '매입자발행세금계산서매입세액'},
                                {label: '그밖의 공제매입세액', name: '그밖의공제매입명세합계세액', detail: '그 밖의 공제매입세액'},
                                {label: '공제받지 못할 매입세액', name: '공제받지못할매입합계세액'},
                            ]
                        },
                        {
                            label: '매출세액 - 매입세액', name: '납부_환급세액'
                        },
                        {
                            label: '경감, 공제 세액',
                            name: '경감공제합계세액',
                            sumOf: [
                                {label: '그밖의 경감,공제', name: '그밖의경감공제세액'},
                                {label: '신용카드 발행공제', name: '그밖의경감공제명세합계세액'},
                            ]
                        },
                        {label: '예정신고 미환급세액', name: '예정신고미환급세액'},
                        {label: '예정고지세액', name: '예정고지세액'},
                        {label: '사업양수자의 기납부세액', name: '사업양수자의대리납부기납부세액'},
                        {label: '매입자 납부특례 기납부세액', name: '매입자납부특례기납부세액'},
                        {label: '가산세액계', name: '가산세액계', detail: '가산세'},
                        {label: '소규모사업자 감면세액', name: '소규모_개인사업자_부가가치세_감면세액'},
                        {
                            label: '차감 후 납부/환급 세액',
                            name: '차감납부할세액'
                        },
                    ]
                },
                v1_3_간이과세자_신고서_레코드: {
                    fields: [
                        {
                            label: '과세표준',
                            name: '과세표준금액',
                        },
                        {
                            label: '산출세액',
                            name: '산출세액',
                        },
                        {
                            label: '공제세액',
                            name: '공제세액',
                        },
                        {
                            label: '기타',
                            sumOf: [
                                {label: '예정고지 신고세액', name: '예정고지_신고세액'},
                                {label: '가산세액 합계', name: '가산세액세액합계', detail: '가산세'},
                            ]
                        },
                        {label: '소규모사업자 부가가치세 감면세액', name: '소규모_개인사업자_부가가치세_감면세액'},
                        {
                            label: '차감 후 납부/환급 세액',
                            name: '실차감납부할세액'
                        },
                    ]
                }
            },
            '매출 세금계산서/계산서 합계표': {
                v3_1_세금계산서합계표_전자세금계산서분_매출합계_Total_Record: {
                    label: '전자세금계산서 매출',
                    route: {name: '거래내역', query: {부가가치세: this.id, 서식명: 'v3_1_세금계산서합계표_전자세금계산서분_매출합계_Total_Record', title: '전자세금계산서 매출'}},
                    fields: [
                        {
                            label: '사업자번호 발행분', name: '사업자번호발행분_세액',
                            sumOf: [
                                {label: '거래처수', name: '사업자번호발행분_거래처수'},
                                {label: '세금계산서매수', name: '사업자번호발행분_세금계산서매수'},
                                {label: '공급가액', name: '사업자번호발행분_공급가액'},
                                {label: '세액', name: '사업자번호발행분_세액'},
                            ]
                        },
                        {
                            label: '주민번호 발행분', name: '주민번호발행분_세액',
                            sumOf: [
                                {label: '거래처수', name: '주민번호발행분_거래처수'},
                                {label: '세금계산서매수', name: '주민번호발행분_세금계산서매수'},
                                {label: '공급가액', name: '주민번호발행분_공급가액'},
                                {label: '세액', name: '주민번호발행분_세액'},
                            ]
                        },
                        {
                            label: '합계', name: '합계분_세액',
                            sumOf: [
                                {label: '거래처수', name: '합계분_거래처수'},
                                {label: '세금계산서매수', name: '합계분_세금계산서매수'},
                                {label: '공급가액', name: '합계분_공급가액'},
                                {label: '세액', name: '합계분_세액'},
                            ]
                        },
                    ]
                },
                v3_1_세금계산서합계표_전자세금계산서_이외분매출합계_Total_Record: {
                    label: '전자세금계산서 이외 매출',
                    route: {name: '거래내역', query: {부가가치세: this.id, 서식명: 'v3_1_세금계산서합계표_전자세금계산서_이외분매출합계_Total_Record', title: '전자 이외 세금계산서 매출'}},
                    fields: [
                        {
                            label: '사업자번호 발행분', name: '사업자번호발행분_세액',
                            sumOf: [
                                {label: '거래처수', name: '사업자번호발행분_거래처수'},
                                {label: '세금계산서매수', name: '사업자번호발행분_세금계산서매수'},
                                {label: '공급가액', name: '사업자번호발행분_공급가액'},
                                {label: '세액', name: '사업자번호발행분_세액'},
                            ]
                        },
                        {
                            label: '주민번호 발행분', name: '주민번호발행분_세액',
                            sumOf: [
                                {label: '거래처수', name: '주민번호발행분_거래처수'},
                                {label: '세금계산서매수', name: '주민번호발행분_세금계산서매수'},
                                {label: '공급가액', name: '주민번호발행분_공급가액'},
                                {label: '세액', name: '주민번호발행분_세액'},
                            ]
                        },
                        {
                            label: '합계', name: '합계분_세액',
                            sumOf: [
                                {label: '거래처수', name: '합계분_거래처수'},
                                {label: '세금계산서매수', name: '합계분_세금계산서매수'},
                                {label: '공급가액', name: '합계분_공급가액'},
                                {label: '세액', name: '합계분_세액'},
                            ]
                        },
                    ]
                },
                v3_2_계산서합계표_전자계산서_매출집계레코드_매출: {
                    label: '전자계산서 매출 (부가세 면세)',
                    route: {name: '거래내역', query: {부가가치세: this.id, 서식명: 'v3_2_계산서합계표_전자계산서_매출집계레코드_매출', title: '전자계산서 매출'}},
                    fields: [
                        {
                            label: '사업자번호 수입금액', name: '사업자등록번호발행분매출_수입금액',
                            sumOf: [
                                {label: '거래처수', name: '사업자등록번호발행분매출처수'},
                                {label: '세금계산서매수', name: '사업자등록번호발행분계산서매수'},
                                {label: '수입금액', name: '사업자등록번호발행분매출_수입금액'},
                            ]
                        },
                        {
                            label: '주민번호 발행분', name: '주민등록번호발행분매출_수입금액',
                            sumOf: [
                                {label: '거래처수', name: '주민등록번호발행분매출처수'},
                                {label: '세금계산서매수', name: '주민등록번호발행분계산서매수'},
                                {label: '공급가액', name: '주민등록번호발행분매출_수입금액'},
                            ]
                        },
                        {
                            label: '합계', name: '매출_수입금액합계',
                            sumOf: [
                                {label: '거래처수', name: '매출처수합계'},
                                {label: '세금계산서매수', name: '계산서매수합계'},
                                {label: '공급가액', name: '매출_수입금액합계'},
                            ]
                        },
                    ]
                },
                v3_2_계산서합계표_제출의무자별집계레코드_매출: {
                    label: '전자계산서 이외 매출 (부가세 면세)',
                    route: {name: '거래내역', query: {부가가치세: this.id, 서식명: 'v3_2_계산서합계표_제출의무자별집계레코드_매출', title: '전자 이외 계산서 매출'}},
                    fields: [
                        {
                            label: '사업자번호 수입금액', name: '사업자등록번호발행분매출_수입금액',
                            sumOf: [
                                {label: '거래처수', name: '사업자등록번호발행분매출처수'},
                                {label: '세금계산서매수', name: '사업자등록번호발행분계산서매수'},
                                {label: '수입금액', name: '사업자등록번호발행분매출_수입금액'},
                            ]
                        },
                        {
                            label: '주민번호 발행분', name: '주민등록번호발행분매출_수입금액',
                            sumOf: [
                                {label: '거래처수', name: '주민등록번호발행분매출처수'},
                                {label: '세금계산서매수', name: '주민등록번호발행분계산서매수'},
                                {label: '공급가액', name: '주민등록번호발행분매출_수입금액'},
                            ]
                        },
                        {
                            label: '합계', name: '매출_수입금액합계',
                            sumOf: [
                                {label: '거래처수', name: '매출처수합계'},
                                {label: '세금계산서매수', name: '계산서매수합계'},
                                {label: '공급가액', name: '매출_수입금액합계'},
                            ]
                        },
                    ]
                }
            },
            '매입 세금계산서 합계표': {
                v3_1_세금계산서합계표_전자세금계산서분_매입합계_Total_Record: {
                    label: '전자세금계산서 매입',
                    route: {name: '거래내역', query: {부가가치세: this.id, 서식명: 'v3_1_세금계산서합계표_전자세금계산서분_매입합계_Total_Record', title: '전자세금계산서 매입'}},
                    fields: [
                        {
                            label: '사업자번호 수취분', name: '사업자번호수취분_세액',
                            sumOf: [
                                {label: '거래처수', name: '사업자번호수취분_거래처수'},
                                {label: '세금계산서매수', name: '사업자번호수취분_세금계산서매수'},
                                {label: '공급가액', name: '사업자번호수취분_공급가액'},
                                {label: '세액', name: '사업자번호수취분_세액'},
                            ]
                        },
                        {
                            label: '주민번호 수취분', name: '주민번호수취분_세액',
                            sumOf: [
                                {label: '거래처수', name: '주민번호수취분_거래처수'},
                                {label: '세금계산서매수', name: '주민번호수취분_세금계산서매수'},
                                {label: '공급가액', name: '주민번호수취분_공급가액'},
                                {label: '세액', name: '주민번호수취분_세액'},
                            ]
                        },
                        {
                            label: '합계', name: '합계분_세액',
                            sumOf: [
                                {label: '거래처수', name: '합계분_거래처수'},
                                {label: '세금계산서매수', name: '합계분_세금계산서매수'},
                                {label: '공급가액', name: '합계분_공급가액'},
                                {label: '세액', name: '합계분_세액'},
                            ]
                        },
                    ]
                },
                v3_1_세금계산서합계표_전자세금계산서_이외분매입합계_Total_Record: {
                    label: '전자세금계산서 이외 매입',
                    route: {name: '거래내역', query: {부가가치세: this.id, 서식명: 'v3_1_세금계산서합계표_전자세금계산서_이외분매입합계_Total_Record', title: '전자 이외 세금계산서 매입'}},
                    fields: [
                        {
                            label: '사업자번호 수취분', name: '사업자번호수취분_세액',
                            sumOf: [
                                {label: '거래처수', name: '사업자번호수취분_거래처수'},
                                {label: '세금계산서매수', name: '사업자번호수취분_세금계산서매수'},
                                {label: '공급가액', name: '사업자번호수취분_공급가액'},
                                {label: '세액', name: '사업자번호수취분_세액'},
                            ]
                        },
                        {
                            label: '주민번호 수취분', name: '주민번호수취분_세액',
                            sumOf: [
                                {label: '거래처수', name: '주민번호수취분_거래처수'},
                                {label: '세금계산서매수', name: '주민번호수취분_세금계산서매수'},
                                {label: '공급가액', name: '주민번호수취분_공급가액'},
                                {label: '세액', name: '주민번호수취분_세액'},
                            ]
                        },
                        {
                            label: '합계', name: '합계분_세액',
                            sumOf: [
                                {label: '거래처수', name: '합계분_거래처수'},
                                {label: '세금계산서매수', name: '합계분_세금계산서매수'},
                                {label: '공급가액', name: '합계분_공급가액'},
                                {label: '세액', name: '합계분_세액'},
                            ]
                        },
                    ]
                }
            },
            '신용카드, 현금영수증 매출': {
                v2_2_신용카드매출전표_등_발행금액_집계표: {
                    label: '신용카드 매출전표 등 발행금액 집계표',
                    route: {name: '거래내역', query: {부가가치세: this.id, 서식명: 'v2_2_신용카드매출전표_등_발행금액_집계표', title: '신용카드 매출전표 등 발행금액'}},
                    fields: [
                        {name: '전체발행금액_합계', label: '전체'},
                        {
                            name: '신용카드등발행금액_합계', label: '신용카드 등',
                            sumOf: [
                                {name: '신용카드등발행금액_과세매출분', label: '과세매출분'},
                                {name: '신용카드등발행금액_면세매출분', label: '면세매출분'},
                                {name: '신용카드등발행금액_봉사료', label: '봉사료'},
                            ]
                        },
                        {
                            name: '현금영수증발행금액_합계', label: '현금영수증',
                            sumOf: [
                                {name: '현금영수증발행금액_과세매출분', label: '과세매출분'},
                                {name: '현금영수증발행금액_면세매출분', label: '면세매출분'},
                                {name: '현금영수증발행금액_봉사료', label: '봉사료'},
                            ]
                        },
                        {
                            name: '직불_기명식_선불전자지급수단_합계', label: '직불카드, 기명식, 선불전자지급수단',
                            sumOf: [
                                {name: '직불_기명식_선불전자지급수단_과세매출분', label: '과세매출분'},
                                {name: '직불_기명식_선불전자지급수단_면세매출분', label: '면세매출분'},
                                {name: '직불_기명식_선불전자지급수단_봉사료', label: '봉사료'},
                            ]
                        },
                        {name: '세금계산서교부금액_집계표'},
                        {name: '계산서교부금액_집계표'}
                    ]
                }
            },
            '신용카드 매입': {
                v3_4_신용카드매출전표등수령명세서_갑_을_신용카드등_매입내용_합계_Tail_Record: {
                    fields: [
                        {name: '총거래건수'},
                        {name: '총공급가액'},
                        {name: '총세액'},
                    ]
                }
            },
            '그 밖의 공제매입세액': {
                v1_5_부가가치세_공제감면_신고서_레코드_신규: {
                    type: 'list',
                    fields: [
                        {name: '공제감면코드', format: (value) => {
                            return globals.codes.cache['공제감면코드/'].filter(code => {
                                return code[0] == value
                            })[0][1].replace(/_/g, ' ')
                        }},
                        {name: '공제감면세액'},
                    ]
                },
                v3_4_신용카드매출전표등수령명세서_갑_을_신용카드등_매입내용_합계_Tail_Record: {
                    label: '신용카드 매입',
                    route: {name: '거래내역', query: {부가가치세: this.id, 서식명: 'v3_4_신용카드매출전표등수령명세서_갑_을_신용카드등_매입내용_합계_Tail_Record', title: '신용카드 매입'}},
                    fields: [
                        {name: '총거래건수'},
                        {name: '총공급가액'},
                        {name: '총세액'},
                    ]
                }
            },
            '현금매출명세서': {
                v2_20_현금매출명세서: {
                    fields: [
                        {
                            name: '합계금액',
                            sumOf: [
                                {name: '금액_세금계산서', label: '세금계산서'},
                                {name: '금액_신용카드', label: '신용카드'},
                                {name: '금액_현금영수증', label: '현금영수증'},
                                {name: '금액_현금매출', label: '현금매출'},
                            ],
                        },
                        {name: '공급대가합계금액'},
                        {name: '부가세합계금액'},
                    ]
                }
            },
            '가산세': {
                v1_6_부가가치세_가산세_신고서_레코드_신규: {
                    type: 'list',
                    fields: [
                        {name: '가산세코드', format: (value) => {
                            return globals.codes.cache['부가가치세_가산세코드/'].filter(code => {
                                return code[0] == value
                            })[0][1].replace(/_/g, ' ')
                        }},
                        {name: '가산세액'}
                    ]
                }
            }
        }
    }
}


export class 사업장현황신고 extends TaxReport {
    constructor(report) {
        super(report);
        this.세금 = '사업장현황'
    }

    title() {
        return `${this.귀속년도}년`
    }

    amount() {
        return this.records[0].매출수입금액합계
    }

    screens() {
        return {
            '사업장현황신고서': {
                BP_1_1_사업장현황신고서_기본사항: {
                    fields: [
                        {
                            label: '수입금액(매출액)',
                            name: '매출수입금액합계',
                            sumOf: [
                                {label: '계산서', name: '계산서발행금액'},
                                {label: '신용카드', name: '신용카드매출액'},
                                {label: '현금영수증', name: '현금영수증매출액'},
                                {label: '기타', name: '기타매출액'},
                            ]
                        },
                        {
                            label: '매입 적격 증명 수취금액',
                            name: '매입금액합계',
                            sumOf: [
                                {label: '전자 계산서', name: '전자계산서매입금액'},
                                {label: '전자 계산서 외', name: '전자계산서외매입금액'},
                                {label: '전자 세금계산서', name: '전자세금계산서_매입금액'},
                                {label: '전자 세금계산서 외', name: '전자세금계산서외_매입금액'},
                                {label: '신용카드 및 현금영수증', name: '신용카드매입금액'},
                            ]
                        },
                        {
                            label: '수입금액 검토표',
                            name: '수입금액검토표첨부건수',
                            detail: '수입금액 검토표'
                        }
                    ]
                }
            },
            '수입금액 검토표': {
                BP_13_1_주택임대사업자_수입금액검토표_기본사항: {
                    label: '주택임대사업자 수입금액검토표',
                    fields: [
                        {name: '종목명', format: 'string'},
                        {name: '임대업_등록번호', format: 'string'},
                        {label: '월세 합계', name: '월세수입금액_월세합계'},
                        {label: '보증금 등의 간주임대료', name: '보증금등의수입금액'},
                    ]
                },
                BP_13_2_주택임대사업자_수입금액검토표_총수입금액_명세: {
                    label: '주택임대사업자 수입금액검토표 명세',
                    fields: [
                        {name: '도로명', format: 'string'},
                        {label: '아파트 동', name: '주택소재지_아파트동', format: 'string'},
                        {label: '아파트 호', name: '주택소재지_아파트호', format: 'string'},
                        {label: '월세 합계', name: '월세수입금액'},
                        {label: '간주임대료', name: '보증금등수입금액'},
                    ]
                }
            }
        }
    }
}


export class 종합소득세 extends TaxReport {
    constructor(report) {
        super(report);
    }

    title() {
        return `${this.과세기간_년월.slice(0, 4)}년`
    }

    amount() {
        return this.records[2].종합소득세_신고기한내납부할세액
    }

    screens() {
        return {
            '과세표준확정신고 및 납부계산서': {
                A3_종합소득세_세액의계산: {
                    fields: [
                        {name: '종합소득금액', detail: {name: '사업소득명세서'}},
                        {name: '소득공제'},
                        {name: '종합소득세_과세표준', label: '과세표준'},
                        {name: '종합소득세_세율', label: '세율 (%)'},
                        {name: '종합소득세_산출세액', label: '산출세액'},
                        {name: '종합소득세_세액감면', label: '세액감면'},
                        {name: '종합소득세_세액공제', label: '세액공제'},
                        {name: '종합소득세_결정세액_합계', label: '결정세액'},
                        {name: '종합소득세_가산세', label: '가산세'},
                        {name: '종합소득세_추가납부세액', label: '추가납부세액'},
                        {name: '종합소득세_합계', label: '종합소득세 합계'},
                        {name: '종합소득세_기납부세액', label: '기납부세액'},
                        {name: '종합소득세_납부할총세액', label: '납부할 총세액'},
                        {name: '종합소득세_납부특례세액_차감', label: '납부특례세액 차감'},
                        {name: '종합소득세_납부특례세액_가산', label: '납부특례세액 가산'},
                        {name: '종합소득세_분납할세액', label: '분납할 세액'},
                        {name: '종합소득세_신고기한내납부할세액', label: '신고기한내 납부할 세액'},
                    ]
                }
            },
            사업소득명세: {
                A6_종합소득세_사업소득명세서: {
                    fields: [
                        {name: '소득구분코드', format: 'string'},
                        {name: '일련번호', format: 'string'},
                        {name: '상호', format: 'string'},
                        {name: '사업자등록번호', format: 'string'},
                        {name: '기장의무', format: 'string'},
                        {name: '신고유형', format: 'string'},
                        {name: '주업종코드', format: 'string'},
                        {name: '총수입금액'},
                        {name: '필요경비'},
                        {name: '소득금액'},
                    ]
                }
            }
        }
    }

    get 과세년도() {
        return parseInt(`${this.과세기간_년월.slice(0, 4)}`)
    }

    get 신고시작일자() {
        return moment(this.납기일).subtract(7, 'day').date(1)
    }
}


export class 연말정산 extends TaxReport {
    constructor(report) {
        super(report);
    }

    title() {
        return `${this.귀속년도}년 연말정산`
    }

    screens() {
        return {
            '정산명세': {
                라_C레코드_주_현근무처_레코드: {
                    fields: [
                        {name: '_9_주현근무처_근무처명', format: 'string'},
                        {name: '총급여'},
                        {name: '근로소득공제'},
                        {name: '근로소득금액'},
                        {
                            name: '종합소득공제', format: 'string',
                            sumOf: [
                                {name: '본인공제금액'},
                                {name: '배우자공제금액'},
                                {name: '국민연금보험료공제_국세청', label: '국민연금보험료공제'},
                            ]
                        },
                        {name: '차감소득금액'},
                        {
                            name: '그_밖의_소득공제계',
                            sumOf: [
                                {name: '개인연금저축소득공제'},
                                {name: '소기업_소상공인_공제부금'},
                                {name: '_주택마련저축소득공제_청약저축'},
                            ]
                        },
                        {name: '종합소득_과세표준'},
                        {name: '산출세액'},
                        {
                            name: '세액감면계',
                            sumOf: [
                                {name: '조특법_제30조'},
                            ]
                        },
                        {
                            name: '세액공제계',
                            sumOf: [
                                {name: '특별세액공제_보장성보험료_세액공제액'},
                                {name: '특별세액공제_장애인전용보장성보험료_세액공제액'},
                                {name: '특별세액공제_의료비_세액공제액'},
                                {name: '특별세액공제_교육비_세액공제액'},
                                {name: '_특별세액공제_기부금_정치자금_10만원이하_세액공제액'},
                                {name: '표준세액공제'},
                                {name: '납세조합공제'},
                                {name: '주택차입금'},
                            ]
                        },
                        {name: '결정세액_소득세'},
                        {name: '기납부세액_소득세'},
                        {name: '차감징수세액소득세'}
                    ]
                }
            },
            '급여내역': {

            }
        }
    }
}


export class Resource {
    modelClass;
    constructor(baseURL, modelClass) {
        this.baseURL = baseURL
        this.modelClass = modelClass;
        this.cache = {}
    }

    async get(path, config, silent=false) {
        if (this.cache[path]) {
            return {data: this.cache[path], object: this.cache[path]}
        }

        let res = await getAPIClient(silent).get(this.baseURL + path, config)
        if (res.data.results) {
            res.objects = []
            for (let data of res.data.results) {
                res.objects.push(this.store(data))
            }
        } else if (res.data.id) {
            res.object = this.store(res.data)
        } else {
            res.object = this.store(res.data, path)
        }
        return res
    }

    async getObject(id, silent=false) {
        return (await this.get(id + '/', null, silent)).object
    }

    async saveObject(object, silent=false) {
        return Object.assign(object, (await api.put(this.baseURL + object.id + '/', object)).data)
    }

    store(data, key) {
        if (this.modelClass) {
            data = new this.modelClass(data)
        }
        this.cache[key || (data.id + '/')] = data
        return data
    }
}


export class AccountResource {
    constructor() {
        this.accountResource = new Resource('robotax/account/')
        this.bankAccountResource = new Resource('robotax/bankaccount/')
    }

    async cardSalesAccount() {
        this.accountResource.cache = {}
        return (await this.accountResource.get('', {
            params: {사업자: globals.trader.robotax_id, type: '여신금융협회'}
        })).data.results[0] || null
    }

    async accounts() {
        this.accountResource.cache = {}
        return (await this.accountResource.get('', {
            params: {사업자: globals.trader.robotax_id}
        })).data.results || null
    }

    async bankAccounts(사업자_robotax_id, options) {
        this.bankAccountResource.cache = {}
        return (await this.bankAccountResource.get('', {
            params: {사업자: 사업자_robotax_id || globals.trader.robotax_id, ...options}
        })).data.results
    }

    async transferAccount(account, trader) {
        Object.assign(account, (await api.put(`robotax/bankaccount/${account.id}/transfer/`, {
            사업자: trader.robotax_id
        })).data)
    }
}


export async function loadTags() {
    if (!globals.tags.매출액) {
        globals.tags = (await api.get(`robotax/사업자/${globals.trader.robotax_id}/tags/`)).data
    }

    for (let path of Object.keys(globals.tags)) {
        let parts = path.split('/')
        let tag = globals.tags[path]
        tag.parent = parts.slice(0, parts.length - 1).join('/')
        tag.name = parts[parts.length - 1]
        tag.id__count = 0
        tag.공급가액__sum = 0

        if (parts.length > 1) {
            if (!globals.tags[tag.parent].children_count) {
                globals.tags[tag.parent].children_count = 0
            }
            globals.tags[tag.parent].children_count += 1
        }
    }
    return globals.tags
}


export function validate주민등록번호(number) {
    number = new String(number).replace('-', '')
    if (number.length != 13) {
        return false
    }

    if (!/[0-9]+/.test(number)) {
        return false
    }

    if (!moment(number.substring(0, 6), 'YYMMDD').isValid()) {
        return false
    }

    if (['1', '2', '3', '4'].indexOf(number[6]) < 0) {
        return false
    }

    return true
}


export class Coupon {
    static async objects() {
        return (await api.get(`coupon/?owner=${globals.user.id}`)).data.results
    }
}


export let 신고유형코드 = {
    자기조정: '11',
    외부조정: '12',
    성실신고확인: '14',
    간편장부: '20',
    '추계-기준율': '31',
    '추계-단순율': '32',
    비사업자: '40',
}


export class TaxInvoice {

    SCHEMA = {
        ExchangedDocument: {
            ID: "서비스사업자",
            ReferencedDocument: {
                ID: "사업자관리번호"
            },
            IssueDateTime: "발급일시"
        },
        Signature: "서명",
        TaxInvoiceDocument: {
            IssueID: "승인번호",
            IssueDateTime: "작성일자",
            TypeCode: "세금계산서종류코드",
            PurposeCode: "영수청구코드",
            AmendmentStatusCode: "수정코드",
            OriginalIssueID: "당초승인번호",
            DescriptionText: "비고",
            ReferencedImportDocument: {
                ID: "수입_신고번호",
                AcceptablePeriod: {
                    StartDateTime: "수입_일괄발급시작일",
                    EndDateTime: "수입_일괄발급종료일"
                },
                ItemQuantity: "수입_총건"
            }
        },
        TaxInvoiceTradeSettlement: {
            InvoicerParty: {
                ID: "공급자_사업자등록번호",
                SpecifiedOrganization: {
                    TaxRegistrationID: "공급자_종사업장번호"
                },
                NameText: "공급자_상호",
                SpecifiedPerson: {
                    NameText: "공급자_대표자명"
                },
                SpecifiedAddress: {
                    LineOneText: "공급자_주소"
                },
                TypeCode: "공급자_업태",
                ClassificationCode: "공급자_업종",
                DefinedContact: {
                    DepartmentNameText: "공급자_담당부서명",
                    PersonNameText: "공급자_담당자명",
                    TelephoneCommunication: "공급자_담당자전화번호",
                    URICommunication: "공급자_이메일"
                }
            },
            InvoiceeParty: {
                ID: "공급받는자_사업자등록번호",
                SpecifiedOrganization: {
                    BusinessTypeCode: "공급받는자_사업자등록번호_구분코드",
                    TaxRegistrationID: "공급받는자_종사업장번호"
                },
                NameText: "공급받는자_상호",
                SpecifiedPerson: {
                    NameText: "공급받는자_대표자명"
                },
                SpecifiedAddress: {
                    LineOneText: "공급받는자_주소"
                },
                TypeCode: "공급받는자_업태",
                ClassificationCode: "공급받는자_업종",
                PrimaryDefinedContact: {
                    DepartmentNameText: "공급받는자_담당부서명1",
                    PersonNameText: "공급받는자_담당자명1",
                    TelephoneCommunication: "공급받는자_담당자전화번호1",
                    URICommunication: "공급받는자_이메일1"
                },
                SecondaryDefinedContact: {
                    DepartmentNameText: "공급받는자_담당부서명2",
                    PersonNameText: "공급받는자_담당자명2",
                    TelephoneCommunication: "공급받는자_담당자전화번호2",
                    URICommunication: "공급받는자_이메일2"
                }
            },
            BrokerParty: {
                ID: "수탁_사업자등록번호",
                SpecifiedOrganization: {
                    TaxRegistrationID: "수탁_종사업장번호"
                },
                NameText: "수탁_상호",
                SpecifiedPerson: {
                    NameText: "수탁_대표자명"
                },
                SpecifiedAddress: {
                    LineOneText: "수탁_주소"
                },
                TypeCode: "수탁_업태",
                ClassificationCode: "수탁_업종",
                DefinedContact: {
                    DepartmentNameText: "수탁_담당부서명",
                    PersonNameText: "수탁_담당자명",
                    TelephoneCommunication: "수탁_담당자전화번호",
                    URICommunication: "수탁_이메일"
                }
            },
            SpecifiedPaymentMeans: {
                TypeCode: "결제방법",
                PaidAmount: "결제금액"
            },
            SpecifiedMonetarySummation: {
                ChargeTotalAmount: "공급가액",
                TaxTotalAmount: "세액",
                GrandTotalAmount: "총금액"
            }
        },
        TaxInvoiceTradeLineItem: {
            SequenceNumeric: "품목일련번호",
            PurchaseExpiryDateTime: "품목공급일자",
            NameText: "품목명",
            InformationText: "품목규격",
            DescriptionText: "품목비고",
            ChargeableUnitQuantity: "품목수량",
            UnitPrice: {
                UnitAmount: "품목단가"
            },
            InvoiceAmount: "품목공급가액",
            TotalTax: {
                CalculatedAmount: "품목세액"
            }
        }
    }

    CODES = {
        세금계산서분류: {
            '01': '세금계산서',
            '02': '수정세금계산서',
            '03': '계산서',
            '04': '수정계산서'
        },
        세금계산서종류: {
            '01': '일반',
            '02': '영세율',
            '03': '위수탁',
            '04': '수입',
            '05': '영세율위수탁',
            '06': '수입납부 유예'
        },
        PaymentMeansTypeCode: {
            '10': '현금',
            '20': '수표',
            '30': '어음',
            '40': '외상미수금'
        }
    }

    constructor(data) {
        Object.assign(this, data)

        if (!this.TaxInvoiceTradeSettlement.SpecifiedPaymentMeans) {
            this.TaxInvoiceTradeSettlement.SpecifiedPaymentMeans = []
        }

        for (let code of Object.keys(this.CODES.PaymentMeansTypeCode)) {
            let mean = this.TaxInvoiceTradeSettlement.SpecifiedPaymentMeans.find(m => m.TypeCode == code)
            if (!mean) {
                this.TaxInvoiceTradeSettlement.SpecifiedPaymentMeans.push({
                    TypeCode: code,
                    PaidAmount: 0
                })
            }
        }

        this.invoicer = this.proxy('TaxInvoiceTradeSettlement.InvoicerParty')
        this.invoicee = this.proxy('TaxInvoiceTradeSettlement.InvoiceeParty')
    }

    data() {
        return Object.fromEntries(Object.entries(this).filter((entry) => this.SCHEMA[entry[0]] != undefined))
    }

    partyFields(contactPrefix) {
        return [
            'ID', 'SpecifiedOrganization.TaxRegistrationID', 'NameText', 'SpecifiedPerson.NameText',
            'SpecifiedAddress.LineOneText', "TypeCode", 'ClassificationCode',
            contactPrefix + 'DefinedContact.URICommunication'
        ]
    }

    label(field) {
        let value = getNestedProperty(this.SCHEMA, field.replace(/\.[0-9]+\./, '.'))
        if (typeof value == 'object') {
            return Object.values(value)[0]
        }
        return value
    }

    calculateItem(selectedItem) {
        if (!selectedItem) { return }
        if (selectedItem.UnitPrice.UnitAmount || selectedItem.ChargeableUnitQuantity) {
            selectedItem.InvoiceAmount = selectedItem.UnitPrice.UnitAmount * selectedItem.ChargeableUnitQuantity
        }
        selectedItem.TotalTax.CalculatedAmount = Math.floor(selectedItem.InvoiceAmount * 0.1)
        // item.품목합계 = Number(item.품목공급가액) + Number(item.품목세액)

        let sum = this.TaxInvoiceTradeSettlement.SpecifiedMonetarySummation
        sum.ChargeTotalAmount = sum.TaxTotalAmount = sum.GrandTotalAmount = 0

        for (let [index, item] of this.TaxInvoiceTradeLineItem.entries()) {
            item.SequenceNumeric = index + 1
            sum.ChargeTotalAmount += Number(item.InvoiceAmount)
            sum.TaxTotalAmount += Number(item.TotalTax.CalculatedAmount)
        }
        sum.GrandTotalAmount = sum.ChargeTotalAmount + sum.TaxTotalAmount
    }

    fillPaymentMeans(code) {
        for (let item of this.TaxInvoiceTradeSettlement.SpecifiedPaymentMeans) {
            if (item.TypeCode == code) {
                item.PaidAmount = this.TaxInvoiceTradeSettlement.SpecifiedMonetarySummation.GrandTotalAmount
            } else {
                item.PaidAmount = 0
            }
        }
    }

    proxy(path) {
        let self = this
        return new Proxy(getNestedProperty(this, path), {
            getOwnPropertyDescriptor(target, key) {
                let label = getNestedProperty(self.SCHEMA, path + '.' + key)
                if (typeof label == 'string') label = label.split('_')[1]
                return {configurable: true, value: label}
            },
            get(target, key) {
                if (typeof key == 'symbol') {
                    return target[key]
                }
                let value = getNestedProperty(target, key)
                return typeof value == 'object' ? target[key] : value
            },
            set(target, key, value) {
                let parent = target
                let parts = key.split('.')
                for (let field of parts.slice(0, -1)) {
                    if (!parent[field]) {
                        parent[field] = {}
                    }
                    parent = parent[field] || {}
                }
                parent[parts.at(-1)] = value
                return true
            },
        })
    }
}


export function getNestedProperty(obj, path) {
    let value = obj
    for (let field of path.split('.')) {
        value = value[field] || {}
    }
    return value
}