<template>
    <div class="" v-if="report">
        <nav class="navbar navbar-light titlebar pt-0">
            <back-button></back-button>
            <h5 class="mt-2">사업장현황 {{ report.title() }} 신고</h5>
            <span></span>
        </nav>
        <b-nav class="bg-white p-2 shadow" pills style="z-index: 1">
            <b-nav-item :to="{name: '사업장현황 신고서'}" exact exact-active-class="active" replace><strong>내역보기</strong></b-nav-item>
            <b-nav-item :to="{name: '사업장현황 신고'}" exact exact-active-class="active" replace v-if="true"><strong>신고하기</strong></b-nav-item>
        </b-nav>
        <router-view></router-view>
    </div>
</template>

<script>
    import moment from "moment";
    import BackButton from "@/components/BackButton";

    export default {
        name: "BusinessPlaceReportMenu",
        components: {BackButton},
        props: ['id'],
        data: function() {
            return {
                report: null,
                hometaxDocument: null,
            }
        },
        computed: {
            canReport() {
                if (!this.report) { return false }
                return moment().isAfter(moment(this.report.과세기간종료일자), 'day')
            }
        },
        async mounted() {
            this.report = (await this.globals.사업장현황신고.get(this.id + '/')).object
            // this.hometaxDocument = (await api.get(`robotax/hometaxdocument/?report_type=부가가치세&report_id=${this.report.id}`)).data.results[0]
        },
        methods: {
        }

    }
</script>

<style scoped>

</style>