<template>
    <div class="h-100 d-flex flex-column justify-content-center align-items-center text-white">
        <template v-if="!$route.query.client_info">
            <div>오픈뱅킹 인증 준비 중..</div>
            <b-spinner class="mt-3" variant="dark"></b-spinner>
        </template>
        <template v-else-if="$route.query.client_info === 'auth' && $route.query.code">
            <div>연동을 완료하는 중 입니다. 잠시만 기다려주세요.</div>
            <b-spinner class="mt-3" variant="warning"></b-spinner>
        </template>
        <template v-else-if="globals.user.openbanking">
            <div>오픈뱅킹 연동이 완료되었습니다.</div>
            <b-spinner class="mt-3" variant="success"></b-spinner>
        </template>
        <template v-else-if="error">
            <div>오픈뱅킹 연동 중 오류가 있었습니다. 잠시 후 다시 시도해주세요.</div>
            <div>에러코드 : {{ error }}</div>
        </template>
        <template v-else>
            {{ $route.query.client_info }} {{ $route.query.code }}
        </template>
    </div>
</template>

<script>
    import api from '@/util/api'
    import {CALLBACK_URI, CLIENT_ID, OPENBANKING_ENDPOINT} from "@/util/openbanking"

    export default {
        name: "Auth",
        data: () => ({ error: null }),
        mounted () {
            if (!this.$route.query.client_info) {
                this.oauth()
            } else if (this.$route.query.client_info === 'auth') {
                this.requestAccessToken()
            }
        },
        methods: {
            oauth() {
                setTimeout(() => {
                    let query = {
                        response_type: 'code',
                        client_id: CLIENT_ID,
                        redirect_uri: CALLBACK_URI,
                        scope: 'login inquiry transfer',
                        state: Math.random().toFixed(32).substring(2, 34),
                        client_info: 'auth',
                        auth_type: 0
                    }
                    window.location.href = `${OPENBANKING_ENDPOINT}/oauth/2.0/authorize?${Object.keys(query).map(k => `${k}=${encodeURIComponent(query[k])}`).join('&')}`
                }, 500) // removes animation crop
            },
            requestAccessToken() {
                api.post('openbanking/access_token/', {code: this.$route.query.code}).then(res => {
                    this.globals.user = res.data
                    setTimeout(() => this.$router.push({name: '설정'}), 1000)
                }).catch(err => {
                    this.error = err
                    setTimeout(() => this.$router.push({name: '설정'}), 3000)
                })
            }
        }
    }
</script>

<style scoped>

</style>
