import axios from 'axios'
import * as Sentry from '@sentry/browser';

import {globals} from '@/globals'
// @ts-ignore
import {getCookie} from '@/util/vue-commons'


const baseDomain = process.env.NODE_ENV === 'production' ? '' : location.origin.replace('8080', '8000')
export const baseURL = `${baseDomain}/api`

export const api = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken'
})
export const silentApi = axios.create(api.defaults)


api.interceptors.request.use(config => {
    globals.loading = true
    return config
}, error => {
    globals.loading = false
    globals.error = '네트워크 오류. 설정을 확인하고 다시 시도해주세요.'
    return Promise.reject(error)
})

api.interceptors.response.use(response => {
    globals.loading = false
    globals.loadingMessage = null
    return response
}, error => {
    globals.loading = false
    globals.loadingMessage = null

    if (!error.response) {
        globals.error = '서버 오류. 잠시 후 다시 시도해보세요.'
        Sentry.captureMessage(error.message)
    } else if (error.response.data.message) {
        globals.error = formatError(error)
    } else if (Math.floor(error.response.status / 100) == 4) {
        globals.error = formatError(error)
    } else {
        globals.error = '서버 오류. 잠시 후 다시 시도해보세요.'
    }
    return Promise.reject(error)
})


// @ts-ignore
export function formatError(error) {
    if (error.response.data.detail) {
        return error.response.data.detail
    } else if (error.response.data instanceof Array) {
        return error.response.data.join('\n')
    } else {
        return error.response.data
    }

}

export default api
