<template>
    <b-button variant="outline-primary"
              style="white-space: nowrap"
              size="sm"
              @click="startScraping(source)"
              :title="label || '새로고침'"
              :disabled="scrapingSources[source] && (scrapingSources[source].status == 'running' || scrapingSources[source].status == 'ready')">
        <i class="fas fa-sync"></i>
        <span class="d-none d-sm-inline pl-1">{{ label || '새로고침' }}</span>
    </b-button>

</template>
<script>
    import AccountMixin from "@/util/AccountMixin";

    export default {
        name: 'RefreshScrapingButton',
        mixins: [AccountMixin],
        props: {
            source: {},
            label: {},
            spinner: {type: Boolean, default: false}
        }
    }
</script>