
function validate(_birth, _backNumber) {
     let birth    = _birth,
         backNumber    = _backNumber,
         ssn     = birth+''+backNumber,
         arr_ssn = [], compare = [2,3,4,5,6,7,8,9,2,3,4,5],
         sum     = 0
 
    if (ssn.match('[^0-9]'))
    {
        alert("주민등록번호는 숫자만 입력하셔야 합니다."); 
        return false; 
    }

    if (ssn.length !== 13)
    {
        alert("올바른 주민등록 번호를 입력하여 주세요.");
        return false;
    }    
 
    // 공식: M = (11 - ((2×A + 3×B + 4×C + 5×D + 6×E + 7×F + 8×G + 9×H + 2×I + 3×J + 4×K + 5×L) % 11)) % 11
    for (let i = 0; i<13; i++)
    { 
        arr_ssn[i] = Number(ssn.substring(i,i+1));
    }
     
    for (let i = 0; i<12; i++)
    {
        sum = sum + (arr_ssn[i] * compare[i]); 
    }
 
    sum = (11 - (sum % 11)) % 10;
    if (sum !== arr_ssn[12])
    {
        console.log(sum, arr_ssn[12])
        alert("올바른 주민등록 번호를 입력하여 주세요.");
        return false; 
    }
 
    return true;
}

export {validate}
