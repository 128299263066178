<template>
    <div v-if="report">
        <nav class="navbar navbar-light titlebar pt-0">
            <back-button></back-button>
            <h5 class="mt-2">소득구분별 사업소득명세</h5>
            <span></span>
        </nav>

        <div class="card m-3" v-for="record in report.filter('A6_종합소득세_사업소득명세서')" :key="record.소득구분코드 + record.사업자등록번호">
            <div class="card-header" v-b-toggle="'income-record-' + record.일련번호">
                <span class="badge badge-secondary mr-1">{{ record.일련번호 }}</span>
                <span>{{ record.상호 }}</span>
                <div class="float-right text-muted">
                    <span v-if="record.소득구분코드[0] == '3'">부동산임대업</span>
                    <span v-else-if="record.소득구분코드 == '32'">주택임대업</span>
                    <span v-else>일반사업소득</span>
                    <span v-if="record.소득구분코드[1] == '1'">, 동업</span>
                </div>
            </div>
            <div class="card-body px-0">
                <table class="table">
                    <tbody>
                        <tr>
                            <th>소득금액</th>
                            <th class="text-right">{{ moneyFormat(record.소득금액) }}</th>
                        </tr>
                        <tr>
                            <td>총수입금액</td>
                            <th class="text-right">{{ moneyFormat(record.총수입금액) }}</th>
                        </tr>
                        <tr>
                            <td>필요경비</td>
                            <th class="text-right">{{ moneyFormat(record.필요경비) }}</th>
                        </tr>
                    </tbody>
                </table>
            </div>
            <b-card-footer>
                <button class="btn btn-sm btn-block btn-outline-secondary" v-b-toggle="'income-record-' + record.일련번호">
                    상세보기
                    <collapse-indicator/>
                </button>
            </b-card-footer>
            <b-collapse :id="'income-record-' + record.일련번호">
                <b-tabs card nav-wrapper-class="rounded-0">
                    <b-tab title="간편장부 소득금액계산서">
                        <div class="card-body px-0">
                            <h5>간편장부 소득금액계산서</h5>
                            <div v-for="간편장부소득금액계산서 in report.filter('A27_종합소득세_간편장부소득금액계산서').filter(r => r.소득구분코드 == record.소득구분코드 && r.사업자등록번호 == record.사업자등록번호)" :key="간편장부소득금액계산서.일련번호">
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <th>총수입금액</th>
                                            <td class="text-right">{{ moneyFormat(간편장부소득금액계산서.세무조정후수입금액) }}</td>
                                        </tr>
                                        <tr>
                                            <td class="pl-4">장부상 수입금액</td>
                                            <td class="text-right">{{ moneyFormat(간편장부소득금액계산서.장부상수입금액) }}</td>
                                        </tr>
                                        <tr>
                                            <td class="pl-4">제외할 수입금액</td>
                                            <td class="text-right">{{ moneyFormat(간편장부소득금액계산서.수입금액에서제외할금액) }}</td>
                                        </tr>
                                        <tr>
                                            <td class="pl-4">가산할 수입금액</td>
                                            <td class="text-right">{{ moneyFormat(간편장부소득금액계산서.수입금액에가산할금액) }}</td>
                                        </tr>
                                        <tr>
                                            <th>필요경비</th>
                                            <td class="text-right">{{ moneyFormat(간편장부소득금액계산서.세무조정후필요경비) }}</td>
                                        </tr>
                                        <tr>
                                            <td class="pl-4">장부상 필요경비</td>
                                            <td class="text-right">{{ moneyFormat(간편장부소득금액계산서.장부상필요경비) }}</td>
                                        </tr>
                                        <tr>
                                            <td class="pl-4">제외할 필요경비</td>
                                            <td class="text-right">{{ moneyFormat(간편장부소득금액계산서.필요경비에서제외할금액) }}</td>
                                        </tr>
                                        <tr>
                                            <td class="pl-4">가산할 필요경비</td>
                                            <td class="text-right">{{ moneyFormat(간편장부소득금액계산서.필요경비에가산할금액) }}</td>
                                        </tr>
                                        <tr>
                                            <th>차가감 소득금액</th>
                                            <td class="text-right">{{ moneyFormat(간편장부소득금액계산서.차가감소득금액) }}</td>
                                        </tr>
                                        <tr>
                                            <th>기부금 한도초과액</th>
                                            <td class="text-right">{{ moneyFormat(간편장부소득금액계산서.기부금한도초과액) }}</td>
                                        </tr>
                                        <tr>
                                            <th>기부금 이월액 중<br>필요경비산입액</th>
                                            <td class="text-right">{{ moneyFormat(간편장부소득금액계산서.기부금이월액중필요경비산입액) }}</td>
                                        </tr>
                                        <tr>
                                            <th>소득금액</th>
                                            <td class="text-right">{{ moneyFormat(간편장부소득금액계산서.당해연도소득금액) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr>
                            <h5>총수입금액 및 필요경비명세서</h5>
                            <div v-for="총수입금액및필요경비명세서 in report.filter('A28_종합소득세_총수입금액및필요경비명세서').filter(r => r.소득구분코드 == record.소득구분코드 && r.사업자등록번호 == record.사업자등록번호)" :key="총수입금액및필요경비명세서.일련번호 + '1'">
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <th>장부상 수입금액</th>
                                            <th class="text-right pr-4">{{ moneyFormat(총수입금액및필요경비명세서.장부상수입금액_합계) }}</th>
                                        </tr>
                                        <tr>
                                            <td class="pl-4">매출액</td>
                                            <td class="text-right" @click="transactionsByTags('매출액')">
                                                {{ moneyFormat(총수입금액및필요경비명세서.장부상수입금액_매출액) }}
                                                <i class="fas fa-chevron-right"></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="pl-4">기타</td>
                                            <td class="text-right" @click="transactionsByTags('영업외수익')">{{ moneyFormat(총수입금액및필요경비명세서.장부상수입금액_기타) }} <i class="fas fa-chevron-right"></i></td>
                                        </tr>

                                        <tr>
                                            <th>매출원가</th>
                                            <th class="text-right pr-4">{{ moneyFormat(총수입금액및필요경비명세서.매출원가) }}</th>
                                        </tr>
                                        <tr v-for="field in Object.keys(총수입금액및필요경비명세서).filter(f => f.startsWith('매출원가_'))" :key="field" v-show="총수입금액및필요경비명세서[field] != '0'">
                                            <td class="pl-4" v-if="field == '매출원가_당기상품매입액또는제조비용'">당기상품매입액<br>또는 제조비용</td>
                                            <td class="pl-4" v-else>{{ field.split('_')[1] }}</td>
                                            <td class="text-right">{{ moneyFormat(총수입금액및필요경비명세서[field]) }} <i class="fas fa-chevron-right"></i></td>
                                        </tr>

                                        <template v-if="총수입금액및필요경비명세서.제조비_당기제조비용 != '0'">
                                            <tr>
                                                <th>제조원가</th>
                                                <th class="text-right pr-4">{{ moneyFormat(총수입금액및필요경비명세서.제조비_당기제조비용) }}</th>
                                            </tr>
                                            <tr v-for="field in Object.keys(총수입금액및필요경비명세서).filter(f => f.startsWith('제조비_'))" :key="field">
                                                <td class="pl-4">{{ field.split('_')[1] }}</td>
                                                <td class="text-right">{{ moneyFormat(총수입금액및필요경비명세서[field]) }} <i class="fas fa-chevron-right"></i></td>
                                            </tr>
                                        </template>

                                        <tr>
                                            <th>일반관리비</th>
                                            <th class="text-right pr-4">{{ moneyFormat(총수입금액및필요경비명세서.일반관리비등계) }}</th>
                                        </tr>
                                        <tr v-for="field in Object.keys(총수입금액및필요경비명세서).filter(f => f.startsWith('일반관리비_'))" :key="field" v-show="총수입금액및필요경비명세서[field] != '0'">
                                            <td class="pl-4">{{ field.split('_')[1] }}</td>
                                            <td class="text-right">{{ moneyFormat(총수입금액및필요경비명세서[field]) }} <i class="fas fa-chevron-right"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="단순경비율" @click="load사업소득명세(record)">
                        <p>
                            간편장부로 계산한 소득을 단순경비율로 계산한 소득과 비교해보시고 간편장부 소득이 크게 나올 경우 단순경비율 소득에 가깝게
                            줄일 수 있도록 장부에서 <code>판매비와관리비</code>, <code>매출원가</code> 등의 태그를 잘 붙여주세요.
                            <b-button variant="link" size="sm" v-b-modal.종합소득세-신고유형-help><i class="fas fa-info-circle"> 단순경비율이란?</i></b-button>
                        </p>
                        <table class="table" v-if="사업소득명세">
                            <tbody>
                                <tr>
                                    <th>총수입금액</th>
                                    <td class="text-right">{{ moneyFormat(사업소득명세.data.단순경비율_추계소득금액계산서.총수입금액) }}</td>
                                </tr>
                                <tr>
                                    <th>단순경비율(일반율)</th>
                                    <td class="text-right">{{ 사업소득명세.data.단순경비율_추계소득금액계산서.단순경비율_일반율 }}%</td>
                                </tr>
                                <tr>
                                    <th>필요경비</th>
                                    <td class="text-right">
                                        {{ moneyFormat(decimal(사업소득명세.data.단순경비율_추계소득금액계산서.총수입금액) - decimal(사업소득명세.data.단순경비율_추계소득금액계산서.금액_단순경비율)) }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>소득금액</th>
                                    <td class="text-right">{{ moneyFormat(사업소득명세.data.단순경비율_추계소득금액계산서.금액_단순경비율) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </b-tab>
                </b-tabs>
            </b-collapse>
        </div>
    </div>
</template>

<script>
    import BackButton from "@/components/BackButton";
    import CollapseIndicator from "@/views/income-tax/CollapseIndicator";
    import IncomeTaxMixin from "@/util/IncomeTaxMixin";
    import api from "@/util/api";

    export default {
        name: "IncomeRecords",
        components: {CollapseIndicator, BackButton},
        props: ['id'],
        mixins: [IncomeTaxMixin],
        data: function() {
            return {
                report: null,
                hometaxDocument: null,
                사업소득명세: null,
            }
        },
        async mounted() {
            this.report = (await this.globals.종합소득세.get(this.id + '/')).object
        },
        methods: {
            async load사업소득명세(record) {
                this.사업소득명세 = (await api.get('robotax/사업소득명세/', {
                    params: {
                        사업자__납세자ID: record.사업자등록번호,
                        소득구분코드: record.소득구분코드,
                    }
                })).data.results[0]
            }
        }
    }
</script>

