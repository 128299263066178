<template>
    <div class="h-100 d-flex flex-column" v-if="taxInvoice">
        <nav class="navbar navbar-light titlebar">
            <back-button>
                <span v-if="issueMode">취소</span>
            </back-button>
            <h5 class="mt-2">
                세금계산서/계산서
                <strong v-if="issueMode">발급하기</strong>
            </h5>
            <span>&nbsp;</span>
        </nav>
        <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
            <div class="alert mb-0" :class="errors ? 'alert-danger': 'alert-light'" style="border-radius: 0; box-shadow: 0px 1px 10px gray; z-index: 1" v-if="issueMode">
                선택한 세금계산서 내용을 복사해서 <strong class="text-danger">새로운 세금계산서를 발급</strong>합니다.
                <h6 v-if="errors"><strong>다음 항목을 확인해주세요.</strong></h6>
                <ul v-if="errors">
                    <li v-for="(messages, field) in errors" :key="field">
                        <a :href="'#' + field">{{ taxInvoice.label(field) }}</a>:
                        <span class="ml-1">{{ messages.join('\n') }}</span>
                    </li>

                </ul>
            </div>
            <div class="alert alert-light mb-0" style="border-radius: 0; box-shadow: 0px 1px 10px gray; z-index: 1" v-else-if="invoice.asp_key && !invoice.전송일자">
                키퍼에서 발급한 세금계산서이며, 아직 홈택스에 등록되었는지는 확인되지 않았습니다. 발급 후 홈택스에 등록되기까지 15분 정도 걸립니다.
                <button class="btn btn-sm btn-primary m-1" @click="checkHometax">다시 확인하기</button>
                <template v-if="refreshing">
                    <span class="text-primary">홈택스 확인 중..</span><b-spinner class="ml-3 align-middle" variant="primary"></b-spinner>
                </template>
            </div>
            <div class="alert alert-success mb-0" style="border-radius: 0; box-shadow: 0px 1px 10px gray; z-index: 1" v-else-if="invoice.asp_key && invoice.전송일자">
                키퍼에서 발급한 세금계산서이며, 홈택스에 {{ localDateFormat(invoice.전송일자) }}에 전송되었습니다.
            </div>
        </transition>

        <div class="overflow-auto">
            <div class="card m-3">
                <div class="card-body">
                    <table class="table">
                        <tbody>
                            <tr>
                                <th>종류</th>
                                <td class="text-right">
                                    {{ taxInvoice.CODES.세금계산서분류[taxInvoice.TaxInvoiceDocument.TypeCode.slice(0, 2)] }}
                                    {{ taxInvoice.CODES.세금계산서종류[taxInvoice.TaxInvoiceDocument.TypeCode.slice(2)] }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="container">
                <form class="row">
                    <div class="col-sm-6">
                        <div class="card">
                            <div class="card-header bg-light-danger border-bottom border-danger">
                                공급자
                            </div>
                            <div class="card-body px-0 pt-0">
                                <table class="table">
                                    <tbody>
                                        <tr v-for="field in taxInvoice.partyFields('')" :key="field">
                                            <th class="bg-light-danger w-25">{{ Object.getOwnPropertyDescriptor(taxInvoice.invoicer, field).value }}</th>
                                            <td><input class="border-0" v-model="taxInvoice.invoicer[field]" :disabled="!issueMode"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="card mt-3 mt-sm-0">
                            <div class="card-header bg-light-primary border-bottom border-primary">
                                공급받는자
                            </div>
                            <div class="card-body px-0 pt-0">
                                <table class="table">
                                    <tbody>
                                        <tr v-for="field in taxInvoice.partyFields('Primary')" :key="field">
                                            <th class="bg-light-primary w-25">{{ Object.getOwnPropertyDescriptor(taxInvoice.invoicee, field).value }}</th>
                                            <td><input class="border-0" v-model="taxInvoice.invoicee[field]" :disabled="!issueMode"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="card m-3">
                <div class="card-body">
                    <table class="table">
                        <tbody>
                            <tr>
                                <th>작성일자</th>
                                <td><input class="border-0" type="date" v-model="taxInvoice.TaxInvoiceDocument.IssueDateTime" :disabled="!issueMode"></td>
                            </tr>
                            <tr>
                                <th>비고</th>
                                <td><textarea class="border-0" v-model="taxInvoice.TaxInvoiceDocument.DescriptionText" :disabled="!issueMode"></textarea></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card m-3">
                <div class="card-header" id="TaxInvoiceTradeLineItem">
                    품목
                    <button class="float-right btn btn-sm btn-outline-primary" @click="addItem" :disabled="!issueMode"><i class="fas fa-plus"></i> 추가</button>
                </div>
                <div class="card-body p-0">
                    <table class="table">
                        <tbody>
                            <tr v-for="(item, index) in taxInvoice.TaxInvoiceTradeLineItem" :key="item.NameText" @click="selectedItem = item">
                                <td>
                                    <div :id="`TaxInvoiceTradeLineItem.${index}.PurchaseExpiryDateTime`"><small class="text-muted">공급일자</small><br>{{ item.PurchaseExpiryDateTime}}</div>
                                    <div><small class="text-muted">품목명</small><br><strong> {{ item.NameText }}</strong></div>
                                    <div class="text-danger" v-if="errors && Object.keys(errors).some(k => k.startsWith(`TaxInvoiceTradeLineItem.${index}`))">
                                        오류
                                    </div>
                                </td>
                                <td class="text-right text-monospace">
                                    <div><small class="text-muted">단가</small> {{ moneyFormat(item.UnitPrice && item.UnitPrice.UnitAmount)}}</div>
                                    <div><small class="text-muted">수량</small> {{ item.ChargeableUnitQuantity }}</div>
                                    <div><small class="text-muted">공급가액</small> {{ moneyFormat(item.InvoiceAmount) }}</div>
                                    <div><small class="text-muted">세액</small> {{ moneyFormat(item.TotalTax.CalculatedAmount) }}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <b-modal id="edit-item-modal"
                         title="품목 입력"
                         :visible="Boolean(selectedItem)"
                         ok-title="입력"
                         :cancel-disabled="!issueMode"
                         cancel-title="삭제"
                         cancel-variant="outline-danger"
                         @cancel="taxInvoice.TaxInvoiceTradeLineItem.splice(taxInvoice.TaxInvoiceTradeLineItem.indexOf(selectedItem), 1)"
                         @hidden="selectedItem = null">
                    <form class="form" v-if="selectedItem">
                        <b-form-group label-cols="4" :label="label"
                                      :state="!Boolean(errors && errors[`TaxInvoiceTradeLineItem.${selectedItemIndex}.${field.key}`])"
                                      :invalid-feedback="errors && errors[`TaxInvoiceTradeLineItem.${selectedItemIndex}.${field.key}`]"
                                      v-for="(field, label) in itemTypes" :key="field.key">
                            <b-input class="text-right" :type="field.type || 'text'"
                                     v-model="taxInvoice.proxy(`TaxInvoiceTradeLineItem.${selectedItemIndex}`)[field.key]"
                                     @input="taxInvoice.calculateItem(selectedItem)"
                                     :disabled="!issueMode"/>
                        </b-form-group>
                    </form>
                </b-modal>
                <div class="card-header">
                    합계
                </div>
                <div class="card-body">
                    <table class="table">
                        <tbody>
                            <tr v-for="(value, field) in taxInvoice.TaxInvoiceTradeSettlement.SpecifiedMonetarySummation" :key="field">
                                <th>{{ taxInvoice.SCHEMA.TaxInvoiceTradeSettlement.SpecifiedMonetarySummation[field] }}</th>
                                <td class="text-right text-monospace">{{ moneyFormat(value) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card m-3">
                <div class="card-header" id="TaxInvoiceTradeSettlement.SpecifiedPaymentMeans">
                    결제방법
                </div>
                <div class="card-body">
                    <div class="alert alert-danger"
                         v-if="errors && getNestedProperty(errors, 'TaxInvoiceTradeSettlement.SpecifiedPaymentMeans')">
                        결제방법에 따른 금액을 입력하세요. 원하는 결제방법을 클릭하시면 자동으로 합계금액이 입력됩니다.
                    </div>
                    <table class="table">
                        <tbody>
                            <tr v-for="(label, code) in taxInvoice.CODES.PaymentMeansTypeCode" :key="code">
                                <th @click="issueMode && taxInvoice.fillPaymentMeans(code)">
                                    {{ label }} &nbsp;
                                    <i class="fas fa-long-arrow-alt-right" v-if="issueMode"></i>
                                </th>
                                <td><input class="border-0 text-right" style="font-font: monospace;"
                                           v-model="taxInvoice.TaxInvoiceTradeSettlement.SpecifiedPaymentMeans.find(m => m.TypeCode == code).PaidAmount"
                                           :disabled="!issueMode"></td>
                            </tr>
                        </tbody>
                    </table>
                    <b-form class="d-flex flex-row align-items-center justify-content-center">
                        <div class="">이 금액을</div>
                        <b-form-group class="mx-2 mb-0"
                                      id="TaxInvoiceDocument.PurposeCode"
                                      :state="!Boolean(formatFieldError('TaxInvoiceDocument.PurposeCode'))"
                                      :invalid-feedback="formatFieldError('TaxInvoiceDocument.PurposeCode')">
                            <b-radio name="PurposeCode" value="01" v-model="taxInvoice.TaxInvoiceDocument.PurposeCode" :disabled="!issueMode">영수</b-radio>
                            <b-radio name="PurposeCode" value="02" v-model="taxInvoice.TaxInvoiceDocument.PurposeCode" :disabled="!issueMode">청구</b-radio>
                        </b-form-group>
                        <div>함</div>
                    </b-form>
                </div>
            </div>
            <div class="m-3" v-if="issueMode">
                <button class="btn btn-block btn-success" @click="issue" v-if="!globals.loading">발급하기</button>
                <button class="btn btn-block btn-success" disabled v-else>처리 중...</button>
            </div>
            <div class="m-3" v-else>
                <router-link :to="{query: {mode: 'issue'}}" class="btn btn-block btn-link">현재 세금계산서 복사해서 신규 발급</router-link>
            </div>
            <b-modal id="issue-complete-modal" title="세금계산서 발급 완료" ok-only ok-title="발급한 세금계산서 보기"
                     @hidden="$router.replace({params: {id: invoice.id}, query: {issueMode: undefined}})">
                <p>세금계산서가 정상적으로 발급되었습니다. 발급된 세금계산서는 홈택스에 정상적으로 등록되고 나면 전표에서도 확인 가능합니다. 홈택스에 등록되기까지는 15분 정도 걸릴 수 있습니다.</p>
            </b-modal>
        </div>
    </div>
</template>

<script>
    import BackButton from "@/components/BackButton";
    import {api, silentApi} from "@/util/api";
    import moment from "moment/moment";
    import {TaxInvoice} from "@/models";
    import ScrapingTaskPollMixin from "@/util/ScrapingTaskPollMixin";

    export default {
        name: "InvoiceDetail",
        props: ['id'],
        components: {BackButton},
        mixins: [ScrapingTaskPollMixin],
        data() {
            return {
                invoice: null,  // 세금계산서 모델
                taxInvoice: null,  // 세금계산서 내용 표준 스키마
                errors: null,
                selectedItem: null,
                itemTypes: {
                    품목순번: {key: 'SequenceNumeric', type: 'number'},
                    품목공급일자: {key: 'PurchaseExpiryDateTime', type: 'date'},
                    품목명: {key: 'NameText'},
                    품목규격: {key: 'InformationText'},
                    품목비고: {key: 'DescriptionText.0'},
                    품목단가: {key: 'UnitPrice.UnitAmount', type: 'number'},
                    품목수량: {key: 'ChargeableUnitQuantity', type: 'number'},
                    품목공급가액: {key: 'InvoiceAmount', type: 'number'},
                    품목세액: {key: 'TotalTax.CalculatedAmount', type: 'number'},
                },
            }
        },
        computed: {
            selectedItemIndex() {
                return this.taxInvoice.TaxInvoiceTradeLineItem.indexOf(this.selectedItem)
            },
            issueMode() {
                return this.$route.query.mode == 'issue'
            },
            validation_errors() {
                // TODO validate in server side
                return {
                }
            }
        },
        watch: {
            '$route.query'() {
                this.reload()
            }
        },
        async mounted() {
            await this.reload()
        },
        methods: {
            async reload() {
                if (this.issueMode) {
                    this.invoice = (await api.post('robotax/세금계산서/draft/', {
                        공급자: this.globals.trader.robotax_id,
                        공급받는자: this.$route.query.공급받는자,
                        source: this.id
                    })).data
                } else {
                    this.invoice = (await api.get(`robotax/세금계산서/${this.id}/`)).data
                }
                this.taxInvoice = new TaxInvoice(this.invoice.data)
                // console.log(this.$el, this.$el.parentElement.scrollTop)
                this.$el.parentElement.scrollTop = 0
            },
            addItem() {
                this.selectedItem = {
                    SequenceNumeric: 1,
                    PurchaseExpiryDateTime: moment().format('YYYY-MM-DD'),
                    NameText: "",
                    InformationText: "",
                    DescriptionText: [],
                    ChargeableUnitQuantity: null,
                    UnitPrice: {
                        UnitAmount: 0
                    },
                    InvoiceAmount: 0,
                    TotalTax: {
                        CalculatedAmount: 0
                    }
                }
                this.taxInvoice.TaxInvoiceTradeLineItem.push(this.selectedItem)
            },
            async issue() {
                this.globals.loading = true
                this.errors = null
                try {
                    this.invoice = (await silentApi.post('robotax/세금계산서/issue/', {
                        '사업자': this.globals.trader.robotax_id,
                        'data': this.taxInvoice.data()
                    })).data
                    this.taxInvoice = new TaxInvoice(this.invoice.data)
                    if (this.taxInvoice.TaxInvoiceDocument.IssueID) {
                        this.$bvModal.show('issue-complete-modal')
                        // await this.$router.replace({query: {issueMode: undefined}})
                    }
                } catch(e) {
                    this.errors = e.response.data
                } finally {
                    this.globals.loading = false
                }
            },
            async checkHometax() {
                await this.refresh('세금계산서')
                await this.reload()
            },
            formatFieldError(field) {
                let value = this.errors && this.errors[field]
                if (value instanceof Array) {
                    return value.join('\n')
                }
                return value
            }
        }
    }
</script>

<style scoped>

</style>