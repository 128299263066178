<template>
    <div style="position: fixed; left: 0; right: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.8); z-index: 1000; font-size: 1.5rem"
         class="d-flex justify-content-center align-items-center"
        @click="forward" v-if="!finish">
        <div v-for="s in step" :key="s.message">
            <b-tooltip :target="targetId(s)" variant="primary" :show="s === currentStep" v-if="s.target">
                <div style="white-space: pre-line">
                    {{ s.message }}
                </div>
            </b-tooltip>
        </div>
        <div class="text-center p-4">
            <div class="text-white mb-4">키퍼 도움말 <br>{{ current+1 }} / {{ step.length }}</div>
            <div style="white-space: pre-line" class="m-4 text-white" v-if="currentStep && !currentStep.target">
                {{ currentStep.message }}
                <div v-if="currentStep.showSpinner"><b-spinner></b-spinner></div>
            </div>
            <b-button size="lg" variant="primary">{{ current !== step.length - 1 ? '다음' : '완료' }}</b-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Tutorial",
        data: () => ({
            step: [
                {
                    target: null,
                    message: '안녕하세요, 키퍼를 이용해주셔서 감사합니다. \n\n처음 공동인증서를 등록하시면 바로 자료수집이 시작되며, 올해 데이터를 모두 수집하기까지 10~20분 걸릴 수 있습니다.',
                    showSpinner: true
                }, {
                    target: '자료수집',
                    message: '여기에서는 \n데이터 수집 상태를 확인할 수 있습니다.'
                }, {
                    target: 'trader-dropdown',
                    message: '이 곳에서 사업자를 선택하세요.'
                }, {
                    target: null,
                    message: '궁금한 점이 있으면 언제든지 도움말에서 게시판이나, 채팅상담으로 문의해주세요. \n\n이제, 가장쉬운 간편장부 키퍼로 손쉽게 사업을 관리해보세요.'
                },
            ],
            current: null,
            finish: false
        }),
        computed: {
            currentStep () {
                if (this.current > this.step.length - 1) { return null }
                return this.step[this.current]
            }
        },
        mounted () {
            this.forward()
        },
        methods: {
            forward () {
                if (this.currentStep && this.currentStep.target) {
                    document.getElementById(this.targetId(this.currentStep)).style.zIndex = null
                }
                this.current = this.current === null ? 0 : this.current + 1

                if (!this.currentStep) {
                    this.finish = true
                    localStorage.setItem('complete-tutorial', true)
                    return
                }

                if (this.currentStep.target) {
                    document.getElementById(this.targetId(this.currentStep)).style.zIndex = 1001
                }
            },
            targetId (step) {
                if (!step.idPostfix) {
                    return step.target
                }
                return step.target + (this.globals.smallScreen ? '' : '-upper')
            }
        }
    }
</script>

<style scoped>
</style>
