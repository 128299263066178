<template>
    <div class="h-100 d-flex flex-column">
        <nav class="navbar navbar-light titlebar pt-0">
            <back-button></back-button>
            <h5 class="mt-2">지방세 신고납부내역</h5>
            <span class="text-underline" v-b-toggle.help-wetax>
                <i class="fas fa-question-circle"></i> 도움말
            </span>
        </nav>
        <b-collapse class="mx-3 mb-3 alert alert-info" id="help-wetax" title="Wetax 안내" ok-only ok-title="닫기">
            <p>
                <strong>위택스(Wetax)</strong>에서는 지방세를 조회하고 납부하실 수 있어요.
                아직 위택스 계정이 없다면 PC에서 위택스에 접속하시거나,
                <a href="https://play.google.com/store/apps/details?id=kr.go.wetax.android&hl=ko&gl=US">스마트 위택스</a>를
                설치하신 후에 회원가입해주세요.
                <strong>사업자용 공인인증서</strong>로 등록하시면 키퍼에서도 연동되어 위택스의 지방세 등 신고납부내역을 확인하실 수 있어요.
            </p>
        </b-collapse>

        <div class="m-3 alert alert-danger" v-if="!accountTypes.Wetax || !accountTypes.Wetax.valid ">
            <strong>위택스(Wetax)</strong>가 연동되지 않았습니다. 키퍼에 등록하신 <strong>공동인증서</strong>를 통해
            위택스에 가입하시면 키퍼에서 4대보험 고지 및 납부내역을 확인하실 수 있어요.
        </div>

        <scraping-account-panel class="mx-3 mb-3" source="Wetax" :account="accountTypes.Wetax" use-cert></scraping-account-panel>

        <div class="overflow-auto" @scroll="loadMore($event)">
            <table class="table bg-white" v-if="paginator">
                <tbody v-if="paginator.objects.length">
                    <tr v-for="obj in paginator.objects" :key="obj.id">
                        <td>
                            <div><strong class="m-0">{{ obj.신고세목 }}</strong></div>
                            <small class="text-muted">납기일자 : {{ localDateFormat(obj.납기일자) }}</small>
                        </td>
                        <td class="text-right" style="min-width: 40px">
                            <div class="">{{ moneyFormat(obj.납부세액) }}</div>
                            <small>
                                <span :class="{
                                    'text-danger': obj.납부여부.includes('미납'),
                                    'text-success': ['납부', '면세'].some(s => obj.납부여부.includes(s)),
                                }">
                                    {{ obj.납부여부 }}
                                </span>
                            </small>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else-if="certs.find(c => c.subject_type != '개인' && c.wetax && c.wetax.valid)">
                    <tr>
                        <td>신고납부 내역이 없습니다.</td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td class="text-danger">Wetax가 연동되지 않았습니다.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import {api} from "@/util/api";
    import Paginator from "@/util/paginator";
    import BackButton from "@/components/BackButton";
    import querystring from 'querystring'
    import AccountMixin from "@/util/AccountMixin";
    import ScrapingAccountPanel from "@/views/connect/ScrapingAccountPanel";

    export default {
        name: "Wetax",
        mixins: [AccountMixin],
        components: {ScrapingAccountPanel, BackButton},
        data() {
            return {
                certs: [],
                paginator: null,
            }
        },
        async mounted() {
            await this.reload()
            this.pollAccounts()
        },
        methods: {
            accountsUpdated() {
                this.pollScraping('Wetax')
            },
            async scrapingUpdated() {
                await this.reload()
            },
            async reload() {
                this.certs = (await api.get('/robotax/공인인증서/', {
                    params: {사업자_set: this.globals.trader.robotax_id},
                    paramsSerializer: querystring.stringify
                })).data.results
                this.paginator = new Paginator(api.get, 'robotax/wetax/?사업자=' + this.globals.trader.robotax_id)
                this.paginator.loadNext()
            },
            loadMore: function(event) {
                if (!this.paginator.canLoadNext()) {
                    return
                }
                if (event.target.scrollTop + event.target.clientHeight + 100 > event.target.scrollHeight) {
                    this.paginator.loadNext()
                }
            }
        }
    }
</script>

