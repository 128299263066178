<template>
    <div class="h-100 overflow-auto" v-if="report && 대표자">
        <div class="btn btn-toolbar" v-if="false">
            <b-button variant="outline-dark" disabled>신고하기</b-button>
            <b-button variant="outline-primary" class="ml-1" disabled>납부서</b-button>
        </div>
        <div class="">
            <div class="">
                <div class="card m-3">
                    <div class="card-body">
                        <table class="table bg-white mb-1">
                            <tbody>
                                <tr>
                                    <th>납세자명</th>
                                    <th class="text-right">{{ 대표자.법인명_상호 }}</th>
                                </tr>
                                <tr>
                                    <th>납부 세액</th>
                                    <td class="text-right text-primary">{{ moneyFormat(report.records[2].종합소득세_신고기한내납부할세액) }}</td>
                                </tr>
                                <tr>
                                    <th>납기일</th>
                                    <td class="text-right">{{ localDateFormat(report.납기일) }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <p class="text-center" :class="'text-' + report.status.color" v-if="report.status">
                            {{ report.status.label }}
                        </p>
                        <div class="" v-if="대표자.id != globals.trader.id">
                            <div class="alert alert-danger">
                                <p>
                                    <i class="fas fa-info-circle mr-1"></i>
                                    <strong>종합소득세</strong>는 사업자가 아니라 사업자를 보유한 <strong>대표자 개인에게 부과되는 세금</strong>입니다.
                                    현황판이나 설정에서 <strong>{{ globals.trader.법인명_상호 }}</strong> 대신
                                    <strong>{{ 대표자.법인명_상호 }}</strong> 개인을 선택하시면 신고하기 기능도 이용하실 수 있어요.
                                </p>
                                <button class="btn btn-block btn-outline-primary" @click="selectPerson">{{ 대표자.법인명_상호 }} 개인 현황판 보기</button>
                            </div>
                        </div>

                    </div>
                </div>

                <tag-guide :should-tag="untagged && untagged.length"></tag-guide>
            </div>
            <div class="">
                <div class="card mx-3 mb-3">
                    <div class="card-header">과세표준확정신고 및 납부계산서</div>
                    <div class="card-body p-0">
                        <TaxReportBrowser resource-name="종합소득세" :id="report.id"/>
                        <br>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TaxReportBrowser from "@/components/TaxReportBrowser";
    import {api} from "@/util/api";
    import querystring from "querystring";
    import TagGuide from "@/components/TagGuide";
    import {saveGlobal} from "@/globals";

    export default {
        name: "IncomeTaxReport",
        components: {TagGuide, TaxReportBrowser},
        props: ['id'],
        data() {
            return {
                report: null,
                untagged: null,
                대표자: null,
            }
        },
        watch: {
            id(to, from) {
                this.reload()
            }
        },
        async mounted() {
            await this.reload();
        },
        methods: {
            selectPerson() {
                saveGlobal('trader', this.대표자)
                this.$router.replace({name: '현황판'})
            },
            async reload() {
                this.report = (await this.globals.종합소득세.get(this.id + '/')).object
                await this.report.loadHometax()
                this.대표자 = this.globals.user.사업자_set.find(t => t.robotax_id == this.report.사업자)
                this.untagged = (await api.get('robotax/전표/annotate/', {
                    params: {
                        사업자: this.globals.trader.robotax_id,
                        거래일__year: this.report.과세년도,
                        tag__isnull: true,
                        매출매입__isnull: false,
                        groupby_fields: ['매출매입'],
                        count_fields: ['id'],
                    },
                    paramsSerializer: querystring.stringify
                })).data
            }
        }
    }
</script>

