<template>
    <span/>
</template>

<script>
    import api from '@/util/api'

    export default {
        name: "VersionChecker",
        data: () => ({
            refreshing: false,
            registration: null,
        }),
        created () {
            document.addEventListener('swRegistered', this.handleRegistered, { once: true })
            document.addEventListener('swUpdated', this.handleUpdated, { once: true })
            if (navigator.serviceWorker) {
                navigator.serviceWorker.addEventListener('controllerchange', () => {
                    if (this.refreshing) return
                    this.refreshing = true
                    window.location.reload()
                })
            }
        },
        watch: {
            '$route': function (to, from) {
                if (!this.registration) { return }
                this.registration.update()
            }
        },
        methods: {
            handleRegistered (e) {
                if (!e.detail) { return }
                this.registration = e.detail
                this.registration.update()
            },
            handleUpdated (e) {
                this.registration = e.detail
                if (!this.registration || !this.registration.waiting) { return }
                this.registration.waiting.postMessage('skipWaiting')
            }
        }
    }
</script>

<style scoped>

</style>
