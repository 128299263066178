<template>
    <div>
        <nav class="navbar navbar-light titlebar text-white">
            <back-button></back-button>
            <h5 class="mt-2">
                키퍼 세무대행 안내
            </h5>
            <span></span>
        </nav>
        <div class="card m-4">
            <div class="card-body">
                <h5>서비스 소개</h5>
                <p>
                    키퍼는 세무회계 업무가 단순한 사업자들을 위해 직접 회계처리도 하고 세금신고도 할 수 있는 기능을 제공하지만,
                    좀더 회계가 복잡한 고객을 위해 <strong>정식 세무대행</strong> 서비스도 제공합니다.
                    키퍼 앱 내에서 채팅 상담 기능을 통해 세무 기장 직원과 <strong>실시간으로 상담</strong>하면서 세무회계 업무를 처리할 수 있습니다.
                </p>
                <h5>혜움세무회계</h5>
                <p>
                    키퍼 세무대행은 최고의 고객 만족도를 자랑하는 <strong>혜움세무회계</strong>에서 담당합니다.
                    세무대행을 혜움에 맡긴 고객의 92%가 보험료를 절약했으며 52%는 누락된 부가세를 더 많이 환급 받았고, 21%의 사업자는 누락된 공제, 지원금 혜택을 받았습니다.
                </p>
                <h5>이용료</h5>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th style="min-width: 100px">기준</th>
                            <th style="min-width: 90px">기장료</th>
                            <th style="min-width: 100px">세무조정료</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>10인 이하</td>
                            <td>10만원</td>
                            <td>30만원</td>
                        </tr>
                        <tr>
                            <td>30인 이하</td>
                            <td>20만원</td>
                            <td>40만원</td>
                        </tr>
                        <tr>
                            <td>30인 이상</td>
                            <td>별도 협의</td>
                            <td>별도 협의</td>
                        </tr>
                    </tbody>
                </table>
                <p>
                    세무대행 이용료는 <strong>매월 발생하는 기장료</strong>와 <strong>연 1회 법인세나 종합소득세 신고 때 발생하는 세무조정료</strong>로 나뉘며,
                    사업자의 특성에 따라 조금씩 달라지기 때문에 저희 담당자가 한 번 연락을 드려서 논의한 다음 확정하게 됩니다.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import BackButton from "@/components/BackButton";
    export default {
        name: "TaxAgentIntro",
        components: {BackButton}
    }
</script>

<style scoped>

</style>