<template>
    <div class="" v-if="globals.user">
        <p>친구에게 키퍼를 추천해주세요. 친구가 키퍼에 가입하고 공동인증서까지 등록하시면 추천하신 분, 추천 받으신 분 모두에게 <strong>한달 무료이용 쿠폰</strong>을 드립니다.</p>
        <p>다음 추천 코드를 복사해서 친구에게 보내시거나, 문자나 카톡으로 직접 보내보세요.</p>
        <h3 class="text-center" @click="copyToClipboard" v-if="globals.user.referral_code">
            <span id="referral-code">{{ globals.user.referral_code }}</span>
            <i class="ml-2 far fa-copy"></i>
        </h3>
        <b-button block variant="primary" @click="issueReferralCode" v-else>추천코드 발급받기</b-button>
        <div class="container-fluid mt-3" v-if="globals.user.referral_code">
            <div class="row">
                <div class="col-6">
                    <b-button variant="outline-primary" class="" block @click="share('sms')">문자로 공유하기</b-button>
                </div>
                <div class="col-6">
                    <b-button variant="outline-primary" class="" block @click="share('kakao')">카톡으로 공유하기</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {api, silentApi} from "@/util/api";
    import {saveGlobal} from "@/globals";

    export default {
        name: 'MyReferral',
        props: {
            couponButton: { default: false }
        },
        mounted () {
            // TODO 모든 유저에게 referral코드는 바로 발급하고 이 부분 삭제하기
            if (this.globals.user && !this.globals.user.referral_code) {
                this.issueReferralCode()
            }
        },
        methods: {
            issueReferralCode () {
                this.globals.loading = true
                this.$nextTick(async () => {
                    try {
                        saveGlobal('user', (await api.post(`user/issue_referral_code/`)).data)
                    } catch {
                        saveGlobal('user', (await silentApi.get('user/me/')).data)
                    }
                    this.globals.loading = false
                })
            },
            copyToClipboard (event) {
                let range = document.createRange();
                range.selectNode(document.getElementById('referral-code'));
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
                document.execCommand("copy");
                this.$bvToast.toast('추천코드가 복사되었습니다.')
            },
            share (method) {
                const message = `키퍼 간편장부로 내 사업장의 장부를 모바일로 관리해보세요! 추천코드입력하시면 3+1달 무료!  \n\n 추천코드 : ${this.globals.user.referral_code} `
                const url = 'https://keeper.business/app/landing?&utm_campaign=friend_promotion'
                const storeLink = {
                    'ios': 'https://apps.apple.com/app/id1561082556',
                    'android': 'https://play.google.com/store/apps/details?id=com.finecode.selftax&utm_campaign=friend_promotion'
                }
                if (method === 'kakao') {
                    // 카카오링크는 이미 플랫폼에서 앱링크가 제공되어 모바일페이지를 링크로 제공
                    Kakao.Link.sendDefault({
                        objectType: 'text',
                        text: message,
                        link: {
                            mobileWebUrl: url,
                            webUrl: url,
                        }
                    })
                } else if (method === 'sms') {
                    location.href = `sms:${this.globals.platform === 'ios' ? '&' : '?'}body=${message} ${storeLink[this.globals.platform]}`
                }
            }
        }
    }
</script>
