<template>
    <div class="h-100 d-flex flex-column">
        <nav class="navbar navbar-light titlebar pt-0">
            <back-button></back-button>
            <h5 class="mt-2">4대보험 고지 및 납부내역</h5>
            <span class="text-underline" v-b-toggle.help-사회보험통합징수포털>
                <i class="fas fa-info-circle"></i> 도움말
            </span>
        </nav>
        <b-collapse class="mx-3 mb-3 alert alert-light" id="help-사회보험통합징수포털" title="사회보험통합징수포털 안내" ok-only ok-title="닫기" :visible="!account">
            <p>
                <strong>사회보험통합징수포털</strong>을 이용하시면 4대보험을 한 번에 조회하고 납부하실 수 있어요.
                아직 사회보험통합징수포털 계정이 없다면 회원가입해주세요. 키퍼에 등록하신 <strong>공동인증서</strong>로 회원가입하시면
                키퍼에서도 연동되어 4대보험 납부내역을 확인하실 수 있어요.
            </p>
            <p><i class="fas fa-exclamation-triangle"></i> 고용하는 직원이 없거나, 사업자가 아닌 경우는 사회보험통합징수포털 가입은 필요하지 않습니다.</p>
        </b-collapse>
        <scraping-account-panel class="mx-3 mb-3" source="사회보험" :account="accountTypes.사회보험" use-cert v-if="globals.trader.사업자구분 != '개인'"></scraping-account-panel>

        <div class="overflow-auto" @scroll="loadMore($event)">
            <table class="table bg-white" v-if="paginator">
                <tbody>
                    <tr v-for="row in paginator.objects" :key="row.id">
                        <td>
                            <i class="fas fa-check text-success" v-if="isPaid(row)"></i>
                            <i class="fas fa-times text-danger" v-else></i>
                            {{ row.구분 == '노인장기요양보험' ? '장기요양보험' : row.구분 }}

                            <div class="text-right" :class="color(row)"><span>{{ isPaid(row) ? '납부일:' : '납기일:'}}</span></div>
                        </td>
                        <td style="min-width: 150px">
                            {{ yearMonthFormat(row.귀속연월) }} 귀속
                            <div :class="color(row)">
                                {{ localDateFormat(isPaid(row) ? row.납부일 : row.납기일) }}
                            </div>
                        </td>
                        <td class="text-right">
                            {{ moneyFormat(row.고지금액) }}
                            <div :class="color(row)">+ {{ moneyFormat(isPaid(row) ? row.수납연체금 : row.미납연체금) }}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import {api} from "@/util/api";
    import Paginator from "@/util/paginator";
    import BackButton from "@/components/BackButton";
    import moment from "moment";
    import ScrapingAccountPanel from "@/views/connect/ScrapingAccountPanel";
    import AccountMixin from "@/util/AccountMixin";

    export default {
        name: "SI4NPayments",
        mixins: [AccountMixin],
        components: {ScrapingAccountPanel, BackButton},
        data() {
            return {
                account: null,
                paginator: null,
            }
        },
        watch: {
            refreshing(newValue) {
                if (!newValue) {
                    this.reload()
                }
            }
        },
        async mounted() {
            this.reload()
            this.pollAccounts()
        },
        methods: {
            accountsUpdated() {
                this.pollScraping('사회보험')
            },
            async scrapingUpdated() {
                await this.reload()
            },
            reload: function () {
                this.paginator = new Paginator(api.get, 'robotax/사회보험/?ordering=-귀속연월&사업자=' + this.globals.trader.robotax_id)
                this.paginator.loadNext()
            },
            loadMore: function(event) {
                if (!this.paginator.canLoadNext()) {
                    return
                }
                if (event.target.scrollTop + event.target.clientHeight + 100 > event.target.scrollHeight) {
                    this.paginator.loadNext()
                }
            },
            isPaid(row) {
                return row.미납보험료 == '0' && row.미납연체금 == '0'
            },
            color(row) {
                if (row.납부일) {
                    return moment(row.납부일).isAfter(moment(row.납기일)) ? 'text-warning' : ''
                } else {
                    return moment(row.납기일).isAfter(moment()) ? 'text-primary' : 'text-danger'
                }
            }
        }
    }
</script>

<style scoped>

</style>