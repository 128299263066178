<template>
    <div class="h-100 d-flex flex-column">
        <nav class="navbar navbar-light titlebar pt-0">
            <back-button></back-button>
            <h5 class="mt-2">세금신고 및 납부 내역</h5>
            <span></span>
        </nav>
        <b-nav class="bg-white p-2 mb-2" pills>
            <b-nav-item :to="{name: '세금 납부내역'}" exact exact-active-class="active" replace>납부완료</b-nav-item>
            <b-nav-item :to="{name: '세금 환급내역'}" exact exact-active-class="active" replace>환급</b-nav-item>
            <b-nav-item :to="{name: '세금 체납내역'}" exact exact-active-class="active" replace>체납</b-nav-item>
        </b-nav>
        <induce-cert class="p-3" v-if="!globals.trader">
            <p>
                <strong>홈택스</strong>에 접속 가능한 <strong>공인인증서</strong>를 등록하시면 홈택스에 신고한 <strong>세금내역, 납부해야 할 내역, 환급 결정된 내역</strong> 등을 조회하실 수 있어요.
            </p>
        </induce-cert>
        <router-view v-else></router-view>
    </div>
</template>

<script>
    import LoginRequired from "../../components/LoginRequired";
    import AppUpdateMixin from "../../components/AppUpdateMixin";
    import Paginator from "@/util/paginator";
    import api from "@/util/api";
    import InduceCert from "@/components/InduceCert";
    import BackButton from "@/components/BackButton";

    export default {
        name: "HometaxDocuments",
        components: {BackButton, InduceCert},
        mixins: [LoginRequired, AppUpdateMixin],
        data: function() {
            return {
                taskPaginator: null,
            }
        },
        watch: {
            'globals.transactions.syncing': function() {
                this.reload()
            },
            'globals.trader': function() {
                this.reload()
            },
        },
        mounted() {
        },
        methods: {
            async reload () {
                if (!this.globals.trader) { return }

                this.taskPaginator = new Paginator(api.get, 'robotax/hometaxdocument/?page_size=10&source=전자신고결과조회&사업자=' + this.globals.trader.robotax_id)
                this.taskPaginator.loadNext()
            },
            loadMore: function(event) {
                if (!this.globals.trader) { return }

                if (!this.taskPaginator.canLoadNext()) {
                    return
                }
                if (event.target.scrollTop + event.target.clientHeight + 100 > event.target.scrollHeight) {
                    this.taskPaginator.loadNext()
                }
            }
        }
    }
</script>

<style scoped>

</style>
