<template>
    <div class="d-flex flex-row justify-content-between mx-2">
        <b-dropdown id="trader-dropdown" :variant="toggleVariant" toggle-class="border-0" v-if="globals.trader">
            <template #button-content>
                <span class="text-ellipsis d-inline-block" style="max-width: 150px; vertical-align: bottom">{{ 법인명_상호_display(globals.trader) }}</span>
            </template>

            <div style="max-height: 60vh" class="d-flex flex-column">
                <div style="flex: 9; overflow-y: auto">
                    <b-dropdown-item v-for="trader in globals.user.사업자_set.filter(t => !t.폐업일)" :key="trader.id" @click="selectTrader(trader)" :active="trader.id === globals.trader.id">
                        {{ 법인명_상호_display(trader) }} <small>({{ trader.사업자구분 }})</small>
                    </b-dropdown-item>
                </div>
                <div style="flex: 1">
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="$router.push({name: '공인인증서 등록'})"><i class="fas fa-plus"></i> 사업자 추가</b-dropdown-item>
                </div>
            </div>
        </b-dropdown>
        <div>
            <router-link :to="{name: '도움말'}" class="btn btn-link text-white pr-0 d-sm-none">
                <i class="fas fa-question-circle"></i> 도움말
            </router-link>
            <router-link :to="{name: '알림'}" class="btn btn-link text-white" :class="this.globals.unfinishedTaskCount ? 'text-warning' : 'text-white'">
                <i class="fas fa-bell"></i> <span v-if="this.globals.unfinishedTaskCount">{{ this.globals.unfinishedTaskCount }}</span>
            </router-link>
        </div>
    </div>
</template>

<script>
    import {saveGlobal} from "../globals";
    import {api, silentApi} from "@/util/api";
    import moment from "moment";

    export default {
        name: "StatusBar",
        props: {
            toggleVariant: {default: 'primary'},
            textClass: {default: 'text-white'},
        },
        data: function() {
            return {
            }
        },
        async mounted() {
        },
        methods: {
            selectTrader (trader) {
                if (trader.id === this.globals.trader.id) {
                    return
                }
                saveGlobal('trader', trader)
                location.reload()
                // this.$emit('change', trader)
            },
        }
    }
</script>
