<template>
    <div v-if="report">
        <nav class="navbar navbar-light titlebar" v-if="!isRoot()">
            <back-button />
            <h5 class="mt-2 text-white">
                {{ current }}
            </h5>
            <span></span>
        </nav>
        <div v-for="(recordSpec, recordName) in screens[current]" :key="recordName">
            <template v-if="recordSpec.type && recordSpec.type == 'list'">
                <table class="table bg-white" :key="recordName" v-if="report.filter(recordName).length">
                    <thead v-if="recordSpec.label">
                        <tr>
                            <th colspan="2" class="text-center" @click="push(recordSpec)">
                                {{ recordSpec.label }}
                                <i class="fas fa-chevron-right text-muted ml-2" v-if="recordSpec.route"></i>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="text-center" v-for="field in recordSpec.fields" :key="field.name">
                                {{ field.name }}
                            </th>
                        </tr>
                        <tr class="" v-for="(record, i) in report.filter(recordName)" :key="i">
                            <td v-for="field in recordSpec.fields" :key="field.name">
                                {{ format(record, field) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
            <template v-else>
                <table class="table bg-white" v-for="(record, i) in report.filter(recordName)" :key="i">
                    <thead v-if="recordSpec.label">
                        <tr>
                            <th colspan="2" class="text-center" @click="push(recordSpec)">
                                {{ recordSpec.label }}
                                <i class="fas fa-chevron-right text-muted ml-2" v-if="recordSpec.route"></i>
                            </th>
                        </tr>
                    </thead>
                    <template v-for="field in recordSpec.fields">
                        <thead :key="field.name">
                            <tax-report-field :report="report" :record="record" :field="field" :strong="true"></tax-report-field>
                        </thead>
                        <tbody :key="field.name + 'body'" v-if="field.$expanded" class="table-sm">
                            <tax-report-field :report="report" :record="record" :field="subField"
                                          v-for="subField in field.sumOf" :key="subField.name"></tax-report-field>
                        </tbody>
                    </template>
                </table>
            </template>
        </div>
    </div>
</template>
<script>
    import BackButton from "@/components/BackButton";
    import TaxReportField from "@/components/TaxReportField";

    export default {
        name: 'TaxReportBrowser',
        components: {TaxReportField, BackButton},
        props: {
            id: {},
            resourceName: {},
            screenName: {},
            reportData: {},
        },
        data: function() {
            return {
                report: null,
                screens: null,
            }
        },
        computed: {
            resource: function() {
                return this.globals[this.resourceName]
            },
            current: function () {
                return this.screenName || Object.keys(this.screens)[0]
            }
        },
        watch: {
            id() {
                this.reload()
            }
        },
        async mounted() {
            await this.reload()
        },
        methods: {
            async reload() {
                if (this.reportData) {
                    this.report = new this.resource.modelClass(this.reportData)
                } else {
                    this.report = (await this.resource.get(this.id + '/')).object
                }
                this.screens = this.report.screens()
            },
            toggle: function(record) {
                this.$set(record, '$expanded', !record.$expanded)
            },
            push: function(recordSpec) {
                if (recordSpec.route) {
                    this.$router.push(Object.assign({params: {transition: 'push'}}, recordSpec.route))
                }
            },
            format: function(record, field) {
                if (!record) {
                    return ''
                }

                if (typeof field.format == 'function') {
                    return field.format(record[field.name])
                } else if (field.format == 'string') {
                    return record[field.name]
                } else {
                    return this.numberFormat(record[field.name])
                }
            },
            isRoot: function() {
                return this.current == Object.keys(this.screens)[0]
            }
        }
    }
</script>
