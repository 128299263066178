<template>
    <div class="h-100" v-if="!globals.trader">
        <status-bar v-if="globals.smallScreen"></status-bar>
        <induce-cert></induce-cert>
    </div>
    <div class="h-100" v-else>
        <status-bar v-if="globals.smallScreen"></status-bar>
        <nav class="navbar navbar-light titlebar shadow pt-0">
            <span></span>
            <h5 class="m-0">거래처</h5>
            <router-link :to="{name: '거래처 상세보기'}" class="btn btn-primary text-white"><i class="fas fa-plus"></i> 신규등록</router-link>
        </nav>

        <full-version-banner></full-version-banner>
        <div class="card m-3">
            <div class="card-body">
                <period-selector :initial-unit="globals.period.unit || 'month'" :initial-step="globals.period.step" @change="setPeriod($event)"></period-selector>
                <form class="mt-2">
                    <b-input-group>
                        <b-input-group-prepend>
                            <b-input-group-text><i class="fas fa-search"></i>검색어</b-input-group-text>
                        </b-input-group-prepend>
                        <input class="form-control" type="search" @input="query = $event.target.value">
                    </b-input-group>
                    <b-form-checkbox-group class="mt-2 mr-1"
                                           button-variant="outline-primary"
                                           v-model="selectedSources"
                                           :options="sources"
                                           name="buttons-1"
                                           buttons></b-form-checkbox-group>

                    <b-form-checkbox-group class="mt-2 mr-1"
                                           button-variant="outline-primary"
                                           v-model="options"
                                           :options="['무거래포함']"
                                           name="buttons-1"
                                           buttons></b-form-checkbox-group>
                </form>
            </div>
        </div>

        <div class="card m-3" v-if="clientsPaginator">
            <div class="card-body text-center" v-if="clientsPaginator.loading">
                <b-spinner variant="primary"></b-spinner>
            </div>
            <div class="card-body" v-else-if="clientsPaginator.objects.length == 0">
                주어진 조건의 거래처가 발견되지 않습니다. 조건을 바꿔서 찾아보세요.
            </div>
            <template v-for="client in clientsPaginator.objects">
                <router-link tag="div" :to="{name: '거래처 상세보기', params: {id: client.id}}" class="card-header border-top" :key="'a' + client.id">
                    <strong class="">{{ client.거래처명 }}</strong> <span class="float-right"><span class="text-muted">거래</span> {{ client.전표__count }}건</span>
                </router-link>
                <router-link tag="div" :to="{name: '거래처 상세보기', params: {id: client.id}}" class="card-body pt-0 ptx-0" :key="client.id">
                    <table class="table">
                        <tbody>
                            <template v-for="(data, field) in client.summary || {}">
                                <tr :key="field" v-if="data">
                                    <td>{{ field }}</td>
                                    <td class="text-right text-monospace" :class="field.indexOf('매출') >= 0 || field.indexOf('입금') >= 0 ? 'text-success' : 'text-danger'">{{ moneyFormat(data.공급가액__sum + data.부가세__sum) }}</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </router-link>
            </template>
            <div class="card-footer" >
                <button class="btn btn-block btn-outline-secondary"
                        @click="loadNext()"
                        v-if="clientsPaginator.canLoadNext()">
                    더보기 {{ clientsPaginator.objects.length }} / {{ clientsPaginator.count }}<i class="fas fa-chevron-down"></i>
                </button>
            </div>
        </div>
        <br>
    </div>
</template>

<script>
    import api from "@/util/api";
    import Paginator from "@/util/paginator";
    import querystring from "querystring"
    import LoginRequired from "@/components/LoginRequired";
    import PeriodSelector from "@/components/PeriodSelector";
    import StatusBar from "@/components/StatusBar";
    import InduceCert from "@/components/InduceCert";
    import FullVersionBanner from "@/views/FullVersionBanner";

    export default {
        name: "PartyList",
        components: {InduceCert, StatusBar, PeriodSelector, FullVersionBanner},
        mixins: [LoginRequired],
        data() {
            return {
                summary: [],
                selectedSources: ['세금계산서', '은행'],
                options: [],
                sources: {
                    세금계산서: '세금계산서',
                    현금영수증: '현금영수증',
                    신용카드: '카드',
                    은행: '은행',
                },
                query: '',
                selected: null,
                clientsPaginator: null,
            }
        },
        watch: {
            async 'globals.period'() {
                await this.reload()
            },
            async query() {
                await this.reload()
            },
            async selectedSources() {
                await this.reload()
            },
            async options() {
                await this.reload()
            },
        },
        async mounted() {
        },
        methods: {
            async reload() {
                let params = {}
                if (this.options.indexOf('무거래포함') >= 0) {
                    params.무거래포함 = true
                }
                this.clientsPaginator = new Paginator(api.get, 'robotax/거래처/summary/?' + querystring.stringify(Object.assign({
                    사업자: this.globals.trader.robotax_id,
                    거래처명__contains: this.query || undefined,
                    전표__증빙종류__in: this.selectedSources,
                    전표__거래일__gte: this.dateFormat(this.globals.period.begin),
                    전표__거래일__lte: this.dateFormat(this.globals.period.end),
                    page_size: 10,
                }, params)))
                this.loadNext()
            },
            loadNext() {
                this.clientsPaginator.loadNext((res) => {
                    if (res.data.results.length == 0) {
                        return
                    }
                    api.get('robotax/전표/annotate/?' + querystring.stringify({
                        거래처__in: res.data.results.map(e => e.id).join(','),
                        증빙종류__in: this.selectedSources.join(','),
                        거래일__gte: this.dateFormat(this.globals.period.begin),
                        거래일__lte: this.dateFormat(this.globals.period.end),
                        groupby_fields: ['거래처', '거래처__거래처명', '증빙종류', '매출매입', '은행입출금'],
                        count_fields: ['id'],
                        sum_fields: ['공급가액', '부가세'],
                        ordering: '-공급가액__sum,거래처명',
                    })).then((res) => {
                        for (let summary of res.data) {
                            let party = this.clientsPaginator.objects.find(p => p.id == summary.거래처)
                            if (!party.summary) {
                                this.$set(party, 'summary', {})
                            }
                            this.$set(party.summary, [summary.증빙종류, summary.매출매입, summary.은행입출금].join(' '), summary)
                        }
                    })
                })
            },
            async setPeriod($event) {
                // TODO refactor
                this.globals.period = $event
                this.globals.회계연도 = $event.begin.year()
            }
        }
    }
</script>

<style scoped>

</style>
