<script>
    import moment from "moment"
    import {globals, saveGlobal} from '@/globals'
    import {silentApi} from "@/util/api";
    import * as Sentry from "@sentry/browser";
    import {trackUser} from "@/util/vue-commons";
    import {사업자} from "@/models";

    async function checkLogin(force=false) {
        if (force || !globals.authenticatedTime || moment().diff(globals.authenticatedTime, 'minutes') > 10) {
            try {
                let res = await silentApi.get('user/me/');
                saveGlobal('user', res.data)
                globals.authenticatedTime = moment()
                trackUser(globals.user.id, {
                    name: globals.user.last_name + globals.user.first_name,
                    $email: globals.user.verified_email || globals.user.email,
                    traders: globals.user.사업자_set.map(t => t.법인명_상호)
                })
            } catch (e) {
                if (e.response && e.response.status == 401) {
                    if (globals.user) {
                        Sentry.captureMessage(`401에러추적 - 로그아웃된 User id = ${globals.user.id}`)
                    }
                    saveGlobal('user', null)
                }
            }
        }

        if (!globals.user) {
            return {name: '랜딩'}
        } else if (!globals.user.terms_agreed) {
            return {name: '약관'}
        } else if (!globals.trader) {
            globals.trader = globals.user.사업자_set[0]
        }

        saveGlobal('trader', globals.user.사업자_set.filter(t => t.id == globals.trader.id)[0])

        if (globals.trader) {
            globals.robotaxTrader = new 사업자((await silentApi.get(`robotax/사업자/${globals.trader.robotax_id}/`)).data)
        }
        return {}
    }

    export default {
        name: "LoginRequired",
        beforeRouteEnter: async function(to, from_, next) {
            const nextRoute = await checkLogin()
            next(to.name === nextRoute.name ? {} : nextRoute)
        },
        async mounted() {
            // if (this.globals.trader || this.globals.robotaxTrader || this.globals.trader.id != this.globals.robotaxTrader.id) {
            //     this.globals.robotaxTrader = (await silentApi.get(`robotax/사업자/${this.globals.trader.robotax_id}/`)).data
            // }
        },
        methods: {
            async checkLogin() {
                // Should be called with await
                const nextRoute = await checkLogin(true)
                if (nextRoute.name) {
                    this.$nextTick(() => this.$router.push(nextRoute))
                    return false
                }
                return true
            }
        }
    }
</script>

<style scoped>

</style>
