<template>
    <p v-if="transaction">
        <span v-if="transaction.매출매입 == '매출'">
            <strong>매출</strong>에는 반드시 <strong>매출액</strong>이나 <strong>영업외수익</strong>
            중에 하나의 태그를 붙여두어야 <strong>손익계산</strong>이 가능합니다.
        </span>
        <span v-else-if="transaction.매출매입 == '매입'">
            <strong>매입</strong>은 사업과 관련 있는 경우에 반드시 <strong>매출원가</strong>, <strong>판매비와관리비</strong>, <strong>영업외비용</strong>
            중에 하나의 태그를 붙여두어야 <strong>손익계산</strong>이 가능합니다.
        </span>
        <span v-else-if="transaction.은행입출금 == '입금'">
            은행 입금은 연결되는 세금계산서나 카드매출이 있으면 태깅하지 않아도 됩니다. 계좌이체를 통한 현금매출이 발생했을 때만 <strong>매출액</strong> 태그를 붙이면 됩니다.
        </span>
        <span v-else-if="transaction.은행입출금 == '출금'">
            <strong>은행 출금</strong>은 연결되는 세금계산서나 카드매입이 있으면 태깅하지 않아도 됩니다. 계좌이체를 통해 매입하거나 공과금 등을 납부했을 때만
            <strong>매출원가</strong>나 <strong>판매비와관리비</strong> 태그를 붙이면 됩니다.
        </span>
    </p>
</template>
<script>
    export default {
        name: 'TaggingHelp',
        props: {
            transaction: {}
        }
    }
</script>