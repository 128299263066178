<template>
    <div>
        <nav class="navbar navbar-light titlebar">
            <back-button></back-button>
            <h5 class="mt-2">
               {{ account.type }}  계정 등록
            </h5>
            <span></span>
        </nav>
        <div>
            <div class="card mx-3 mb-3" v-if="account.type == '스마트스토어'">
                <div class="card-body">
                    <p>스마트스토어의 매출 정보를 연동하려면 연동 시작 버튼을 누르신 후에 아래의 안내와 같이 스마트스토어에서 매니저를 추가로 등록해주세요.</p>
                    <p v-if="!account.id">
                        <button class="btn btn-outline-primary" @click="registerSmartstoreAccount">
                            연동 시작
                        </button>
                    </p>
                    <form class="form" v-else>
                        계정 연동 확인:
                        <status-cell tag="span" :task="account" detail></status-cell>
                        <span class="ml-1 text-warning" v-if="account.valid == null">{{ account.error }}</span>
                        <p v-if="account.valid && account.data.length > 0">
                            <strong class="ml-1" >{{ account.data[0].channelName }}</strong> 연동됨
                        </p>
                    </form>
                </div>
            </div>
            <div class="card mx-3 mb-3" v-else-if="account.type == '유튜브'">
                <div class="card-body">
                    <p>유튜브 계정을 연결하면 유튜브에서 발생한 매출을 장부로 가져올 수 있습니다. </p>
                    <p v-if="account.id">
                        계정 연동 확인: <status-cell tag="span" :task="account" detail></status-cell>
                        <span class="ml-1 text-warning" v-if="account.valid == null">{{ account.error }}</span>
                    </p>
                    <div v-if="!account.id || !account.valid">
                        <p>
                            아래 버튼을 누르면 구글 로그인을 통해서 유튜브 매출 연동이 시작됩니다. 구글로 로그인하고 나면 다음과 같은 권한에 동의를 요청하는데,
                            이에 승인해주시면 자료수집이 시작됩니다.
                        </p>
                        <ul>
                            <li>YouTube 콘텐츠에 대한 수익 및 비수익 YouTube 분석 보고서 보기</li>
                            <li>YouTube 콘텐츠에 대한 YouTube 분석 보고서 조회</li>
                            <li>YouTube에서 콘텐츠 소유자 계정 세부정보 조회</li>
                        </ul>
                        <div class="text-center">
                            <button class="btn btn-outline-primary" @click="registerYoutubeAccount">
                                <i class="fab fa-youtube text-danger"></i> 유튜브 계정 연결
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mx-3 mb-3" v-else-if="account.type === '은행빠른조회'">
                <div class="card-body">
                    <p class="" v-if="source && source.explain && !account.id">{{ source.explain }}</p>
                    <p v-if="account.id">
                        계정 연동 확인: <status-cell tag="span" :task="account" detail></status-cell>
                        <span class="ml-1 text-success" v-if="account.valid">은행빠른조회가 연동되었습니다.</span>
                        <span class="ml-1 text-warning" v-else-if="account.valid == null">은행빠른조회가 연동 중 입니다.</span>
                        <span class="ml-1 text-danger" v-else-if="account.valid == false">
                            {{ account.error }}
                            {{ source.explain }}
                        </span>
                    </p>
                </div>
            </div>
            <div class="card mx-3 mb-3" v-else>
                <div class="card-body">
                    <p class="" v-if="source && source.explain && !account.id">{{ source.explain }}</p>
                    <p v-if="account.id">
                        계정 연동 확인: <status-cell tag="span" :task="account" detail></status-cell>
                        <span class="ml-1 text-warning" v-if="account.valid == null">{{ account.error }}</span>
                    </p>

                    <b-form @submit.prevent v-if="!account.공인인증서">
                        <b-form-group>
                            <label class="">ID</label>
                            <b-form-input v-model="account.username"></b-form-input>
                        </b-form-group>
                        <b-form-group>
                            <label class="">비밀번호</label>
                            <b-form-input :type="showPassword ? 'text' : 'password'" v-model="account.password"></b-form-input>
                            <b-checkbox class="mt-1" v-model="showPassword">비밀번호 보기</b-checkbox>
                        </b-form-group>
                        <button class="btn btn-block btn-primary mt-4 mb-3" @click="registerAccount">
                            <span v-if="!account.id">{{ account.type }} 등록하기</span>
                            <span v-else>수정</span>
                        </button>
                    </b-form>
                    <div v-else>
                        <p>다음 공동인증서로 연동 중입니다.</p>
                        <div class="alert alert-secondary">
                            {{ account.공인인증서.subject }}
                        </div>
                    </div>
                </div>
            </div>
            <template>
                <div class="card m-3" v-if="account.type == '스마트스토어'">
                    <div class="card-header" v-b-toggle.smartstore-add-manager-help>
                        스마트스토어 매니저 추가 방법 <collapse-indicator></collapse-indicator>
                    </div>
                    <b-collapse class="card-body border-top" id="smartstore-add-manager-help" :visible="!account.valid">
                        <ul>
                            <li>스마트스토어 접속</li>
                            <li>판매자정보 메뉴 펼치기</li>
                            <li>매니저 관리 메뉴 선택</li>
                            <li>우상단의 매니저 초대 버튼 클릭</li>
                            <li><strong>이름</strong>에 <code>키퍼</code> 입력</li>
                            <li><strong>휴대폰 번호</strong>에 <code>01026282408</code> 입력</li>
                            <li><span class="badge badge-danger">중요</span> <code>계정 부매니저</code>를 <code>계정 주매니저</code>로 변경</li>
                            <li>확인 버튼 클릭</li>
                        </ul>
                        <p>위와 같이 등록하고 나면 5분 이내에 키퍼와 스마트스토어 연동이 되어 스마트스토어의 매출내역을 확인하실 수 있습니다.</p>
                    </b-collapse>
                </div>

                <div class="card m-3" v-if="account.type === '여신금융협회'">
                    <div class="card-header" v-b-toggle.cardsales-help>
                        여신금융협회 등록 방법 <collapse-indicator></collapse-indicator>
                    </div>
                    <b-collapse class="card-body border-top" id="cardsales-help" :visible="!account.valid">
                        <ol>
                            <li><a href="https://www.cardsales.or.kr/">여신금융협회</a> 접속하기</li>
                            <li>가입약관이 나오면 동의합니다.</li>
                            <li>회원정보를 입력합니다.</li>
                            <li>본인인증 버튼을 눌러서 인증을 시작합니다.</li>
                            <li>사용 중인 통신사를 선택해서 본인인증 절차를 진행합니다.</li>
                            <li>회원정보 화면으로 복귀합니다.</li>
                            <li>가맹점 정보를 입력합니다.</li>
                        </ol>
                        <p class="text-muted">세부 절차는 여신금융협회 사정에 의해 변경될 수 있습니다.</p>
                    </b-collapse>
                </div>
            </template>
        </div>

        <div class="m-3">
            <button class="btn btn-danger btn-block"
                    v-if="account.id && !account.공인인증서"
                    @click="removeAccount(account, account.type); $router.back()">
                계정 연동 중지
            </button>
        </div>

        <b-modal id="account-verify-modal"
                 :title="`${account.type} 연동 중` "
                 ok-only
                 ok-title="닫기"
                 @ok="$router.back()">
            <div class="text-center">
                <p>
                    <b-spinner variant="primary"></b-spinner>
                    {{ account.type }} 계정을 등록하는 중입니다. 이 작업은 1~2분 정도 걸릴 수 있습니다.
                </p>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import BackButton from "@/components/BackButton";
    import api, {silentApi, formatError} from "@/util/api";
    import AccountMixin from "@/util/AccountMixin";
    import StatusCell from "@/views/connect/StatusCell";
    import CollapseIndicator from "@/views/income-tax/CollapseIndicator";

    export default {
        name: "Account",
        components: {CollapseIndicator, StatusCell, BackButton},
        mixins: [AccountMixin],
        data() {
            return {
                type: null,
                explain: null,
                account: {
                    id: null,
                    사업자: null,
                    institution: '',
                    type: '',
                    username: '',
                    password: '',
                },
                status: null,
                message: null,
                showPassword: false,
                sources: {
                    여신금융협회: {
                        explain: '여신금융협회 계정을 등록하면 매일 카드매출내역을 확인하실 수 있어요.'
                    },
                    배달의민족: {
                        explain: '배달의민족 사장님광장 계정을 등록하면 배달매출이 자동으로 손익에 적용되고, 세금신고 데이터에 활용할 수 있도록 변환해드려요.'
                    },
                    스마트스토어: {
                        explain: '스마트스토어 계정을 등록하고 스마트스토어의 매출도 연동해보세요.'
                    },
                    은행빠른조회: {
                        explain: '은행빠른조회가 문제가 있을 경우, 아래 `계정 연동 중지` 버튼을 눌러 중지하고, `현황판 > 은행잔고/은행계좌 연결` 버튼으로 새로 연동해주세요'
                    }
                }
            }
        },
        computed: {
            source() {
                return this.sources[this.account.type]
            },
        },
        watch: {
        },
        async mounted () {
            await this.reload()
        },
        methods: {
            async reload() {
                if (this.$route.params.id) {
                    if (this.$route.query.type === '은행빠른조회') {
                        this.account = await this.globals.BankAccount.getObject(this.$route.params.id)
                    } else {
                        this.account = this.globals.accounts.find(a => a.id == this.$route.params.id)
                    }
                } else {
                    this.account.type = this.$route.query['type']
                    this.account.사업자 = this.globals.trader.robotax_id
                }
            },
            async registerAccount() {
                if (!this.account.username.length || !this.account.password.length) {
                    alert('계정정보가 비어있습니다. 다시 확인하고 등록해주세요.')
                    return
                }

                this.trackEvent(`${this.type}_account_verifying`)

                this.$bvModal.show('account-verify-modal')
                if (this.account.id) {
                    this.account = (await api.put(`robotax/account/${this.account.id}/`, this.account)).data
                } else {
                    this.account = (await api.post('robotax/account/', this.account)).data
                }
            },
            async registerSmartstoreAccount() {
                this.account.username = `kp${Math.random().toString(36).slice(2, 10)}@finecode.kr`
                this.account.password = Math.random().toString(36).slice(2)

                this.$bvModal.show('account-verify-modal')
                this.account = (await api.post('robotax/account/singleton/', this.account)).data
            },
            async updateAccount(data) {
                this.account = (await api.patch(`robotax/account/${this.account.id}/`, data)).data
                this.retryAccount(this.account.type)
            },
            async registerYoutubeAccount() {
                let apiHost = location.origin.replace(':8080', ':8000');
                location.href = `${apiHost}/api/login/youtube/?next=${apiHost}/api/사업자/${this.globals.trader.id}/youtube/?path=/datasources/youtube`;
            }
        }
    }
</script>

<style scoped>

</style>
