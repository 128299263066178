<template>
    <div>
        <b-navbar class="text-white" variant="primary" fixed="top">
            <b-navbar-nav><back-button></back-button></b-navbar-nav>
            <b-navbar-nav class="ml-auto mr-auto">
                <h5 class="ml-4 mt-2 text-nowrap">
                    {{ title }}
                </h5>
            </b-navbar-nav>
            <b-button size="sm"
                      variant="light"
                      class="text-nowrap"
                      @click="$router.push({name: '도움말', query: {openChat: true}})">
                <i class="fas fa-comment"></i>상담
            </b-button>
        </b-navbar>
        <div class="bg-white p-4" style="margin-top: 56px" v-html="html">
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import BackButton from "@/components/BackButton";

    export default {
        name: "HtmlViewer",
        components: {BackButton},
        props: ['title', 'url'],
        data: function() {
            return {
                html: null
            }
        },
        async mounted() {
            this.html = (await axios.get(this.url)).data
        }
    }
</script>

<style scoped>

</style>
