<template>
    <b-dropdown class="mr-1"
                :toggle-class="(readOnly ? 'badge' : '') + ' ' + (tag.confirmed ? '' : 'border-dashed') + ' text-ellipsis'"
                :no-caret="readOnly || tag.confirmed"
                :variant="'outline-' + (globals.TAG_COLORS[tag.path.split('/')[0]] || 'secondary')"
                size="sm"
                @show="cancelIfReadOnly"
                :text="tag.path.split('/').pop()">
        <b-dropdown-header>{{ tag.path.split('/')[0] }}</b-dropdown-header>
        <b-dropdown-item :active="tag.path == tagPath" v-for="tagPath in choices" :key="tagPath" @click="select(tagPath)">
            <span class="pl-3">{{ tagPath.slice(tagPath.lastIndexOf('/') + 1) }}</span>
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item link-class="text-danger" @click="$emit('untag')" v-if="tag.confirmed">삭제</b-dropdown-item>
        <b-dropdown-item link-class="text-danger" @click="$emit('remove-recommend')" v-else>추천태그 삭제</b-dropdown-item>
    </b-dropdown>
</template>
<script>
    export default {
        name: 'TagButton',
        components: {},
        props: {
            tag: {},
            recommendation: {type: Object},
            readOnly: {default: false},
        },
        data() {
            return {
            }
        },
        computed: {
            choices() {
                if (this.recommendation && this.recommendation.choices) {
                    return [this.tag.path].concat(this.recommendation.choices)
                }
                return Object.keys(this.globals.tags).filter(t => t.startsWith(this.parent(this.tag.path) + '/')).slice(0, 12)
            },
        },
        methods: {
            parent(tag) {
                if (tag.lastIndexOf('/') >= 0) {
                    return tag.slice(0, tag.lastIndexOf('/'))
                }
                return '미분류'
            },
            select(tag) {
                this.$emit('select', tag)
            },
            cancelIfReadOnly(event) {
                if (this.readOnly) {
                    event.preventDefault()
                    this.$emit('click')
                }
            }
        }
    }
</script>
<style>
    .badge.text-ellipsis {
        display: inline-block;
        max-width: 80px;
    }
</style>
