<template>
    <div class="card mx-3 my-5">
        <div class="card-body p-4">
            <h2>환영합니다!</h2>
            <p>키퍼 간편장부를 이용해주셔서 감사합니다.</p>
            <h4>
                공동인증서를 등록해주세요.
            </h4>
            <p><strong>홈택스에 접속 가능한 공동인증서</strong>를 등록해주시면 각종 회계정보가 자동으로 수집되어 키퍼의 다양한 기능을 이용하실 수 있어요.</p>

            <div class="text-center">
                <b-button class="" variant="primary" size="lg" @click="$router.push({name: '공인인증서 등록'})">
                    공동인증서 등록하러가기
                </b-button>
            </div>
            <br>
            <h4>1월은 부가세 신고 기간입니다. 부가세 신고 방법을 확인해보세요.</h4>
            <div class="text-center d-block d-sm-none">
                <iframe style="width: 80vw; height: 150vw" src="https://www.youtube.com/embed/iDfHcAEuAdY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="text-center d-none d-sm-block">
                <iframe style="width: 60vw; height: 30vw" src="https://www.youtube.com/embed/iDfHcAEuAdY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InduceCert",
        props: {
            theme: {
                default: 'dark'
            }
        }
    }
</script>

<style scoped>

</style>