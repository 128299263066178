function Paginator(requestFunction, path, modelClass) {
    this.requestFunction = requestFunction
    this.loading = false;
    this.error = null
    this.count = 0;
    this.next = path;
    this.prev = null;
    this.modelClass = modelClass;
    this.objects = [];
    this.loadedPages = 0;
}

Paginator.prototype.canLoadNext = function() {
    return this.next && !this.loading
}

Paginator.prototype.loadNext = function(callback) {
    if (!this.next || this.loading) return;

    var self = this;
    this.loading = true;
    this.requestFunction(this.next).then(function(res) {
        self.update(res, true);
        self.loading = false;
        self.error = null
        self.loadedPages++;
        if (callback) callback(res)
    }).catch(error => {
        self.loading = false;
        this.error = error
    })
};

Paginator.prototype.loadAll = function(callback) {
    var loadNextCallback = function(res) {
        if (res.data.next) {
            this.loadNext(loadNextCallback)
        } else if (callback) {
            callback(res)
        }
    }.bind(this)
    this.loadNext(loadNextCallback)
}

Paginator.prototype.isEmpty = function() {
    return this.objects.length == 0 && !this.loading && this.loadedPages
}

Paginator.prototype.update = function(res, append) {
    this.count = res.data.count
    this.next = res.data.next
    this.prev = res.data.previous

    var objects = res.data.results;
    if (this.modelClass) {
        objects = objects.map(function(e) { return new this.modelClass(e)}.bind(this))
    }

    if (append) {
        this.objects = this.objects.concat(objects)
    } else {
        this.objects = objects
    }
};

export default Paginator
