<template>
    <div>
        <div class="card m-3" v-if="summary">
            <div class="card-body px-0">
                <table class="table">
                    <thead>
                        <router-link :to="{name: '증빙종류별 거래', params: {transition: 'push'}, query: 거래일Filter}"
                                     tag="tr"
                                     exact-active-class="table-active"
                                     :replace="!globals.smallScreen">
                            <th colspan="2">전체 거래내역</th>
                            <th class="text-right">
                                <i class="fas fa-chevron-right text-secondary"></i>
                            </th>
                        </router-link>
                    </thead>
                    <tbody  v-for="매출매입 in ['매출', '매입']" :key="매출매입">
                        <router-link :to="{name: '증빙종류별 거래', query: {push: true, 매출매입: 매출매입, ...거래일Filter}}"
                                     tag="tr"
                                     exact-active-class="table-active"
                                     :replace="!globals.smallScreen">
                            <th colspan="2">{{ 매출매입 }}</th>
                            <th class="text-right">
                                {{ moneyFormat(summary.filter(s => s.매출매입 == 매출매입).map(s => s.공급가액__sum).reduce((acc, value) => acc + value, 0))  }}
                                <i class="fas fa-chevron-right text-secondary"></i>
                            </th>
                        </router-link>
                        <router-link :to="{name: '증빙종류별 거래', query: {push: true, 증빙종류: item.증빙종류, 매출매입: item.매출매입, ...거래일Filter}}"
                                     tag="tr"
                                     exact-active-class="table-active"
                                     v-for="item in summary.filter(s => s.매출매입 == 매출매입)"
                                     :key="item.증빙종류 + item.매출매입 + item.은행입출금"
                                     :replace="!globals.smallScreen">
                            <td>
                                {{ item.증빙종류 }}
                            </td>
                            <td class="text-right">
                                <span class="badge badge-light" v-if="isNew(item.거래일__max)"><i class="far fa-bell"></i></span>
                                {{ item.id__count}}건
                            </td>
                            <td class="text-right">{{ moneyFormat(item.공급가액__sum) }} <i class="fas fa-chevron-right text-secondary"></i></td>
                        </router-link>
                    </tbody>
                    <tbody>
                        <tr>
                            <th colspan="2">은행 입출금</th>
                            <td class="text-right pr-4">
                                <strong v-if="summary.filter(s => s.은행입출금).length">
                                    {{ moneyFormat(decimal(summary.filter(e => e.은행입출금 == '입금').reduce((s, e) => s + e.공급가액__sum, 0)) - decimal(summary.filter(e => e.은행입출금 == '출금').reduce((s, e) => s + e.공급가액__sum, 0))) }}
                                </strong>
                                <span v-else>없음</span>
                            </td>
                        </tr>
                        <router-link :to="{name: '증빙종류별 거래', query: {push: true, 증빙종류: item.증빙종류, 은행입출금: item.은행입출금, ...거래일Filter}}"
                                     tag="tr"
                                     exact-active-class="table-active"
                                     v-for="item in summary.filter(s => s.은행입출금)"
                                     :key="item.증빙종류 + item.매출매입 + item.은행입출금"
                                     :replace="!globals.smallScreen">
                            <td>
                                {{ item.매출매입 }} {{ item.은행입출금 }}
                            </td>
                            <td class="text-right">
                                <span class="badge badge-light" v-if="isNew(item.거래일__max)"><i class="far fa-bell"></i></span>
                                {{ item.id__count}}건
                            </td>
                            <td class="text-right">{{ moneyFormat(item.공급가액__sum) }} <i class="fas fa-chevron-right text-secondary"></i></td>
                        </router-link>
                    </tbody>
                </table>
            </div>

        </div>
        <div class="m-3">
            <router-link :to="{name: '세금계산서'}" class="btn btn-block btn-outline-info">세금계산서 발급 목록 <i class="fas fa-chevron-right"></i></router-link>
        </div>
    </div>
</template>

<script>
    import api from "@/util/api";
    import querystring from "querystring";
    import moment from "moment";
    export default {
        name: "TransactionsByTypes",
        props: ['period'],
        data() {
            return {
                summary: null
            }
        },
        computed: {
            거래일Filter() {
                return {
                    거래일__gte: this.dateFormat(this.period.begin),
                    거래일__lte: this.dateFormat(this.period.end),
                }
            }
        },
        watch: {
        },
        async mounted() {
            await this.reload()
            this.$watch('period', this.reload)
        },
        methods: {
            async reload() {
                this.summary = (await api.get('robotax/전표/annotate/', {
                    params: {
                        ...this.거래일Filter,
                        사업자: this.globals.trader.robotax_id,
                        groupby_fields: ['증빙종류', '매출매입', '은행입출금'],
                        count_fields: ['id'],
                        sum_fields: ['공급가액'],
                        max_fields: ['거래일'],
                        ordering: '증빙종류'
                    },
                    paramsSerializer: querystring.stringify
                })).data
            },
            isNew(date) {
                return moment().diff(moment(date), 'days') <= 2
            }
        }
    }
</script>

<style scoped>

</style>