<template>
    <span v-if="user.social.indexOf('kakao') >= 0">
        <span class="btn btn-sm" style="background-color: #F7E317;">
            <img :src="globals.publicPath + 'img/kakao_logo.png'" style="max-width: 18px;">
        </span>
        {{ user.nickname || '카카오' }}
    </span>
    <span v-else-if="user.social.indexOf('naver') >= 0">
        <span class="btn btn-sm text-white" style="background-color: #1ec800;">
            <img :src="globals.publicPath + 'img/naver_logo.png'" style="max-width: 18px;">
        </span>
        {{ user.nickname || '네이버' }}
    </span>
    <span v-else-if="user.social.indexOf('google-oauth2') >= 0">
        <span class="" style="background-color: white;">
            <img :src="globals.publicPath + 'img/google_logo.svg'" style="max-width: 18px;">
        </span>
        {{ user.nickname || '구글' }}
    </span>
    <span v-else-if="user.social.indexOf('apple') >= 0">
        <i class="fab fa-apple" style="font-size: 1.2em"></i>
        {{ user.nickname || '애플' }}
    </span>
</template>
<script>
    export default {
        name: 'SocialLoginInfo',
        props: {
            user: {},
            showEmail: {type: Boolean, default: false}
        }
    }
</script>