<template>
    <div class="">
        <nav class="navbar navbar-light titlebar">
            <back-button></back-button>
            <h5 class="mt-2">
                추천
            </h5>
            <span></span>
        </nav>
        <div class="card mx-3">
            <div class="card-header">
                친구 추천하기
            </div>
            <div class="card-body">
                <my-referral coupon-button="true"></my-referral>
            </div>
        </div>
        <div class="card m-3" v-if="referrer.paginator">
            <div class="card-header">
                내가 추천한 친구
                <div class="float-right">총 {{ referrer.paginator.objects.length }} 명</div>
            </div>
            <div class="card-body">
                <div class="text-center" v-if="!referrer.paginator.objects.length">추천받아 가입한 분이 아직 없습니다.</div>
                <div v-else>
                    <div class="text-right"></div>
                    <table class="table">
                        <tbody>
                            <tr v-for="referral in referrer.paginator.objects" :key="referral.id">
                                <td>{{ referral.recipient.email || referral.recipient.username }}</td>
                                <td class="text-success" v-if="referral.granted">쿠폰 지급 완료</td>
                                <td v-else>인증서 등록 대기</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="card m-3">
            <div class="card-header">
                추천 받아서 가입하셨나요?
            </div>
            <div class="card-body">
                <p>친구에게 받은 추천코드를 입력하고, 공동인증서를 등록하시면 <strong>한달 무료이용 쿠폰</strong>을 받으실 수 있어요!</p>
                <b-input class="my-3" placeholder="추천코드 입력" :state="recipient.checked" v-model="recipient.code"></b-input>
                <b-button variant="primary" class="my-3" block @click="check_referral_code" v-if="!recipient.checked">코드 확인</b-button>
                <template v-if="recipient.paginator && recipient.paginator.objects.length">
                    <hr>
                    <div>추천코드 입력 현황</div>
                    <table class="table">
                        <tbody>
                            <tr v-for="referral in recipient.paginator.objects" :key="referral.id">
                                <td>{{ referral.referrer.email || referral.referrer.username }}</td>
                                <td class="text-success" v-if="referral.granted">쿠폰 지급 완료</td>
                                <td v-else>인증서 등록 대기</td>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </div>
        </div>
    </div>
</template>


<script>
    import BackButton from "@/components/BackButton";
    import MyReferral from "@/components/MyReferral";
    import Paginator from "@/util/paginator";
    import {api, silentApi} from "@/util/api";
    import {saveGlobal} from "@/globals";

    export default {
        name: "Referral",
        components: { BackButton, MyReferral },
        data () {
            return {
                referrer: {
                    paginator: null,
                },
                recipient: {
                    paginator: null,
                    code: '',
                    checked: null,
                    message: '',
                },
            }
        },
        mounted () {
            if (!this.globals.user) {
                this.$router.back()
            }

            this.reload()
        },
        methods: {
            reload () {
                this.globals.loading = true
                this.referrer.paginator = new Paginator(api.get, `referral/?referrer=${this.globals.user.id}&link__isnull=true`)
                this.referrer.paginator.loadNext(() => {
                    this.recipient.paginator = new Paginator(api.get, `referral/?recipient=${this.globals.user.id}`)
                    this.recipient.paginator.loadNext(() => {
                        this.globals.loading = false
                    })
                })
            },
            loadNext (direction) {
                this[direction].paginator.loadNext()
            },
            check_referral_code () {
                if (!this.recipient.code) {
                    this.recipient.checked = false
                    alert('추천코드를 입력해주세요.')
                    return
                }

                this.globals.loading = true
                silentApi.post('/referral/check/', {
                    code: this.recipient.code
                }).then(res => {
                    this.recipient.checked = true
                    this.$bvToast.toast('추천코드가 확인되었습니다.')
                }).catch(err => {
                    this.recipient.checked = false
                    alert(err.response.data)
                }).finally(() => {
                    this.globals.loading = false
                    this.reload()
                })
            },
        }
    }
</script>
