<template>
    <div class="h-100 d-flex flex-column">
        <nav class="navbar navbar-light titlebar">
            <back-button></back-button>
            <h5 class="mt-2">세무일정 안내</h5>
            <span></span>
        </nav>
        <div ref="container" class="pt-2 overflow-auto">
            <div class="px-4">
                <div :class="일정.id === 'today' ? '': 'card mb-3'" v-for="일정 in 세무일정" :key="일정.id">
                    <div ref="today" class="mb-2 text-light d-flex" v-if="일정.id === 'today'">
                        <div class="w-100 m-2" style="border: 1px solid yellow; height: 0px"></div>
                        <div class="text-nowrap">{{ moment().format('M월 DD일') }}, 오늘</div>
                        <div class="w-100 m-2" style="border: 1px solid yellow; height: 0px"></div>
                    </div>
                    <div class="card-body row" v-else>
                        <div class="col-12">
                            <div class="">{{ localDateFormat(일정.begin) }}</div>
                            <h6 class="m-0">{{ 일정.display }}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center text-white" v-if="세무일정 === null">
                <p>
                    <b-spinner variant="light"></b-spinner>
                </p>
                <p>불러오는 중...</p>
            </div>
            <div class="text-white" v-else-if="세무일정.length === 0">
                <p>세무일정이 없습니다.</p>
            </div>
        </div>
    </div>
</template>

<script>
    import api from "@/util/api";
    import moment from "moment";
    import BackButton from "@/components/BackButton";

    export default {
        name: "TaxSchedule",
        components: {BackButton},
        data: () => ({
            세무일정: null,
        }),
        async mounted () {
            let response = await api.get('robotax/codes/세무일정/')
            let todayIndex = null;
            response.data.forEach((plan, index) => {
                if (todayIndex === null && moment(plan.begin, 'YYYY-MM-DD').isSameOrAfter(moment(), 'day')) {
                    todayIndex = index;
                }
            })
            if (todayIndex === null) { todayIndex = response.data.length; }
            response.data.splice(todayIndex, 0, {id: 'today'})
            this.세무일정 = response.data
            this.$nextTick(() => {
                this.$refs.container.scrollTop = this.$refs.today[0].offsetTop - 120;
            })
        },
        methods: {
            moment () { return moment() },
        }
    }
</script>

<style scoped>

</style>