<template>
    <div class="h-100 d-flex flex-column">
        <nav class="navbar navbar-light titlebar">
            <back-button></back-button>
            <h5 class="mt-2">
                현금영수증
            </h5>
            <span></span>
        </nav>
        <scraping-account-panel class="mx-3 mb-3" source="현금영수증" scraping-only></scraping-account-panel>
        <div class="h-100 bg-white overflow-auto" v-infinite-scroll="loadNext" infiinfinite-scroll-distance="30">
            <table class="table" v-if="paginator">
                <thead>
                    <tr>
                        <th class="">매출매입</th>
                        <th class="">거래처</th>
                        <th class="">총금액</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in paginator.objects" :key="item.id">
                        <td>{{ item.매출매입 }}</td>
                        <td>{{ item.매출매입 == '매입' ? item.가맹점_상호 : item.매입자명 }}</td>
                        <td class="text-right" :class="item.매출매입 == '매입' ? 'text-danger' : 'text-primary'">{{ moneyFormat(item.총금액) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import Paginator from "@/util/paginator";
    import api from "@/util/api";
    import infiniteScroll from "vue-infinite-scroll";
    import BackButton from "@/components/BackButton";
    import querystring from "querystring";
    import RefreshScrapingButton from "@/views/connect/RefreshScrapingButton";
    import ScrapingAccountPanel from "@/views/connect/ScrapingAccountPanel";
    import AccountMixin from "@/util/AccountMixin";

    export default {
        name: "CashReceipts",
        mixins: [AccountMixin],
        components: {ScrapingAccountPanel, BackButton},
        directives: { infiniteScroll },
        data() {
            return {
                paginator: null
            }
        },
        watch: {
            'scrapingSources.현금영수증.status'(newValue) {
                if (newValue == 'success') {
                    this.reload()
                }
            }
        },
        async mounted() {
            this.reload()
        },
        methods: {
            reload() {
                this.paginator = new Paginator(api.get, 'robotax/현금영수증/?' + querystring.stringify({
                    사업자: this.globals.trader.robotax_id,
                    ordering: '-거래일시'
                }))
                this.loadNext()
            },
            loadNext () {
                if (!this.paginator) { return }
                this.paginator.loadNext()
            }
        }
    }
</script>

<style scoped>

</style>