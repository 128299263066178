<template>
    <div>
        <nav class="navbar navbar-light titlebar">
            <back-button />
            <h5 class="mt-2">
                종합소득세 가이드
            </h5>
            <span></span>
        </nav>
        <div class="card m-3">
            <div class="card-body" v-if="!report">
                <div class="d-flex justify-content-center align-items-center">
                    <div class="flex-grow-1 text-center">
                        <div>종합소득세 단순경비율 예상세액</div>
                        <h4 class="mt-2 font-weight-bolder">
                            <b-spinner></b-spinner>
                        </h4>
                    </div>
                </div>
            </div>
            <div class="card-body"
                 @click="$router.push({name: '종합소득세', params: {id: report.id, transition: 'push'}})"
                v-else>
                <div class="d-flex justify-content-center align-items-center">
                    <div class="flex-grow-1 text-center">
                        <div>{{ 과세년도 }}년 귀속</div>
                        <div>종합소득세 단순경비율 예상세액</div>
                        <h4 class="mt-2 font-weight-bolder">
                            {{ numberFormat(report.get('A3_종합소득세_세액의계산').종합소득세_신고기한내납부할세액) }}원
                        </h4>
                        <hr>
                    </div>
                    <i class="fas fa-chevron-right"></i>
                </div>
                <small class="text-muted p-2">
                    * 이 금액은 단순경비율로 추계신고 시 기본 인적공제만을 사용하여 자동계산된 값이며, 실제 신고값과는 다를 수 있으니 참고용으로 활용하시기 바랍니다.
                </small>
            </div>
        </div>
        <div class="card m-3" v-if="report">
            <div class="card-header">
                종합소득세 분석
            </div>
            <div class="card-body mx-2">
                <template v-if="loading">
                    <div class="alert alert-warning text-center">종합소득세 분석을 위한 데이터를 불러오는 중 <br><b-spinner></b-spinner></div>
                </template>
                <template v-else-if="!canScrapeHometaxInfo">
                    <div class="alert alert-warning p-3" @click="registerPersonalCert">
                        <div><i class="fas fa-exclamation-circle mr-1"></i>현재 사업자용 공인인증서만 등록되어 있습니다. 개인 공인인증서를 등록하시면 신고구분을 알려드리고, 신고방법을 추천해드립니다.</div>
                        <hr>
                        <div class="w-100 text-center"><b class="w-100 text-center text-primary">개인 공인인증서 등록하러가기 <i class="fas fa-chevron-right"></i></b></div>
                    </div>
                </template>
                <table class="table table-sm" v-if="hometax_info">
                    <thead>
                        <th class="text-center" colspan="2">사업소득 수입금액</th>
                    </thead>
                    <tr>
                        <th>직전년도 수입금액</th>
                        <td class="text-right"><small class="text-muted" v-if="is_신규사업자">(신규사업자) </small>{{ numberFormat(수입금액(과세년도 - 1)) }}원</td>
                    </tr>
                    <tr>
                        <th>당해년도 수입금액</th>
                        <td class="text-right">{{ numberFormat(수입금액(과세년도)) }}원</td>
                    </tr>
                </table>
                <table class="table table-sm mt-2" v-if="hometax_info">
                    <thead>
                        <th class="text-center" :colspan="Object.keys(타소득자료).length">타소득자료 유무</th>
                    </thead>
                    <tr>
                        <td v-for="(v, k) in 타소득자료" :key="k" style="font-size: 0.8em">
                            {{k}}
                        </td>
                    </tr>
                    <tr>
                        <td class="text-center" v-for="(v, k) in 타소득자료" :key="k+v">
                            {{v}}
                        </td>
                    </tr>
                </table>
                <table class="table table-sm mt-2">
                    <thead>
                        <th class="text-center w-50">기장의무</th>
                        <th class="text-center w-50">추계신고 경비율</th>
                    </thead>
                    <tbody>
                    <tr class="text-center">
                        <td :class="{'font-weight-bolder text-dark' : is_복식부기의무자}"><i class="far fa-hand-point-right mr-1" v-if="is_복식부기의무자"></i>복식부기</td>
                        <td :class="{'font-weight-bolder text-dark' : is_기준경비율}" class="w-50"><i class="far fa-hand-point-right mr-1" v-if="is_기준경비율"></i>기준경비율</td>
                    </tr>
                    <tr class="text-center">
                        <td :class="{'font-weight-bolder text-dark' : is_간편장부대상자}"><i class="far fa-hand-point-right mr-1" v-if="is_간편장부대상자"></i>간편장부</td>
                        <td :class="{'font-weight-bolder text-dark' : is_단순경비율}"><i class="far fa-hand-point-right mr-1" v-if="is_단순경비율"></i>단순경비율</td>
                    </tr>
                    </tbody>
                </table>
                <table class="table table-sm mt-3">
                    <thead>
                    <th class="text-center">신고 방법 추천</th>
                    </thead>
                    <tbody>
                    <tr class="text-center" :class="{'font-weight-bolder text-dark' : is_직접신고}">
                        <td class="text-nowrap"><i class="far fa-hand-point-right mr-1" v-if="is_직접신고"></i>홈택스에서 직접신고</td>
                    </tr>
                    <tr class="text-center" :class="{'font-weight-bolder text-dark' : is_신고대리}" @click="openChat">
                        <td class="text-nowrap"><i class="far fa-hand-point-right mr-1" v-if="is_신고대리"></i><b class="text-primary">키퍼에서 신고대리 <i class="fas fa-chevron-right"></i></b></td>
                    </tr>
                    </tbody>
                </table>
                <template v-if="is_직접신고 != null || is_신고대리 != null">
                    <hr>
                    <b>신고 방법 추천 이유</b>
                    <div class="alert alert-primary mt-2" v-if="is_간편장부대상자 && is_단순경비율">고객님은 단순경비율을 적용할 수 있습니다. 홈택스에서 추계신고 - 단순경비율을 적용하여 직접 신고하시는 것을 추천드립니다.</div>
                    <template class="alert alert-primary mt-2"  v-else-if="is_간편장부대상자 && !무기장가산세">
                        <div class="alert alert-primary mt-2">고객님은 간편장부대상자이시고, 추계신고 시 무기장가산세가 적용되지 않습니다.</div>
                        <div class="alert alert-primary mt-2">홈택스에서 직접 신고 시 추계신고 - 기준경비율을 적용하여 직접 신고하시면 됩니다.</div>
                        <div class="alert alert-primary mt-2">
                            기준경비율 세액이 많을 경우 신고대리를 통하여 경비처리, 기장세액공제 등을 적용하여 세액을 줄이실 수 있습니다. 원하실 경우 신고대리를 통하여 신고해보세요.
                            <hr>
                            <div class="text-center" @click="openChat"><b class="text-primary">키퍼에서 신고대리 <i class="fas fa-chevron-right"></i></b></div>
                        </div>
                    </template>
                    <div class="alert alert-primary mt-2"  v-else-if="is_간편장부대상자 && 무기장가산세">
                        고객님은 간편장부대상자 이시지만, 직전 과세기간의 수입금액이 4,800만원 이상이셔서 추계신고 시 산출세액의 20%의 무기장 가산세가 부과됩니다. 신고대리를 통하여 신고하시는 것을 추천 드립니다.<br>
                        <hr>
                        <div class="text-center" @click="openChat"><b class="text-primary">키퍼에서 신고대리 <i class="fas fa-chevron-right"></i></b></div>
                    </div>
                    <div class="alert alert-primary mt-2"  v-else-if="is_복식부기의무자">
                        고객님은 복식부기의무자로 분류되어 추계신고 시 무신고 가산세 등의 불이익이 있습니다. 신고대리를 통하여 기장하여 신고하시는 것을 추천 드립니다.<br>
                        <hr>
                        <div class="text-center" @click="openChat"><b class="text-primary">키퍼에서 신고대리 <i class="fas fa-chevron-right"></i></b></div>
                    </div>
                    <small class="text-muted" v-if="is_직접신고">* 신고방법추천은 경비율, 무기장가산세 여부 등을 종합해 산출한 결과이며, 실제 세액의 유리함과는 다를 수 있습니다. 정확한 상담은 <span class="text-primary">키퍼에서 신고대리 <i class="fas fa-chevron-right"></i></span>를 눌러 상담해보세요.</small>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import BackButton from "@/components/BackButton";
    import api from "@/util/api";

    export default {
        name: "IncomeTaxGuide",
        data: () => ({
            canScrapeHometaxInfo: null,
            report: null,
            timer: null
        }),
        components: {BackButton},
        props: ['id'],
        computed: {
            hometax_info () {
                if (!this.report) { return null }
                if (this.report.hometax_info && this.report.hometax_info.error) { return null }
                return this.report.hometax_info
            },
            loading () {
                return this.canScrapeHometaxInfo === null
            },
            과세년도 () {
                if (!this.report) { return null }
                return parseInt(this.report.과세기간_년월.substring(0, 4))
            },
            기장의무구분 () {
                if (!this.hometax_info) { return null }
                return this.hometax_info[this.과세년도]['종합소득세_신고도움서비스_기본사항']['기장의무구분']
            },
            is_간편장부대상자 () {
                if (this.기장의무구분 === null) { return null }
                return this.기장의무구분 === '간편장부대상자'
            },
            is_복식부기의무자 () {
                if (this.is_간편장부대상자 === null) { return null }
                return !this.is_간편장부대상자
            },
            추계신고_적용경비율 () {
                if (!this.hometax_info) { return null }
                return this.report.hometax_info[this.과세년도]['종합소득세_신고도움서비스_기본사항']['추계신고_적용경비율']
            },
            is_기준경비율 () {
                if (!this.추계신고_적용경비율) { return null }
                // TODO  또한, 신규사업자 중 당해과세기간 수입금액이 일정규모이상(복식부기의무기준)에 해당하는 경우에도 단순경비율을 적용할 수 없습니다.
                return this.추계신고_적용경비율 === '기준경비율'
            },
            is_단순경비율 () {
                if (this.is_기준경비율 === null) { return null }
                return !this.is_기준경비율
            },
            is_신규사업자 () {
                if (!this.hometax_info) { return null }
                return this.hometax_info[this.과세년도 - 1] === null
            },
            무기장가산세 () {
                if (!this.hometax_info) { return null }

                if (this.is_복식부기의무자) { return true }
                let 직전년도_수입금액 = this.수입금액(this.과세년도 - 1)
                return !(직전년도_수입금액 === null || 직전년도_수입금액 < 48000000)
            },
            is_직접신고 () {
                if (!this.hometax_info) { return null }
                return !this.무기장가산세
            },
            is_신고대리 () {
                if (!this.hometax_info) { return null }
                return this.is_복식부기의무자 || !this.is_단순경비율
            },
            타소득자료 () {
                if (!this.hometax_info) { return [] }
                let 기본사항 = this.hometax_info[this.과세년도]['종합소득세_신고도움서비스_기본사항']
                return Object.keys(기본사항)
                    .filter(k => k.includes('소득여부'))
                    .reduce((obj, k) => {
                        obj[k.split('소득여부')[0]] = 기본사항[k]
                        return obj
                    }, {})
            }
        },
        mounted () {
            gtag('event', 'allincometax_guide_view')
            api.post(`robotax/종합소득세/${this.id}/scrape_hometax_info/`)
            this.reload()
        },
        beforeDestroy() {
            clearTimeout(this.timer)
        },
        methods: {
            async reload () {
                this.globals.종합소득세.cache = {}
                this.report = await this.globals.종합소득세.getObject(this.id)
                if (!this.report.hometax_info) {
                    this.timer = setTimeout(this.reload, 2000)
                } else {
                    this.canScrapeHometaxInfo = !this.report.hometax_info['error']
                    gtag('event', 'allincometax_guide_success')
                }
            },
            수입금액 (year) {
                if (!this.report || !this.hometax_info || !this.hometax_info[year]) {
                    return null
                }

                return this.report.hometax_info[year]['종합소득세_신고도움서비스_사업장별수입금액'][0]['수입금액']
            },
            registerPersonalCert () {
                gtag('event', 'allincometax_guide_register_cert_start')
                this.$router.push({name: '공인인증서 등록', params:{transition: 'push'}})
            },
            openChat () {
                gtag('event', 'allincometax_guide_open_chat')
                this.$router.push({name: '상담', query: {openChat: true}, params: {transition: 'push'}})
            }
        }
    }
</script>

<style scoped>

</style>
