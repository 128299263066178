<template>
    <div class="h-100 overflow-auto">
        <div class="alert alert-light rounded-0 mb-0">
            <p>
                <strong>키퍼</strong>에서 계산한 {{ taxType }} 내역입니다. 키퍼에 가입하신 시점의 직전 과세기간부터 조회할 수 있어요.
            </p>
        </div>
        <table class="table bg-white" v-if="taxes">
            <tbody v-if="taxType == '종합소득세' && !globals.robotaxTrader.owner">
                <tr>
                    <td>종합소득세는 대표자의 공동인증서도 등록되어 있어야 조회할 수 있습니다.</td>
                </tr>
            </tbody>
            <tbody v-else>
                <TaxRow :tax="tax" v-for="tax in taxes" :key="tax.id"></TaxRow>
            </tbody>
        </table>
    </div>
</template>

<script>
    import querystring from "querystring";
    import TaxRow from "@/views/taxreport/TaxRow";

    export default {
        name: "TaxList",
        components: {TaxRow},
        props: ['type'],
        data() {
            return {
                taxes: null
            }
        },
        computed: {
            taxType() {
                return {vat: '부가가치세', ait: '종합소득세', bpreport: '사업장현황신고'}[this.type]
            }
        },
        watch: {
            async taxType() {
                await this.reload()
            }
        },
        async mounted() {
            await this.reload()
        },
        methods: {
            async reload() {
                this.taxes = (await this.globals[this.taxType].get('', {
                    params: {
                        사업자: this.taxType == '종합소득세' && this.globals.robotaxTrader.owner ? this.globals.robotaxTrader.owner.robotax_id : this.globals.trader.robotax_id,
                        ordering: '-납기일,-신고구분상세코드,-신고구분코드'
                    },
                    paramsSerializer: querystring.stringify
                }, true)).objects
                for (let tax of this.taxes) {
                    await tax.loadHometax()
                }
            }
        }
    }
</script>

<style scoped>

</style>