<template>
    <div style="word-break: keep-all;">
        <nav class="navbar navbar-light fixed-top bg-white" style="box-shadow: 0 1px 3px grey">
            <a class="navbar-brand" href="#">
                <img src="img/icons/favicon.svg" height="40px"> 키퍼 간편장부
            </a>
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="btn btn-outline-primary" href="#" @click="startLogin">간편 로그인</a>
                </li>
            </ul>
        </nav>

        <div class="container-fluid mt-5 pt-5 bg-primary text-white text-center">
            <div class="row">
                <div class="col-md-6 vh-75 d-flex align-items-center">
                    <div class="w-100">
                        <h1 class="text-center" style="font-size: 3.5em">가장 쉬운 간편장부</h1>
                        <h4 class="m-4">인증서만 있으면 자동으로 장부작성 끝!</h4>
                    </div>
                </div>
                <div class="col-md-6">
                    <img class="img landing-image" style="box-shadow: 1px 1px 10px white" src="img/screenshot/간편장부.png">
                </div>
            </div>
        </div>

        <div class="container-fluid text-center bg-white py-5">
            <h2 class="m-5 text-primary">공동인증서만 올리면 거래내역이 자동으로 수집됩니다.</h2>
            <div class="row">
                <div class="col-md-6">
                    <img class="m-5" src="img/screenshot/hometax.png">
                    <p class="w-75 mx-auto">세금계산서, 현금영수증, 카드매입 등의 거래내역을 자동으로 가져오며, 세금 신고한 내역, 납부여부, 환급내역 등도 확인해드립니다.</p>
                </div>
                <div class="col-md-6">
                    <img class="m-4" style="width: 80px;" src="img/screenshot/bank.png">
                    <div class="w-75 mx-auto">
                        공동인증서를 통해서 은행계좌를 찾고 입출금내역을 가져옵니다. 인터넷 뱅킹보다 더 편하게 매일 잔고도 확인하고 거래내역도 조회해보세요.
                    </div>
                </div>
                <div class="col-md-6">
                    <img class="m-4" src="img/screenshot/insure.png">
                    <div class="w-75 mx-auto">매달 건강보험, 고용보험 등 4대보험 내는 거 깜빡하기 쉽죠? 키퍼에서 챙겨드립니다.</div>
                </div>
                <div class="col-md-6">
                    <img class="m-4" src="img/screenshot/cardsales.gif">
                    <div class="w-75 mx-auto">여신금융협회 계정을 등록하면 카드매출과 입금예정액 등도 매일 조회할 수 있어요.</div>
                </div>
                <hr>
            </div>
        </div>

        <div class="container-fluid bg-primary text-white py-5">
            <div class="row">
                <div class="col-md-6 p-4 d-flex vh-75 align-items-center text-center order-md-2">
                    <div class="">
                        <h2 class="">매일매일 손익도 확인해보세요.</h2>
                        <br>
                        <p>
                            사업자의 회계는 매출관리만으로는 부족합니다. <strong>매입과 경비까지 포함된 손익</strong>을 따져보는 게 중요하죠.
                            키퍼에서 자동으로 수집된 거래내역은 다시 키퍼의 인공지능으로 <strong>자동분류</strong>되어
                            손익계산서까지 매일매일 확인할 수 있어요.</p>
                    </div>
                </div>
                <div class="col-md-6 text-center order-md-1">
                    <img class="img img-rounded landing-image" src="img/screenshot/손익계산서.png">
                </div>
            </div>
        </div>

        <div class="container-fluid bg-white">
            <div class="row">
                <div class="col-md-6 p-4 d-flex vh-75 align-items-center text-center">
                    <div class="w-75 mx-auto">
                        <h2>예상세금도 미리 알 수 있어요.</h2>
                        <p>세금이 갑자기 너무 많이 나와서 당황하신 적 없나요? 키퍼는 세금을 미리 예상할 수 있기 때문에 대비할 수 있고 절세 전략도 세우기 좋아요.</p>
                        <p>소규모 사업자는 키퍼로 바로 <strong>세금신고</strong>도 할 수 있어요.</p>
                    </div>
                </div>
                <div class="col-md-6 text-center">
                    <img class="img landing-image" src="img/screenshot/예상세금.png">
                </div>
            </div>
        </div>

        <div class="container-fluid bg-primary text-white p-5 mb-1 text-center">
            <h2>두 달 동안 무료로 써보세요!</h2>
            <br>
            <p>결제정보를 등록할 필요 없이 바로 써보실 수 있어요. 체험기간이 끝나기 전에 결제등록하시면 무료 사용기간을 1개월 더 드려요.</p>
            <h3>이용료: 월 5,500원</h3>
            <br>
            <p>
                <a class="btn btn-outline-primary m-2" href="#" @click="startLogin">간편 로그인으로 사용해보기</a>
            </p>
            <h4>부가서비스</h4>
            <p>
                <strong>장부정리 도우미 +5,500원:</strong>
                <br>
                전산회계사가 장부정리를 도와드려요.
            </p>
            <br>
            <p>
                <span style="font-size: 12px">
                    주식회사 파인코드(813-81-00856) |
                    대표 박영록 |
                    경기도 성남시 분당구 백현로 97, 12층 1215호 |
                    keeper@finecode.kr |
                    <router-link class="text-white" :to="{name: '개인정보처리방침'}">개인정보처리방침</router-link>
                </span>
            </p>
            <br>
        </div>
        <div class="position-fixed bg-white w-100 text-center" style="bottom: 0; left: 0; box-shadow: 0 -1px 3px grey" v-if="globals.platform == 'web'">
            <a class="btn btn-dark m-2" :href="`https://play.google.com/store/apps/details?id=com.finecode.selftax&utm_campaign=${$route.query.utm_campaign || ''}`"><i class="fab fa-google-play"></i> 구글 플레이</a>
            <a class="btn btn-dark m-2" href="https://apps.apple.com/kr/app/%ED%82%A4%ED%8D%BC-%EA%B0%84%ED%8E%B8%EC%9E%A5%EB%B6%80/id1561082556" style="background-color: black"><i class="fab fa-app-store-ios"></i> 애플 앱스토어</a>
            <div class="text-white position-fixed" style="font-size: 0.6em; bottom: 0; right: 0;">{{ version }}</div>
        </div>
        <b-modal id="login-modal" title="키퍼 간편 로그인" body-class="p-0" centered ok-only ok-title="취소">
            <div class="bg-primary p-5 d-flex flex-column justify-content-between align-items-center">
                <div class="d-flex align-items-center btn-social-login"
                     style="background-color: #F7E317; cursor: pointer; box-shadow: 0 1px 3px grey"
                     @click="kakaoLogin" v-if="enabledLogin.includes('kakao')">
                    <img src="img/kakao_logo.png" style="max-width: 18px; margin: 11px 24px 11px 11px" >
                    <div style="color: #3C1E1E;">카카오 계정으로 로그인</div>
                </div>
                <div class="mt-3 d-flex align-items-center btn-social-login"
                     style="background-color: #1ec800; cursor: pointer; box-shadow: 0 1px 3px grey"
                     @click="naverLogin" v-if="enabledLogin.includes('naver')">
                    <img src="img/naver_logo.png" style="max-width: 18px; margin: 11px 24px 11px 11px" >
                    <div style="color: white;">네이버 아이디로 로그인</div>
                </div>
                <div class="mt-3 bg-white d-flex align-items-center btn-social-login"
                     style="cursor: pointer; box-shadow: 0 1px 3px grey"
                     @click="googleLogin" v-if="enabledLogin.includes('google')">
                    <img src="img/google_logo.svg" style="max-width: 18px; margin: 11px 24px 11px 11px" >
                    <div style="color: rgba(0,0,0,0.54); font-weight: bold">Google계정으로 로그인</div>
                </div>
                <a class="mt-3 d-flex align-items-center btn-social-login text-white"
                     style="background-color: black; cursor: pointer; box-shadow: 0 1px 3px grey; "
                     :href="serverLoginURL('apple-id')" v-if="globals.platform == 'ios'">
                    <i class="fab fa-apple p-3" style="font-size: 28px;"></i>
                    <div style="font-weight: bold">Apple로 로그인</div>
                </a>
<!--                <div class="mt-3 bg-white d-flex align-items-center btn-social-login"-->
<!--                     style="cursor: pointer; box-shadow: 0 1px 3px grey"-->
<!--                     v-b-modal.login-with-email-modal v-if="globals.platform == 'ios'">-->
<!--                    <i class="fas fa-envelope p-3" style="font-size: 20px;"></i>-->
<!--                    <div style="color: rgba(0,0,0,0.54); font-weight: bold">메일 주소로 로그인</div>-->
<!--                </div>-->
            </div>
        </b-modal>
        <b-modal id="login-with-email-modal" title="이메일 로그인">

            <div class="">
                <h5 v-if="emailLoginMode == 'signup'">회원가입</h5>
                <h5 v-else>로그인</h5>
                <form class="form">
                    <div class="form-group">
                        <label>
                            메일주소
                        </label>
                        <b-input name="email" v-model="account.email"></b-input>
                    </div>
                    <div class="form-group">
                        <label>
                            비밀번호
                        </label>
                        <b-input type="password" name="password" v-model="account.password"></b-input>
                    </div>
                    <div class="form-group" v-if="emailLoginMode == 'signup'">
                        <label>
                            비밀번호 확인
                        </label>
                        <b-input type="password" name="password_confirm" v-model="account.password_confirm"></b-input>
                    </div>
                </form>
            </div>
            <div class="w-100" slot="modal-footer">
                <template v-if="emailLoginMode == 'signup'">
                    <b-button variant="outline-secondary" class="float-left" @click="emailLoginMode = 'signin'">바로 로그인하기</b-button>
                    <b-button variant="primary" class="float-right" @click="emailLogin">회원가입</b-button>
                </template>

                <template v-else>
                    <b-button variant="outline-secondary" class="float-left" @click="emailLoginMode = 'signup'">회원가입하기</b-button>
                    <b-button variant="primary" class="float-right" @click="emailLogin">로그인</b-button>
                </template>
            </div>
        </b-modal>
        <b-modal id="retry-login"
                 title="로그인 정보 오류"
                 ok-title="재시도"
                 cancel-title="로그아웃"
                 @ok="retry" @cancel="logout"
                 hide-header-close no-close-on-esc no-close-on-backdrop centered>
            <div>
                <div>
                    로그인 정보를 확인할 수 없습니다. <br>재시도 하시거나, 로그아웃 후 다시 로그인해주세요.
                </div>
            </div>
        </b-modal>

    </div>
</template>

<script>
    import api, {silentApi, baseURL} from '@/util/api'
    import {globals, saveGlobal} from "@/globals";
    import * as querystring from "querystring";

    export default {
        name: 'Landing',
        components: {},
        data: () => ({
            traders: [],
            selected: null,
            account: {
                email: '',
                password: '',
                password_confirm: ''
            },
            emailLoginMode: 'signup',
            enabledLogin: ['kakao', 'naver', 'google'],
            retryTimer: null,
        }),
        computed: {
            version () {
                if (window.Android) {
                    return `${window.Android.getVersion()} - ${process.env.VUE_APP_VERSION}`
                }

                return process.env.VUE_APP_VERSION
            }
        },
        mounted () {
            if (this.$route.query.retry) {
                this.$bvModal.show('retry-login')
                this.retryTimer = setInterval(this.retry, 3000)
            }
            this.checkSocialLogin()
        },
        beforeDestroy() {
            clearInterval(this.retryTimer)
        },
        beforeRouteEnter: function(to, from_, next) {
            if (globals.user) {
                next({name: '현황판'})
            } else {
                next()
            }
        },
        methods: {
            serverLoginURL(provider) {
                return `${baseURL}/login/${provider}/`
            },
            startLogin () {
                this.trackEvent('start_login')
                this.$bvModal.show('login-modal')
            },
            async kakaoLogin () {
                this.trackEvent('select_login', {method: 'kakao'})

                if (this.globals.platform == 'ios') {
                    location.href = `${baseURL}/login/kakao/`
                } else {
                    let params = querystring.parse((await api.get(`auth/begin/kakao/`)).data.split('?')[1])

                    window.Kakao.Auth.authorize({
                        redirectUri: params.redirect_uri,
                        state: params.state
                    })
                }

            },
            async checkSocialLogin () {

                if (this.$route.query.backend) {
                    if (this.$route.query.backend === 'naver') {
                        // 안드로이드에서만 호출. 웹에서는 서버 사이드로 complete
                        this.trackEvent('login_complete', {method: 'naver'})
                        this.registerByAccessToken(this.$route.query.backend, this.$route.query.access_token)
                    } else if (this.$route.query.backend === 'google-oauth2') {
                        this.trackEvent('login_complete', {method: 'google'})
                        let response = await api.post('user/access_token_by_auth_code/', {'auth-code': this.$route.query.auth_code})
                        this.registerByAccessToken(this.$route.query.backend, response.data.access_token)
                    }
                }
            },
            naverLogin () {
                this.trackEvent('select_login', {method: 'naver'})
                try {
                    window.Android.naverLogin()
                } catch (e) {
                    location.href = this.serverLoginURL('naver')
                }
            },
            googleLogin () {
                this.trackEvent('select_login', {method: 'google'})
                try {
                    window.Android.googleSignIn()
                } catch (e) {
                    location.href = `${location.origin.replace(':8080', ':8000')}/api/login/google-oauth2/`;
                }
            },
            async emailLogin() {
                console.log(this.emailLoginMode, this.account.password, this.account.password_confirm)
                if (this.emailLoginMode == 'signup' && this.account.password == this.account.password_confirm) {
                    let user = (await api.post('user/signup/', {email: this.account.email, password: this.account.password})).data
                    if (user.id) {
                        location.href = `${globals.appDomain}/dashboard`
                    }
                } else if (this.emailLoginMode == 'signin') {
                    let user = (await api.post('user/authenticate/', {email: this.account.email, password: this.account.password})).data
                    if (user.id) {
                        location.href = `${globals.appDomain}/dashboard`
                    }
                }
            },
            registerByAccessToken (backend, access_token) {
                setTimeout(function() {
                    location.href = `${location.origin.replace(':8080', ':8000')}/register-by-token/${backend}/?access_token=${encodeURIComponent(access_token)}`
                }, 100)
            },
            async retry () {
                try {
                    let res = await silentApi.get('user/me/');
                    saveGlobal('user', res.data.social.length > 0 ? res.data : null)
                } catch (e) {
                    if (e.response) {
                        saveGlobal('user', null)
                    }
                }

                if (globals.user) {
                    this.$router.push({name: this.$route.query.next || '현황판'})
                    return
                }

                this.$nextTick(() => this.$bvModal.show('retry-login'))
            },
            logout () {
                this.$bvModal.hide('retry-login')
                clearInterval(this.retryTimer)
                this.$router.push({name: '랜딩'})
            }
        },
    }
</script>
