<template>
    <div class="h-100 d-flex flex-column">
        <nav class="navbar navbar-light titlebar">
            <back-button></back-button>
            <h5 class="mt-2">
                쿠폰
            </h5>
            <span></span>
        </nav>
        <div class="h-100 overflow-auto" infinite-scroll-distance="30" v-infinite-scroll="loadNext" v-if="paginator">
            <div class="card mx-3">
                <div class="card-header">
                    쿠폰 받기
                </div>
                <div class="card-body">
                    <table class="table">
                        <tbody>
                            <tr v-if="paginator.objects.find(c => c.reason == '정식버전_결제등록')">
                                <th>정식버전 등록하고 쿠폰 받기</th>
                                <td class="text-right"><i class="fas fa-check text-success"></i></td>
                            </tr>
                            <tr v-else-if="globals.user.사업자_set.filter(t => t.사업자구분 !== '개인').map(t => t.plan).includes('체험기간')" @click="handleRegular">
                                <th>정식버전 등록하고 쿠폰 받기</th>
                                <td class="text-right"><i class="fas fa-arrow-circle-left"></i></td>
                            </tr>
                            <!-- 정식버전이면서 정식버전 결제등록 쿠폰을 받은 적이 없는 사용자는 이 항목이 나오지 않는다. -->

                            <router-link tag="tr" :to="{name: '친구 추천', params: {transition: 'push'}}">
                                <th>친구추천하고 쿠폰받기</th>
                                <td class="text-right">
                                    <b-badge :variant="paginator.objects.filter(c => c.reason == '친구추천').length ? 'success' : 'secondary'">{{ paginator.objects.filter(c => c.reason == '친구추천').length }}</b-badge>
                                    <i class="fas fa-chevron-right ml-2"></i>
                                </td>
                            </router-link>

                            <tr v-if="paginator.objects.find(c => c.reason == '체험기간_설문조사_응답')">
                                <th>체험기간 설문에 응답하고 쿠폰받기</th>
                                <td class="text-right"><i class="fas fa-check text-success"></i></td>
                            </tr>
                            <router-link tag="tr" :to="{name: 'External Link Browser', params: {link: surveyLink, title: '설문조사', transition: 'push'}}"
                                         v-else-if="globals.user.사업자_set.filter(t => t.사업자구분 !== '개인').map(t => t.plan).includes('체험기간')">
                                <th>
                                    체험기간 설문에 응답하고 쿠폰받기
                                    <div><small>설문응답 확인에 시간이 걸릴 수 있어요.</small></div>
                                </th>
                                <td class="text-right"><i class="fas fa-chevron-right"></i></td>
                            </router-link>

                            <tr v-if="paginator.objects.find(c => c.reason == '소셜_미디어_공유')">
                                <th>소셜 미디어에 공유하고 쿠폰받기</th>
                                <td class="text-right"><i class="fas fa-check text-success"></i></td>
                            </tr>
                            <router-link tag="tr" :to="{name: '소셜 미디어 공유', params: {transition: 'push'}}"
                                         v-else>
                                <th>
                                    소셜 미디어에 공유하고 쿠폰받기
                                    <div><small>게시물 확인하는데 시간이 걸릴 수 있어요.</small></div>
                                </th>
                                <td class="text-right"><i class="fas fa-chevron-right"></i></td>
                            </router-link>
                            <router-link tag="tr" :to="{name: '영세사업자 지원 쿠폰 신청', params: {transition: 'push'}}">
                                <th>
                                    영세사업자 지원 쿠폰 신청
                                </th>
                                <td class="text-right"><i class="fas fa-chevron-right"></i></td>
                            </router-link>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card m-3">
                <div class="card-header">
                    내 쿠폰
                </div>
                <div class="card-body px-0">
                    <p class="mx-3">
                        쿠폰을 <strong>어느 사업자에 사용할지 지정</strong>해두면 사용기간이 만료될 때 자동으로 쿠폰이 사용되어 사용기간이 연장됩니다.
                    </p>
                    <table class="table">
                        <tbody>
                            <tr v-if="!paginator.objects.length">
                                <td colspan="2">발급된 쿠폰이 아직 없습니다.</td>
                            </tr>
                            <tr v-for="coupon in paginator.objects" :key="coupon.id" :style="coupon.redeemed ? 'opacity: 0.6' : ''">
                                <th>
                                    {{ enumFormat(coupon.name) }}
                                    <div class="text-muted">{{ coupon.reason.replace(/_/g, ' ') }}</div>
                                </th>
                                <td class="text-right">
                                    <span class="text-danger p-1"
                                             style="position: absolute; left: calc(50% - 80px); transform: rotate(-12deg) translatey(10px); z-index: 1; font-size: 1.1em; border: 2px solid var(--danger);"
                                             v-if="coupon.redeemed">{{ dateFormat(coupon.redeemed) }} <strong>사용완료</strong></span>
                                    <div class="">발급일 : {{ dateFormat(coupon.created) }}</div>
                                    <div>
                                        <div v-if="coupon.redeemed">{{ coupon.사업자.법인명_상호 }}</div>
                                        <b-dropdown id="trader-dropdown" :variant="coupon.사업자 ? 'outline-primary' : 'outline-danger'" toggle-class="border-0"
                                                    :text="coupon.사업자 ? 법인명_상호_display(coupon.사업자) : '사업자를 선택하세요'" v-else>
                                            <b-dropdown-item v-for="trader in globals.user.사업자_set.filter(t => !t.폐업일 && t.사업자구분 !== '개인')" :key="trader.id" @click="selectTrader(coupon, trader)">
                                                {{ 법인명_상호_display(trader) }} <small>({{ 납세자IDFormat(trader.사업자등록번호) }})</small>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import BackButton from "@/components/BackButton";
    import Paginator from "@/util/paginator";
    import api from "@/util/api";
    import infiniteScroll from 'vue-infinite-scroll'

    export default {
        name: "Coupon",
        components: { BackButton },
        directives: {infiniteScroll},
        data () {
            return {
                paginator: null,
            }
        },
        async mounted () {
            if (!this.globals.user) {
                this.$router.back()
            }
            await this.reload()
        },
        computed: {
            surveyLink() {
                return `https://docs.google.com/forms/d/e/1FAIpQLSdNJE3LMlgwHA8Y2ia7H6KUct6S2xvszXU_HDU1gfZH6EHR5g/viewform?usp=pp_url&embedded=true&entry.43980342=${this.globals.trader.법인명_상호}`
            }
        },
        methods: {
            async reload () {
                this.globals.loading = true
                this.paginator = new Paginator(api.get, `coupon/?owner=${this.globals.user.id}`)
                await this.paginator.loadNext()
                console.log(this.paginator.objects)
            },
            loadNext () {
                this.paginator.loadNext()
            },
            selectTrader (coupon, trader) {
                this.globals.loading = true
                api.post(`coupon/${coupon.id}/select/`, {
                    사업자: trader.id
                }).then(res => {
                    this.reload()
                }).catch(err => {
                    alert(err.response.data)
                }).finally(() => this.globals.loading = false)
            },
            handleRegular () {
                if (this.globals.trader.사업자구분 !== '개인' && this.globals.trader.plan === '체험기간') {
                    this.$router.replace({name: '정식버전 결제'})
                    return
                }

                alert('현황판 상단에서 체험기간 사업자를 선택하신 후, 정식버전을 등록하고 쿠폰을 받아보세요.')
                this.$router.replace({name: '현황판'})
            }
        }
    }
</script>
