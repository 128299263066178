<template>
    <div>
        <nav class="navbar navbar-light titlebar">
            <back-button></back-button>
            <h5 class="mt-2">
                임대 사업자 정보 편집
            </h5>
            <span></span>
        </nav>

        <div class="card m-3">
            <div class="card-header">
                임대업 등록번호
            </div>
            <div class="card-body">
                <div v-if="optionExists && rentTraderOptions[0]['value']['등록번호']">
                    <h5 class="text-center text-primary">{{ rentTraderOptions[0]['value']['등록번호'] }}</h5>
                    <b-button block variant="success" class="mt-3" @click="open(rentTraderOptions[0]['value']['등록번호'])">수정하기</b-button>
                </div>
                <div v-else>
                    구청에 임대사업자를 등록하신 경우 임대업 등록번호를 입력해주세요.
                    <b-button block variant="primary" class="mt-3" @click="open()">임대업 등록번호 입력하기</b-button>
                </div>
            </div>
        </div>

        <b-modal id="input-rent-register-number" centered title="임대업 등록번호 입력" ok-only ok-title="취소" @ok="rentRegisterNumber = null">
            <div class="text-center">
                <b-input-group>
                    <b-input type="text" placeholder="임대업 등록번호(- 제외)" v-model="rentRegisterNumber"></b-input>
                    <b-input-group-append>
                        <button class="btn btn-outline-primary" @click="updateRentRegisterNumber">{{optionExists ? '수정' : '입력'}}</button>
                    </b-input-group-append>
                </b-input-group>
            </div>
        </b-modal>

        <div class="card m-3">
            <div class="card-header">
                임대 사업장 정보
                <router-link class="float-right" :to="{name: '부동산임대 편집', params: {transition: 'push'}}">추가하기</router-link>
            </div>
            <div class="card-body" v-if="rentPaginator && !rentPaginator.loading">
                <div v-if="!rentPaginator.objects.length">
                    아직 사업장 정보가 입력되지 않았습니다.
                </div>
                <table class="table" v-else>
                    <tbody>
                    <tr v-for="rent in rentPaginator.objects" :key="rent.id">
                        <td @click="$router.push({name: '부동산임대 편집', query: {id: rent.id}, params: {transition: 'push'}})">
                            <div class="d-flex justify-content-between align-items-center">
                                <div>
                                    <div class="text-secondary">임대 {{localDateFormat(rent.임대기간_시작일)}} ~ {{localDateFormat(rent.임대기간_종료일)}}</div>
                                    <strong class="text-primary">{{ `${rent.도로명주소.roadAddr} ${rent.상세주소}` }}</strong>
                                    <div class="d-flex justify-content-between mt-1">
                                        <div>
                                            <div><strong>유형</strong> {{ rent.임대주택종류 ? 임대주택종류[rent.임대주택종류] : '미설정' }}</div>
                                            <div><strong>면적</strong> {{ parseFloat(rent.건물면적).toFixed(2) }}㎡ </div>
                                        </div>
                                        <div>
                                            <div class="text-right"><strong>보증금</strong> {{ moneyFormat(rent.보증금)}}</div>
                                            <div class="text-right"><strong>월세</strong> {{ moneyFormat(rent.월세)}}</div>
                                        </div>
                                    </div>
                                </div>
                                <i class="float-right fas fa-chevron-right"></i>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import api from "@/util/api";
    import Paginator from "@/util/paginator";
    import BackButton from "@/components/BackButton";

    export default {
        name: "Rents.vue",
        components: {BackButton},
        data: () => ({
            // TODO robotax 부동산임대 모델에서 코드 받아오도록
            임대주택종류: {
                11: '단독주택',
                14: '다가구주택',
                15: '아파트',
                16: '연립주택',
                17: '다세대주택',
                18: '오피스텔',
                99: '기타',
            },
            rentPaginator: null,
            rentRegisterNumber: null,
            rentTraderOptions: null,
        }),
        computed: {
            optionExists () {
                return this.rentTraderOptions && this.rentTraderOptions.length
            }
        },
        async mounted () {
            this.globals.loading = true
            this.globals.loadingMessage = '임대 사업장 정보를 불러오는 중 입니다'
            this.rentPaginator = new Paginator(api.get, `robotax/부동산임대/?사업자=${this.globals.trader.robotax_id}`)
            this.rentPaginator.loadAll(() => {
                this.globals.loading = false
            })
            await this.getRentRegisterNumber()
        },
        methods: {
            open (rentRegisterNumber) {
                this.rentRegisterNumber = rentRegisterNumber
                this.$bvModal.show('input-rent-register-number')
            },
            async getRentRegisterNumber () {
                this.rentTraderOptions = (await api.get(`robotax/사업자항목/?사업자=${this.globals.trader.robotax_id}&key=임대업`)).data.results
            },
            async updateRentRegisterNumber () {
                for (const option of this.rentTraderOptions) {
                    await api.delete(`robotax/사업자항목/${option.id}`)
                }

                api.post(`robotax/사업자항목/`, {
                    사업자: this.globals.trader.robotax_id,
                    key: '임대업',
                    value: {'등록번호': this.rentRegisterNumber}
                }).then(() => {
                    this.getRentRegisterNumber()
                    this.$bvModal.hide('input-rent-register-number')
                }).catch(err => {
                    alert(err)
                })
            }
        }
    }
</script>
