<template>
    <div class="h-100 d-flex flex-column bg-white">
        <div class="h-100 bg-white overflow-auto mt-4 p-4">
            <div class="mb-4" style="font-size: 1.2em">
                서비스를 이용하기 위해<br>
                <span class="text-primary">약관동의</span>가 필요합니다.
            </div>
            <div class="mb-4 p-2 pl-4" style="border: 1px solid black; font-size: 1.2em" @click="agree('all')">
                <i class="text-primary fas fa-check-circle mr-2" v-if="allAgreed"></i>
                <i class="text-primary far fa-circle mr-2" v-else></i>
                서비스 이용약관 전체동의
            </div>
            <div class="mt-4" style="font-size: 0.9em">
                <hr>
                <span @click="agree('term')">
                    <i class="text-primary fas fa-check-circle mr-2" v-if="agreed.includes('term')"></i>
                    <i class="text-primary far fa-circle mr-2" v-else></i>
                    키퍼 이용약관
                    <span class="float-right text-primary " @click="$bvModal.show('term')">내용보기</span>
                </span>
                <hr>
                <span @click="agree('privacy')">
                    <i class="text-primary fas fa-check-circle mr-2" v-if="agreed.includes('privacy')"></i>
                    <i class="text-primary far fa-circle mr-2" v-else></i>
                    개인정보 처리방침
                    <span class="float-right text-primary" @click="$bvModal.show('privacy')">내용보기</span>
                </span>
            </div>
        </div>
        <div class="bg-primary d-flex justify-content-center align-items-center"
             style="height: 70px"
             @click="agreeTerms" v-if="allAgreed">
            <span class="text-white">다음</span>
        </div>
        <div class="d-flex justify-content-center align-items-center"
             style="height: 70px; background-color: #999999;" v-else>
            <span class="text-white">다음</span>
        </div>
        <b-modal id="term" title="이용약관" size="sm" ok-only ok-title="확인">
            <div v-html="termsHTML"></div>
        </b-modal>
        <b-modal id="privacy" title="개인정보 처리방침" size="sm" ok-only ok-title="확인">
            <div v-html="privacyHTML"></div>
        </b-modal>
    </div>
</template>

<script>
    import {api, silentApi} from "@/util/api";
    import LoginRequired from "@/components/LoginRequired";
    import {globals, saveGlobal} from "@/globals";

    export default {
        name: "Terms",
        mixins: [LoginRequired],
        data: function() {
            return {
                allTerms: ['term', 'privacy'],
                agreed: [],
                termsHTML: '',
                privacyHTML: '',
            }
        },
        mounted: async function() {
            this.termsHTML = (await api.get('user/terms/')).data
            this.privacyHTML = (await api.get('user/privacy/')).data
        },
        computed: {
            allAgreed () {
                return this.agreed.length === this.allTerms.length
            }
        },
        methods: {
            agree (term = 'all') {
                if (term === 'all') {
                    if (this.allAgreed) {
                        this.agreed = []
                    } else {
                        this.agreed = this.allTerms.map(term => term)
                    }
                    return
                }

                if (!this.agreed.includes(term)) {
                    this.agreed.push(term)
                } else {
                    this.agreed.splice(this.agreed.indexOf(term), 1)
                }
            },
            agreeTerms: async function() {
                if (!this.allAgreed) {
                    alert('약관에 동의해 주세요.')
                    return
                }
                saveGlobal('user', (await api.post(`/user/agree_terms/`)).data)
                this.trackEvent('agree_terms')
                gtag('event', 'sign_up', {method: globals.user.social[0]})
                this.$router.replace({path: '/'})
            },
        }
    }
</script>

<style scoped>

</style>
