<template>
    <tr @click="toggle_or_push(field)">
        <component :is="cellTag" style="width: 200px">
            <span class="d-inline-block" style="width: 16px">
                <span class="" v-if="!field.sumOf">&nbsp;</span>
                <i class="fas fa-chevron-right ml-1" v-else-if="!field.$expanded"></i>
                <i class="fas fa-chevron-down ml-1" v-else></i>
            </span>
            {{ field.label || field.name }}
        </component>
        <component :is="cellTag" class="text-right text-ellipsis" style="">
            {{ format(record, field) }}
            <i class="fas fa-chevron-right text-muted" v-if="field.detail"></i>
            <span class="d-inline-block" style="width: 10px;" v-else>&nbsp;</span>
            <span class="d-inline-block" style="width: 8px" v-if="cellTag == 'td'"></span>
        </component>
    </tr>
</template>

<script>
    export default {
        name: "TaxReportField",
        props: ['report', 'record', 'field', 'strong'],
        computed: {
            cellTag: function() {
                if (this.strong) {
                    return 'th'
                } else {
                    return 'td'
                }

            }
        },
        methods: {
            toggle_or_push: function(field) {
                if (field.sumOf) {
                    this.$set(field, '$expanded', !field.$expanded)
                    return
                }

                if (!field.detail) {
                    return
                }

                if (field.detail.name) {
                    this.$router.push(Object.assign({
                        params: {id: this.report.id, resourceName: this.resourceName},
                    }, field.detail))
                } else {
                    this.$router.push({
                        name: `${this.report.세금} 신고서 상세`,
                        params: {id: this.report.id, screenName: field.detail, resourceName: this.report.세금},
                    })
                }

            },
            format: function(record, field) {
                if (!record) {
                    return ''
                }

                if (field.format == 'string') {
                    return record[field.name]
                } else {
                    return this.numberFormat(record[field.name])
                }
            },
        }
    }
</script>

<style scoped>

</style>
