<template>
    <div>
        <nav class="navbar navbar-light titlebar text-white">
            <back-button></back-button>
            <h5 class="mt-2">
                정부 지원금 및 세제 혜택 안내
            </h5>
            <span></span>
        </nav>
        <p class="text-white mx-4">
            여러 가지 정부 지원금과 세제 혜택 중에 키퍼가 추려낸 알짜배기 지원을 안내해드립니다.
            지원금 신청 절차가 복잡한 경우도 많은데, <strong>지원금 신청도 대행</strong>해드리니 <strong>상담하기</strong>로 신청해보세요.
        </p>
        <div class="card mx-4 mb-4" v-for="(content, category) in supports" :key="category">
            <div class="card-header">
                <strong>{{ category }}</strong>
            </div>
            <div class="card-body">
                <dl class="border-bottom">
                    <template v-for="(subContent, key) in content">
                        <dt class="border-top p-2" v-b-toggle="key" :key="key">
                            <i class="float-right mt-1 fas fa-chevron-down"></i>
                            <i class="float-right mt-1 fas fa-chevron-up"></i>
                            {{ key }}
                        </dt>
                        <b-collapse tag="dd" :id="key" :key="key + '-detail'" class="table-sm">
                            <dl class="">
                                <template v-for="(detail, title) in subContent">
                                    <template v-if="detail.내용">
                                        <dt class="border-top p-2 font-weight-normal" v-b-toggle="title" :key="title">
                                            <div class="float-right mt-1 text-muted">
                                                <i class="fas fa-chevron-down"></i>
                                                <i class="fas fa-chevron-up"></i>
                                            </div>
                                            {{ title }}
                                        </dt>
                                        <b-collapse tag="dd" :id="title" :key="title + '-detail'">
                                            <table class="table table-sm" style="background-color: #eee">
                                                <tbody>
                                                    <tr v-for="(value, field) in detail" :key="field">
                                                        <td>{{ field }}</td>
                                                        <td><pre class="mb-0" style="white-space: pre-wrap" v-html="value"></pre></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </b-collapse>
                                    </template>
                                    <dt class="border-top p-2 font-weight-normal"
                                        :key="title"
                                        @click="$router.push({name: 'HTML Viewer', query: {title: title}})"
                                        v-else>
                                        <div class="float-right mt-1 text-muted">
                                            <i class="fas fa-chevron-right"></i>
                                        </div>
                                        {{ title }}
                                    </dt>
                                </template>
                            </dl>
                        </b-collapse>
                    </template>
                </dl>
            </div>
        </div>
    </div>
</template>

<script>
    import api from "@/util/api";
    import BackButton from "@/components/BackButton";

    export default {
        name: "GovernmentSupport",
        components: {BackButton},
        data: function () {
            return {
                supports: {}
            }
        },
        async mounted() {
            this.supports = (await api.get('code/government_support/')).data
        }
    }
</script>

<style scoped>

</style>