<template>
    <div>
        <nav class="navbar navbar-light titlebar text-white">
            <back-button ref="backbutton"/>
            <h5 class="mt-2">
                공인인증서 상세
            </h5>
            <span></span>
        </nav>

        <div class="card m-3" v-if="cert">
            <div class="card-body">
                <h4 class="text-center" style="word-break: break-all">{{cert.subject}}</h4>
                <p class="text-center text-success" v-if="cert.valid"><i class="fas fa-check"></i> 홈택스 접속 확인이 완료되었습니다.</p>
                <table class="table mt-3">
                    <tbody>
                        <tr v-for="(v, k) in fields" :key="k">
                            <td>{{ v }}</td>
                            <td>{{ cert[k] }}</td>
                        </tr>
                        <tr>
                            <td>연결된 사업자</td>
                            <td>
                                <div v-for="t in cert.사업자_set" :key="t.id">
                                    {{ t.법인명_상호 }}
                                </div>
                                <span class="text-danger" v-if="cert.사업자_set.length == 0">없음</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card m-3" v-if="false && cert && cert.valid && cert.bank_set.length">
            <div class="card-header">
                연결된 은행
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th>은행</th>
                            <th>상태</th>
                        </tr>
                    </thead>
                    <tbody v-if="hasExpiryError">
                        <tr>
                            <td class="text-center" colspan="2">
                                <i class="fas fa-warning"></i> 공인인증서가 만료되었습니다.
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr v-for="bank in cert.bank_set.filter(b => !b.status)" :key="bank.organization">
                            <td>{{ globals.codes.cache['bank/'][bank.organization].name }}</td>
                            <td><i class="fas fa-check text-success"></i> 연결됨</td>
                        </tr>
                        <tr v-for="bank in cert.bank_set.filter(b => b.status == 'error')" :key="bank.organization">
                            <td>{{ globals.codes.cache['bank/'][bank.organization].name }}</td>
                            <td><i class="fas fa-times text-danger"></i> {{ bank.message }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="card m-3" v-if="cert && !cert.valid">
            <div class="card-header">
                공인인증서 오류
            </div>
            <div class="card-body">
                <div class="text-danger">{{ cert.error }}</div>
                <hr>
                <div v-if="cert.error.indexOf('홈택스에 등록된 인증서가 아닙니다') >= 0">
                    <p>공인인증서 종류에따라 <span class="text-primary">개인·사업자</span>를 선택하시고, 주민등록번호·사업자번호를 입력 후 "등록하기"를 눌러주세요.</p>
                    <b-tabs content-class="mt-3" v-model="tabIndex">
                        <b-tab title="개인인증서">
                            <label>주민등록번호</label>
                            <b-form-row>
                                <b-col><b-input size="sm" type="text" maxlength="6" pattern="[0-9]*" inputmode="numeric" placeholder="앞자리 6자리" @focus="$event.target.select()" v-model="registration_no.personal_1"></b-input></b-col>
                                -
                                <b-col><b-input size="sm" type="password" maxlength="7" pattern="[0-9]*" inputmode="numeric" placeholder="뒷자리 7자리 입력" @focus="$event.target.select()" v-model="registration_no.personal_2"></b-input></b-col>
                            </b-form-row>
                        </b-tab>
                        <b-tab title="사업자(개인·법인)">
                            <label>사업자등록번호</label>
                            <b-form-row>
                                <b-col><b-input size="sm" type="text" maxlength="3" pattern="[0-9]*" inputmode="numeric" placeholder="3자리" @focus="$event.target.select()" v-model="registration_no.trader_1"></b-input></b-col>
                                -
                                <b-col><b-input size="sm" type="text" maxlength="2" pattern="[0-9]*" inputmode="numeric" placeholder="2자리" @focus="$event.target.select()" v-model="registration_no.trader_2"></b-input></b-col>
                                -
                                <b-col><b-input size="sm" type="password" maxlength="5" pattern="[0-9]*" inputmode="numeric" placeholder="5자리" @focus="$event.target.select()" v-model="registration_no.trader_3"></b-input></b-col>
                            </b-form-row>
                        </b-tab>
                    </b-tabs>
                    <b-button class="mt-4" block variant="primary" @click="register">
                        등록하기
                    </b-button>
                    <b-button block variant="outline-primary" @click="$router.push({name: '도움말', query: {openChat: true}, params: {transition: 'push'}})">
                        상담원과 바로연결
                    </b-button>
                    <b-modal title="홈택스 공인인증서 등록안내" centered ok-only ok-title="확인" id="hometax-cert-register">
                        <strong>먼저 홈택스에서 공인인증서를 등록하셔야 합니다.</strong>
                        <ol>
                            <li v-if="isApp"><a class="text-underline" href="market://details?id=kr.go.nts.android&hl=ko">손택스</a> 어플리케이션 설치/실행</li>
                            <li v-else><a href="https://hometax.go.kr/">홈택스</a>에 접속 <span class="">(모바일에서는 손택스 앱 사용)</span></li>
                            <li>인증센터 -> 공인인증센터 메뉴로 들어가기</li>
                            <li>인증서 선택 후 등록하기 버튼을 눌러 등록</li>
                            <li>키퍼로 돌아와 인증서로 연동하기</li>
                        </ol>
                        <b-button variant="outline-primary" block><a href="market://details?id=kr.go.nts.android&hl=ko">손택스 어플리케이션 설치/실행하기</a></b-button>
                    </b-modal>
                </div>
                <div v-else>인증서를 확인 후, 다시 업로드 해주세요.</div>
            </div>
        </div>

        <div class="m-3">
            <b-button variant="danger" block @click="removeCert(cert)">공인인증서 삭제</b-button>
        </div>

        <b-modal id="cert-alert" :title="alert.title" centered ok-only>
            <div v-for="(error, key) in alert.errors" :key="key">
                <div>{{ error }}</div>
            </div>
            <p v-if="alert.traders">{{ alert.traders.length }}개의 사업자가 연결되었습니다.</p>
            <ul class="">
                <li v-for="trader in alert.traders" :key="trader.id">
                    {{ trader.법인명_상호 }}
                </li>
            </ul>
        </b-modal>
    </div>
</template>

<script>
    import api, {silentApi} from "../util/api";
    import BackButton from "@/components/BackButton";
    import {saveGlobal} from "@/globals";

    export default {
        name: "CertDetail",
        components: {BackButton},
        props: ['id'],
        data () {
            return {
                alert: {title: '', content: '', errors: []},
                cert: null,
                fields: {
                    'issuer': '발급기관',
                    'not_after': '만료일',
                },
                tabIndex: 0,
                registration_no: {
                    personal_1: '',
                    personal_2: '',
                    trader_1: '',
                    trader_2: '',
                    trader_3: '',
                }
            }
        },
        computed: {
            hasExpiryError () {
                if (!this.cert) {
                    return false
                }
                return !!this.cert.bank_set.filter(bank => bank.message && bank.message.includes('만료')).length
            }
        },
        async mounted () {
            await this.globals.codes.get('bank/')
            this.cert = (await silentApi.get(`robotax/공인인증서/${this.id}/`)).data
        },
        methods: {
            async removeCert(cert) {
                if (confirm('공인인증서를 삭제하면 홈택스의 데이터 업데이트가 중단됩니다. 삭제하시겠습니까?')) {
                    await api.delete(`robotax/공인인증서/${cert.id}/`)
                    this.$refs.backbutton.go()
                }
            },
            register() {
                this.trackEvent('cert_register_to_hometax')
                this.globals.loadingMessage = '공인인증서 등록 중..'
                this.globals.loading = true
                setTimeout(() => this.request_register(), 500)
            },
            async request_register () {
                try {
                    if (this.tabIndex === 0 && (this.registration_no.personal_1.length !== 6 || this.registration_no.personal_2.length !== 7)) {
                        throw '주민등록번호를 확인해주세요.'
                    } else if (this.tabIndex === 1 && (this.registration_no.trader_1.length !== 3 || this.registration_no.trader_2.length !== 2 | this.registration_no.trader_3.length !== 5)) {
                        throw '사업자등록번호를 확인해주세요.'
                    }
                    await silentApi.post('user/공인인증서_register/', {
                        cert_id: this.cert.id,
                        registration_no: this.tabIndex === 0 ? this.registration_no.personal_1 + this.registration_no.personal_2 :
                            this.registration_no.trader_1 + this.registration_no.trader_2 + this.registration_no.trader_3
                    })
                    this.trackEvent('cert_register_to_hometax_success')
                    this.authenticate()
                } catch (error) {
                    this.trackEvent('cert_register_to_hometax_error', { error })
                    this.alert = {title: '오류', errors: typeof(error) === 'string' ? [error] : error.response.data}
                    this.$bvModal.show('cert-alert')
                    this.globals.loadingMessage = null
                    this.globals.loading = false
                }
            },
            authenticate() {
                this.globals.loadingMessage = '공인인증서 연동 중..'
                this.globals.loading = true
                setTimeout(() => this.request_authenticate(), 500)
            },
            async request_authenticate() {
                try {
                    let response = await silentApi.post('user/공인인증서/', {
                        only_authenticate: true,
                        cert_id: this.cert.id
                    })
                    Object.assign(this.cert, response.data.공인인증서)
                    saveGlobal('user', response.data.user)
                    if (response.data.사업자_set.length) {
                        // TODO refactor to use StatusBar.selectTrader
                        saveGlobal('trader', this.globals.user.사업자_set.find(t => t.id == response.data.사업자_set[0].id))
                    }

                    if (response.data.공인인증서.valid) {
                        this.trackEvent('cert_verified')
                        this.alert = {title: '홈택스 공인인증서 확인 완료', traders: response.data.사업자_set, errors: []}
                        this.$bvModal.show('cert-alert')
                    }
                } catch (error) {
                    this.alert = {title: '오류', errors: typeof(error) === 'string' ? [error] : error.response.data}
                    this.$bvModal.show('cert-alert')
                } finally {
                    this.globals.loadingMessage = null
                    this.globals.loading = false
                }
            }
        }
    }
</script>

<style scoped>

</style>
