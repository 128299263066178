<template>
    <div v-if="hometaxDocument">
        <nav class="navbar navbar-light titlebar">
            <back-button v-if="globals.smallScreen"></back-button>
            <span v-else></span>
            <h5 class="mt-2">
                {{ $route.query.title || (hometaxDocument ? hometaxDocument.title : '') }}
                <div class="text-center">납부 안내</div>
            </h5>
            <span></span>
        </nav>
        <div class="card mx-3" v-if="$route.query.type == '국세'">
            <div class="card-body">
                <p>
                    거래하시는 은행의 <strong>인터넷 뱅킹</strong>에 들어가서 다음과 같이 계좌이체하시면 납부가 됩니다. <strong>수수료도 무료</strong>입니다.
                    은행에 따라 국세 납부 기능을 따로 제공하는 경우도 많으며, 이 경우는 국세 납부 메뉴에서 바로 조회, 납부가 가능합니다.
                </p>
                <table class="table">
                    <tbody>
                        <tr><th>입금은행</th> <td>국세</td></tr>
                        <tr>
                            <th>입금계좌</th>
                            <td @click="copyToClipboard">
                                <span id="tax-account-no">{{ $route.query.accountNo || hometaxDocument.data.납부서[0].전자납부번호 }}</span>
                                <i class="ml-2 far fa-copy"></i>
                            </td>
                        </tr>
                        <tr><th>입금액</th> <td>{{ moneyFormat($route.query.amount >= 0 ? $route.query.amount : hometaxDocument.data.납부서[0].총납부금액) }} <span class="text-muted">(자동입력)</span></td></tr>
                        <tr>
                            <th class="align-middle">
                                납부상태
                            </th>
                            <td>
                                <template v-if="refreshing">
                                    <span class="text-primary">납부상태 확인 중..</span><b-spinner class="ml-3 align-middle" variant="primary" v-if="refreshing"></b-spinner>
                                </template>
                                <div class="d-flex align-items-center" v-else>
                                    <strong :class="['text-' + status.color, 'align-middle']" class="text-nowrap" v-if="status">{{ status.label }}</strong>
                                    <b-button class="ml-2" variant="outline-primary" @click="refresh('고지내역', '납부내역')">
                                        <div class="text-nowrap">납부상태 재확인</div>
                                    </b-button>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="refreshed">
                            <td colspan="2" class="text-danger">* 은행이나 인터넷뱅킹으로 납부하셨을 경우, 납부상태가 변하는데 최소 10분이상 걸릴 수 있습니다. 납부확인이 안될 경우 시간을 두고 다시 확인해주세요.</td>
                        </tr>
                    </tbody>
                </table>
                <br>
                <p>
                    입금계좌의 계좌번호를 누르면 복사되어 인터넷 뱅킹에서 쉽게 입력할 수 있습니다. 계좌번호를 입력하면 입금액은 자동으로 입력됩니다.
                </p>
                <small class="text-danger">
                    * 혹시 은행앱에서 고지내역이 없다는 오류가 나오면, 복사된 입금계좌번호앞의 4자리, 0126을 삭제하고 입력을 시도해보세요. <br>
                    은행앱에 따라 고정된 앞의 4자리인 0126이 미리 입력되어있어 입금계봐번호 오류가 발생할 수 있습니다.
                </small>
            </div>
        </div>
        <div class="card mx-3" v-if="$route.query.type == '지방세'">
            <p>지방세는 <a href="https://www.wetax.go.kr/main/">위택스</a>로 접속하신 후, 스마트 위택스 앱을 설치해서 사용하시면 쉽게 납부하실 수 있습니다.</p>
            <p>스마트 위택스 앱에서 공인인증서로 로그인하신 후 우상단 메뉴 버튼을 눌러서 <strong>나의 위택스</strong>로 들어가면 납부해야 할 내역을 조회할 수 있습니다.</p>
            <p>원천세, 종합소득세, 법인세는 모두 지방소득세를 별도로 납부해야 하니, 국세 납부를 완료하신 후에 위택스에서 지방소득세도 빠뜨리지 말고 확인하시기 바랍니다. </p>
        </div>
    </div>
</template>

<script>
    import BackButton from "../components/BackButton";
    import api from '@/util/api';
    import ScrapingTaskPollMixin from '@/util/ScrapingTaskPollMixin';

    export default {
        name: "TaxPayment",
        components: {BackButton},
        mixins: [ScrapingTaskPollMixin],
        data: () => ({
            hometaxDocument: null,
            refreshed: false
        }),
        computed: {
            status: function() {
                // TODO 체납내역의 status 값 조정 필요
                return this.hometaxDocument && this.globals.TAX_STATUSES[this.hometaxDocument.status]
            },
        },
        watch: {
            refreshing () {
                this.reload()
                this.refreshed = !this.refreshing
            }
        },
        mounted () {
            if (!this.$route.query.id) { return }
            this.reload()
        },
        methods: {
            copyToClipboard (event) {
                let range = document.createRange();
                range.selectNode(document.getElementById('tax-account-no'));
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
                document.execCommand("copy");
                this.$bvToast.toast('전자납부번호가 복사되었습니다.')
            },
            reload () {
                api.get(`robotax/hometaxdocument/${this.$route.query.id}/`).then(res => {
                    this.hometaxDocument = res.data
                })
            },
        }
    }
</script>

<style scoped>

</style>
