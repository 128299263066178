<template>
    <div class="h-100 overflow-auto" v-if="report">
        <div class="">
            <div class="">
                <div class="card m-3">
                    <div class="card-body">
                        <table class="table bg-white">
                            <tbody>
                                <tr>
                                    <th style="min-width: 80px">과세기간</th>
                                    <td class="text-right">{{ localDateFormat(report.과세기간시작일자) }} ~ {{ localDateFormat(report.과세기간종료일자, true) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="m-3 text-center">
                    <button class="btn btn-light" v-b-modal.sendmail-modal><i class="fas fa-envelope"></i> 세무사에게 증빙자료 메일 보내기</button>
                    <b-modal id="sendmail-modal" title="증빙자료 메일 보내기" cancel-title="취소" ok-title="보내기" @ok="sendToAccountant">
                        <form class="form">
                            <div class="form-group">
                                <label>증빙자료를 받을 담당 세무대리인의 메일주소를 입력하세요.</label>
                                <input class="form-control" type="email" placeholder="메일주소" v-model="accountantEmail">
                            </div>
                        </form>
                    </b-modal>
                </div>

            </div>
            <div class="">
                <div class="card m-3" v-if="invoices">
                    <div class="card-header" @click="browse('세금계산서')">
                        <i class="float-right fas fa-chevron-right text-muted" style="font-size: 1.2em;"></i>
                        세금계산서 / 계산서
                    </div>
                    <div class="card-body">
                        <table class="table">
                            <tbody>
                                <tr v-for="invoice in invoices" :key="invoice.세금계산서종류코드 + invoice.매출매입">
                                    <td>{{ globals.codes.cache['세금계산서종류코드/'][invoice.세금계산서종류코드] }}</td>
                                    <td>{{ invoice.매출매입 }}</td>
                                    <td class="text-right">{{ invoice.id__count }}건</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="card m-3" v-if="cashReceipts">
                    <div class="card-header" @click="browse('현금영수증')">
                        <i class="float-right fas fa-chevron-right text-muted" style="font-size: 1.2em;"></i>
                        현금영수증
                    </div>
                    <div class="card-body">
                        <table class="table">
                            <tbody>
                                <tr v-for="item in cashReceipts" :key="item.매출매입">
                                    <td>{{ item.매출매입 }}</td>
                                    <td class="text-right">{{ item.id__count }}건</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="card m-3" v-if="cardPurchases">
                    <div class="card-header" @click="browse('카드매입')">
                        <i class="float-right fas fa-chevron-right text-muted" style="font-size: 1.2em;"></i>
                        카드매입
                    </div>
                    <div class="card-body">
                        <table class="table">
                            <tbody>
                                <tr v-for="item in cardPurchases" :key="item.카드번호">
                                    <td>{{ item.카드사 }}</td>
                                    <td>{{ item.카드번호 }}</td>
                                    <td class="text-right">{{ item.id__count }}건</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="card m-3" v-if="cardSales">
                    <div class="card-header" @click="browse('카드매출')">
                        <i class="float-right fas fa-chevron-right text-muted" style="font-size: 1.2em;"></i>
                        카드매출
                    </div>
                    <div class="card-body">
                        <table class="table">
                            <tbody>
                                <tr v-for="item in cardSales" :key="item.사업자">
                                    <td>합계</td>
                                    <td class="text-right">{{ item.id__count }}건</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="card m-3" v-if="bankTransactions">
                    <div class="card-header" @click="browse('은행거래')">
                        <i class="float-right fas fa-chevron-right text-muted" style="font-size: 1.2em;"></i>
                        은행
                    </div>
                    <div class="card-body">
                        <table class="table">
                            <tbody>
                                <tr v-for="account in bankTransactions" :key="account.account__account_no">
                                    <template v-if="account.account__account_no">
                                        <td>{{ globals.codes.cache['bank/'][account.account__bank_code].name }}</td>
                                        <td>{{ account.account__account_no }}</td>
                                    </template>
                                    <template v-else>
                                        <td colspan="2">계좌정보 삭제됨</td>
                                    </template>
                                    <td class="text-right">{{ account.id__count }}건</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import querystring from "querystring"
    import api from "@/util/api";

    export default {
        name: "IncomeTaxTransactions",
        props: ['id'],
        data() {
            return {
                report: null,
                hometaxDocument: null,
                bankTransactions: null,
                invoices: null,
                cashReceipts: null,
                cardPurchases: null,
                cardSales: null,
                accountantEmail: '',
            }
        },
        async mounted() {
            await this.globals.codes.get('세금계산서종류코드/')
            await this.globals.codes.get('bank/')

            this.report = (await this.globals.종합소득세.get(this.id + '/')).object
            console.log(this.report)
            console.log(`${this.report.과세년도}-01-01`)
            this.report.과세기간시작일자 = `${this.report.과세년도}-01-01`
            this.report.과세기간종료일자 = `${this.report.과세년도}-12-31`

            this.invoices = (await api.get('robotax/세금계산서/annotate/', {
                params: {
                    사업자: this.globals.trader.robotax_id,
                    groupby_fields: ['매출매입', '세금계산서종류코드'],
                    전송일자__gte: this.dateFormat(this.report.과세기간시작일자),
                    전송일자__lte: this.dateFormat(this.report.과세기간종료일자),
                    count_fields: 'id',
                },
                paramsSerializer: querystring.stringify,
            })).data

            this.cashReceipts = (await api.get('robotax/현금영수증/annotate/', {
                params: {
                    사업자: this.globals.trader.robotax_id,
                    groupby_fields: ['매출매입'],
                    거래일시__date__gte: this.dateFormat(this.report.과세기간시작일자),
                    거래일시__date__lte: this.dateFormat(this.report.과세기간종료일자),
                    count_fields: 'id',
                },
                paramsSerializer: querystring.stringify,
            })).data

            this.cardPurchases = (await api.get('robotax/카드매입/annotate/', {
                params: {
                    사업자: this.globals.trader.robotax_id,
                    groupby_fields: ['카드사', '카드번호'],
                    거래일시__date__gte: this.dateFormat(this.report.과세기간시작일자),
                    거래일시__date__lte: this.dateFormat(this.report.과세기간종료일자),
                    count_fields: 'id',
                },
                paramsSerializer: querystring.stringify,
            })).data

            this.cardSales = (await api.get('robotax/카드매출/annotate/', {
                params: {
                    사업자: this.globals.trader.robotax_id,
                    groupby_fields: ['사업자'],
                    거래일시__date__gte: this.dateFormat(this.report.과세기간시작일자),
                    거래일시__date__lte: this.dateFormat(this.report.과세기간종료일자),
                    count_fields: 'id',
                },
                paramsSerializer: querystring.stringify,
            })).data

            this.bankTransactions = (await api.get('robotax/은행거래/annotate/', {
                params: {
                    사업자: this.globals.trader.robotax_id,
                    groupby_fields: ['account__account_no', 'account__bank_code'],
                    거래일시__date__gte: this.dateFormat(this.report.과세기간시작일자),
                    거래일시__date__lte: this.dateFormat(this.report.과세기간종료일자),
                    count_fields: 'id',
                },
                paramsSerializer: querystring.stringify,
            })).data
        },
        methods: {
            async sendToAccountant() {
                await api.post(`taxreport/${this.id}/send_증빙자료/`, {
                    tax: '종합소득세',
                    recipient: this.accountantEmail
                })
                alert('증빙자료 전송에 몇 분 정도 소요됩니다. 증빙자료 전송이 완료되면 알려드릴께요.')
            },
            browse(model) {
                this.$router.push({name: '증빙자료', query: {
                    model: model, begin: this.report.과세기간시작일자, end: this.report.과세기간종료일자
                }})
            }
        }
    }
</script>

<style scoped>

</style>
