<template>
    <tr @click="$emit('select')">
        <slot name="prepend"></slot>
        <td style="width: 125px">
            <div>{{ object.거래일 }}</div>
            <div class="">
                <small><i class="fas fa-link" v-if="(object.증빙종류 == '세금계산서' && object.related_set.length) || object.source"></i>{{ object.증빙종류 }} {{ object.매출매입 }} {{ object.은행입출금 }}</small>
            </div>
            <div>
                <tag-button :tag="tag"
                            :recommendation="object.tag_set[tag.path]"
                            :read-only="true"
                            v-for="tag in object.tag_set"
                            :key="tag.id"
                            @click="$emit('showTags')"></tag-button>
            </div>
        </td>

        <td class="px-0">
            <div class="font-weight-bolder text-ellipsis" style="font-size: 1.1em" :title="object.거래처명">
                {{ convertFullWidthCharacters(object.거래처명) }}
            </div>
            <div class="text-muted">
                <span>{{ convertFullWidthCharacters(object.품명 || object.적요 || object.비고) }}</span>
            </div>
        </td>

        <td class="text-right" style="min-width: 150px">
            <div class="" :class="{'text-success': object.매출매입 === '매출' || object.은행입출금 == '입금'}" style="font-size: 1.1em">
                <strong>{{ moneyFormat(parseInt(object.공급가액) + parseInt(object.부가세)) }}</strong>
            </div>
            <template v-if="object.매출매입">
                <div>
                    <small class="text-secondary">공급가액 {{ numberFormat(object.공급가액) }}원</small><br>
                </div>
                <div>
                    <small class="text-secondary">부가세 {{ numberFormat(object.부가세) }}원</small>
                </div>
            </template>
            <div v-else-if="object.은행입출금">
                <small class="text-secondary">잔액 {{ moneyFormat(object.거래_후_잔액) }}</small><br>
            </div>
        </td>
        <slot name="append"></slot>
    </tr>
</template>
<script>
    import TagButton from "@/views/transaction/TagButton";
    export default {
        name: 'transaction-item',
        components: {TagButton},
        props: {
            filters: {},
            mode: {},
            object: {},
            toggle: null,
        },
        methods: {
            convertFullWidthCharacters(text) {
                if (!text) { return text }
                // TODO 서버에도 변환되어서 저장될 필요가 있을 듯. 검색 등의 문제.
                return text.replace(/[\uff01-\uff5e]/g, fullwidthChar => String.fromCharCode(fullwidthChar.charCodeAt(0) - 0xfee0)).replace(/\u3000/g, '\u0020')
            }
        }
    }
</script>
