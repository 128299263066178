<template>
    <div>
        <nav class="navbar navbar-light titlebar">
            <back-button ref="backbutton" ></back-button>
            <h5 class="mt-2">
                은행계좌 등록
            </h5>
            <span></span>
        </nav>
        <div class="mx-3 alert alert-warning">
            공동인증서로 은행 자료수집을 하는 기능은 앞으로 중단될 예정입니다.
        </div>
        <div class="card m-3">
            <div class="card-header">
                빠른조회 
            </div>
            <div class="card-body">
                <p class="">
                    등록하기 전에 미리 <strong>거래하시는 은행의 인터넷 뱅킹에서 빠른조회를 신청</strong>하셔야 합니다.
                </p>
                <b-form class="pt-2" @submit.prevent>
                    <b-form-group>
                        <div>
                            <input type="hidden" v-model="account.bankCode">
                            <template v-if="account.bankCode">
                                <img class="rounded mr-3 border" :src="bankImage(account.bankCode)">
                                <b-button variant="primary" v-b-modal.select-bank-modal>은행선택</b-button>
                            </template>
                            <div class="d-flex justify-content-center" v-else>
                                <b-button variant="primary" v-b-modal.select-bank-modal>빠른조회 은행선택</b-button>
                            </div>
                            <b-modal id="select-bank-modal" ok-only ok-title="닫기">
                                <div class="text-center mt-2">
                                    <img :src="bankImage(bankCode)"
                                         v-for="(bank, bankCode) in 빠른조회_banks"
                                         :key="bankCode"
                                         @click="selectBank(bankCode)">
                                </div>
                            </b-modal>
                        </div>
                        <div class="p-2" v-if="account.bankCode">
                            <h6 style="text-decoration: underline; font-weight: bold" class="animated tada">{{ banks[account.bankCode].name }} {{ bankType }} 빠른조회 신청 방법</h6>
                            <pre class="m-0" style="white-space: pre-wrap">{{ banks[account.bankCode][bankType] }}</pre>
                            <div class="text-underline"><i class="fas fa-exclamation-circle"></i> 모바일 뱅킹에서는 신청이 안되는 경우도 있으므로, PC의 인터넷 뱅킹을 이용하시기 바랍니다.</div>
                        </div>
                    </b-form-group>
                    <template  v-if="사업자 && account.bankCode">
                        <b-form-group v-for="field in 빠른조회_banks[account.bankCode][bankType + '_fields']" :key="field">
                            <label class="">{{ field }}</label>
                            <b-form-input :type="field.indexOf('비밀번호') >= 0 || field.indexOf('PW') >= 0 ? 'password' : 'text'" v-model="account[field]"></b-form-input>
                        </b-form-group>
                        <button class="btn btn-block btn-primary mt-4 mb-3" @click="verifyAccount">등록하기</button>
                    </template>
                </b-form>
            </div>
        </div>
        <b-modal id="bankaccount-verify-modal"
                 title="인터넷 뱅킹 빠른조회 연동 중"
                 ok-only
                 :ok-disabled="status == 'verifying'"
                 ok-title="닫기"
                 @hidden="closeModal">
            <div class="text-center">
                <p>
                    <i class="fas fa-times text-danger" style="font-size: 1.5em" v-if="status == 'failed'"></i>
                    <i class="fas fa-check text-success" style="font-size: 1.5em" v-if="status == 'verified'"></i>
                    <b-spinner variant="primary" v-if="status == 'verifying'"></b-spinner>
                </p>
                <p>{{ message }}</p>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import querystring from 'querystring'
    import BackButton from "@/components/BackButton";
    import {api, silentApi} from "@/util/api";

    export default {
        name: "BankAccount",
        components: {BackButton},
        data() {
            return {
                banks: {},
                사업자: {},
                account: {
                    bankCode: '',
                    계좌번호: '',
                    계좌비밀번호: '',
                    사업자등록번호: '',
                    인터넷뱅킹ID: null,
                    간편조회ID: null,
                    조회용ID: null,
                    안전계좌번호: null,
                    인터넷뱅킹PW: null,
                    간편조회PW: null,
                    조회용PW: null,
                    안전계좌비밀번호: null,
                },
                status: null,
                message: null,
                certs: [],
            }
        },
        computed: {
            bankType() {
                return this.사업자.사업자구분 == '법인' ? '기업뱅킹' : '개인뱅킹'
            },
            빠른조회_banks() {
                return Object.keys(this.banks).filter(key => key.length === 2).reduce((o, k) => {
                    o[k] = this.banks[k]
                    return o
                }, {})
            },
        },
        async mounted() {
            this.banks = (await api.get('robotax/codes/bank/')).data
            this.사업자 = await this.globals.사업자.getObject(this.globals.trader.robotax_id)
            this.account.사업자등록번호 = this.사업자.납세자ID

            this.account.bankCode = this.bankCodeFromCodefTo빠른조회(this.$router.currentRoute.query.bankCode || '')
            this.account.계좌번호 = this.$router.currentRoute.query.accountNo

            await this.reloadCert()
        },
        methods: {
            bankImage: function (code) {
                return process.env.BASE_URL + 'img/bank/bank_logo_' + code + '.gif'
            },
            async verifyAccount() {
                this.account.계좌번호 = this.account.계좌번호.replace(/-/g, '')

                let missingFields = this.banks[this.account.bankCode][this.bankType + '_fields'].filter(field => {
                    return !this.account[field]
                })
                if (missingFields.length) {
                    alert(`${missingFields.join(',')} 값을 입력해주세요.`)
                    return
                }

                try {
                    await silentApi.post('robotax/bankaccount/', {
                        사업자: this.globals.trader.robotax_id,
                        type: '은행빠른조회',
                        bank_code: this.account.bankCode,
                        account_no: this.account.계좌번호,
                        account_password: this.account.계좌비밀번호,
                        username: this.account.인터넷뱅킹ID || this.account.간편조회ID || this.account.조회용ID || this.account.안전계좌번호,
                        password: this.account.인터넷뱅킹PW || this.account.간편조회PW || this.account.조회용PW || this.account.안전계좌비밀번호,
                    })
                } catch (e) {
                    this.trackEvent('fail_bank_verifying_due_to_codef')
                    this.openModal('failed', e.response.data)
                    return
                }
                this.trackEvent('bank_verifying')
                this.openModal('verified', '은행계좌를 확인하고 있으며, 5분에서 10분 정도 걸릴 수 있습니다. 현황판에서 확인하실 수 있어요.')
            },
            openModal(status, message) {
                this.status = status
                this.message = message
                this.$bvModal.show('bankaccount-verify-modal')
            },
            closeModal() {
                if (this.status == 'verified') {
                    this.$router.back()
                } else {
                    this.status = null
                    this.message = null
                }
            },
            async selectBank(code) {
                this.account.bankCode = code
                this.$bvModal.hide('select-bank-modal')
            },
            async reloadCert() {
                this.certs = (await api.get('/robotax/공인인증서/', {
                    params: {사업자_set: [this.globals.trader.robotax_id]},
                    paramsSerializer: querystring.stringify
                })).data.results
            },
            bankCodeFromCodefTo빠른조회(bankCode) {
                if (!bankCode) {
                    return bankCode
                }

                let codeMap = {
                    'bankda_05': '05',
                    'bankda_06': '06',
                    '0011': '13',
                    '0088': '26',
                    '0027': '28',
                    '0007': '88',
                    '0002': '89',
                    '0089': '91',
                }
                if (codeMap[bankCode]) {
                    return codeMap[bankCode]
                } else {
                    return bankCode.substring(2)
                }
            }
        }
    }
</script>

<style scoped>

</style>
