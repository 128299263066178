// @ts-ignore
import {Resource, 사업자, 종합소득세, 부가가치세, 사업장현황신고, 연말정산, AccountResource} from "@/models";


export const constants = {
    TAG_COLORS: {
        매출액: 'success',
        영업외수익: 'success',
        매출원가: 'danger',
        판매비와관리비: 'danger',
        영업외비용: 'danger',
        제조원가: 'danger',
        공사원가: 'danger',
        분양원가: 'danger',
        기타원가: 'danger',
        부가가치세: 'warning',
        감가상각자산: 'info',
    },
    TAG_CATEGORIES: {
        손익: {
            tags: ['매출액', '영업외수익', '매출원가', '판매비와관리비', '영업외비용'],
            description: '손익을 식별하기 위한 태그입니다.'
        },
        표준원가: {
            tags: ['제조원가', '공사원가', '분양원가', '기타원가'],
            description: '제조업이나 건설업 등 표준원가명세서를 작성해야 하는 경우에만 선택하세요.'
        },
        손익과원가: {
            tags: ['매출액', '영업외수익', '매출원가', '판매비와관리비', '영업외비용', '제조원가', '공사원가', '분양원가', '기타원가'],
            description: ''
        },
        부가가치세: {
            tags: ['부가가치세'],
            description: '부가가치세 신고에 적용되는 사항들을 기록하기 위한 태그입니다.'
        },
        재무상태: {
            tags: ['감가상각자산'],
            description: '재무상태표(대차대조표)로 관리하는 계정입니다.'
        },
    },
    손익계산서Tags: ['매출액', '영업외수익', '매출원가', '판매비와관리비', '영업외비용', '제조원가', '공사원가', '분양원가', '기타원가'],
    TAG_EXCLUDES: {
        매출: ['매출원가', '판매비와관리비', '영업외비용', '제조원가', '공사원가', '분양원가', '기타원가'],
        매입: ['매출액', '영업외수익'],
        입금: ['매출원가', '판매비와관리비', '영업외비용', '제조원가', '공사원가', '분양원가', '기타원가'],
        출금: ['매출액', '영업외수익'],
    },
    PLAN_COLORS: {
        '정식버전': 'success',
        '체험기간': 'primary',
        '사용기간_만료': 'danger',
    },
    HOMETAX_TAX_SOURCES: ['전자신고결과조회', 'My홈택스_세금신고내역', '납부내역', '환급금조회', '체납내역', '고지내역'],
    HOMETAX_VOUCHER_SOURCES: ['세금계산서', '현금영수증', '카드매입', '카드매출집계'],
    DATA_SOURCES: {
        'My홈택스_세금신고내역': {
            label: 'My 홈택스'
        },
        세금계산서: {
            dateField: '전송일자',
            route: {name: '세금계산서'}
        },
        현금영수증: {
            dateField: '거래일시__date',
            route: {name: '현금영수증'}
        },
        카드매입: {
            dateField: '거래일시__date',
            description: '매달 15일 전월 자료 수집',
            route: {name: '카드매입'}
        },
        카드매출집계: {
            dateField: '거래기간종료일',
            description: '매달 15일 전월 집계 수집',
            route: {name: '카드매출집계'}
        },
        카드매출: {
            dateField: '거래일시__date',
        },
        'Codef': {
            label: '공인인증서 은행계좌',
        },
        사회보험: {
            label: '사회보험통합징수포털',
            dateField: '납기일'
        },
        Wetax: {
            label: '지방세신고납부',
            dateField: '납기일자'
        },
        스마트스토어: {
            dateField: '거래일'
        },
        배달의민족: {
            dateField: '거래일'
        },
        유튜브: {
            dateField: '거래일'
        },
    },
}


export const globals = {
    authenticatedTime: null,
    appTitle: '키퍼',
    publicPath: process.env.BASE_URL,
    appDomain: process.env.NODE_ENV === 'production' ? 'https://keeper.business/app' : 'http://localhost:8080',
    appVersion: null,
    smallScreen: true,
    production: process.env.NODE_ENV === 'production',
    user: null,
    trader: null,
    robotaxTrader: null,
    platform: 'web',  // 접속한 클라이언트가 ios, android 앱인지 web인지 식별
    device: null as unknown,
    loading: false as boolean,
    loadingMessage: null,
    error: null as unknown,
    회계연도: new Date().getFullYear(),
    period: {},
    부가가치세: new Resource('robotax/부가가치세/', 부가가치세),
    사업장현황신고: new Resource('robotax/사업장현황신고/', 사업장현황신고),
    종합소득세: new Resource('robotax/종합소득세/', 종합소득세),
    연말정산: new Resource('robotax/연말정산/', 연말정산),
    사업자: new Resource('robotax/사업자/', 사업자),
    사업소득명세: new Resource('robotax/사업소득명세/'),
    BankAccount: new Resource('robotax/bankaccount/'),
    accountResource: new AccountResource(),
    codes: new Resource('robotax/codes/'),
    consts: new Resource('const/'),
    transactions: {
        paginator: null,
        syncing: false,
        updated: null,
        forceQuery: null,
        batchTagging: {
            tagPath: null,
            target: null
        }
    },
    TAX_STATUSES: {
        'paid': {label: '납부 완료', color: 'success', description: '납부가 완료되었습니다.'},
        'paid-less': {label: '추가납부 필요', color: 'danger', description: '납부가 되었으나 금액이 부족하여 추가 납부가 필요합니다.'},
        'paid-more': {label: '과대납부로 환급대기', color: 'primary', description: '신고가 완료되었고, 환급 받으실 예정입니다.'},
        'waiting-refund': {label: '환급 대기중', color: 'primary', description: '신고가 완료되었고, 환급 받으실 예정입니다.'},
        'refund-confirmed': {label: '환급 확정', color: 'success', description: '신고 완료 후 환급까지 완료되었습니다.'},
        'reported-zero': {label: '납부 불필요', color: 'success', description: '신고가 완료되었고 세액이 0원이라 납부하시지 않아도 됩니다.'},
        'reported': {label: '납부해야 함', color: 'danger', description: '신고가 완료되었으니 납부기한까지 납부하세요.'},
    },
    tags: {},
    recentUsedTags: [],
    TAG_COLORS: {
        매출액: 'success',
        영업외수익: 'success',
        매출원가: 'danger',
        판매비와관리비: 'danger',
        영업외비용: 'danger',
        제조원가: 'danger',
        공사원가: 'danger',
        분양원가: 'danger',
        기타원가: 'danger',
        부가가치세: 'warning',
        감가상각자산: 'info',
    },
    TAG_CATEGORIES: {
        손익: {
            tags: ['매출액', '영업외수익', '매출원가', '판매비와관리비', '영업외비용'],
            description: '손익을 식별하기 위한 태그입니다.'
        },
        표준원가: {
            tags: ['제조원가', '공사원가', '분양원가', '기타원가'],
            description: '제조업이나 건설업 등 표준원가명세서를 작성해야 하는 경우에만 선택하세요.'
        },
        손익과원가: {
            tags: ['매출액', '영업외수익', '매출원가', '판매비와관리비', '영업외비용', '제조원가', '공사원가', '분양원가', '기타원가'],
            description: ''
        },
        부가가치세: {
            tags: ['부가가치세'],
            description: '부가가치세 신고에 적용되는 사항들을 기록하기 위한 태그입니다.'
        },
        재무상태: {
            tags: ['감가상각자산'],
            description: '재무상태표(대차대조표)로 관리하는 계정입니다.'
        },
    },
    손익계산서Tags: ['매출액', '영업외수익', '매출원가', '판매비와관리비', '영업외비용', '제조원가', '공사원가', '분양원가', '기타원가'],
    TAG_EXCLUDES: {
        매출: ['매출원가', '판매비와관리비', '영업외비용', '제조원가', '공사원가', '분양원가', '기타원가'],
        매입: ['매출액', '영업외수익'],
        입금: ['매출원가', '판매비와관리비', '영업외비용', '제조원가', '공사원가', '분양원가', '기타원가'],
        출금: ['매출액', '영업외수익'],
    },
    chatEnabled: false,
    latestBankTransactions: [],
    unfinishedTaskCount: 0,
    소득구분코드: {
        '30': '가. 부동산임대업(주택임대업 제외)의 사업소득',
        '31': '나. 「조세특례제한법」 제100조의18에 따라 동업기업에서 배분받은 부동산임대업의 사업소득',
        '32': '다. 주택임대업의 사업소득: 32 (701101, 701102, 701103, 701104)',
        '40': '라. 부동산임대업 외의 사업소득',
        '41': '마. 「조세특례제한법」 제100조의18에 따라 동업기업에서 배분받은 부동산임대업 외의 사업소득',
    },
    pathBackFromPayment: null,
    종합소득세_신고비용: 11000,
    scraping: [],
    accounts: [],
    polling: {},
}


try{
    globals.user = JSON.parse(localStorage.getItem('user') || 'null')
} catch (e) {
    console.log(e)
}

let trader = null;
try{
    globals.trader = JSON.parse(localStorage.getItem('trader') || 'null')
    // if (globals.trader && globals.trader.constructor !== Object) { trader = undefined }
} catch (e) {
    console.log(e)
}


export function loadGlobal(key: string) {
    try {
        // @ts-ignore
        return globals[key] = JSON.parse(localStorage.getItem(key) || 'null')
    } catch (e) {
        console.log(e)
    }
}


export function saveGlobal(key: string, object: any) {
    // @ts-ignore
    globals[key] = object
    localStorage.setItem(key, JSON.stringify(object))
}


export function removeGlobal(key: string) {
    // @ts-ignore
    globals[key] = null
    localStorage.removeItem(key)
}
