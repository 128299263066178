<template>
    <div class="p-4">
        <h5 class="text-white">연동계좌</h5>
        <div class="card">
            <div class="card-body">
                <table class="table" v-if="info">
                    <tbody>
                        <tr v-for="res in info.res_list" :key="res.fintech_use_num">
                            <td>{{res.account_holder_name }} - {{res.bank_name}} - {{res.account_num_masked}} - {{res.account_alias}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <h5 class="mt-3 text-white">거래내역(TEST)</h5>
        <div class="card">
            <div class="card-body">
                <table class="table" v-if="transactions">
                    <tbody>
                        <tr v-for="t in transactions" :key="t.api_tran_id">
                            <td>{{t}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '@/util/api'

    export default {
        name: "Manage",
        data: () => ({ info: null, transactions: null }),
        mounted () {
            if (!this.globals.user || !this.globals.user.openbanking) {
                this.$router.push({name: '설정'})
                return
            }

            api.get('openbanking/me/').then(res => this.info = res.data)
            api.get('openbanking/transaction_list/').then(res => this.transactions = res.data[0].res_list)
        }
    }
</script>

<style scoped>

</style>
