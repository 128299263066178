<template>
    <div>
        <status-bar v-if="globals.smallScreen"></status-bar>
        <nav class="navbar navbar-light titlebar">
            <span></span>
            <h5 class="">
                설정
            </h5>
            <span></span>
        </nav>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6 p-0">
                    <div class="card mx-3">
                        <div class="card-body">
                            <table class="table mt-2">
                                <tbody>
                                    <tr v-if="globals.user">
                                        <th>로그인</th>
                                        <td>
                                            <social-login-info :user="globals.user"/>
                                        </td>
                                    </tr>
                                    <tr v-if="globals.user">
                                        <th>이메일</th>
                                        <td>
                                            {{ globals.user.verified_email || globals.user.email }}
                                            <i class="fas fa-check text-success" v-if="globals.user.verified_email"></i>
                                        </td>
                                    </tr>
                                    <template v-if="globals.trader">
                                    <tr>
                                        <th>사업자</th>
                                        <td>
                                            {{ globals.trader.법인명_상호 }}
                                            <br>
                                            <small>{{ globals.trader.사업자구분 }} {{ 납세자IDFormat(globals.trader.사업자등록번호) }}</small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>요금제</th>
                                        <td>
                                            <div>
                                                <span class="text-underline"
                                                      v-b-tooltip.hover
                                                      title="개인 모드의 기능은 대부분 무료로 이용 가능하나, 종합소득세 신고를 하려면 소유한 사업자가 모두 정식버전이어야 합니다."
                                                      v-if="globals.trader.사업자구분 == '개인'">
                                                    소유 사업자의 사용권과 동일 <i class="fas fa-info-circle"></i>
                                                </span>
                                                <router-link :to="{name: '정식버전 결제', query: {back: $route.fullPath}}"
                                                             class="btn btn-sm btn-block"
                                                             :class="'btn-outline-' + constants.PLAN_COLORS[globals.trader.plan]"
                                                             v-else>
                                                    <span v-if="globals.trader.plan == '체험기간'">무료</span>
                                                    {{ globals.trader.plan }}
                                                    <small v-if="globals.trader.plan == '체험기간'">({{ humanizeDate(globals.trader.expiry) }} 만료)</small>
                                                    <i class="fas fa-chevron-right"></i>
                                                </router-link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr @click="$router.push({name: '쿠폰', params: {transition: 'push'}})">
                                        <th>쿠폰</th>
                                        <td class="text-right">
                                            <span class="text-success" v-if="coupons.filter(c => !c.redeemed).length">{{ coupons.filter(c => !c.redeemed).length }}장</span>
                                            <span class="text-primary" v-else>무료쿠폰 받기</span>
                                            <i class="fas fa-chevron-right ml-2"></i>
                                        </td>
                                    </tr>

                                    <tr v-show="false">
                                        <th>
                                            대표자 주민번호
                                            <br>
                                            <small class="text-danger" v-if="needEditResNumber">전자신고 시 필요</small>
                                            <small class="text-success" v-else>입력완료</small>
                                        </th>
                                        <td>
                                            {{ globals.trader.대표자주민등록번호.substring(0, 6) }} -
                                            <div class="d-flex">
                                                <b-input class="flex-grow-1"
                                                         name="registration_no"
                                                         placeholder="7자리"
                                                         v-model="backNumber"
                                                         size="sm"
                                                         @keyup.13="updateTrader"></b-input>
                                                <b-button class="ml-1 flex-fill"
                                                          size="sm"
                                                          variant="outline-primary"
                                                          :disabled="!backNumber || globals.trader.대표자주민등록번호.substring(6, 13) === backNumber"
                                                          @click="updateTrader">
                                                    <i class="fas fa-check"></i>
                                                </b-button>
                                            </div>
                                        </td>
                                    </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="m-3">
                        <router-link :to="{name: '공인인증서 목록'}" class="btn btn-block btn-lg btn-outline-info"><i class="fas fa-key"></i> 공동인증서 관리</router-link>
                    </div>
                </div>
                <div class="col-md-6 p-0">
                    <div class="card mx-3">
                        <div class="card-header">기타 설정</div>
                        <div class="card-body">
                            <table class="table table-sm">
                                <tbody>
                                <tr>
                                    <th>알림 수신</th>
                                    <td @click="editNotification(!globals.device.active)" v-if="globals.device">
                                        <b-form-checkbox v-model="globals.device.active" switch></b-form-checkbox>
                                    </td>
                                    <td v-else>
                                        <b-form-checkbox disabled switch></b-form-checkbox>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="card m-3">
                        <div class="card-header">키퍼 정보</div>
                        <div class="card-body">
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <th>버전</th>
                                        <td>{{ version }}</td>
                                    </tr>
                                    <tr v-if="globals.appVersion">
                                        <th>앱 버전</th>
                                        <td>{{ globals.appVersion }}</td>
                                    </tr>
                                    <tr @click="$router.push({
                                            name: '개인정보처리방침',
                                            query: {next: '설정'}
                                        })">
                                        <th>개인정보처리방침</th>
                                        <td class="text-right"><i class="fas fa-chevron-right"></i></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="m-3">
                        <b-button class="btn-block w-75 my-5 mx-auto" variant="outline-danger" @click="logout">로그아웃</b-button>
                    </div>
                    <div class="m-3">
                        <b-button class="btn-block w-50 my-5 mx-auto" variant="secondary" v-b-modal.quit-modal>탈퇴신청</b-button>
                        <b-modal id="quit-modal" title="탈퇴신청" ok-title="탈퇴신청 접수" cancel-title="취소" @ok="quit">
                            <p>
                                키퍼에서 탈퇴하고 싶으신가요? 아래에 탈퇴 주의사항을 읽어주시고, <strong>탈퇴 사유란에 탈퇴 주의사항을 읽었다고 써주신 후, 탈퇴 사유를 간단하게 남겨주세요</strong>.
                                그리고 탈퇴신청 접수를 누르시면 1 영업일 이내에 고객님의 자료를 완전히 삭제하고 탈퇴처리 해드립니다.
                                혹시 탈퇴신청을 하신 후 탈퇴처리가 완료되기 전에 취소하고 싶으신 경우는 채팅상담으로 문의해주세요.
                            </p>

                            <h5>탈퇴 주의사항</h5>
                            <ol class="pl-3">
                                <li>
                                    <strong>탈퇴처리가 완료되면 키퍼에 보관된 고객님의 모든 자료가 삭제되며, 다시 복구할 수 없습니다.</strong>
                                </li>
                                <li>
                                    결제 수단을 등록하신 적이 없으면 탈퇴하지 않아도 과금이 발생하지 않습니다. 키퍼는 무료로도 사용하실 수 있습니다.
                                </li>
                                <li>
                                    결제 수단을 등록하셨다가 다시 제거하고 싶은 경우는 탈퇴신청을 하지 마시고 요금제 메뉴에서 정식버전 해지를 눌러주세요.
                                </li>
                                <li>
                                    탈퇴신청을 해도 이미 결제된 이용료는 환불되지 않습니다. 환불을 원하시면 따로 환불요청을 해주시기 부탁드립니다.
                                </li>
                            </ol>
                            <label>탈퇴 주의사항 확인 및 탈퇴 사유 입력</label>
                            <b-form-textarea v-model="quitReason"></b-form-textarea>
                        </b-modal>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="setting-alert" :title="alert.title" centered ok-only>
            {{ alert.content }}
        </b-modal>
    </div>
</template>

<script>
    import api from '@/util/api'
    import {saveGlobal} from "@/globals"
    import {validate} from "@/util/res-number"
    import StatusBar from "@/components/StatusBar";
    import LoginRequired from "@/components/LoginRequired";
    import AccountMixin from "@/util/AccountMixin";
    import {Coupon} from "@/models";
    import SocialLoginInfo from "@/components/SocialLoginInfo";

    export default {
        name: "Setting.vue",
        mixins: [LoginRequired, AccountMixin],
        components: {SocialLoginInfo, StatusBar},
        data: () => ({
            사업자: null,
            selectedBankAccount: null,
            backNumber: null,
            alert: {title: '', content: ''},
            coupons: [],
            quitReason: '',
        }),
        computed: {
            needEditResNumber () {
                return this.globals.trader.대표자주민등록번호.includes('*')
            },
            version () {
                return process.env.VUE_APP_VERSION
            },
            businessCert() {
                return this.traderCerts.find(cert => cert.subject_type != '개인' && cert.valid)
            },
        },
        async mounted () {
            this.reload()
        },
        watch: {
            'globals.trader.대표자주민등록번호': function (to, from_) {
                if (!to) {
                    return
                }
                this.backNumber = to.substring(6, 13).replace(/\*/g, '')
            },
            'globals.trader': function(newTrader, oldTrader) {
                if (!oldTrader || oldTrader.id !== newTrader.id) {
                    this.reload()
                }
            },
        },
        methods: {
            async reload () {
                if (!(await this.checkLogin())) {
                    return
                }

                await this.globals.codes.get('bank/')

                if (!this.globals.trader) {
                    return
                }

                this.사업자 = await this.globals.사업자.getObject(this.globals.trader.robotax_id)
                this.coupons = await Coupon.objects()
            },
            logout () {
                api.post('user/logout/').then(() => {
                    saveGlobal('user', null)
                    saveGlobal('trader', null)
                    saveGlobal('device', null)
                    this.globals.transactions.paginator = null
                    this.globals.transactions.syncing = false
                    this.$router.push({name: '랜딩'})
                })
            },
            updateTrader () {
                if (validate(this.globals.trader.대표자주민등록번호.substring(0, 6), this.backNumber) === false) {
                    return
                }

                this.globals.trader.대표자주민등록번호 = this.globals.trader.대표자주민등록번호.substring(0, 6) + '' + this.backNumber
                api.patch(`robotax/사업자/${this.globals.trader.id}/`, {대표자주민등록번호: this.globals.trader.대표자주민등록번호})
            },
            editNotification (active) {
                api.patch(`device/${this.globals.device.id}/`, {active}).then(res => {
                    this.$set(this.globals, 'device', res.data)
                })
            },
            async retryScraping(source, certInfoKey) {
                // 사회보험과 Wetax는 재시도 상황을 보여주기 위해 여기서 프로퍼티를 삭제하지만 서버에는 반영하진 않는다.
                // 서버는 아래의 start api에서 반영한다.
                this.$set(this.businessCert, certInfoKey, undefined)

                api.post('robotax/scrapingtask/start/', {
                    '사업자': this.globals.trader.robotax_id,
                    'sources': [source],
                })
            },
            async quit() {
                if (this.quitReason.trim().length < 3) {
                    alert('탈퇴하시려는 이유를 적어주시기 부탁드립니다.')
                    return
                }
                alert((await api.post('user/quit/', {
                    reason: this.quitReason
                })).data)
            }
        }
    }
</script>

