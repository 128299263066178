<template>
    <div class="pt-2 overflow-auto" @scroll="loadMore($event)" v-if="docPaginator">
        <div class="px-3">
            <div class="card mb-3" v-for="doc in docPaginator.objects" :key="doc.id">
                <div class="card-body row"
                     @click="showPayment(doc)">
                    <div class="col-2" style="font-size: 2.4em" >
                        <i class="fas fa-check-circle text-success" v-if="doc.data.납부여부 == '완납' || globals.TAX_STATUSES[doc.tax_status].color == 'success'"></i>
                        <i class="far fa-circle text-secondary" v-else></i>
                    </div>
                    <div class="col-10">
                        <h5 class="m-0">{{ doc.title }}</h5>
                        <div class="text-muted">{{ localDateFormat(doc.issued) }}</div>
                        <hr>
                        <div v-if="doc.data.납부여부 == '완납'">
                            <span class="text-success">
                                {{ doc.data.납부의무종결사유 }}
                            </span>
                        </div>
                        <div v-else-if="globals.TAX_STATUSES[doc.tax_status].color != 'success'">
                            <span class="text-danger">
                                납기일: {{ localDateFormat(doc.data.납부기한) }}
                            </span>
                        </div>
                        <div class="">
                            <strong style="font-size: 1.5em" v-if="doc.data.납부여부 == '완납'">{{ moneyFormat(doc.data.고지세액) }}</strong>
                            <strong style="font-size: 1.5em" v-else>{{ moneyFormat(doc.data.납부할세액) }}</strong>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center text-white" v-if="docPaginator.loading || globals.transactions.syncing">
                <p>
                    <b-spinner variant="light"></b-spinner>
                </p>
                <p>불러오는 중...</p>
            </div>
            <div class="text-white" v-else-if="docPaginator.objects.length == 0">
                <p>고지내역이 없습니다.</p>
            </div>

        </div>
    </div>
</template>

<script>
    import Paginator from "@/util/paginator";
    import api from "@/util/api";
    import LoginRequired from "@/components/LoginRequired";
    import AppUpdateMixin from "@/components/AppUpdateMixin";

    export default {
        name: "HometaxNotices",
        mixins: [LoginRequired, AppUpdateMixin],
        data: function() {
            return {
                docPaginator: null,
            }
        },
        mounted() {
            this.reload()
        },
        methods: {
            async reload() {
                if (!this.globals.trader) {
                    return
                }

                this.docPaginator = new Paginator(api.get, 'robotax/hometaxdocument/?page_size=10&source=고지내역&사업자=' + this.globals.trader.robotax_id)
                this.docPaginator.loadNext()
            },
            loadMore: function(event) {
                if (!this.globals.trader) { return }

                if (!this.docPaginator.canLoadNext()) {
                    return
                }
                if (event.target.scrollTop + event.target.clientHeight + 100 > event.target.scrollHeight) {
                    this.docPaginator.loadNext()
                }
            },
            showPayment(doc) {
                this.$router.push({name: '납부안내', query: {title: doc.title, accountNo: doc.data.전자납부번호, amount: doc.data.납부할세액, type: '국세', id: doc.id}})
            }
        }
    }
</script>

<style scoped>

</style>