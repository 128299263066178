<template>
    <div>
        <TaxReportBrowser resource-name="연말정산" :report-data="report"></TaxReportBrowser>
    </div>
</template>

<script>
    import TaxReportBrowser from "@/components/TaxReportBrowser";
    export default {
        name: "YearEndTaxAdjustment",
        components: {TaxReportBrowser},
        data: function() {
            return {
                report: {
                    "id": "cfb33f2c5b954f448ab3125dfd012be2",
                    "records": [
                        {
                            "M01_국외근로100만원": 0.0,
                            "M02_국외근로300만원": 0.0,
                            "M03_국외근로": 0.0,
                            "Y22_전공의_수련_보조_수당": 0.0,
                            "_10K01_외국주둔군인등": 0.0,
                            "_10_주현근무처_사업자등록번호": "8138100856",
                            "_11S01_주식매수선택권": 0.0,
                            "_11_근무기간_시작연월일": 20181015.0,
                            "_11_근무기간_종료연월일": 20181231.0,
                            "_12T01_외국인기술자": 0.0,
                            "_12_감면기간_시작연월일": 0.0,
                            "_12_감면기간_종료연월일": 0.0,
                            "_13_급여": 21451308.0,
                            "_14Y02_우리사주조합인출금50": 0.0,
                            "_14_상여": 0.0,
                            "_15_1주식매수_선택권행사이익": 0.0,
                            "_15_2_우리사주조합인출금": 0.0,
                            "_15_3_임원퇴직소득금액한도초과액": 0.0,
                            "_15_4_직무발명보상금": 0.0,
                            "_15_Y03_우리사주조합인출금75": 0.0,
                            "_15_인정상여": 0.0,
                            "_16_Y04_우리사주조합인출금100": 0.0,
                            "_18_H05_경호_승선수당": 0.0,
                            "_19_I01_외국정부등근무자": 0.0,
                            "_1O01_야간근로수당": 0.0,
                            "_1_감면소득_계": 0.0,
                            "_2011년_이전_차입분_주택자금_장기주택저당차입금이자상환액_15년~29년": 0.0,
                            "_2011년_이전_차입분_주택자금_장기주택저당차입금이자상환액_15년미만": 0.0,
                            "_2011년_이전_차입분_주택자금_장기주택저당차입금이자상환액_30년이상": 0.0,
                            "_2012년_이후_차입분_15년_이상_고정금리_비거치식_상환_대출": 0.0,
                            "_2012년_이후_차입분_15년_이상_기타_대출": 0.0,
                            "_2015년_이후_차입분_상환기간_10년_이상_고정금리_or_비거치_상환_대출": 0.0,
                            "_2015년_이후_차입분_상환기간_15년_이상_고정금리_and_비거치_상환_대출": 0.0,
                            "_2015년_이후_차입분_상환기간_15년_이상_고정금리_or_비거치_상환_대출": 0.0,
                            "_2015년_이후_차입분_상환기간_15년_이상_그_밖의_대출": 0.0,
                            "_21_R10_근로장학금": 0.0,
                            "_22H14_보육교사_근무환경개선비": 0.0,
                            "_23H15_사립유치원수석교사_교사의_인건비": 0.0,
                            "_24_H16_정부_공공기관지방이전기관_종사자_이주수당": 0.0,
                            "_28_T20_조세조약상_교직자감면": 0.0,
                            "_29_R11_직무발명보상금": 0.0,
                            "_2Q01_출산보육수당": 0.0,
                            "_30_H17_종교활동비": 0.0,
                            "_31U01_벤처기업_주식매수선택권": 0.0,
                            "_32T13_중소기업취업청년_소득세_감면90": 0.0,
                            "_3_1_사업자단위과세자_여부": "2",
                            "_3_2_종사업장_일련번호": "",
                            "_3_사업자등록번호": "8138100856",
                            "_4H08_특별법": 0.0,
                            "_4H09_연구기관_등": 0.0,
                            "_4H10_기업부설연구소": 0.0,
                            "_4_H06_유아_초중등": 0.0,
                            "_4_H07_고등교육법": 0.0,
                            "_5_G01_비과세학자금": 0.0,
                            "_6_H11_취재수당": 0.0,
                            "_6_성명": "이숙번",
                            "_7_H12_벽지수당": 0.0,
                            "_7_주민등록번호": "7902271011215",
                            "_8_H13_재해관련급여": 0.0,
                            "_9_H01_무보수위원수당": 0.0,
                            "_9_주현근무처_근무처명": "주식회사 파인코드",
                            "_공적연금보험료공제_공무원연금_공제금액": 0.0,
                            "_공적연금보험료공제_공무원연금_대상금액": 0.0,
                            "_공적연금보험료공제_군인연금_공제금액": 0.0,
                            "_공적연금보험료공제_군인연금_대상금액": 0.0,
                            "_공적연금보험료공제_별정우체국연금_공제금액": 0.0,
                            "_공적연금보험료공제_별정우체국연금_대상금액": 0.0,
                            "_공적연금보험료공제_사립학교교직원연금_공제금액": 0.0,
                            "_공적연금보험료공제_사립학교교직원연금_대상금액": 0.0,
                            "_보험료_건강보험료_노인장기요양보험료_포함": 0,
                            "_보험료_건강보험료_노인장기요양보험료_포함_공제금액": 0.0,
                            "_보험료_건강보험료_노인장기요양보험료_포함_국세청": 0,
                            "_보험료_건강보험료_노인장기요양보험료_포함_급여자료": 558360,
                            "_보험료_건강보험료_노인장기요양보험료_포함_대상금액": 0.0,
                            "_보험료_고용보험료": 139420,
                            "_보험료_고용보험료_공제금액": 0.0,
                            "_보험료_고용보험료_대상금액": 0.0,
                            "_자녀세액공제": 0.0,
                            "_자녀세액공제_인원": 0.0,
                            "_주택마련저축소득공제_근로자주택마련저축": 0.0,
                            "_주택마련저축소득공제_주택청약종합저축": 0.0,
                            "_주택마련저축소득공제_청약저축": 0.0,
                            "_주택자금_주택임차차입금원리금상환액_거주자": 0.0,
                            "_주택자금_주택임차차입금원리금상환액_대출기관": 0.0,
                            "_출산_입양세액공제": 0.0,
                            "_출산_입양세액공제인원": 0.0,
                            "_특별세액공제_기부금_법정기부금_공제대상금액": 0.0,
                            "_특별세액공제_기부금_법정기부금_세액공제액": 0.0,
                            "_특별세액공제_기부금_우리사주조합기부금_공제대상금액": 0.0,
                            "_특별세액공제_기부금_우리사주조합기부금_세액공제액": 0.0,
                            "_특별세액공제_기부금_정치자금_10만원이하_공제대상금액": 0.0,
                            "_특별세액공제_기부금_정치자금_10만원이하_세액공제액": 0.0,
                            "_특별세액공제_기부금_정치자금_10만원초과_공제대상금액": 0.0,
                            "_특별세액공제_기부금_정치자금_10만원초과_세액공제액": 0.0,
                            "_특별세액공제_기부금_지정기부금_종교단체_공제대상금액": 0.0,
                            "_특별세액공제_기부금_지정기부금_종교단체_세액공제액": 0.0,
                            "_특별세액공제_기부금_지정기부금_종교단체외_공제대상금액": 0.0,
                            "_특별세액공제_기부금_지정기부금_종교단체외_세액공제액": 0.0,
                            "개인연금저축소득공제": 0.0,
                            "거주자구분코드": 1.0,
                            "거주지국코드": "",
                            "결정세액_농특세": 0.0,
                            "결정세액_소득세": 168685.0,
                            "결정세액_지방소득세": 16868.0,
                            "경로우대공제금액": 0.0,
                            "경로우대공제인원": 0.0,
                            "고용유지중소기업_근로자소득공제": 0.0,
                            "공란_C106": 0.0,
                            "공란_C116": 0.0,
                            "공란_C135": 0.0,
                            "공란_C143": 0.0,
                            "공란_C150": "",
                            "공란_C28": 0,
                            "공란_C32": 0.0,
                            "공란_C66": 0.0,
                            "공란_C92": 0.0,
                            "국민연금보험료공제": 421200,
                            "국민연금보험료공제_공제금액": 421200.0,
                            "국민연금보험료공제_국세청": 0,
                            "국민연금보험료공제_급여자료": 421200,
                            "국민연금보험료공제_대상금액": 421200.0,
                            "국민연금보험료공제_종전근무처": 0,
                            "국적코드": "",
                            "그_밖의_소득공제계": 0.0,
                            "근로소득공제": 8467696.0,
                            "근로소득금액": 12983612.0,
                            "근로소득세액공제": 365059.0,
                            "근무처별_소득명세_계": 21451308.0,
                            "기납부세액_농특세": 0.0,
                            "기납부세액_소득세": 2473770.0,
                            "기납부세액_지방소득세": 247360.0,
                            "기부금_이월분": 0.0,
                            "납부특례세액_농특세": 0.0,
                            "납부특례세액_소득세": 0.0,
                            "납부특례세액_지방소득세": 0.0,
                            "납세조합공제": 0.0,
                            "내_외국인_구분코드": 1.0,
                            "레코드_구분": "C",
                            "배우자공제금액": 0.0,
                            "본인공제금액": 1500000.0,
                            "부녀자공제금액": 0.0,
                            "부양가족공제금액": 0.0,
                            "부양가족공제인원": 0.0,
                            "비과세_계": 0.0,
                            "산출세액": 663744.0,
                            "서식명": "라_C레코드_주_현근무처_레코드",
                            "세대주여부": "2",
                            "세무서코드": "144",
                            "세액감면계": 0.0,
                            "세액공제계": 495059.0,
                            "소기업_소상공인_공제부금": 0.0,
                            "소득공제종합한도초과액": 0.0,
                            "소득세법": 0.0,
                            "신용카드등_소득공제": 0.0,
                            "연금계좌_과학기술인공제_공제대상금액": 0.0,
                            "연금계좌_과학기술인공제_세액공제액": 0.0,
                            "연금계좌_근로자퇴직급여보장법에_따른_퇴직급여_공제대상금액": 0.0,
                            "연금계좌_근로자퇴직급여보장법에_따른_퇴직급여_세액공제액": 0.0,
                            "연금계좌_연금저축_공제대상금액": 0.0,
                            "연금계좌_연금저축_세액공제액": 0.0,
                            "연말정산구분": "1",
                            "외국납부": 0.0,
                            "외국법인소속파견근로자여부": "2",
                            "외국인단일세율적용": 2.0,
                            "우리사주조합출연금": 0.0,
                            "월세세액공제대상금액": 0.0,
                            "월세세액공제액": 0.0,
                            "일련번호": 0.0,
                            "자료구분": 20.0,
                            "장기집합투자증권저축": 0.0,
                            "장애인공제금액": 0.0,
                            "장애인공제인원": 0.0,
                            "조세조약": 0.0,
                            "조특법_제30조": 0.0,
                            "조특법_제외": 0.0,
                            "종_전근무처_수": 0.0,
                            "종합소득_과세표준": 11062412.0,
                            "주택차입금": 0.0,
                            "중소기업취업청년_소득세_감면100": 0.0,
                            "중소기업취업청년_소득세_감면50": 0.0,
                            "중소기업취업청년_소득세_감면70": 0.0,
                            "차감소득금액": 11062412.0,
                            "차감징수세액_농특세": 0.0,
                            "차감징수세액_농특세_abs": 0.0,
                            "차감징수세액_소득세": 1.0,
                            "차감징수세액_소득세_abs": 2305080.0,
                            "차감징수세액_지방소득세": 1.0,
                            "차감징수세액_지방소득세_abs": 230490.0,
                            "총급여": 21451308.0,
                            "투자조합출자등_소득공제": 0.0,
                            "특별세액공제_교육비_공제대상금액": 0.0,
                            "특별세액공제_교육비_세액공제액": 0.0,
                            "특별세액공제_보장성보험료_공제대상금액": 0.0,
                            "특별세액공제_보장성보험료_세액공제액": 0.0,
                            "특별세액공제_의료비_공제대상금액": 0.0,
                            "특별세액공제_의료비_세액공제액": 0.0,
                            "특별세액공제_장애인전용보장성보험료_공제대상금액": 0.0,
                            "특별세액공제_장애인전용보장성보험료_세액공제액": 0.0,
                            "특별세액공제계": 0.0,
                            "특별소득공제_계": 0.0,
                            "표준세액공제": 130000.0,
                            "표준세액공제_강제적용": false,
                            "한부모가족공제금액": 0.0
                        },
                        {
                            "_3_사업자등록번호": "8138100856",
                            "_7_소득자_주민등록번호": "7902271011215",
                            "경로우대공제_E100": "",
                            "경로우대공제_E14": "",
                            "경로우대공제_E143": "",
                            "경로우대공제_E186": "",
                            "경로우대공제_E57": "",
                            "관계_E136": "",
                            "관계_E179": "",
                            "관계_E50": "",
                            "관계_E7": "0",
                            "관계_E93": "",
                            "교육비_일반_E112": 0.0,
                            "교육비_일반_E128": 0.0,
                            "교육비_일반_E155": 0.0,
                            "교육비_일반_E171": 0.0,
                            "교육비_일반_E198": 0.0,
                            "교육비_일반_E214": 0.0,
                            "교육비_일반_E26": 0.0,
                            "교육비_일반_E42": 0.0,
                            "교육비_일반_E69": 0.0,
                            "교육비_일반_E85": 0.0,
                            "교육비_장애인특수교육_E113": 0.0,
                            "교육비_장애인특수교육_E129": 0.0,
                            "교육비_장애인특수교육_E156": 0.0,
                            "교육비_장애인특수교육_E172": 0.0,
                            "교육비_장애인특수교육_E199": 0.0,
                            "교육비_장애인특수교육_E215": 0.0,
                            "교육비_장애인특수교육_E27": 0.0,
                            "교육비_장애인특수교육_E43": 0.0,
                            "교육비_장애인특수교육_E70": 0.0,
                            "교육비_장애인특수교육_E86": 0.0,
                            "교육비공제_E104": "",
                            "교육비공제_E147": "",
                            "교육비공제_E18": "",
                            "교육비공제_E190": "",
                            "교육비공제_E61": "",
                            "기본공제_E11": "1",
                            "기본공제_E140": "",
                            "기본공제_E183": "",
                            "기본공제_E54": "",
                            "기본공제_E97": "",
                            "기부금_E120": 0.0,
                            "기부금_E135": 0.0,
                            "기부금_E163": 0.0,
                            "기부금_E178": 0.0,
                            "기부금_E206": 0.0,
                            "기부금_E221": 0.0,
                            "기부금_E34": 0.0,
                            "기부금_E49": 0.0,
                            "기부금_E77": 0.0,
                            "기부금_E92": 0.0,
                            "내_외국인_구분코드_E137": "",
                            "내_외국인_구분코드_E180": "",
                            "내_외국인_구분코드_E51": "",
                            "내_외국인_구분코드_E8": "1",
                            "내_외국인_구분코드_E94": "",
                            "대중교통이용액_E119": 0.0,
                            "대중교통이용액_E134": 0.0,
                            "대중교통이용액_E162": 0.0,
                            "대중교통이용액_E177": 0.0,
                            "대중교통이용액_E205": 0.0,
                            "대중교통이용액_E220": 0.0,
                            "대중교통이용액_E33": 0.0,
                            "대중교통이용액_E48": 0.0,
                            "대중교통이용액_E76": 0.0,
                            "대중교통이용액_E91": 0.0,
                            "도서_공연_사용분_E117": 0.0,
                            "도서_공연_사용분_E132": 0.0,
                            "도서_공연_사용분_E160": 0.0,
                            "도서_공연_사용분_E175": 0.0,
                            "도서_공연_사용분_E203": 0.0,
                            "도서_공연_사용분_E218": 0.0,
                            "도서_공연_사용분_E31": 0.0,
                            "도서_공연_사용분_E46": 0.0,
                            "도서_공연_사용분_E74": 0.0,
                            "도서_공연_사용분_E89": 0.0,
                            "레코드_구분": "E",
                            "보험료_건강보험_E105": 0.0,
                            "보험료_건강보험_E121": 0.0,
                            "보험료_건강보험_E148": 0.0,
                            "보험료_건강보험_E164": 0.0,
                            "보험료_건강보험_E19": 0.0,
                            "보험료_건강보험_E191": 0.0,
                            "보험료_건강보험_E207": 0.0,
                            "보험료_건강보험_E35": 0.0,
                            "보험료_건강보험_E62": 0.0,
                            "보험료_건강보험_E78": 0.0,
                            "보험료_고용보험_E106": 0.0,
                            "보험료_고용보험_E122": 0.0,
                            "보험료_고용보험_E149": 0.0,
                            "보험료_고용보험_E165": 0.0,
                            "보험료_고용보험_E192": 0.0,
                            "보험료_고용보험_E20": 0.0,
                            "보험료_고용보험_E208": 0.0,
                            "보험료_고용보험_E36": 139420.0,
                            "보험료_고용보험_E63": 0.0,
                            "보험료_고용보험_E79": 0.0,
                            "보험료_보장성보험_E107": 0.0,
                            "보험료_보장성보험_E123": 0.0,
                            "보험료_보장성보험_E150": 0.0,
                            "보험료_보장성보험_E166": 0.0,
                            "보험료_보장성보험_E193": 0.0,
                            "보험료_보장성보험_E209": 0.0,
                            "보험료_보장성보험_E21": 0.0,
                            "보험료_보장성보험_E37": 0.0,
                            "보험료_보장성보험_E64": 0.0,
                            "보험료_보장성보험_E80": 0.0,
                            "보험료_장애인전용_보장성보험_E108": 0.0,
                            "보험료_장애인전용_보장성보험_E124": 0.0,
                            "보험료_장애인전용_보장성보험_E151": 0.0,
                            "보험료_장애인전용_보장성보험_E167": 0.0,
                            "보험료_장애인전용_보장성보험_E194": 0.0,
                            "보험료_장애인전용_보장성보험_E210": 0.0,
                            "보험료_장애인전용_보장성보험_E22": 0.0,
                            "보험료_장애인전용_보장성보험_E38": 0.0,
                            "보험료_장애인전용_보장성보험_E65": 0.0,
                            "보험료_장애인전용_보장성보험_E81": 0.0,
                            "부녀자공제_E13": "",
                            "부녀자공제_E142": "",
                            "부녀자공제_E185": "",
                            "부녀자공제_E56": "",
                            "부녀자공제_E99": "",
                            "부양가족레코드_일련번호": 1.0,
                            "서식명": "바_E레코드_소득공제명세_레코드",
                            "성명_E138": "",
                            "성명_E181": "",
                            "성명_E52": "",
                            "성명_E9": "이숙번",
                            "성명_E95": "",
                            "세무서코드": "144",
                            "신용카드_전통시장_대중_교통비_제외_E114": 0.0,
                            "신용카드_전통시장_대중_교통비_제외_E130": 0.0,
                            "신용카드_전통시장_대중_교통비_제외_E157": 0.0,
                            "신용카드_전통시장_대중_교통비_제외_E173": 0.0,
                            "신용카드_전통시장_대중_교통비_제외_E200": 0.0,
                            "신용카드_전통시장_대중_교통비_제외_E216": 0.0,
                            "신용카드_전통시장_대중_교통비_제외_E28": 0.0,
                            "신용카드_전통시장_대중_교통비_제외_E44": 0.0,
                            "신용카드_전통시장_대중_교통비_제외_E71": 0.0,
                            "신용카드_전통시장_대중_교통비_제외_E87": 0.0,
                            "의료비_난임_E110": 0.0,
                            "의료비_난임_E126": 0.0,
                            "의료비_난임_E153": 0.0,
                            "의료비_난임_E169": 0.0,
                            "의료비_난임_E196": 0.0,
                            "의료비_난임_E212": 0.0,
                            "의료비_난임_E24": 0.0,
                            "의료비_난임_E40": 0.0,
                            "의료비_난임_E67": 0.0,
                            "의료비_난임_E83": 0.0,
                            "의료비_일반_E109": 0.0,
                            "의료비_일반_E125": 0.0,
                            "의료비_일반_E152": 0.0,
                            "의료비_일반_E168": 0.0,
                            "의료비_일반_E195": 0.0,
                            "의료비_일반_E211": 0.0,
                            "의료비_일반_E23": 0.0,
                            "의료비_일반_E39": 0.0,
                            "의료비_일반_E66": 0.0,
                            "의료비_일반_E82": 0.0,
                            "의료비_장애인_건강보험산정특례자_E111": 0.0,
                            "의료비_장애인_건강보험산정특례자_E127": 0.0,
                            "의료비_장애인_건강보험산정특례자_E154": 0.0,
                            "의료비_장애인_건강보험산정특례자_E170": 0.0,
                            "의료비_장애인_건강보험산정특례자_E197": 0.0,
                            "의료비_장애인_건강보험산정특례자_E213": 0.0,
                            "의료비_장애인_건강보험산정특례자_E25": 0.0,
                            "의료비_장애인_건강보험산정특례자_E41": 0.0,
                            "의료비_장애인_건강보험산정특례자_E68": 0.0,
                            "의료비_장애인_건강보험산정특례자_E84": 0.0,
                            "일련번호": 0.0,
                            "자녀공제_E103": "",
                            "자녀공제_E146": "",
                            "자녀공제_E17": "",
                            "자녀공제_E189": "",
                            "자녀공제_E60": "",
                            "자료구분": 20.0,
                            "장애인공제_E12": "",
                            "장애인공제_E141": "",
                            "장애인공제_E184": "",
                            "장애인공제_E55": "",
                            "장애인공제_E98": "",
                            "전통시장사용액_E118": 0.0,
                            "전통시장사용액_E133": 0.0,
                            "전통시장사용액_E161": 0.0,
                            "전통시장사용액_E176": 0.0,
                            "전통시장사용액_E204": 0.0,
                            "전통시장사용액_E219": 0.0,
                            "전통시장사용액_E32": 0.0,
                            "전통시장사용액_E47": 0.0,
                            "전통시장사용액_E75": 0.0,
                            "전통시장사용액_E90": 0.0,
                            "주민등록번호_E10": "7902271011215",
                            "주민등록번호_E139": "",
                            "주민등록번호_E182": "",
                            "주민등록번호_E53": "",
                            "주민등록번호_E96": "",
                            "직불_선불카드_전통시장_대중_교통비_제외_E115": 0.0,
                            "직불_선불카드_전통시장_대중_교통비_제외_E131": 0.0,
                            "직불_선불카드_전통시장_대중_교통비_제외_E158": 0.0,
                            "직불_선불카드_전통시장_대중_교통비_제외_E174": 0.0,
                            "직불_선불카드_전통시장_대중_교통비_제외_E201": 0.0,
                            "직불_선불카드_전통시장_대중_교통비_제외_E217": 0.0,
                            "직불_선불카드_전통시장_대중_교통비_제외_E29": 0.0,
                            "직불_선불카드_전통시장_대중_교통비_제외_E45": 0.0,
                            "직불_선불카드_전통시장_대중_교통비_제외_E72": 0.0,
                            "직불_선불카드_전통시장_대중_교통비_제외_E88": 0.0,
                            "출산_입양공제_E102": "",
                            "출산_입양공제_E145": "",
                            "출산_입양공제_E16": "",
                            "출산_입양공제_E188": "",
                            "출산_입양공제_E59": "",
                            "한부모가족공제_E101": "",
                            "한부모가족공제_E144": "",
                            "한부모가족공제_E15": "",
                            "한부모가족공제_E187": "",
                            "한부모가족공제_E58": "",
                            "현금영수증_전통시장_대중_교통비_제외_E116": 0.0,
                            "현금영수증_전통시장_대중_교통비_제외_E159": 0.0,
                            "현금영수증_전통시장_대중_교통비_제외_E202": 0.0,
                            "현금영수증_전통시장_대중_교통비_제외_E30": 0.0,
                            "현금영수증_전통시장_대중_교통비_제외_E73": 0.0
                        }
                    ],
                    "data": [
                        {
                            "M01_국외근로100만원": 0.0,
                            "M02_국외근로300만원": 0.0,
                            "M03_국외근로": 0.0,
                            "Y22_전공의_수련_보조_수당": 0.0,
                            "_10K01_외국주둔군인등": 0.0,
                            "_10_주현근무처_사업자등록번호": "8138100856",
                            "_11S01_주식매수선택권": 0.0,
                            "_11_근무기간_시작연월일": 20181015.0,
                            "_11_근무기간_종료연월일": 20181231.0,
                            "_12T01_외국인기술자": 0.0,
                            "_12_감면기간_시작연월일": 0.0,
                            "_12_감면기간_종료연월일": 0.0,
                            "_13_급여": 21451308.0,
                            "_14Y02_우리사주조합인출금50": 0.0,
                            "_14_상여": 0.0,
                            "_15_1주식매수_선택권행사이익": 0.0,
                            "_15_2_우리사주조합인출금": 0.0,
                            "_15_3_임원퇴직소득금액한도초과액": 0.0,
                            "_15_4_직무발명보상금": 0.0,
                            "_15_Y03_우리사주조합인출금75": 0.0,
                            "_15_인정상여": 0.0,
                            "_16_Y04_우리사주조합인출금100": 0.0,
                            "_18_H05_경호_승선수당": 0.0,
                            "_19_I01_외국정부등근무자": 0.0,
                            "_1O01_야간근로수당": 0.0,
                            "_1_감면소득_계": 0.0,
                            "_2011년_이전_차입분_주택자금_장기주택저당차입금이자상환액_15년~29년": 0.0,
                            "_2011년_이전_차입분_주택자금_장기주택저당차입금이자상환액_15년미만": 0.0,
                            "_2011년_이전_차입분_주택자금_장기주택저당차입금이자상환액_30년이상": 0.0,
                            "_2012년_이후_차입분_15년_이상_고정금리_비거치식_상환_대출": 0.0,
                            "_2012년_이후_차입분_15년_이상_기타_대출": 0.0,
                            "_2015년_이후_차입분_상환기간_10년_이상_고정금리_or_비거치_상환_대출": 0.0,
                            "_2015년_이후_차입분_상환기간_15년_이상_고정금리_and_비거치_상환_대출": 0.0,
                            "_2015년_이후_차입분_상환기간_15년_이상_고정금리_or_비거치_상환_대출": 0.0,
                            "_2015년_이후_차입분_상환기간_15년_이상_그_밖의_대출": 0.0,
                            "_21_R10_근로장학금": 0.0,
                            "_22H14_보육교사_근무환경개선비": 0.0,
                            "_23H15_사립유치원수석교사_교사의_인건비": 0.0,
                            "_24_H16_정부_공공기관지방이전기관_종사자_이주수당": 0.0,
                            "_28_T20_조세조약상_교직자감면": 0.0,
                            "_29_R11_직무발명보상금": 0.0,
                            "_2Q01_출산보육수당": 0.0,
                            "_30_H17_종교활동비": 0.0,
                            "_31U01_벤처기업_주식매수선택권": 0.0,
                            "_32T13_중소기업취업청년_소득세_감면90": 0.0,
                            "_3_1_사업자단위과세자_여부": "2",
                            "_3_2_종사업장_일련번호": "",
                            "_3_사업자등록번호": "8138100856",
                            "_4H08_특별법": 0.0,
                            "_4H09_연구기관_등": 0.0,
                            "_4H10_기업부설연구소": 0.0,
                            "_4_H06_유아_초중등": 0.0,
                            "_4_H07_고등교육법": 0.0,
                            "_5_G01_비과세학자금": 0.0,
                            "_6_H11_취재수당": 0.0,
                            "_6_성명": "이숙번",
                            "_7_H12_벽지수당": 0.0,
                            "_7_주민등록번호": "7902271011215",
                            "_8_H13_재해관련급여": 0.0,
                            "_9_H01_무보수위원수당": 0.0,
                            "_9_주현근무처_근무처명": "주식회사 파인코드",
                            "_공적연금보험료공제_공무원연금_공제금액": 0.0,
                            "_공적연금보험료공제_공무원연금_대상금액": 0.0,
                            "_공적연금보험료공제_군인연금_공제금액": 0.0,
                            "_공적연금보험료공제_군인연금_대상금액": 0.0,
                            "_공적연금보험료공제_별정우체국연금_공제금액": 0.0,
                            "_공적연금보험료공제_별정우체국연금_대상금액": 0.0,
                            "_공적연금보험료공제_사립학교교직원연금_공제금액": 0.0,
                            "_공적연금보험료공제_사립학교교직원연금_대상금액": 0.0,
                            "_보험료_건강보험료_노인장기요양보험료_포함": 0,
                            "_보험료_건강보험료_노인장기요양보험료_포함_공제금액": 0.0,
                            "_보험료_건강보험료_노인장기요양보험료_포함_국세청": 0,
                            "_보험료_건강보험료_노인장기요양보험료_포함_급여자료": 558360,
                            "_보험료_건강보험료_노인장기요양보험료_포함_대상금액": 0.0,
                            "_보험료_고용보험료": 139420,
                            "_보험료_고용보험료_공제금액": 0.0,
                            "_보험료_고용보험료_대상금액": 0.0,
                            "_자녀세액공제": 0.0,
                            "_자녀세액공제_인원": 0.0,
                            "_주택마련저축소득공제_근로자주택마련저축": 0.0,
                            "_주택마련저축소득공제_주택청약종합저축": 0.0,
                            "_주택마련저축소득공제_청약저축": 0.0,
                            "_주택자금_주택임차차입금원리금상환액_거주자": 0.0,
                            "_주택자금_주택임차차입금원리금상환액_대출기관": 0.0,
                            "_출산_입양세액공제": 0.0,
                            "_출산_입양세액공제인원": 0.0,
                            "_특별세액공제_기부금_법정기부금_공제대상금액": 0.0,
                            "_특별세액공제_기부금_법정기부금_세액공제액": 0.0,
                            "_특별세액공제_기부금_우리사주조합기부금_공제대상금액": 0.0,
                            "_특별세액공제_기부금_우리사주조합기부금_세액공제액": 0.0,
                            "_특별세액공제_기부금_정치자금_10만원이하_공제대상금액": 0.0,
                            "_특별세액공제_기부금_정치자금_10만원이하_세액공제액": 0.0,
                            "_특별세액공제_기부금_정치자금_10만원초과_공제대상금액": 0.0,
                            "_특별세액공제_기부금_정치자금_10만원초과_세액공제액": 0.0,
                            "_특별세액공제_기부금_지정기부금_종교단체_공제대상금액": 0.0,
                            "_특별세액공제_기부금_지정기부금_종교단체_세액공제액": 0.0,
                            "_특별세액공제_기부금_지정기부금_종교단체외_공제대상금액": 0.0,
                            "_특별세액공제_기부금_지정기부금_종교단체외_세액공제액": 0.0,
                            "개인연금저축소득공제": 0.0,
                            "거주자구분코드": 1.0,
                            "거주지국코드": "",
                            "결정세액_농특세": 0.0,
                            "결정세액_소득세": 168685.0,
                            "결정세액_지방소득세": 16868.0,
                            "경로우대공제금액": 0.0,
                            "경로우대공제인원": 0.0,
                            "고용유지중소기업_근로자소득공제": 0.0,
                            "공란_C106": 0.0,
                            "공란_C116": 0.0,
                            "공란_C135": 0.0,
                            "공란_C143": 0.0,
                            "공란_C150": "",
                            "공란_C28": 0,
                            "공란_C32": 0.0,
                            "공란_C66": 0.0,
                            "공란_C92": 0.0,
                            "국민연금보험료공제": 421200,
                            "국민연금보험료공제_공제금액": 421200.0,
                            "국민연금보험료공제_국세청": 0,
                            "국민연금보험료공제_급여자료": 421200,
                            "국민연금보험료공제_대상금액": 421200.0,
                            "국민연금보험료공제_종전근무처": 0,
                            "국적코드": "",
                            "그_밖의_소득공제계": 0.0,
                            "근로소득공제": 8467696.0,
                            "근로소득금액": 12983612.0,
                            "근로소득세액공제": 365059.0,
                            "근무처별_소득명세_계": 21451308.0,
                            "기납부세액_농특세": 0.0,
                            "기납부세액_소득세": 2473770.0,
                            "기납부세액_지방소득세": 247360.0,
                            "기부금_이월분": 0.0,
                            "납부특례세액_농특세": 0.0,
                            "납부특례세액_소득세": 0.0,
                            "납부특례세액_지방소득세": 0.0,
                            "납세조합공제": 0.0,
                            "내_외국인_구분코드": 1.0,
                            "레코드_구분": "C",
                            "배우자공제금액": 0.0,
                            "본인공제금액": 1500000.0,
                            "부녀자공제금액": 0.0,
                            "부양가족공제금액": 0.0,
                            "부양가족공제인원": 0.0,
                            "비과세_계": 0.0,
                            "산출세액": 663744.0,
                            "서식명": "라_C레코드_주_현근무처_레코드",
                            "세대주여부": "2",
                            "세무서코드": "144",
                            "세액감면계": 0.0,
                            "세액공제계": 495059.0,
                            "소기업_소상공인_공제부금": 0.0,
                            "소득공제종합한도초과액": 0.0,
                            "소득세법": 0.0,
                            "신용카드등_소득공제": 0.0,
                            "연금계좌_과학기술인공제_공제대상금액": 0.0,
                            "연금계좌_과학기술인공제_세액공제액": 0.0,
                            "연금계좌_근로자퇴직급여보장법에_따른_퇴직급여_공제대상금액": 0.0,
                            "연금계좌_근로자퇴직급여보장법에_따른_퇴직급여_세액공제액": 0.0,
                            "연금계좌_연금저축_공제대상금액": 0.0,
                            "연금계좌_연금저축_세액공제액": 0.0,
                            "연말정산구분": "1",
                            "외국납부": 0.0,
                            "외국법인소속파견근로자여부": "2",
                            "외국인단일세율적용": 2.0,
                            "우리사주조합출연금": 0.0,
                            "월세세액공제대상금액": 0.0,
                            "월세세액공제액": 0.0,
                            "일련번호": 0.0,
                            "자료구분": 20.0,
                            "장기집합투자증권저축": 0.0,
                            "장애인공제금액": 0.0,
                            "장애인공제인원": 0.0,
                            "조세조약": 0.0,
                            "조특법_제30조": 0.0,
                            "조특법_제외": 0.0,
                            "종_전근무처_수": 0.0,
                            "종합소득_과세표준": 11062412.0,
                            "주택차입금": 0.0,
                            "중소기업취업청년_소득세_감면100": 0.0,
                            "중소기업취업청년_소득세_감면50": 0.0,
                            "중소기업취업청년_소득세_감면70": 0.0,
                            "차감소득금액": 11062412.0,
                            "차감징수세액_농특세": 0.0,
                            "차감징수세액_농특세_abs": 0.0,
                            "차감징수세액_소득세": 1.0,
                            "차감징수세액_소득세_abs": 2305080.0,
                            "차감징수세액_지방소득세": 1.0,
                            "차감징수세액_지방소득세_abs": 230490.0,
                            "총급여": 21451308.0,
                            "투자조합출자등_소득공제": 0.0,
                            "특별세액공제_교육비_공제대상금액": 0.0,
                            "특별세액공제_교육비_세액공제액": 0.0,
                            "특별세액공제_보장성보험료_공제대상금액": 0.0,
                            "특별세액공제_보장성보험료_세액공제액": 0.0,
                            "특별세액공제_의료비_공제대상금액": 0.0,
                            "특별세액공제_의료비_세액공제액": 0.0,
                            "특별세액공제_장애인전용보장성보험료_공제대상금액": 0.0,
                            "특별세액공제_장애인전용보장성보험료_세액공제액": 0.0,
                            "특별세액공제계": 0.0,
                            "특별소득공제_계": 0.0,
                            "표준세액공제": 130000.0,
                            "표준세액공제_강제적용": false,
                            "한부모가족공제금액": 0.0
                        },
                        {
                            "세무서코드": "144",
                            "_3_사업자등록번호": "8138100856",
                            "_7_소득자_주민등록번호": "7902271011215",
                            "관계": "0",
                            "내_외국인_구분코드": "1",
                            "성명": "이숙번",
                            "주민등록번호": "7902271011215",
                            "기본공제": "1",
                            "장애인공제": "",
                            "부녀자공제": "",
                            "경로우대공제": "",
                            "한부모가족공제": "",
                            "출산_입양공제": "",
                            "자녀공제": "",
                            "교육비공제": "",
                            "보험료_건강보험_국세청": 0.0,
                            "보험료_고용보험_국세청": 0.0,
                            "보험료_보장성보험_국세청": 0.0,
                            "보험료_장애인전용_보장성보험_국세청": 0.0,
                            "의료비_일반_국세청": 0.0,
                            "의료비_난임_국세청": 0.0,
                            "의료비_장애인_건강보험산정특례자_국세청": 0.0,
                            "교육비_일반_국세청": 0.0,
                            "교육비_장애인특수교육_국세청": 0.0,
                            "신용카드_전통시장_대중_교통비_제외_국세청": 0.0,
                            "직불_선불카드_전통시장_대중_교통비_제외_국세청": 0.0,
                            "현금영수증_전통시장_대중_교통비_제외_국세청": 0.0,
                            "도서_공연_사용분_국세청": 0.0,
                            "전통시장사용액_국세청": 0.0,
                            "대중교통이용액_국세청": 0.0,
                            "기부금_국세청": 0.0,
                            "보험료_건강보험_기타": 0.0,
                            "보험료_고용보험_기타": 139420.0,
                            "보험료_보장성보험_기타": 0.0,
                            "보험료_장애인전용_보장성보험_기타": 0.0,
                            "의료비_일반_기타": 0.0,
                            "의료비_난임_기타": 0.0,
                            "의료비_장애인_건강보험산정특례자_기타": 0.0,
                            "교육비_일반_기타": 0.0,
                            "교육비_장애인특수교육_기타": 0.0,
                            "신용카드_전통시장_대중_교통비_제외_기타": 0.0,
                            "직불_선불카드_전통시장_대중_교통비_제외_기타": 0.0,
                            "도서_공연_사용분_기타": 0.0,
                            "전통시장사용액_기타": 0.0,
                            "대중교통이용액_기타": 0.0,
                            "기부금_기타": 0.0,
                            "서식명": "바_E레코드_소득공제명세_레코드_split"
                        }
                    ],
                    "표준세액공제_적용": {
                        "_6_성명": "이숙번",
                        "서식명": "라_C레코드_주_현근무처_레코드",
                        "총급여": "21451308",
                        "_13_급여": "21451308",
                        "_14_상여": "0",
                        "공란_C28": 0,
                        "공란_C32": "0",
                        "공란_C66": "0",
                        "공란_C92": "0",
                        "공란_C106": "0",
                        "공란_C116": "0",
                        "공란_C135": "0",
                        "공란_C143": "0",
                        "공란_C150": "",
                        "국적코드": "",
                        "산출세액": "663744",
                        "소득세법": "0",
                        "외국납부": "0",
                        "일련번호": "0",
                        "자료구분": "20",
                        "조세조약": "0",
                        "비과세_계": "0",
                        "_4H08_특별법": "0",
                        "세대주여부": "2",
                        "세무서코드": "144",
                        "세액감면계": "0",
                        "세액공제계": "495059",
                        "주택차입금": "0",
                        "M03_국외근로": "0",
                        "_15_인정상여": "0",
                        "레코드_구분": "C",
                        "조특법_제외": "0",
                        "거주지국코드": "",
                        "근로소득공제": "8467696",
                        "근로소득금액": "12983612",
                        "납세조합공제": "0",
                        "본인공제금액": "1500000",
                        "연말정산구분": "1",
                        "조특법_제30조": "0",
                        "차감소득금액": "11062412",
                        "표준세액공제": "130000",
                        "_1_감면소득_계": "0",
                        "_6_H11_취재수당": "0",
                        "_7_H12_벽지수당": "0",
                        "_자녀세액공제": "0",
                        "기부금_이월분": "0",
                        "종_전근무처_수": "0",
                        "_7_주민등록번호": "7902271011215",
                        "거주자구분코드": "1",
                        "배우자공제금액": "0",
                        "부녀자공제금액": "0",
                        "월세세액공제액": "0",
                        "장애인공제금액": "0",
                        "장애인공제인원": "0",
                        "특별세액공제계": "0",
                        "_4H09_연구기관_등": "0",
                        "_4_H07_고등교육법": "0",
                        "결정세액_농특세": "0",
                        "결정세액_소득세": "168685",
                        "특별소득공제_계": "0",
                        "_21_R10_근로장학금": "0",
                        "_30_H17_종교활동비": "0",
                        "_4_H06_유아_초중등": "0",
                        "_1O01_야간근로수당": "0",
                        "_2Q01_출산보육수당": "0",
                        "_3_사업자등록번호": "8138100856",
                        "경로우대공제금액": "0",
                        "경로우대공제인원": "0",
                        "근로소득세액공제": "365059",
                        "부양가족공제금액": "0",
                        "부양가족공제인원": "0",
                        "M01_국외근로100만원": "0",
                        "M02_국외근로300만원": "0",
                        "_12T01_외국인기술자": "0",
                        "_5_G01_비과세학자금": "0",
                        "_8_H13_재해관련급여": "0",
                        "기납부세액_농특세": "0",
                        "기납부세액_소득세": "2473770",
                        "종합소득_과세표준": "11062412",
                        "_보험료_고용보험료": 139420,
                        "_자녀세액공제_인원": "0",
                        "_출산_입양세액공제": "0",
                        "그_밖의_소득공제계": "0",
                        "내_외국인_구분코드": "1",
                        "_15_4_직무발명보상금": "0",
                        "_18_H05_경호_승선수당": "0",
                        "_4H10_기업부설연구소": "0",
                        "국민연금보험료공제": 421200,
                        "외국인단일세율적용": "2",
                        "우리사주조합출연금": "0",
                        "한부모가족공제금액": "0",
                        "_10K01_외국주둔군인등": "0",
                        "_11S01_주식매수선택권": "0",
                        "_9_H01_무보수위원수당": "0",
                        "결정세액_지방소득세": "16868",
                        "납부특례세액_농특세": "0",
                        "납부특례세액_소득세": "0",
                        "신용카드등_소득공제": "0",
                        "차감징수세액_농특세": "0",
                        "차감징수세액_소득세": "1",
                        "_29_R11_직무발명보상금": "0",
                        "근무처별_소득명세_계": "21451308",
                        "_3_2_종사업장_일련번호": "",
                        "개인연금저축소득공제": "0",
                        "월세세액공제대상금액": "0",
                        "장기집합투자증권저축": "0",
                        "_9_주현근무처_근무처명": "주식회사 파인코드",
                        "기납부세액_지방소득세": "247360",
                        "표준세액공제_강제적용": false,
                        "_11_근무기간_시작연월일": "20181015",
                        "_11_근무기간_종료연월일": "20181231",
                        "_12_감면기간_시작연월일": "0",
                        "_12_감면기간_종료연월일": "0",
                        "_19_I01_외국정부등근무자": "0",
                        "_출산_입양세액공제인원": "0",
                        "차감징수세액_농특세_abs": "0",
                        "차감징수세액_소득세_abs": "2305080",
                        "_15_2_우리사주조합인출금": "0",
                        "소득공제종합한도초과액": "0",
                        "Y22_전공의_수련_보조_수당": "0",
                        "납부특례세액_지방소득세": "0",
                        "차감징수세액_지방소득세": "1",
                        "투자조합출자등_소득공제": "0",
                        "소기업_소상공인_공제부금": "0",
                        "_14Y02_우리사주조합인출금50": "0",
                        "_3_1_사업자단위과세자_여부": "2",
                        "_15_Y03_우리사주조합인출금75": "0",
                        "국민연금보험료공제_국세청": 0,
                        "_16_Y04_우리사주조합인출금100": "0",
                        "차감징수세액_지방소득세_abs": "230490",
                        "_15_1주식매수_선택권행사이익": "0",
                        "_28_T20_조세조약상_교직자감면": "0",
                        "_보험료_고용보험료_공제금액": "0",
                        "_보험료_고용보험료_대상금액": "0",
                        "외국법인소속파견근로자여부": "2",
                        "국민연금보험료공제_공제금액": "421200",
                        "국민연금보험료공제_급여자료": 421200,
                        "국민연금보험료공제_대상금액": "421200",
                        "_10_주현근무처_사업자등록번호": "8138100856",
                        "_22H14_보육교사_근무환경개선비": "0",
                        "_31U01_벤처기업_주식매수선택권": "0",
                        "연금계좌_연금저축_세액공제액": "0",
                        "국민연금보험료공제_종전근무처": 0,
                        "중소기업취업청년_소득세_감면50": "0",
                        "중소기업취업청년_소득세_감면70": "0",
                        "_주택마련저축소득공제_청약저축": "0",
                        "연금계좌_연금저축_공제대상금액": "0",
                        "중소기업취업청년_소득세_감면100": "0",
                        "특별세액공제_교육비_세액공제액": "0",
                        "특별세액공제_의료비_세액공제액": "0",
                        "_15_3_임원퇴직소득금액한도초과액": "0",
                        "고용유지중소기업_근로자소득공제": "0",
                        "특별세액공제_교육비_공제대상금액": "0",
                        "특별세액공제_의료비_공제대상금액": "0",
                        "_32T13_중소기업취업청년_소득세_감면90": "0",
                        "연금계좌_과학기술인공제_세액공제액": "0",
                        "_2012년_이후_차입분_15년_이상_기타_대출": "0",
                        "연금계좌_과학기술인공제_공제대상금액": "0",
                        "특별세액공제_보장성보험료_세액공제액": "0",
                        "_23H15_사립유치원수석교사_교사의_인건비": "0",
                        "_공적연금보험료공제_군인연금_공제금액": "0",
                        "_공적연금보험료공제_군인연금_대상금액": "0",
                        "_주택마련저축소득공제_주택청약종합저축": "0",
                        "특별세액공제_보장성보험료_공제대상금액": "0",
                        "_공적연금보험료공제_공무원연금_공제금액": "0",
                        "_공적연금보험료공제_공무원연금_대상금액": "0",
                        "_주택마련저축소득공제_근로자주택마련저축": "0",
                        "_보험료_건강보험료_노인장기요양보험료_포함": 0,
                        "_특별세액공제_기부금_법정기부금_세액공제액": "0",
                        "_공적연금보험료공제_별정우체국연금_공제금액": "0",
                        "_공적연금보험료공제_별정우체국연금_대상금액": "0",
                        "_주택자금_주택임차차입금원리금상환액_거주자": "0",
                        "_특별세액공제_기부금_법정기부금_공제대상금액": "0",
                        "_주택자금_주택임차차입금원리금상환액_대출기관": "0",
                        "_24_H16_정부_공공기관지방이전기관_종사자_이주수당": "0",
                        "특별세액공제_장애인전용보장성보험료_세액공제액": "0",
                        "_2015년_이후_차입분_상환기간_15년_이상_그_밖의_대출": "0",
                        "_공적연금보험료공제_사립학교교직원연금_공제금액": "0",
                        "_공적연금보험료공제_사립학교교직원연금_대상금액": "0",
                        "_보험료_건강보험료_노인장기요양보험료_포함_국세청": 0,
                        "특별세액공제_장애인전용보장성보험료_공제대상금액": "0",
                        "_특별세액공제_기부금_우리사주조합기부금_세액공제액": "0",
                        "_특별세액공제_기부금_정치자금_10만원이하_세액공제액": "0",
                        "_특별세액공제_기부금_정치자금_10만원초과_세액공제액": "0",
                        "_보험료_건강보험료_노인장기요양보험료_포함_공제금액": "0",
                        "_보험료_건강보험료_노인장기요양보험료_포함_급여자료": 558360,
                        "_보험료_건강보험료_노인장기요양보험료_포함_대상금액": "0",
                        "_특별세액공제_기부금_지정기부금_종교단체_세액공제액": "0",
                        "_특별세액공제_기부금_우리사주조합기부금_공제대상금액": "0",
                        "_특별세액공제_기부금_정치자금_10만원이하_공제대상금액": "0",
                        "_특별세액공제_기부금_정치자금_10만원초과_공제대상금액": "0",
                        "_특별세액공제_기부금_지정기부금_종교단체_공제대상금액": "0",
                        "_특별세액공제_기부금_지정기부금_종교단체외_세액공제액": "0",
                        "_2012년_이후_차입분_15년_이상_고정금리_비거치식_상환_대출": "0",
                        "_특별세액공제_기부금_지정기부금_종교단체외_공제대상금액": "0",
                        "연금계좌_근로자퇴직급여보장법에_따른_퇴직급여_세액공제액": "0",
                        "연금계좌_근로자퇴직급여보장법에_따른_퇴직급여_공제대상금액": "0",
                        "_2015년_이후_차입분_상환기간_10년_이상_고정금리_or_비거치_상환_대출": "0",
                        "_2015년_이후_차입분_상환기간_15년_이상_고정금리_or_비거치_상환_대출": "0",
                        "_2015년_이후_차입분_상환기간_15년_이상_고정금리_and_비거치_상환_대출": "0",
                        "_2011년_이전_차입분_주택자금_장기주택저당차입금이자상환액_15년~29년": "0",
                        "_2011년_이전_차입분_주택자금_장기주택저당차입금이자상환액_15년미만": "0",
                        "_2011년_이전_차입분_주택자금_장기주택저당차입금이자상환액_30년이상": "0"
                    },
                    "표준세액공제_미적용": {
                        "_6_성명": "이숙번",
                        "서식명": "라_C레코드_주_현근무처_레코드",
                        "총급여": "21451308",
                        "_13_급여": "21451308",
                        "_14_상여": "0",
                        "공란_C28": 0,
                        "공란_C32": "0",
                        "공란_C66": "0",
                        "공란_C92": "0",
                        "공란_C106": "0",
                        "공란_C116": "0",
                        "공란_C135": "0",
                        "공란_C143": "0",
                        "공란_C150": "",
                        "국적코드": "",
                        "산출세액": "655379",
                        "소득세법": "0",
                        "외국납부": "0",
                        "일련번호": "0",
                        "자료구분": "20",
                        "조세조약": "0",
                        "비과세_계": "0",
                        "_4H08_특별법": "0",
                        "세대주여부": "2",
                        "세무서코드": "144",
                        "세액감면계": "0",
                        "세액공제계": "360458",
                        "주택차입금": "0",
                        "M03_국외근로": "0",
                        "_15_인정상여": "0",
                        "레코드_구분": "C",
                        "조특법_제외": "0",
                        "거주지국코드": "",
                        "근로소득공제": "8467696",
                        "근로소득금액": "12983612",
                        "납세조합공제": "0",
                        "본인공제금액": "1500000",
                        "연말정산구분": "1",
                        "조특법_제30조": "0",
                        "차감소득금액": "10922992",
                        "표준세액공제": "0",
                        "_1_감면소득_계": "0",
                        "_6_H11_취재수당": "0",
                        "_7_H12_벽지수당": "0",
                        "_자녀세액공제": "0",
                        "기부금_이월분": "0",
                        "종_전근무처_수": "0",
                        "_7_주민등록번호": "7902271011215",
                        "거주자구분코드": "1",
                        "배우자공제금액": "0",
                        "부녀자공제금액": "0",
                        "월세세액공제액": "0",
                        "장애인공제금액": "0",
                        "장애인공제인원": "0",
                        "특별세액공제계": "0",
                        "_4H09_연구기관_등": "0",
                        "_4_H07_고등교육법": "0",
                        "결정세액_농특세": "0",
                        "결정세액_소득세": "294921",
                        "특별소득공제_계": "139420",
                        "_21_R10_근로장학금": "0",
                        "_30_H17_종교활동비": "0",
                        "_4_H06_유아_초중등": "0",
                        "_1O01_야간근로수당": "0",
                        "_2Q01_출산보육수당": "0",
                        "_3_사업자등록번호": "8138100856",
                        "경로우대공제금액": "0",
                        "경로우대공제인원": "0",
                        "근로소득세액공제": "360458",
                        "부양가족공제금액": "0",
                        "부양가족공제인원": "0",
                        "M01_국외근로100만원": "0",
                        "M02_국외근로300만원": "0",
                        "_12T01_외국인기술자": "0",
                        "_5_G01_비과세학자금": "0",
                        "_8_H13_재해관련급여": "0",
                        "기납부세액_농특세": "0",
                        "기납부세액_소득세": "2473770",
                        "종합소득_과세표준": "10922992",
                        "_보험료_고용보험료": 139420,
                        "_자녀세액공제_인원": "0",
                        "_출산_입양세액공제": "0",
                        "그_밖의_소득공제계": "0",
                        "내_외국인_구분코드": "1",
                        "_15_4_직무발명보상금": "0",
                        "_18_H05_경호_승선수당": "0",
                        "_4H10_기업부설연구소": "0",
                        "국민연금보험료공제": 421200,
                        "외국인단일세율적용": "2",
                        "우리사주조합출연금": "0",
                        "한부모가족공제금액": "0",
                        "_10K01_외국주둔군인등": "0",
                        "_11S01_주식매수선택권": "0",
                        "_9_H01_무보수위원수당": "0",
                        "결정세액_지방소득세": "16868",
                        "납부특례세액_농특세": "0",
                        "납부특례세액_소득세": "0",
                        "신용카드등_소득공제": "0",
                        "차감징수세액_농특세": "0",
                        "차감징수세액_소득세": "1",
                        "_29_R11_직무발명보상금": "0",
                        "근무처별_소득명세_계": "21451308",
                        "_3_2_종사업장_일련번호": "",
                        "개인연금저축소득공제": "0",
                        "월세세액공제대상금액": "0",
                        "장기집합투자증권저축": "0",
                        "_9_주현근무처_근무처명": "주식회사 파인코드",
                        "기납부세액_지방소득세": "247360",
                        "표준세액공제_강제적용": false,
                        "_11_근무기간_시작연월일": "20181015",
                        "_11_근무기간_종료연월일": "20181231",
                        "_12_감면기간_시작연월일": "0",
                        "_12_감면기간_종료연월일": "0",
                        "_19_I01_외국정부등근무자": "0",
                        "_출산_입양세액공제인원": "0",
                        "차감징수세액_농특세_abs": "0",
                        "차감징수세액_소득세_abs": "2305080",
                        "_15_2_우리사주조합인출금": "0",
                        "소득공제종합한도초과액": "0",
                        "Y22_전공의_수련_보조_수당": "0",
                        "납부특례세액_지방소득세": "0",
                        "차감징수세액_지방소득세": "1",
                        "투자조합출자등_소득공제": "0",
                        "소기업_소상공인_공제부금": "0",
                        "_14Y02_우리사주조합인출금50": "0",
                        "_3_1_사업자단위과세자_여부": "2",
                        "_15_Y03_우리사주조합인출금75": "0",
                        "국민연금보험료공제_국세청": 0,
                        "_16_Y04_우리사주조합인출금100": "0",
                        "차감징수세액_지방소득세_abs": "230490",
                        "_15_1주식매수_선택권행사이익": "0",
                        "_28_T20_조세조약상_교직자감면": "0",
                        "_보험료_고용보험료_공제금액": "139420",
                        "_보험료_고용보험료_대상금액": "139420",
                        "외국법인소속파견근로자여부": "2",
                        "국민연금보험료공제_공제금액": "421200",
                        "국민연금보험료공제_급여자료": 421200,
                        "국민연금보험료공제_대상금액": "421200",
                        "_10_주현근무처_사업자등록번호": "8138100856",
                        "_22H14_보육교사_근무환경개선비": "0",
                        "_31U01_벤처기업_주식매수선택권": "0",
                        "연금계좌_연금저축_세액공제액": "0",
                        "국민연금보험료공제_종전근무처": 0,
                        "중소기업취업청년_소득세_감면50": "0",
                        "중소기업취업청년_소득세_감면70": "0",
                        "_주택마련저축소득공제_청약저축": "0",
                        "연금계좌_연금저축_공제대상금액": "0",
                        "중소기업취업청년_소득세_감면100": "0",
                        "특별세액공제_교육비_세액공제액": "0",
                        "특별세액공제_의료비_세액공제액": "0",
                        "_15_3_임원퇴직소득금액한도초과액": "0",
                        "고용유지중소기업_근로자소득공제": "0",
                        "특별세액공제_교육비_공제대상금액": "0",
                        "특별세액공제_의료비_공제대상금액": "0",
                        "_32T13_중소기업취업청년_소득세_감면90": "0",
                        "연금계좌_과학기술인공제_세액공제액": "0",
                        "_2012년_이후_차입분_15년_이상_기타_대출": "0",
                        "연금계좌_과학기술인공제_공제대상금액": "0",
                        "특별세액공제_보장성보험료_세액공제액": "0",
                        "_23H15_사립유치원수석교사_교사의_인건비": "0",
                        "_공적연금보험료공제_군인연금_공제금액": "0",
                        "_공적연금보험료공제_군인연금_대상금액": "0",
                        "_주택마련저축소득공제_주택청약종합저축": "0",
                        "특별세액공제_보장성보험료_공제대상금액": "0",
                        "_공적연금보험료공제_공무원연금_공제금액": "0",
                        "_공적연금보험료공제_공무원연금_대상금액": "0",
                        "_주택마련저축소득공제_근로자주택마련저축": "0",
                        "_보험료_건강보험료_노인장기요양보험료_포함": 0,
                        "_특별세액공제_기부금_법정기부금_세액공제액": "0",
                        "_공적연금보험료공제_별정우체국연금_공제금액": "0",
                        "_공적연금보험료공제_별정우체국연금_대상금액": "0",
                        "_주택자금_주택임차차입금원리금상환액_거주자": "0",
                        "_특별세액공제_기부금_법정기부금_공제대상금액": "0",
                        "_주택자금_주택임차차입금원리금상환액_대출기관": "0",
                        "_24_H16_정부_공공기관지방이전기관_종사자_이주수당": "0",
                        "특별세액공제_장애인전용보장성보험료_세액공제액": "0",
                        "_2015년_이후_차입분_상환기간_15년_이상_그_밖의_대출": "0",
                        "_공적연금보험료공제_사립학교교직원연금_공제금액": "0",
                        "_공적연금보험료공제_사립학교교직원연금_대상금액": "0",
                        "_보험료_건강보험료_노인장기요양보험료_포함_국세청": 0,
                        "특별세액공제_장애인전용보장성보험료_공제대상금액": "0",
                        "_특별세액공제_기부금_우리사주조합기부금_세액공제액": "0",
                        "_특별세액공제_기부금_정치자금_10만원이하_세액공제액": "0",
                        "_특별세액공제_기부금_정치자금_10만원초과_세액공제액": "0",
                        "_보험료_건강보험료_노인장기요양보험료_포함_공제금액": "0",
                        "_보험료_건강보험료_노인장기요양보험료_포함_급여자료": 558360,
                        "_보험료_건강보험료_노인장기요양보험료_포함_대상금액": "0",
                        "_특별세액공제_기부금_지정기부금_종교단체_세액공제액": "0",
                        "_특별세액공제_기부금_우리사주조합기부금_공제대상금액": "0",
                        "_특별세액공제_기부금_정치자금_10만원이하_공제대상금액": "0",
                        "_특별세액공제_기부금_정치자금_10만원초과_공제대상금액": "0",
                        "_특별세액공제_기부금_지정기부금_종교단체_공제대상금액": "0",
                        "_특별세액공제_기부금_지정기부금_종교단체외_세액공제액": "0",
                        "_2012년_이후_차입분_15년_이상_고정금리_비거치식_상환_대출": "0",
                        "_특별세액공제_기부금_지정기부금_종교단체외_공제대상금액": "0",
                        "연금계좌_근로자퇴직급여보장법에_따른_퇴직급여_세액공제액": "0",
                        "연금계좌_근로자퇴직급여보장법에_따른_퇴직급여_공제대상금액": "0",
                        "_2015년_이후_차입분_상환기간_10년_이상_고정금리_or_비거치_상환_대출": "0",
                        "_2015년_이후_차입분_상환기간_15년_이상_고정금리_or_비거치_상환_대출": "0",
                        "_2015년_이후_차입분_상환기간_15년_이상_고정금리_and_비거치_상환_대출": "0",
                        "_2011년_이전_차입분_주택자금_장기주택저당차입금이자상환액_15년~29년": "0",
                        "_2011년_이전_차입분_주택자금_장기주택저당차입금이자상환액_15년미만": "0",
                        "_2011년_이전_차입분_주택자금_장기주택저당차입금이자상환액_30년이상": "0"
                    },
                    "직원_object": {
                        "id": "f51b0e7dd4be40aeb61d108e6f0673c2",
                        "기본급여항목": {
                            "식대": "100000",
                            "차량유지비": "200000"
                        },
                        "사원코드": 3,
                        "성명": "이숙번",
                        "주민등록번호": "7902271011215",
                        "고용형태": "정규직",
                        "기본급": "5200000",
                        "부양가족수": 1,
                        "두루누리지원대상": false,
                        "입사일": "2018-10-15",
                        "퇴사일": null,
                        "중도퇴사연말정산지급일": null,
                        "퇴직소득지급일": null,
                        "거주자": "1",
                        "거주지국코드": null,
                        "국적코드": null,
                        "외국인단일세율적용": "2",
                        "외국법인소속파견근로자여부": "2",
                        "내외국인구분코드": "1",
                        "세대주여부": "2",
                        "중소기업취업감면시작월": null,
                        "중소기업취업감면종료월": null,
                        "중소기업취업감면적용": "",
                        "간이세액조정률": "1.000",
                        "건강보험대상금액": "0",
                        "건강보험료": "0",
                        "국민연금대상금액": "0",
                        "국민연금료": "0",
                        "고용보험대상금액": "0",
                        "고용보험료": "0",
                        "고용보험적용여부": true,
                        "산재보험적용여부": true,
                        "장기요양보험적용여부": true,
                        "생산직여부": false,
                        "국외근로적용여부": false,
                        "학자금상환공제자여부": false,
                        "학자금상환공제액": "0",
                        "created": "2019-03-20T10:09:02.950397+09:00",
                        "updated": "2019-04-08T17:06:58.899651+09:00",
                        "소속": "0e6a8ce9152c4adaa8784ccb5f5013ea"
                    },
                    "options": {
                        "국세청자료사용": [
                            "건강보험료"
                        ]
                    },
                    "부양가족s": [
                        {
                            "id": "7fe48ac85d5048cb979372644b65e1bf",
                            "관계설명": "소득자 본인",
                            "관계": "0",
                            "내외국인구분코드": "1",
                            "성명": "이숙번",
                            "주민등록번호": "7902271011215",
                            "부양여부": "1",
                            "장애인공제": "",
                            "부녀자공제": "",
                            "경로우대공제": "",
                            "한부모가족공제": "",
                            "출산입양공제": "",
                            "자녀6세이하공제": "",
                            "교육비공제": "",
                            "직원": "f51b0e7dd4be40aeb61d108e6f0673c2"
                        }
                    ],
                    "귀속년도": 2018,
                    "신고귀속연월": null,
                    "중도퇴사연말정산": false,
                    "총급여": "21451308",
                    "총지급액": "21451308",
                    "결정세액소득세": "168685",
                    "결정세액지방소득세": "16868",
                    "결정세액농특세": "0",
                    "기납부소득세": "2473770",
                    "기납부지방소득세": "247360",
                    "기납부농특세": "0",
                    "차감징수소득세": "-2305080",
                    "차감징수지방소득세": "-230490",
                    "차감징수농특세": "0",
                    "finalized": "2019-04-22T16:57:21+09:00",
                    "verified": null,
                    "직원": "f51b0e7dd4be40aeb61d108e6f0673c2"
                }
            }
        },
        mounted() {
            console.log(this.report.records[0].차감징수세액_소득세_abs)
            this.$set(this.report.records[0], '차감징수세액소득세', - this.report.records[0].차감징수세액_소득세_abs)
            console.log(this.report.records[0].차감징수세액소득세)
        }
    }
</script>

<style scoped>

</style>