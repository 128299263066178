<template>
    <div style="height: 0">
        <nav class="navbar navbar-light titlebar">
            <back-button v-if="!$route.query.required"></back-button>
            <span v-else></span>
            <h5 class="mt-2">
                홈택스 공동인증서 등록
            </h5>
            <span></span>
        </nav>
        <div class="alert alert-light mx-3 mb-3">
            <div>
                <strong>홈택스에 접속할 때 사용하는 공동인증서(구 공인인증서)</strong>를 등록하시면 인증서에 연결된
                <strong>사업자</strong>의 각종 세무회계 정보가 연동됩니다.
                <br>
                <span class="text-underline" v-b-modal.help-hometax-cert>
                    <i class="fas fa-info-circle"></i> 공동인증서 종류 안내
                </span>
            </div>
            <b-modal title="홈택스 공동인증서 종류 안내" ok-only ok-title="닫기" id="help-hometax-cert">
                <table class="table table-sm table-striped mb-4">
                    <thead>
                    <tr>
                        <th>사업자구분</th>
                        <th>인증서 종류</th>
                        <th>데이터 연동</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>법인</th>
                        <th>사업자용</th>
                        <td>홈택스, 법인 은행계좌</td>
                    </tr>
                    <tr>
                        <th>개인사업자</th>
                        <th>사업자용</th>
                        <td>홈택스(종합소득세 제외), 사업용 은행계좌</td>
                    </tr>
                    <tr>
                        <th>개인사업자</th>
                        <th>개인용</th>
                        <td>홈택스(종합소득세 포함)</td>
                    </tr>
                    </tbody>
                </table>
                <p>
                    키퍼의 기능을 이용하시려면 <strong>홈택스에 로그인할 수 있는 공동인증서</strong>가 필요합니다.
                    공동인증서를 등록하시면 홈택스에서 세금신고내역, 세금계산서, 신용카드 사용내역 등을 자동으로 불러올 수 있습니다.
                </p>
                <p><strong>법인</strong>은 <strong>사업자용 공동인증서</strong>만 등록하시면 원천세, 부가가치세, 법인세 등 주요 세금 정보를 모두 연동할 수 있고, 법인의 은행 계좌조회도 가능합니다.</p>
                <p>
                    <strong>개인사업자</strong>는 <strong>사업자용 공동인증서</strong>를 등록하시면 원천세, 부가가치세를 조회할 수 있고 해당 사업자의 거래내역, 은행 계좌조회도 가능합니다.
                    그러나, <span class="text-underline">종합소득세는 사업자가 아니라 대표자에게 부과되는 것</span>이기 때문에 사업자용 공동인증서로는 조회되지 않으며, 개인용 공동인증서가 필요합니다.
                    <strong>개인용 공동인증서</strong>가 있으면 종합소득세를 비롯한 홈택스의 모든 정보를 연동할 수 있고 여러 사업자를 운영하는 경우에도 모두 조회가 가능합니다.
                    그러나, 사업용 은행계좌 조회는 불가능합니다. 그러므로, 사업자용과 개인용 공동인증서 둘다 등록해두시면 가장 많은 정보를 조회할 수 있습니다.
                </p>
                <p>
                    만약에 홈택스에 공동인증서로 로그인한 적이 없으시면 먼저 <strong>홈택스에서 공동인증서를 등록</strong>하셔야 합니다.
                    키퍼를 사용하지 않으시더라도 홈택스는 세금 문제로 사용하셔야 하므로 등록해주시는 게 좋습니다.
                    다음과 같이 홈택스에 공동인증서를 등록할 수 있어요.
                </p>
                <ol>
                    <li><a href="https://hometax.go.kr/">홈택스</a>에 접속 <span class="">(모바일에서는 손택스 앱 사용)</span></li>
                    <li>공동인증센터 메뉴로 들어가기</li>
                    <li>등록 안내에 따라 내 공동인증서 등록</li>
                </ol>
            </b-modal>
        </div>
        <div class="m-3">
            <button class="btn btn-outline-info btn-block" v-b-modal.hometax-cert-register>
                <i class="fas fa-info-circle"></i> 홈택스에 로그인 하신 적이 없으신가요?
            </button>
            <b-modal title="홈택스 공동인증서 등록안내" centered ok-only ok-title="확인" id="hometax-cert-register">
                사업자는 홈택스에 공동인증서를 등록해두어야 세금신고 등의 업무를 홈택스로 할 수 있고, 키퍼도 홈택스에 등록된 공동인증서가 있어야
                사용 가능합니다. 다음과 같이 공동인증서를 등록해보세요.
                <ol>
                    <li v-if="isApp"><a class="text-underline" href="market://details?id=kr.go.nts.android&hl=ko">손택스</a> 어플리케이션 설치/실행</li>
                    <li v-else><a href="https://hometax.go.kr/">홈택스</a>에 접속 <span class="">(모바일에서는 손택스 앱 사용)</span></li>
                    <li>인증센터 -> 공인인증센터 메뉴로 들어가기</li>
                    <li>인증서 선택 후 등록하기 버튼을 눌러 등록</li>
                    <li>키퍼로 돌아와 인증서로 연동하기</li>
                </ol>
                <b-button variant="outline-primary" block v-if="isApp"><a href="market://details?id=kr.go.nts.android&hl=ko">손택스 어플리케이션 설치/실행하기</a></b-button>
                <b-button variant="outline-primary" block v-else><a href="https://hometax.go.kr" target="_blank">새 탭에서 홈택스열기</a></b-button>
            </b-modal>
        </div>
        <div class="card mx-3 mb-3">
            <div class="card-body">
                <div class="mb-1" v-if="isApp">
                    <template v-if="hasDeviceCert">
                        <p>
                            <span class="text-primary">공인인증서를 선택</span> 하신 후,
                            비밀번호를 입력하시고 <span class="text-primary">등록하기</span> 버튼을 눌러주세요.
                        </p>
                        <h6>기기에 저장된 공인인증서</h6>
                        <b-list-group>
                            <b-list-group-item v-for="cert in deviceCerts" :key="cert.subject" @click="selectedCert = cert">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div style="flex:1">
                                        <i class="text-primary fas fa-check-circle mr-2" v-if="selectedCert === cert"></i>
                                        <i class="text-primary far fa-circle mr-2" v-else></i>
                                    </div>
                                    <div style="flex: 9">
                                        <div>{{ parseSubjectDN(cert.subject) }}</div>
                                        <div>만료일 : {{ cert.until }}
                                            <template v-if="certStatus(cert, cert.until)">
                                                <span><i class="ml-1 fas fa-exclamation-circle" :class="`text-${certStatus(cert)}`"></i></span>
                                                <div class="text-warning" style="font-size: 0.8rem" v-if="certStatus(cert) === 'warning'">공인인증서 만료일이 1개월 이내입니다. 금융기관에서 재발급 후 다시 공인인증서를 추가해주세요.</div>
                                                <div class="text-danger" style="font-size: 0.8rem" v-else-if="certStatus(cert) === 'danger'">공인인증서가 만료되었습니다. 금융기관에서 재발급 후 다시 공인인증서를 추가해주세요.</div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </b-list-group-item>
                        </b-list-group>
                    </template>

                    <p v-else-if="!hasStoragePermission">
                        저장공간 접근이 허용되지 않았습니다.<br>
                        아래 버튼을 눌러 권한을 허용해주세요. <br><br>
                        권한 허용 버튼이 뜨지 않는 경우,<br>
                        <span class="text-primary">설정 -> 애플리케이션 -> 키퍼 -> 권한</span> 에서 저장공간을 허용하고 다시 앱으로 돌아와 시도해주세요.
                        <button class="btn btn-block btn-primary" @click="checkStoragePermission">권한 허용하기</button>
                    </p>

                    <template v-else>
                        <p class="">
                            <i class="fas fa-exclamation-triangle text-warning"></i> 기기의 공용저장소에서 공동인증서가 발견되지 않았습니다.
                        </p>
                        <p>
                            2021년 11월 이후 안드로이드의 보안정책이 강화되어서 이제 공동인증서를 공용저장소에 보관할 수 없게 되었습니다.
                            앞으로는 <strong>PC</strong>에서 공동인증서를 올려주시기 바랍니다.
                        </p>
                        <p><strong>PC</strong>에서도 <strong>키퍼 간편장부</strong>를 이용하실 수 있으며, 다음 주소로 접속하시면 됩니다.</p>
                        <div class="alert alert-success">
                            <a href="https://keeper.business">keeper.business</a>
                        </div>
                        <p>
                            혹은 네이버나 구글에서 <strong>키퍼 간편장부</strong>로 검색하셔도 접속하실 수 있어요.
                            PC에서 동일한 간편 로그인으로 로그인하신 후 인증서를 등록하시면 모바일앱에서도 같이 사용하실 수 있어요.
                        </p>
                    </template>
                </div>

                <template>
                    <div v-if="certFiles">
                        <h6>선택한 파일</h6>
                        <div class="bg-light p-1 text-monospace" v-for="f in certFiles" :key="f.name">{{ f.name }}</div>
                    </div>

                    <!-- 안드로이드에서 현재 파일 업로드가 안되기 때문에 이 기능을 사용할 수 없다. 파일 업로드 기능을 개발해서 넣은 다음에 다시 이 기능을 열어야 한다. -->
                    <p class="mt-2" v-if="!isApp">
                        <b-button block variant="outline-primary" @click="$refs.file.click()">
                            {{ certFiles ? '공동인증서 선택됨' : '공동인증서 파일 찾기' }}
                        </b-button>
                    </p>

                    <div class="mb-1">
                        <b-form-group class="mt-2" label="공인인증서 비밀번호" v-if="selectedCert || certFiles">
                            <b-form-input :type="showPassword ? 'text' : 'password'" placeholder="비밀번호를 입력하세요" v-model="certPassword"></b-form-input>
                            <b-checkbox class="mt-1" v-model="showPassword">비밀번호 보기</b-checkbox>
                        </b-form-group>
                        <button class="btn btn-block btn-primary" @click="uploadCert" v-if="selectedCert || certFiles">등록하기</button>
                    </div>

                    <div class="" v-if="!isApp">
                        다음과 같은 공동인증서 파일을 찾아서 올려주세요.
                        <ul class="pl-4">
                            <li><code>signCert.der</code>와 <code>signPri.key</code> <small class="">(<strong>NPKI</strong> 폴더)</small></li>
                            <li>
                                <code>*.pfx</code> 또는 <code>*.p12</code> <small class="">(인증서 PFX내보내기 파일)</small>
                            </li>
                        </ul>

                        <h6 class="text-underline" v-b-toggle.find-cert-in-pc>내 컴퓨터 <strong>NPKI</strong> 폴더에서 공동인증서 찾는 법 <collapse-indicator></collapse-indicator></h6>
                        <b-collapse id="find-cert-in-pc">
                            <ol>
                                <li>
                                    <code>NPKI</code> 폴더를 찾습니다. 윈도우에서는 탐색기를 열어서 주소란에 다음과 같이 입력하면 찾을 수 있어요.
                                    <div>
                                        <code class="bg-light p-1" ref="npkiPath">%UserProfile%\AppData\LocalLow\</code>
                                        <i class="fas fa-copy text-secondary ml-1" @click="copyToClipboard($refs.npkiPath, 'NPKI 폴더 주소가 복사되었습니다.')"></i>
                                    </div>
                                    <a class="text-underline" href="https://www.yessign.or.kr/test/win_ie/request/helpListUp.htm" target="_blank">
                                        NPKI 폴더 찾는 방법 자세히 보기 <i class="fas fa-external-link-alt"></i>
                                    </a>
                                </li>
                                <li>
                                    공동인증서를 발급한 인증기관 폴더로 들어간 후 <code>USER</code> 폴더로 들어갑니다.
                                    인증기관을 정확히 모를 경우 폴더를 다 열어서 <code>USER</code> 폴더를 확인해보세요.
                                </li>
                                <li>
                                    인증서가 <code>cn=[인증서명]</code> 같은 이름의 폴더로 되어 있고,
                                    그 안에 <code>signCert.der</code>와 <code>signPri.key</code> 파일이 있는데 이 파일들을 올리면 됩니다.
                                </li>
                            </ol>
                        </b-collapse>

                        <h6 class="text-underline" v-b-toggle.export-pfx>공동인증서를 PFX로 <strong>내보내기</strong>한 경우 <collapse-indicator></collapse-indicator></h6>
                        <b-collapse id="export-pfx">
                            은행이나 인증기관의 인증서 관리자에서 공동인증서를 <code>pfx</code> 파일로 내보내기를 해두었다면 그 파일을 그대로 올리셔도 됩니다.
                            (<a class="text-underline" href="https://www.crosscert.com/glca/01_3_044.jsp" target="_blank">한국전자인증의 인증서 관리자 열기</a>)
                        </b-collapse>

                        <h6 class="text-underline" v-b-toggle.other-device><strong>다른 기기</strong>에 공동인증서가 있는 경우 <collapse-indicator></collapse-indicator></h6>
                        <b-collapse id="other-device">
                            <p>키퍼 간편장부는 어떤 디바이스에서든 사용하실 수 있고, 간편하게 웹으로 접속해도 됩니다. 인증서가 있는 기기에서 다음 주소에 접속해서 인증서를 올려주세요. </p>
                            <div class="alert alert-success">
                                <a href="https://keeper.business">keeper.business</a>
                            </div>
                            <p>또는 다음과 같은 방법으로 인증서 파일을 보내서 올릴 수도 있습니다.</p>
                            <ul>
                                <li>
                                    <a href="https://drive.google.com">구글 드라이브</a>나 <a href="https://dropbox.com">드롭박스</a>,
                                    <a href="https://onedrive.live.com/">원드라이브</a> 등의 클라우드에 저장
                                </li>
                                <li>메일 첨부파일로 보낸 다음 폰에서 첨부파일을 다운로드하기</li>
                                <li>카톡 등의 메신저 앱으로 자신에게 파일을 보낸 다음 폰에서 다운로드하기</li>
                            </ul>
                        </b-collapse>
                    </div>
                </template>

                <input class="invisible" type="file" ref="file" multiple @change="certFiles = $refs.file.files"/>
            </div>
        </div>

        <div class="m-3">
            <b-button block class="mt-3" variant="outline-primary" @click="$router.push({name: '도움말', query: {openChat: true}, params: {transition: 'push'}})">
                <i class="fas fa-comments"></i> 상담원에게 물어보기
            </b-button>

            <b-button block class="mt-3" variant="outline-info" v-b-modal.cert-help-video>
                <i class="fab fa-youtube"></i> 도움 영상 보기
            </b-button>
            <b-modal id="cert-help-video" title="공동인증서 등록 안내" ok-only ok-title="닫기">
                <iframe class="w-100" style="height: 60vw" src="https://www.youtube.com/embed/6UAE7AAS-VU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </b-modal>

        </div>

        <br>

        <b-modal id="cert-alert" :title="alert.title" centered ok-only @ok="goNext">
            <div v-if="typeof(alert.errors) === 'string'">{{ alert.errors }}</div>
            <div v-for="(error, key) in alert.errors" :key="key" v-else>
                <div>{{ error }}</div>
            </div>
            <p v-if="alert.traders">{{ alert.traders.length }}개의 사업자가 연결되었습니다.</p>
            <ul class="">
                <li v-for="trader in alert.traders" :key="trader.id">
                    {{ trader.법인명_상호 }}
                </li>
            </ul>
        </b-modal>
        <b-modal id="finding-cert-file" title="공인인증서 파일찾기" centered ok-only ok-title="확인">
        </b-modal>
    </div>
</template>

<script>
    import {api, silentApi} from "../util/api";
    import {saveGlobal} from "../globals";
    import BackButton from "@/components/BackButton";
    import CollapseIndicator from "@/views/income-tax/CollapseIndicator";

    export default {
        name: "CertManager",
        components: {CollapseIndicator, BackButton},
        data: () => ({
            mode: 'normal',
            alert: {title: '', content: '', errors: []},
            hasStoragePermission: true,
            deviceCerts: [],
            selectedCert: null,
            certFiles: null,
            certPassword: null,
            showPassword: false,
        }),
        computed: {
            hasDeviceCert () {
                return this.deviceCerts && this.deviceCerts.length
            }
        },
        mounted () {
            if (this.isApp) {
                this.checkStoragePermission()
            }

            this.mode = this.$route.query.mode || 'normal'
        },
        destroyed () {
            clearInterval(this.permissionChecker)
        },
        methods: {
            fetchCertsFromDevice () {
                try {
                    this.deviceCerts = JSON.parse(window.Android.getCerts())
                } catch (e) {
                    this.deviceCerts = []
                }
                if (this.deviceCerts.length === 1) {
                    this.selectedCert = this.deviceCerts[0]
                }
            },
            checkStoragePermission () {
                if (!window.Android.hasStoragePermission || !window.Android.checkStoragePermission) {
                    // Interface없는 이전버전
                    this.fetchCertsFromDevice()
                    return true
                }

                this.hasStoragePermission = window.Android.hasStoragePermission()
                if (!this.hasStoragePermission) {
                    window.Android.checkStoragePermission()
                    clearInterval(this.permissionChecker)
                    this.permissionChecker = setInterval(() => {
                        this.hasStoragePermission = window.Android.hasStoragePermission()
                        if (this.hasStoragePermission) {
                            this.fetchCertsFromDevice()
                            clearInterval(this.permissionChecker)
                        }
                    }, 1000)
                    return
                }

                this.fetchCertsFromDevice()
            },
            async uploadCert (event) {
                if (this.isApp && !this.hasDeviceCert) {
                    return
                }
                if (!this.certPassword) {
                    this.alert = {title: '오류', errors: ['공인인증서 비밀번호를 입력해주세요.']}
                    this.$bvModal.show('cert-alert')
                    event.preventDefault()
                    return
                }

                this.trackEvent('cert_upload', {platform: this.globals.platform})
                this.globals.loadingMessage = '공인인증서 연동 중..'
                this.globals.loading = true
                setTimeout(() => this.startUpload(), 500)
            },
            async startUpload() {
                try {
                    let response = null
                    if (this.isApp) {  // 안드로이드인 경우만 해당
                        let cookies = Object.fromEntries(document.cookie.split(/; */).map(c => {
                            const [ key, ...v ] = c.split('=');
                            return [ key, decodeURIComponent(v.join('=')) ];
                        }));

                        if (!cookies.csrftoken) {
                            throw '공인인증서 전송에 실패했습니다. 앱 재실행 후 다시 시도해보세요.';
                        }

                        if (!this.selectedCert) {
                            event.preventDefault()
                            throw '공인인증서를 선택해주세요.'
                        }

                        if (this.certStatus(this.selectedCert, false).label === 'danger') {
                            event.preventDefault()
                            throw '만료되지 않은 공인인증서를 선택해주세요.'
                        }

                        if (window.Android.registerCert) {
                            response = JSON.parse(window.Android.registerCert(
                                this.selectedCert.path, this.certPassword, cookies.csrftoken, JSON.stringify({
                                    client_key: this.globals.user.id,
                                })))

                            if (!response.error) {
                                saveGlobal('user', response.data.user)
                                if (response.data.사업자_set.length) {
                                    // TODO refactor to use StatusBar.selectTrader
                                    saveGlobal('trader', this.globals.user.사업자_set.find(t => t.id == response.data.사업자_set[0].id))
                                }
                            }
                        } else {
                            throw '구글 플레이에서 새 버전으로 업데이트하세요.'
                        }
                        if (response.error) {
                            throw {
                                response: {
                                    data: JSON.parse(response.response.data)
                                }
                            }
                        }
                    } else {
                        if (!this.$refs.file.files.length) {
                            event.preventDefault()
                            throw '공인인증서 파일을 선택해주세요.'
                        }

                        let formData = new FormData()
                        formData.append('client_key', this.globals.user.id)
                        formData.append('password', this.certPassword)
                        for(let i = 0;i < this.$refs.file.files.length; i++){
                            let file = this.$refs.file.files[i];
                            formData.append('files', file)
                        }
                        response = await silentApi.post('user/공인인증서/', formData, {headers: {
                            'Content-Type': 'multipart/form-data'
                        }})

                        saveGlobal('user', response.data.user)
                        if (response.data.사업자_set.length) {
                            // TODO refactor to use StatusBar.selectTrader
                            saveGlobal('trader', this.globals.user.사업자_set.find(t => t.id == response.data.사업자_set[0].id))
                        }
                    }

                    if (response.data.공인인증서.valid) {
                        this.trackEvent('cert_verified')
                        this.alert = {title: '홈택스 공동인증서 확인 완료', traders: response.data.사업자_set, errors: []}
                        this.$bvModal.show('cert-alert')
                    } else {
                        this.$router.replace({name: '공인인증서 상세', params: {id: response.data.공인인증서.id}})
                    }
                } catch (error) {
                    this.alert = {title: '오류', errors: typeof(error) === 'string' ? [error] : [error.response.data]}
                    this.$bvModal.show('cert-alert')
                    return
                } finally {
                    this.globals.loadingMessage = null
                    this.globals.loading = false
                }
            },
            parseSubjectDN (dn) {
                let components = dn.split(',')
                let splitByEqual = components.map(c => c.split('='))
                let cn = splitByEqual.find(c => c[0].toLowerCase() === 'cn')
                return cn ? cn[1] : dn
            },
            goNext() {
                if (this.alert.errors.length > 0) {
                    return
                }
                if (this.$route.query.back) {
                    this.$router.replace({path: this.$route.query.back})
                } else if (this.alert.errors.length == 0) {
                    this.$router.replace({path: '/'})
                }
            },
            skipRegisterCert () {
                this.$router.push({name: '세금신고납부'})
                this.trackEvent('skip_register_certs')
            }
        }
    }
</script>

<style scoped>

</style>
