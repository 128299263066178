<template>
    <div class="h-100 d-flex flex-column bg-white">
        <nav class="navbar navbar-light titlebar pt-4">
            <back-button color="primary" ref="backbutton">뒤로</back-button>
        </nav>
        <div class="h-100 bg-white overflow-auto p-4">
            <div class="mb-4" style="font-size: 1.2em">
                은행 빠른조회 신청대행을 이용하기 위해<br>
                <span class="text-primary">동의</span>가 필요합니다.
            </div>
            <div class="mt-4" style="font-size: 0.9em">
                <hr>
                <ul class="pl-4">
                    <li>신청대행은 영업일 기준 1일 이내에 처리됩니다.</li>
                    <li>빠른조회용 ID가 필요한 경우 키퍼에서 생성하여 등록해드립니다.</li>
                    <li>고객님의 확인이 필요하거나, 연동이 완료되면 Push알림을 보내드립니다.</li>
                    <li>모든 과정은 설정 > 빠른조회신청대행에서 확인하실 수 있습니다.</li>
                    <li>대행 중 은행기관 로그인 중복이 발생할 수 있습니다. 로그인 중복 시 잠시 후 다시 시도해주세요.</li>
                </ul>
                <hr>
                공인인증서를 이용해 은행 빠른조회 신청을 대행하는 것에 동의하십니까?<br>
                <small class="text-primary">* 모든 과정은 등록된 정보만을 사용하여 안전하게 처리됩니다.</small>
                <div class="d-flex mt-3 flex-column">
                    <b-button variant="primary" class="flex-grow-1" @click="agree">네, 동의합니다.</b-button>
                    <b-button variant="outline-secondary" class="flex-grow-1 mt-3" @click="back">다음에 할게요.</b-button>
                </div>
            </div>
            <hr>
        </div>
    </div>
</template>

<script>
    import api from "@/util/api";
    import LoginRequired from "@/components/LoginRequired";
    import {saveGlobal} from "@/globals";
    import BackButton from "@/components/BackButton";

    export default {
        name: "Terms",
        components: {BackButton},
        mixins: [LoginRequired],
        mounted() {
            gtag('event', 'visit_register_delegate_terms')
        },
        methods: {
            async agree () {
                gtag('event', 'agree_register_delegate_terms')
                let user = await api.post(`/user/agree_register_delegate_terms/`)
                saveGlobal('user', user.data)
                this.$router.replace({path: '/'})
            },
            back () {
                gtag('event', 'decline_register_delegate_terms')
                this.$router.replace({path: '/'})
            }
        }
    }
</script>

<style scoped>

</style>
