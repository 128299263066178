import api from "@/util/api";

export default {
    name: "AppUpdateMixin",
    mounted () {
        if (window.Android) {
            const currentVersion = Android.getVersion()
            api.get(`app/android_version`).then(res => {
                const current = currentVersion.substring(0, 3)
                const update = res.data.substring(0, 3)
                console.log(currentVersion, res.data, current, update)
                if (update > current) {
                    Android.updateApp()
                }
            })
        }
    }
}
