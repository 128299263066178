import api from "@/util/api";
import querystring from "querystring";

export default {
    name: "ScrapingTaskPollMixin",
    data: () => ({
        refreshing: false,
        timer: null,
        latestTasks: {},
        pollCounter: null,
    }),
    beforeDestroy () {
        clearTimeout(this.timer)
    },
    methods: {
        refresh (...sources) {
            this.refreshing = true
            api.post(`robotax/scrapingtask/start/`, {
                사업자: this.globals.trader.robotax_id, 
                sources,
            }).then(res => {
                if (this._isDestroyed) { return }
                this.timer = setTimeout(() => this.poll(...sources), 1000)
            })
        },
        poll (...sources) {
            if (!this.pollCounter == null) {
                this.pollCounter = 0
            }
            api.get(`robotax/scrapingtask/latest/`, {
                params: {사업자: this.globals.trader.robotax_id, source: sources},
                paramsSerializer: querystring.stringify
            }).then(res => {
                this.latestTasks = res.data
                if (sources.every(source => res.data[source] && ['success', 'error', 'invalid'].includes(res.data[source].status))
                    || this.pollCounter > 20) {
                    clearTimeout(this.timer)
                    this.refreshing = false
                    this.pollCounter = null
                } else {
                    if (this._isDestroyed) { return }
                    this.timer = setTimeout(() => this.poll(...sources), 3000)
                }
            })
        }
    }
}
