<template>
    <router-link tag="tr" :to="{name: `${tax.세금} 신고서`, params: {id: tax.id, transition: 'push'}}" exact-active-class="table-active">
        <td>
            <div class="">
                <strong>{{ tax.title() }}</strong>
                <span class="ml-2 badge" :class="`badge-${tax.status.color}`" v-if="tax.status.color != 'muted'">
                    {{ tax.status.label }}
                </span>
            </div>
            <div>
                <small>{{ localDateFormat(tax.납기일) }}<span class="text-muted">까지 신고납부</span></small>
                <div><small class="text-danger" v-if="induceDelayed !== undefined && tax.status.label === '납기일 경과'">지금 기한 후 신고하고 가산세 줄여보세요!</small></div>
            </div>
        </td>
        <td class="text-right align-middle">
            <span class="text-nowrap" style="font-size: 1.1em">
                {{ moneyFormat(tax.amount()) }}
                <i class="fas fa-chevron-right text-muted"></i>
            </span>
        </td>
    </router-link>
</template>
<script>
    export default {
        name: 'TaxRow',
        props: {
            tax: {},
            induceDelayed: {}
        },
        computed: {
        }
    }
</script>
