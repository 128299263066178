<template>
    <div class="h-100 overflow-auto container-fluid" v-if="report">
        <div class="row">
            <div class="col-12 p-0">
                <div class="card m-3">
                    <div class="card-body">
                        <table class="table bg-white">
                            <tbody>
                                <tr>
                                    <th style="min-width: 80px">과세기간</th>
                                    <td class="text-right">{{ localDateFormat(report.과세기간시작일자) }} ~ {{ localDateFormat(report.과세기간종료일자, true) }}</td>
                                </tr>
                                <tr>
                                    <th v-if="canRefund">환급세액</th>
                                    <th v-else>납부세액</th>
                                    <td class="text-right text-primary">
                                        <div>
                                            <small class="text-muted" v-if="report.isNotSameAsHometax()">홈택스 신고</small>
                                            <strong style="font-size: 1.3rem">
                                                {{ moneyFormat(report.hometax ? report.hometax.data.금액 : report.records[1].차감납부할세액 || report.records[1].실차감납부할세액) }}
                                            </strong>
                                        </div>
                                        <div v-if="report.isNotSameAsHometax()">
                                            <small class="text-muted">키퍼의 계산</small>
                                            <strong style="font-size: 1.3rem">
                                                {{ moneyFormat(report.records[1].차감납부할세액 || report.records[1].실차감납부할세액) }}
                                            </strong>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>신고납부기한</th>
                                    <td class="text-right">
                                        <div><strong>{{ localDateFormat(report.납기일) }}</strong></div>
                                    </td>
                                </tr>
                                <tr v-if="report.hometax && report.hometax.data.납부서 && report.hometax.data.금액 != report.records[1].차감납부할세액">
                                    <td colspan="2" class="text-center">
                                        <p class="text-warning">홈택스에 신고된 내역이 현재 키퍼에서 계산한 내역과 다릅니다. <strong>납부해야 하는 경우 홈택스에 신고된 금액을 기준</strong>으로 하셔야 합니다.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="text-center">
                                        <div :class="`text-` + report.status.color">
                                            <strong>{{ report.status.description || report.status.label }}</strong>
                                        </div>

                                        <div class="mt-2" v-if="report.hometax && report.status.color == 'danger'">
                                            <b-button variant="outline-primary"
                                                      @click="$router.push({name: '납부안내', query: {id: report.hometax.id, type: '국세'}})">
                                                납부안내
                                            </b-button>
                                        </div>
                                        <div class="mt-2" v-if="report.finalized && (report.status.color == 'danger' || report.status.color == 'warning')">
                                            <b-button variant="outline-primary"
                                                      @click="startScraping('전자신고결과조회', '납부내역')"
                                                      :disabled="refreshing">
                                                <span v-if="!refreshing">홈택스 신고납부현황 다시 확인하기</span>
                                                <span v-else>홈택스 신고납부현황 다시 확인 중...</span>
                                            </b-button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div class="card-footer border-top text-center" v-if="report.hometax">
        <!--                    <b-button variant="dark" class="mr-1" @click="report.loaddata()">-->
        <!--                        <i class="fas fa-refresh"></i>-->
        <!--                        다시 계산하기-->
        <!--                    </b-button>-->
                        <router-link class="d-block text-underline"
                                     :to="{name: '세금신고납부 상세', params: {id: report.hometax.id, transition: 'push'}}">
                            홈택스에 실제로 신고한 내역 보기 <i class="fas fa-chevron-right"></i>
                        </router-link>
        <!--                    <router-link class="btn btn-primary"-->
        <!--                                 :to="{name: '납부안내', query: {-->
        <!--                                     title: report.hometax.title,-->
        <!--                                     accountNo: report.hometax.data.납부서[0].전자납부번호,-->
        <!--                                     amount: report.hometax.data.납부서[0].총납부금액, type: '국세'}}"-->
        <!--                                 v-if="report.hometax && report.status.color == 'danger'">-->
        <!--                        납부하기-->
        <!--                        <i class="fas fa-chevron-right"></i>-->
        <!--                    </router-link>-->
                    </div>
                </div>
                <div class="m-3 card" v-if="!report.hometax && moment().isBefore(moment(report.납기일, 'YYYY-MM-DD').set('day', 15), 'day')">
                    <div class="card-body">
                        <p class=""><i class="fas fa-exclamation-triangle text-warning"></i> 카드매입 자료는 홈택스에 매월 15일 업데이트 되기 때문에 15일이 지나면 더 정확한 예상세액을 확인하실 수 있습니다.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 p-0">
                <div class="card mx-3 mb-3">
                    <div class="card-header">
                        신고서 내역
                    </div>
                    <div class="card-body p-0">
                        <TaxReportBrowser resource-name="부가가치세" :id="report.id"></TaxReportBrowser>
                        <br>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Decimal from "decimal.js";
    import TaxReportBrowser from "@/components/TaxReportBrowser";
    import AccountMixin from "@/util/AccountMixin";

    export default {
        name: "VatReport",
        components: {TaxReportBrowser},
        mixins: [AccountMixin],
        props: ['id'],
        watch: {
            id() {
                this.reload()
            }
        },
        data() {
            return {
                report: null,
            }
        },
        computed: {
            refreshing() {
                return ['전자신고결과조회', '납부내역'].some(source => {
                    return this.scrapingSources[source] && (this.scrapingSources[source].status == 'running' || this.scrapingSources[source].status == 'ready')
                })
            },
            canRefund: function() {
                return new Decimal(this.report.records[1].차감납부할세액 || 0).isNegative()
            },
        },
        async mounted() {
            await this.reload()
        },
        methods: {
            scrapingUpdated() {
                delete this.globals.부가가치세.cache[this.id + '/']
                this.reload()
            },
            async reload () {
                // TODO refactor 코드 관리
                await this.globals.codes.getObject('공제감면코드')
                await this.globals.codes.getObject('부가가치세_가산세코드')

                this.report = (await this.globals.부가가치세.get(this.id + '/')).object
                await this.report.loadHometax()

                // TODO 테스트 계정 - 신고하기 활성화 
                if (this.globals.user.username == 'test_payment') {
                    this.report.status = true
                }
            },
            toggle: function(record) {
                this.$set(record, '$expanded', !record.$expanded)
            },
            getRecord: function(name) {
                return this.report.records.filter(r => r.서식명 == name)[0]
            }
        }
    }
</script>

<style scoped>

</style>
