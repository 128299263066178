<template>
    <div class="h-100 d-flex flex-column">
        <nav class="navbar navbar-light titlebar">
            <back-button :to="{name: '자료수집'}"></back-button>
            <h5 class="mt-2">유튜브 매출</h5>
            <span class="text-underline" v-b-toggle.help-유튜브>
                <i class="fas fa-info-circle"></i> 도움말
            </span>
        </nav>
        <div class="container-fluid h-100 overflow-auto">
            <b-collapse class="mt-1 alert alert-light" id="help-유튜브" title="유튜브 안내" ok-only ok-title="닫기" :visible="!account">
                <p>
                    <strong>유튜브</strong> 계정을 연동하면 유튜브을 통해서 발생하는 매출도 간편장부에서 건별로 정확하게
                    전표처리하여 손익계산서를 정확하게 만들 수 있고, 부가가치세 등의 세금신고도 정확하게 할 수 있습니다.
                </p>
                <p>등록하신 계정 정보에 오류가 있는 경우 연결해제를 하고 다시 등록해보세요.</p>
            </b-collapse>
            <scraping-account-panel source="유튜브" :account="account"/>

            <div class="row">
                <div class="col-md-6 p-0">
                    <div class="card m-3">
                        <div class="card-header">
                            <period-selector initial-unit="year" :week-offset="1" @change="reload"></period-selector>
                        </div>
                        <div class="card-body">
                            <div>
                                <canvas id="chart-baemin" style="width: 100%;"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 p-0">
                    <div class="card m-3" v-if="summary && period">
                        <div class="card-header">
                            {{ period.unit === 'year' ? '월' : '일' }}별 매출
                        </div>
                        <div class="card-body">
                            <table class="table">
                                <thead>
                                <tr class="text-center">
                                    <th>날짜</th>
                                    <th>매출합계</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr exact-active-class="table-active" v-for="sale in summary" :key="sale.거래일">
                                    <td v-if="period.unit === 'year'">{{ sale.거래일__month }}월</td>
                                    <td v-else>{{ `${localDateFormat(sale.거래일, true)}(${moment(sale.거래일).format("dd")})` }}</td>
                                    <td class="text-right">{{ moneyFormat(sale.합계__sum) }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BackButton from "@/components/BackButton";
    import api from "@/util/api";
    import ScrapingTaskPollMixin from "@/util/ScrapingTaskPollMixin";
    import Chart from "chart.js"
    import moment from "moment"
    import {getCssVar, humanizeNumberScale, range} from "@/util/vue-commons";
    import PeriodSelector from "@/components/PeriodSelector";
    import querystring from "querystring";
    import AccountMixin from "@/util/AccountMixin";
    import ScrapingAccountPanel from "@/views/connect/ScrapingAccountPanel";

    export default {
        name: "YoutubeReport",
        components: {ScrapingAccountPanel, PeriodSelector, BackButton},
        mixins: [ScrapingTaskPollMixin, AccountMixin],
        data() {
            return {
                summary: [],
                deposit: {},
                chart: null,
                period: null,
            }
        },
        watch: {
            latestTasks () {
                this.reload(this.period)
            },
        },
        computed: {
            account() {
                return this.accountTypes.유튜브
            },
            points: function () {
                if (!this.period) {
                    return []
                }
                if (this.period.unit == 'week') {
                    let dates = range(moment.min(moment(), this.period.end).diff(this.period.begin, 'days') + 1).map(n => moment(this.period.begin).add(n, 'days'))
                    return dates.map(d => { return {
                        value: d.format('YYYY-MM-DD'),
                        label: `${d.format('M-DD')}(${d.format("dd")})`
                    }})
                } else if (this.period.unit == 'month') {
                    let month = moment().add(this.period.step, 'months');
                    let dates = range(month.daysInMonth()).map(n => month.date(n + 1).format('YYYY-MM-DD'))
                    return dates.map(d => { return {
                        value: d,
                        label: this.localDateFormat(d, true)
                    }})
                } else if (this.period.unit == 'year') {
                    let dates = range(moment().add(this.period.step, 'years').month() + 1).map(n => n + 1)
                    return dates.map(d => { return {value: d, label: `${d}월`} })
                }
                return []
            },
        },
        mounted () {
            this.pollAccounts()
            this.pollScraping('유튜브')
        },
        methods: {
            accountsUpdated() {
                setTimeout(() => this.pollScraping('유튜브'), 1000)
            },
            async scrapingUpdated() {
                if (this.period) {
                    await this.reload(this.period)
                }
            },
            async reload(period) {
                // TODO 기간별 최고매출 메뉴, 입금 및 정산내역
                // Called by period-selector
                this.period = period
                const params = this.queryParams('거래일', ['합계'], ['id'])
                this.summary = (await api.get('robotax/결제대행/annotate/', {
                    params,
                    paramsSerializer: querystring.stringify
                })).data

                if (this.chart) {
                    this.chart.destroy()
                }
                this.chart = new Chart('chart-baemin', {
                    type: 'bar',
                    data: {
                        labels: this.points.map(p => p.label),
                        datasets: [
                            {
                                type: 'bar',
                                label: '매출',
                                yAxisID: '매출',
                                borderColor: getCssVar('success'),
                                backgroundColor: getCssVar('success'),
                                data: this.points.map(p => this.summary.find(s => s[params.groupby_fields] == p.value) || {합계: 0}).map(e => e.합계__sum)
                            },
                        ],
                    },
                    options: {
                        legend: {
                            position: 'top'
                        },
                        scales: {
                            yAxes: [
                                {
                                    id: '건수',
                                    display: true,
                                    labelString: '건수',
                                    ticks: {
                                        beginAtZero: true,
                                        callback: function (value, index, values) {
                                            return humanizeNumberScale(parseInt(value))
                                        }
                                    }
                                },
                                {
                                    id: '매출',
                                    display: true,
                                    position: 'right',
                                    ticks: {
                                        beginAtZero: true,
                                        fontColor: getCssVar('primary'),
                                        callback: function (value, index, values) {
                                            return humanizeNumberScale(value)
                                        }
                                    }

                                },
                            ]
                        },
                    }
                })

                this.$set(this.deposit, '입금예정', (await api.get('robotax/정산/', {
                    params: {
                        type: '유튜브',
                        사업자: this.globals.trader.robotax_id,
                        status__in: '입금요청, 입금예정'
                    }
                })).data.results)
                this.$set(this.deposit, '입금완료', (await api.get('robotax/정산/annotate/', {
                    params: this.queryParams('입금예정일', ['거래금액', '차감금액', '조정금액', '입금액'], [], {type: '유튜브', status: '입금완료'}),
                    paramsSerializer: querystring.stringify
                })).data)
            },
            queryParams(groupby_field, sum_fields, count_fields, filter={}) {
                let params = {
                    사업자: this.globals.trader.robotax_id,
                    type: '유튜브',
                    groupby_fields: this.period.unit == 'year' ? groupby_field + '__month' : groupby_field,
                    sum_fields,
                    count_fields,
                    ordering: this.period.unit == 'year' ? `-${groupby_field}__month` : `-${groupby_field}`,
                    ...filter
                }
                params[groupby_field + '__gte'] = this.period.begin.format('YYYY-MM-DD')
                params[groupby_field + '__lte'] = this.period.end.format('YYYY-MM-DD')
                return params
            },
        }
    }
</script>

