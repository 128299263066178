<template>
    <div class="">
        <div class="text-center m-3" v-if="show">
            <router-link :to="{name: '정식버전 결제', query: {back: $route.fullPath}}" class="btn btn-block btn-light btn-danger" v-if="globals.trader.plan == '체험기간'">
                <span>체험기간입니다.</span>
                &nbsp;
                <span style="font-weight: normal">
                    ({{ humanizeDate(moment(globals.trader.expiry).startOf('day')) }} 만료)
                    <b-badge :variant="unusedCouponsCount ? 'success' : 'secondary'" v-if="couponCount">쿠폰 {{unusedCouponsCount}}장</b-badge>
                    <b-badge variant="success" v-else>무료쿠폰 받기</b-badge>
                </span>
                <i class="fas fa-chevron-right ml-2"></i>
            </router-link>
            <router-link :to="{name: '정식버전 결제', query: {back: $route.fullPath}}" class="btn btn-block btn-danger" v-if="globals.trader.plan == '사용기간_만료'">
                <span>사용기간이 만료되었습니다.</span>
                &nbsp;
                <span style="font-weight: normal">({{ humanizeDate(globals.trader.expiry) }} 만료)</span>
                &nbsp;
                <i class="fas fa-chevron-right"></i>
                <br>
                <b-badge variant="primary">무료쿠폰으로 정식버전을 이용해보세요!</b-badge>
            </router-link>
        </div>
        <div class="m-3" v-else-if="globals.trader.폐업일">
            <b-button variant="light" block>폐업한 사업자 입니다.</b-button>
        </div>
    </div>
</template>
<script>
    import api from "@/util/api";
    import {Coupon} from "@/models";

    export default {
        name: 'FullVersionBanner',
        data() {
            return {
                couponCount: 0,
                unusedCouponsCount: 0
            }
        },
        computed: {
            show() {
                return !this.globals.trader.폐업일 && this.globals.trader.사업자구분 != '개인' && this.globals.trader.plan != '정식버전'
            },
        },
        async mounted () {
            let coupons = await Coupon.objects()
            this.couponCount = coupons.length
            this.unusedCouponsCount = coupons.filter(c => !c.redeemed).length
        }
    }
</script>
