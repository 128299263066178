<template>
    <div class="h-100 d-flex flex-column">
        <nav class="navbar navbar-light titlebar">
            <back-button></back-button>
            <h5 class="mt-2">
                포인트 적립/사용내역
            </h5>
            <span></span>
        </nav>
        <div class="bg-white" style="overflow-y: auto"
             v-infinite-scroll="loadNext"
             infinite-scroll-distance="30">
            <table class="table" v-if="paginator">
                <tbody>
                    <tr v-for="point in paginator.objects" :key="point.id">
                        <td class="d-flex justify-content-between align-items-center">
                            <div>
                                <div class="text-muted">{{ dateFormat(point.created) }}</div>
                                <div>{{ reasonMap[point.reason] || point.reason }}</div>
                            </div>
                            <div>{{ point.amount > 0 ? '+' : '-' }} {{ point.amount }}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>


<script>
    import BackButton from "@/components/BackButton";
    import Paginator from "@/util/paginator";
    import infiniteScroll from 'vue-infinite-scroll'
    import api from "@/util/api";

    export default {
        name: "Point",
        components: { BackButton },
        directives: { infiniteScroll },
        data () {
            return {
                paginator: null,
                reasonMap: {
                    'daily-visit': '매일 방문 포인트'
                }
            }
        },
        mounted () {
            if (!this.globals.user) {
                this.$router.back()
            }

            this.globals.loading = true
            this.paginator = new Paginator(api.get, `point/?user=${this.globals.user.id}`)
            this.paginator.loadNext(() => {
                this.globals.loading = false
            })
        },
        methods: {
            loadNext () {
                this.paginator.loadNext()
            },
        }
    }
</script>
