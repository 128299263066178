<template>
    <div class="h-100 overflow-auto">
        <tutorial v-if="showTutorial"></tutorial>
        <status-bar v-if="globals.smallScreen"></status-bar>
        <induce-cert v-if="!globals.trader"></induce-cert>
        <template v-else>

            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6 p-0">

                        <full-version-banner/>
                        <div class="card m-3" v-if="globals.trader.사업자구분 == '개인'">
                            <div class="card-header border-bottom">
                                내 사업자
                            </div>
                            <div class="card-body px-0">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>사업자명</th>
                                            <th class="text-right">소득금액</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="trader in globals.robotaxTrader.ownTraders.filter(t => !t.폐업일)" :key="trader.id">
                                            <td>{{ trader.법인명_상호 }}</td>
                                            <td class="text-right" v-if="종합소득세now">
                                                {{ moneyFormat((종합소득세now.records.filter(r => r.서식명 == 'A6_종합소득세_사업소득명세서' && r.사업자등록번호 == trader.사업자등록번호)[0] || {}).소득금액) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <template v-if="globals.trader.사업자구분 != '개인'">

                            <div class="card m-3">
                                <div class="card-body p-0">
                                    <table class="table" style="font-size: large">
                                        <tbody v-if="!총수입금액및필요경비명세서">
                                            <tr><th colspan="3" class="text-center"><b-spinner variant="primary"></b-spinner></th></tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr @click="$router.push({name: '손익', params: {transition: 'push'}})">
                                                <th colspan="2">{{ moment().year() }}년 예상 손익</th>
                                                <td class="text-right d-flex justify-content-end align-items-center"
                                                    :class="decimal(총수입금액및필요경비명세서.장부상수입금액_합계) - decimal(총수입금액및필요경비명세서.매출원가) - decimal(총수입금액및필요경비명세서.일반관리비등계) > 0 ? 'text-primary' : 'text-danger'" >
                                                    {{ moneyFormat(decimal(총수입금액및필요경비명세서.장부상수입금액_합계) - decimal(총수입금액및필요경비명세서.매출원가) - decimal(총수입금액및필요경비명세서.일반관리비등계)) }}
                                                    <i class="fas fa-chevron-right text-secondary ml-1"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="card m-3">
                                <div class="card-header">
                                    <period-selector :initial-unit="globals.period.unit || 'month'" :initial-step="globals.period.step" @change="setPeriod($event)"></period-selector>
                                </div>
                                <div class="card-body px-0">
                                    <table class="table">
                                        <tbody  v-for="매출매입 in ['매출', '매입']" :key="매출매입">
                                            <router-link :to="{name: '증빙종류별 거래', query: {push: true, 매출매입: 매출매입, ...거래일Filter}}"
                                                         tag="tr"
                                                         exact-active-class="table-active"
                                                         :replace="!globals.smallScreen">
                                                <th colspan="2">{{ 매출매입 }}</th>
                                                <th class="text-right">
                                                    {{ moneyFormat(summary.filter(s => s.매출매입 == 매출매입).map(s => s.공급가액__sum).reduce((acc, value) => acc + value, 0))  }}
                                                    <i class="fas fa-chevron-right text-secondary"></i>
                                                </th>
                                            </router-link>
                                            <router-link :to="{name: '증빙종류별 거래', query: {push: true, 증빙종류: item.증빙종류, 매출매입: item.매출매입, ...거래일Filter}}"
                                                         tag="tr"
                                                         exact-active-class="table-active"
                                                         v-for="item in summary.filter(s => s.매출매입 == 매출매입)"
                                                         :key="item.증빙종류 + item.매출매입 + item.은행입출금"
                                                         :replace="!globals.smallScreen">
                                                <td>
                                                    {{ item.증빙종류 }}
                                                </td>
                                                <td class="text-right">
                                                    <span class="badge badge-light" v-if="isNew(item.거래일__max)"><i class="far fa-bell"></i></span>
                                                    {{ item.id__count}}건
                                                </td>
                                                <td class="text-right">{{ moneyFormat(item.공급가액__sum) }} <i class="fas fa-chevron-right text-secondary"></i></td>
                                            </router-link>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <th colspan="2">은행 입출금</th>
                                                <td class="text-right pr-4">
                                                    <strong v-if="summary.filter(s => s.은행입출금).length">
                                                        {{ moneyFormat(decimal(summary.filter(e => e.은행입출금 == '입금').reduce((s, e) => s + e.공급가액__sum, 0)) - decimal(summary.filter(e => e.은행입출금 == '출금').reduce((s, e) => s + e.공급가액__sum, 0))) }}
                                                    </strong>
                                                    <span v-else>없음</span>
                                                </td>
                                            </tr>
                                            <router-link :to="{name: '증빙종류별 거래', query: {push: true, 증빙종류: item.증빙종류, 은행입출금: item.은행입출금, ...거래일Filter}}"
                                                         tag="tr"
                                                         exact-active-class="table-active"
                                                         v-for="item in summary.filter(s => s.은행입출금)"
                                                         :key="item.증빙종류 + item.매출매입 + item.은행입출금"
                                                         :replace="!globals.smallScreen">
                                                <td>
                                                    {{ item.매출매입 }} {{ item.은행입출금 }}
                                                </td>
                                                <td class="text-right">
                                                    <span class="badge badge-light" v-if="isNew(item.거래일__max)"><i class="far fa-bell"></i></span>
                                                    {{ item.id__count}}건
                                                </td>
                                                <td class="text-right">{{ moneyFormat(item.공급가액__sum) }} <i class="fas fa-chevron-right text-secondary"></i></td>
                                            </router-link>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </template>

                        <div class="m-3" v-if="is임대업">
                            <b-button block variant="light"
                                      class="text-center"
                                      @click="$router.push({name: '부동산임대', params: {transition: 'push'}})">
                                <i class="fas fa-home"></i>
                                임대사업자 정보
                                <i class="fas fa-chevron-right"></i>
                            </b-button>
                        </div>

                        <div class="card m-3" v-if="globals.trader.plan != '사용기간_만료'">
                            <div class="card-body px-0">
                                <table class="table">
                                    <tbody>
                                        <router-link :to="{name: '은행계좌'}" tag="tr" v-if="validBankAccounts.length">
                                            <th>은행 잔고</th>
                                            <td class="text-right text-nowrap"><small>{{ localDateFormat(bankDate, true) }}</small></td>
                                            <td class="text-right text-nowrap pl-0">
                                                <strong>{{ moneyFormat(bankBalance) }}</strong>
                                                <i class="fas fa-chevron-right text-muted"></i>
                                            </td>
                                        </router-link>
                                        <tr v-b-modal:link-personal-bank-account-help v-else-if="ownerBankAccounts.length">
                                            <th>은행 잔고</th>
                                            <td colspan="2" class="text-right">
                                                <b-button variant="link" size="sm">개인용 계좌(<span>{{ ownerBankAccounts.length }}개</span>)를 사업용으로 할당하기</b-button>
                                                <b-modal id="link-personal-bank-account-help" ok-only ok-title="닫기">
                                                    <p>
                                                        개인용 공동인증서로 연동된 계좌가 <strong>{{ ownerBankAccounts.length }}개</strong> 있습니다.
                                                        이 중에 <strong>{{ globals.trader.법인명_상호 }}</strong>의 사업용으로 쓰는 계좌가 있으면 선택해주세요.
                                                    </p>
                                                    <b-list-group>
                                                        <b-list-group-item v-for="account in ownerBankAccounts" :key="account.id" @click="transferAccount(account)">
                                                            {{ account.bank_name }} {{ account.account_no }}
                                                        </b-list-group-item>
                                                    </b-list-group>
                                                </b-modal>
                                            </td>
                                        </tr>
                                        <router-link :to="{name: '은행계좌'}" tag="tr"  v-else>
                                            <th>은행 잔고</th>
                                            <td colspan="2" class="text-right">
                                                <b-button variant="link" size="sm">
                                                    은행계좌 연동하기 <i class="fas fa-chevron-right"></i>
                                                </b-button>
                                            </td>
                                        </router-link>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="card mx-3" v-if="globals.trader.plan != '사용기간_만료' && globals.trader.사업자구분 != '개인'">
                            <div class="card-body px-0">
                                <table class="table">
                                    <tbody>
                                        <router-link :to="{name: '카드매출내역'}" tag="tr" v-if="accountTypes.여신금융협회 && accountTypes.여신금융협회.valid">
                                            <th>어제 카드매출</th>
                                            <td class="text-muted px-0">{{ cardSalesYesterday.건수 || 0 }}건</td>
                                            <td class="text-right pl-0">
                                                {{ moneyFormat(cardSalesYesterday.승인금액) }}
                                                <i class="fas fa-chevron-right text-muted"></i>
                                            </td>
                                        </router-link>
                                        <router-link :to="{name: '카드매출집계'}" tag="tr" v-else-if="cardSalesLastMonth.length">
                                            <th>지난 달 카드매출</th>
                                            <td class="text-muted px-0">{{ cardSalesLastMonth[0].거래건수 || 0 }}건</td>
                                            <td class="text-right pl-0">
                                                {{ moneyFormat(cardSalesLastMonth[0].합계금액) }}
                                                <i class="fas fa-chevron-right text-muted"></i>
                                            </td>
                                        </router-link>
                                        <router-link :to="{name: '카드매출내역'}" tag="tr" v-else>
                                            <th>어제 카드매출</th>
                                            <td colspan="2" class="text-right">
                                                <b-button variant="link" size="sm">
                                                    여신금융협회 설정
                                                    <i class="fas fa-chevron-right"></i>
                                                </b-button>
                                            </td>
                                        </router-link>
                                        <template v-if="accountTypes.배달의민족 && accountTypes.배달의민족.valid">
                                            <router-link :to="{name: '배달의민족매출집계'}" tag="tr">
                                                <th>어제 배달의민족 매출</th>
                                                <td class="text-muted px-0">{{ numberFormat(baeminSalesYesterday.건수 || 0) }}건</td>
                                                <td class="text-right pl-0">
                                                    {{ moneyFormat(baeminSalesYesterday.합계 || 0 ) }}
                                                    <i class="fas fa-chevron-right text-muted"></i>
                                                </td>
                                            </router-link>
                                            <router-link :to="{name: '배달의민족매출집계'}" tag="tr" v-if="baeminDeposit && baeminDeposit.입금액__sum">
                                                <th>배달의민족 입금예정</th>
                                                <td class="text-right pl-0" colspan="3">
                                                    {{ moneyFormat(baeminDeposit.입금액__sum || 0) }}
                                                    <i class="fas fa-chevron-right text-muted"></i>
                                                </td>
                                            </router-link>
                                        </template>
                                        <template v-else-if="this.globals.trader.업태.includes('음식')">
                                           <router-link :to="{name: '배달의민족매출집계'}" tag="tr">
                                                <th>어제 배달의민족 매출</th>
                                                <td colspan="2" class="text-right">
                                                    <b-button variant="link" size="sm">
                                                        배달의민족 연동하기
                                                        <i class="fas fa-chevron-right"></i>
                                                    </b-button>
                                                </td>
                                            </router-link>
                                        </template>
                                        <router-link :to="{name: '배달의민족매출집계'}" tag="tr" v-if="baeminDeposit && baeminDeposit.입금액__sum">
                                            <th>배달의민족 입금예정</th>
                                            <td class="text-right pl-0" colspan="3">
                                                {{ moneyFormat(baeminDeposit.입금액__sum || 0) }}
                                                <i class="fas fa-chevron-right text-muted"></i>
                                            </td>
                                        </router-link>
                                        <router-link :to="{name: '스마트스토어'}" tag="tr" v-if="smartstoreDeposit.length">
                                            <th>{{ smartstoreDeposit.length == 2 ? '오늘' : '어제' }} 스마트스토어 입금액</th>
                                            <td class="text-right pl-0" colspan="3">
                                                {{ moneyFormat(smartstoreDeposit[0].입금액__sum) }}
                                                <i class="fas fa-chevron-right text-muted"></i>
                                            </td>
                                        </router-link>
                                        <tr>
                                            <th>어제 현금영수증</th>
                                            <td class="text-muted px-0">{{ cashSales.건수 || 0 }}건</td>
                                            <td class="text-right pl-0">
                                                {{ moneyFormat(cashSales.총금액) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 p-0" v-if="globals.trader.plan != '사용기간_만료'">
                        <div class="card m-3">
                            <template v-if="globals.trader.사업자구분 != '개인' && globals.robotaxTrader.면세구분 != '면세'">
                                <div class="card-header border-bottom">
                                    <div class="float-right">
                                        <router-link class="" :to="{name: '세금 내역', params: {type: 'vat'}}">전체보기</router-link>
                                    </div>
                                    부가가치세 예상
                                </div>
                                <div class="card-body px-0 text-center" v-if="!부가가치세now">
                                    <b-spinner variant="primary"></b-spinner>
                                </div>
                                <div class="card-body px-0" v-else>
                                    <table class="table">
                                        <tbody>
                                            <tr v-if="부가가치세예정고지" v-b-modal.vat-prepay-help>
                                                <td>
                                                    <div class="">
                                                        <strong>
                                                            {{ 부가가치세예정고지.display }} 예정고지
                                                            <span v-if="부가가치세예정고지.예정고지.예상세액">(예상)</span>
                                                        </strong>
<!--                                                        <span class="ml-2 badge" :class="`badge-${tax.status.color}`" v-if="tax.status.color != 'muted'">-->
<!--                                                            {{ tax.status.label }}-->
<!--                                                        </span>-->
                                                    </div>
                                                    <div>
                                                        <small>{{ localDateFormat(부가가치세예정고지.begin) }}<span class="text-muted">까지 납부</span></small>
<!--                                                        <div><small class="text-danger" v-if="induceDelayed !== undefined && tax.status.label === '납기일 경과'">지금 기한 후 신고하고 가산세 줄여보세요!</small></div>-->
                                                    </div>
                                                    <b-modal id="vat-prepay-help" title="부가가치세 예정고지 안내" ok-title="닫기">
                                                        <p>
                                                            개인사업자나 소규모 법인은 부가가치세 예정신고를 하지 않아도 됩니다.
                                                            그 대신 국세청에서 지난 반기의 부가가치세 납부세액의 절반을 예정고지세액으로 고지해주는데
                                                            이렇게 고지를 받으면 고지서에 따라 납부하시면 됩니다.
                                                        </p>
                                                        <p>
                                                            국세청에서 예정고지를 실제로 받기 전까지는 키퍼에서 예상하는 예정고지세액을 표시해드리며, 예정고지를 받고 나면
                                                            아래의 <strong>최근 세금 신고/고지</strong> 영역에 표시됩니다. 해당 고지내역을 눌러서 납부 안내를 받으실 수 있습니다.
                                                        </p>
                                                        <p>예정고지세액이 0으로 표시되는 경우는 납부하실 필요가 없으며, 예정고지 예상금액이 나오더라도 실제로 고지를 받기 전까지는 납부하시지 않아도 됩니다.</p>
                                                    </b-modal>
                                                </td>
                                                <td class="text-right align-middle">
                                                    <span class="text-nowrap" style="font-size: 1.1em">
                                                        {{ moneyFormat(부가가치세예정고지.예정고지.고지세액 || 부가가치세예정고지.예정고지.예상세액) }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <TaxRow :tax="부가가치세previous" induce-delayed v-if="부가가치세previous && 부가가치세previous.needsAttention"/>
                                            <TaxRow :tax="부가가치세now" v-if="부가가치세now"/>
                                        </tbody>
                                    </table>
                                </div>
                            </template>
                            <template v-if="globals.trader.사업자구분 != '개인' && globals.robotaxTrader.면세구분 != '면세' && 부가가치세error">
                                <div class="card-header border-bottom">
                                    부가가치세 예상
                                </div>
                                <div class="card-body px-0">
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {{ 부가가치세error.msg }}
                                                    <b-button class="mt-2" variant="primary" block
                                                              @click="$router.push({name: '도움말', query: {openChat: true}})"
                                                              v-if="부가가치세error.openChat">
                                                        채팅상담하기
                                                    </b-button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </template>
                            <template v-if="globals.trader.사업자구분 != '개인' && 사업장현황신고previous">
                                <div class="card-header border-bottom">
                                    사업장현황신고
                                    <b-badge variant="light" class="ml-1">Beta</b-badge>
                                </div>
                                <div class="card-body px-0">
                                    <table class="table">
                                        <tbody>
                                            <TaxRow :tax="사업장현황신고previous" v-if="사업장현황신고previous"/>
                                        </tbody>
                                    </table>
                                </div>
                            </template>

                            <template v-if="종합소득세previous">
                                <div class="card-header border-top border-bottom">
                                    <div class="float-right">
                                        <router-link class="" :to="{name: '세금 내역', params: {type: 'ait'}}">전체보기</router-link>
                                    </div>
                                    종합소득세 예상
                                </div>
                                <div class="card-body px-0">
                                    <table class="table">
                                        <tbody>
                                            <template v-if="globals.robotaxTrader.사업자구분 == '개인사업자' && !globals.robotaxTrader.owner">
                                                <tr>
                                                    <td>
                                                        <strong>종합소득세 예상</strong>
                                                        <div>
                                                            <small class="text-muted" >
                                                                개인용 공인인증서 필요
                                                            </small>
                                                        </div>
                                                    </td>
                                                    <td class="text-right">
                                                        <button class="btn btn-sm btn-link" v-b-modal.income-tax-help>
                                                            종합소득세 안내
                                                        </button>
                                                        <b-modal id="income-tax-help" title="종합소득세 안내" ok-only ok-title="닫기">
                                                            <p>
                                                                종합소득세는 사업자가 아니라 <strong>사업자를 소유한 개인</strong>에게 부과되는 세금입니다.
                                                                그래서 홈택스에서 종합소득세를 신고하려면 개인용 공인인증서가 필요하며,
                                                                키퍼에서도 종합소득세를 올바르게 계산하려면 개인용 공인인증서가 등록되어 있어야 합니다.
                                                            </p>
                                                            <router-link class="btn btn-block btn-outline-primary" :to="{name: '공인인증서 등록'}">공인인증서 등록</router-link>
                                                        </b-modal>
                                                    </td>
                                                </tr>
                                            </template>
                                            <template v-else>
                                                <TaxRow :tax="종합소득세now" v-if="종합소득세now"/>
                                                <TaxRow :tax="종합소득세previous" v-if="종합소득세previous && 종합소득세previous.needsAttention"/>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </template>
                            <template v-else-if="종합소득세error">
                                <div class="card-header border-top border-bottom">
                                    종합소득세 예상
                                </div>
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <td>
                                                {{ 종합소득세error.msg }}
                                                <b-button class="mt-2" variant="primary" block
                                                          @click="$router.push({name: '도움말', query: {openChat: true}})"
                                                          v-if="종합소득세error.openChat">
                                                    채팅상담하기
                                                </b-button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </template>

                            <template v-if="taskPaginator">
                                <div class="card-footer border-top border-bottom">
                                    <div class="float-right">
                                        <router-link class="" :to="{name: '세금 신고내역'}">전체보기</router-link>
                                    </div>
                                    최근 세금 신고/고지
                                </div>
                                <div class="card-body px-0">
                                    <table class="table">
                                        <tbody>
                                        <tr v-for="task in taskPaginator.objects.slice(0, 3)" :key="task.id" @click="showHometaxDocument(task)">
                                            <td>
                                                <div><strong class="m-0">{{ task.title }}</strong></div>
                                                <small class="text-muted">{{ localDateFormat(task.issued) }} {{ task.source == '전자신고결과조회' ? '신고' : '고지' }}</small>
                                            </td>
                                            <td class="text-right" style="min-width: 40px">
                                                <div class="">{{ moneyFormat(task.amount) }}</div>
                                                <small>
                                                    <span class="text-danger" v-if="task.status == 'reported' && task.data.납부서 && task.data.납부서.length">
                                                        납기일: {{ localDateFormat(task.data.납부서[0].납부기한) }}
                                                    </span>
                                                    <span :class="'text-' + globals.TAX_STATUSES[task.status].color" v-else-if="task.status == 'paid'">
                                                        {{ globals.TAX_STATUSES[task.status].label }}
                                                    </span>
                                                    <span :class="'text-' + globals.TAX_STATUSES[task.status].color" v-else-if="task.status">
                                                        {{ globals.TAX_STATUSES[task.status].label }}
                                                    </span>
                                                </small>
                                            </td>
                                        </tr>
                                        <tr v-if="taskPaginator.count == 0">
                                            <td class="text-center p-0 text-muted">없음</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </template>

                        </div>

                        <div class="card m-3" v-if="si4nPaginator">
                            <div class="card-header border-bottom">
                                <div class="float-right">
                                    <router-link class="" :to="{name: '사회보험 납부내역'}">내역보기</router-link>
                                </div>
                                4대보험 미납내역
                            </div>
                            <div class="card-body px-0">
                                <table class="table">
                                    <tbody v-if="si4nPaginator.objects.length">
                                        <tr v-for="si4n in si4nPaginator.objects" :key="si4n.id">
                                            <th>{{ si4n.구분 }}</th>
                                            <td>
                                                {{ yearMonthFormat(si4n.귀속연월) }}분
                                                <br>
                                                <span class="text-danger">{{ localDateFormat(si4n.납기일) }}</span>
                                            </td>
                                            <td>{{ moneyFormat(si4n.고지금액) }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else-if="accountTypes.사회보험 && accountTypes.사회보험.valid">
                                        <tr>
                                            <td>미납내역이 없습니다.</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td>
                                                <p class="text-danger">사회보험통합징수포털이 연동되지 않았습니다. </p>
                                                <hr>
                                                <p>사회보험통합징수포털에 가입하시고 나서 아래 버튼을 눌러주세요.</p>
                                                <button class="btn btn-block btn-outline-primary" @click="startScraping('사회보험')"><i class="fas fa-sync-alt"></i> 자료수집 재시도</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="card m-3" v-if="wetaxPaginator">
                            <div class="card-header border-bottom">
                                <div class="float-right">
                                    <router-link class="" :to="{name: '지방세 신고납부내역'}">전체보기</router-link>
                                </div>
                                지방세 신고납부현황
                            </div>
                            <div class="card-body px-0">
                                <table class="table">
                                    <tbody v-if="certs.find(c => !c.wetax)">
                                        <tr>
                                            <td colspan="2" class="text-primary">Wetax 인증서 등록을 확인 중 입니다. 완료되면 결과를 알려드릴게요.</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else-if="certs.length && certs.every(c => c.wetax && c.wetax.valid === false)">
                                        <tr>
                                            <td colspan="2">
                                                <span class="text-danger">
                                                    <i class="fas fa-exclamation-circle"></i>
                                                    Wetax에 인증서가 등록되지 않았습니다.
                                                </span>
                                                <hr>
                                                Wetax에 인증서를 등록하셨나요? 아래 버튼을 눌러 재시도 해보세요.
                                                <b-button block variant="outline-primary" class="mt-1" @click="retryWetax"><i class="fas fa-sync-alt"></i> 자료수집 재시도</b-button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="wetaxPaginator.objects.length">
                                        <tr v-if="certs.length === 0">
                                            <td colspan="2" class="text-danger">연결된 공인인증서가 없습니다. 지속적인 업데이트를 위해서 인증서를 등록해주세요.</td>
                                        </tr>
                                        <tr v-for="obj in wetaxPaginator.objects" :key="obj.id">
                                            <td>
                                                <div><strong class="m-0">{{ obj.신고세목 }}</strong></div>
                                                <small class="text-muted">납기일자 : {{ localDateFormat(obj.납기일자) }}</small>
                                            </td>
                                            <td class="text-right" style="min-width: 40px">
                                                <div class="">{{ moneyFormat(obj.납부세액) }}</div>
                                                <small>
                                                    <span :class="{
                                                        'text-danger': obj.납부여부.includes('미납'),
                                                        'text-success': ['납부', '면세'].some(s => obj.납부여부.includes(s)),
                                                    }">
                                                        {{ obj.납부여부 }}
                                                    </span>
                                                </small>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else-if="certs.length === 0">
                                        <tr>
                                            <td colspan="2" class="text-danger">연결된 공인인증서가 없습니다.</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else-if="certs.some(c => c.wetax && c.wetax.valid)">
                                        <tr>
                                            <td>신고납부 내역이 없습니다.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <transition enter-active-class="animate__animated animate__slideInRight" leave-active-class="animate__animated animate__slideOutRight">
                            <div class="position-fixed border-light bg-white"
                                 style="right: 10px; bottom: 70px; width: 100px; height: 100px; border: 5px solid; border-radius: 50px; z-index: 5; box-shadow: 2px 2px 6px darkgray"
                                 v-if="globals.user.settings.show_tips && tipVisible" v-b-modal.tip-modal>
                                <div class="text-center p-2" style="font-size: 0.8em">
                                    <span class="border-bottom border-primary"><i class="far fa-lightbulb"></i> 오늘의 팁</span>
                                    <p class="mt-1">{{ tip.title }}</p>
                                </div>
                                <b-modal id="tip-modal" centered :title="tip.title" footer-class="justify-content-between"
                                         @shown="trackEvent('팁 보기', {title: tip.title})">
                                    <p v-html="tip.description"></p>
                                    <router-link class="text-underline" :to="{name: tip.route}" v-if="tip.route">
                                        {{ tip.route }} 바로가기 <i class="fas fa-chevron-right"></i>
                                    </router-link>
                                    <router-link class="text-underline" :to="{name: 'External Link Browser', params: {link: tip.link, title: tip.link_title, transition: 'push'}}"
                                                 v-if="tip.link">
                                        {{ tip.link_title }} 바로가기 <i class="fas fa-chevron-right"></i>
                                    </router-link>
                                    <template #modal-footer>
                                        <b-check switch v-model="globals.user.settings.show_tips" @change="updateUserSettings({show_tips: $event})">팁 그만보기</b-check>
                                        <span>
                                            <router-link class="btn btn-sm btn-outline-info" :to="{name: '도움말', query: {tab: 3}}">팁 전체보기</router-link>
                                            &nbsp;
                                            <b-button size="sm" variant="outline-secondary" @click="$bvModal.hide('tip-modal'); tipVisible = false">닫기</b-button>
                                        </span>
                                    </template>
                                </b-modal>
                            </div>
                        </transition>
                    </div>
                    <div class="col-md-6 p-0" v-else>
                        <div class="alert alert-warning mx-3">
                            <p>
                                사용기간이 만료된 이후에도 <strong>홈택스를 통한 자료수집</strong>은 계속 제공되며 홈택스 자료만을 이용한 장부 기능은 계속 사용하실 수 있지만
                                은행 거래내역, 여신금융협회 카드매출 자료, 지방세, 사회보험 등의 다른 자료수집은 중지되며, 세금신고 등 많은 기능이 제한됩니다.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import api, {formatError, silentApi} from "@/util/api";
    import LoginRequired from "../components/LoginRequired"
    import AppUpdateMixin from "@/components/AppUpdateMixin";
    import StatusBar from "@/components/StatusBar";
    import InduceCert from "@/components/InduceCert";
    import Paginator from "@/util/paginator";
    import moment from 'moment'
    import querystring from 'querystring'
    import FullVersionBanner from "@/views/FullVersionBanner";
    import Tutorial from "@/views/Tutorial";
    import TaxRow from "@/views/taxreport/TaxRow";
    import AccountMixin from "@/util/AccountMixin";
    import {부가가치세} from "@/models";
    import {saveGlobal} from "@/globals";
    import PeriodSelector from "@/components/PeriodSelector";

    export default {
        name: "Dashboard",
        components: {PeriodSelector, TaxRow, Tutorial, FullVersionBanner, InduceCert, StatusBar},
        mixins: [LoginRequired, AppUpdateMixin, AccountMixin],
        data() {
            return {
                // 공통
                period: {},
                ownerBankAccounts: [],
                taskPaginator: new Paginator(api.get, ''),
                showTutorial: false,
                tip: [],
                tipVisible: false,
                세무일정: null,
                summary: [],
                cardSalesYesterday: {},
                cardSalesDepositToday: {},
                cardSalesLastMonth: {},
                baeminSalesYesterday: {},
                baeminDeposit: {},
                smartstoreDeposit: [],
                cashSales: {},
                총수입금액및필요경비명세서: null,
                부가가치세now: null,
                부가가치세previous: null,
                부가가치세error: null,
                사업장현황신고previous: null,
                related_certs: [],
                si4nPaginator: null,  // TODO 개인도 지원할 것
                wetaxPaginator: null,
                codes: {},

                // 개인
                종합소득세now: null,
                종합소득세previous: null,
                종합소득세error: null,
            }
        },
        computed: {
            bankDate() {
                return moment.max(this.globals.latestBankTransactions.map(t => moment(t.거래일시)))
            },
            bankBalance() {
                return this.globals.latestBankTransactions.filter(t => t.account).reduce((s, t) => {
                    return s + parseInt(t.잔고)
                }, 0)
            },
            validBankAccounts () {
                // return this.bankAccounts.filter(bankAccount => bankAccount.valid === true)
                return this.bankAccounts
            },
            is임대업() {
                return this.globals.robotaxTrader && this.globals.robotaxTrader.업종.세세분류 && this.globals.robotaxTrader.업종.세세분류.indexOf('임대업') >= 0
                // if (!this.codes['주택임대사업자_업종코드'] || !this.globals.robotaxTrader) { return false }
                // return this.codes['주택임대사업자_업종코드'].includes(parseInt(this.globals.robotaxTrader.업종코드))
            },
            부가가치세예정고지() {
                if (!this.세무일정) return null

                let today = moment();
                return this.세무일정.find(s => s.begin.startsWith(today.format('YYYY-MM')) && s.예정고지)
            },
            certs () {
                return this.related_certs.filter(c => c.valid && moment(c.not_after).isAfter(moment(), 'day'))
            },
            거래일Filter() {
                return {
                    거래일__gte: this.dateFormat(this.globals.period.begin),
                    거래일__lte: this.dateFormat(this.globals.period.end),
                }
            }
        },
        watch: {
        },
        async mounted() {
            await this.reload()
            this.requestInAppReview()
        },
        methods: {
            async reload () {
                if (!(await this.checkLogin())) {
                    return
                }

                if (!this.globals.trader) {
                    return
                }
                if (!localStorage.getItem('complete-tutorial')) {
                    this.showTutorial = true
                }

                this.bankAccounts = await this.globals.accountResource.bankAccounts()
                this.pollAccounts()
                this.pollScraping()

                if (this.validBankAccounts.length == 0 && this.globals.robotaxTrader.사업자구분 == '개인사업자' && this.globals.robotaxTrader.owner && this.globals.robotaxTrader.owner.id != this.globals.trader.id) {
                    this.ownerBankAccounts = await this.globals.accountResource.bankAccounts(this.globals.robotaxTrader.owner.robotax_id, {valid: true})
                }

                this.globals.latestBankTransactions = (await api.get('robotax/은행거래/latest/', {
                    params: {사업자: this.globals.trader.robotax_id}
                })).data

                this.taskPaginator = new Paginator(api.get, 'robotax/hometaxdocument/?page_size=10&source__in=전자신고결과조회,고지내역&사업자=' + this.globals.trader.robotax_id)
                this.taskPaginator.loadNext()

                this.세무일정 = (await api.get(`robotax/사업자/${this.globals.robotaxTrader.id}/세무일정/?year=${moment().year()}`)).data

                this.related_certs = (await api.get('/robotax/공인인증서/', {
                    params: {사업자_set: this.globals.trader.robotax_id, valid: true},
                    paramsSerializer: querystring.stringify
                })).data.results
                this.wetaxPaginator = new Paginator(api.get, 'robotax/wetax/?page_size=5&사업자=' + this.globals.trader.robotax_id)
                this.wetaxPaginator.loadNext()

                if (this.globals.trader.사업자구분 != '개인') {
                    await this.reloadSummary()

                    this.cardSalesYesterday = (await api.get('robotax/카드매출/summary/', {
                        params: {사업자: this.globals.trader.robotax_id, 거래일시__date: moment().subtract(1, 'days').format('YYYY-MM-DD')}
                    })).data
                    this.cardSalesDepositToday = (await api.get('robotax/카드매출/summary/', {
                        params: {사업자: this.globals.trader.robotax_id, 지급예정일: moment().format('YYYY-MM-DD')}
                    })).data
                    this.cardSalesLastMonth = (await api.get('robotax/카드매출집계/', {
                        params: {사업자: this.globals.trader.robotax_id, 거래기간시작일__gte: moment().subtract(1, 'months').date(1).format('YYYY-MM-DD')}
                    })).data.results
                    this.baeminSalesYesterday = (await api.get('robotax/결제대행/summary/', {
                        params: {사업자: this.globals.trader.robotax_id, type: '배달의민족', 거래일시__date: moment().subtract(1, 'days').format('YYYY-MM-DD')}
                    })).data
                    this.baeminDeposit = (await api.get('robotax/정산/summary/', {
                        params: {사업자: this.globals.trader.robotax_id, type: '배달의민족', status__in:'입금요청, 입금예정'}
                    })).data
                    this.smartstoreDeposit = (await api.get('robotax/정산/annotate/', {
                        params: {
                            사업자: this.globals.trader.robotax_id,
                            type: '스마트스토어',
                            status__in:'입금완료',
                            입금일__gte: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                            groupby_fields: ['입금일'],
                            sum_fields: ['입금액'],
                            ordering: '입금일'
                        },
                        paramsSerializer: querystring.stringify
                    })).data
                    this.cashSales = (await api.get('robotax/현금영수증/summary/', {
                        params: {사업자: this.globals.trader.robotax_id, 거래일시__date: moment().subtract(1, 'days').format('YYYY-MM-DD')}
                    })).data

                    this.si4nPaginator = new Paginator(api.get, 'robotax/사회보험/?page_size=10&납부일__isnull=True&고지금액__gt=0&사업자=' + this.globals.trader.robotax_id)
                    this.si4nPaginator.loadNext()

                    try {
                        if (this.globals.robotaxTrader.면세구분 == '면세') {
                            this.사업장현황신고previous = (await this.globals.사업장현황신고.get(`previous/?사업자=${this.globals.trader.robotax_id}`)).object
                        } else {
                            let res = await silentApi.get(`robotax/부가가치세/now_and_previous/?사업자=${this.globals.trader.robotax_id}`)
                            this.부가가치세now = new 부가가치세((await silentApi.get(`robotax/부가가치세/${res.data.now}/`)).data)
                            this.부가가치세previous = new 부가가치세((await silentApi.get(`robotax/부가가치세/${res.data.previous}/`)).data)
                            await this.부가가치세previous.loadHometax()
                        }
                    } catch (e) {
                        if (e.response.status !== 400) {
                            throw e
                        }
                        if (formatError(e).includes('부가가치세 과세유형 전환')) {
                            this.부가가치세error = {
                                msg: '과세기간에 부가가치세 과세유형이 전환된 경우는 현재 예상세금을 지원하지 않습니다. \n신고가 필요하신 경우 채팅으로 상담요청해주세요. 실력있는 세무사 사무실을 소개해드립니다.',
                                openChat: true
                            }
                        }
                    }

                    this.$set(this.codes, '주택임대사업자_업종코드', (await api.get('robotax/codes/주택임대사업자_업종코드')).data)
                }

                if (this.globals.trader.사업자구분 != '법인' && this.globals.robotaxTrader.owner) {
                    try {
                        this.종합소득세previous = (await this.globals.종합소득세.get(`previous/?사업자=${this.globals.robotaxTrader.owner.robotax_id}`, null, true)).object
                        await this.종합소득세previous.loadHometax()
                        this.종합소득세now = (await this.globals.종합소득세.get(`now/?사업자=${this.globals.robotaxTrader.owner.robotax_id}`, null, true)).object
                    } catch (e) {
                        if (e.response.status !== 400) {
                            throw e
                        }
                        if (formatError(e).includes('unsupported 업종코드')) {
                            this.종합소득세error = {
                                msg: '주 업종코드가 없거나, 업종이 확인되지 않는 사업자가 있는 경우의 종합소득세 신고는 지원하지 않습니다. \n신고가 필요하신 경우 채팅으로 상담요청해주세요. 실력있는 세무사 사무실을 소개해드립니다.',
                                openChat: true
                            }
                        } else if (formatError(e).includes('사업소득이 확인되지 않지만, 자료수집 중인 사업자가 있습니다.')) {
                            this.종합소득세error = {
                                msg: '아직 자료수집이 완료되지 않아 종합소득세가 계산되지 않았습니다. 잠시 후 다시 시도해주세요. \n 궁금하신 점이 있으시면 채팅으로 상담요청해주세요.',
                                openChat: true
                            }
                        } else if (formatError(e).includes('사업소득이 존재하지 않습니다.')) {
                            this.종합소득세error = {
                                msg: '사업소득이 확인되지 않아, 종합소득세 계산이 되지 않았습니다. \n 궁금하신 점이 있으시면 채팅으로 상담요청해주세요.',
                                openChat: true
                            }
                        }

                    }
                }

                let tips = (await api.get(`사업자/${this.globals.trader.id}/tips/`)).data
                this.tip = tips[Math.floor(Math.random() * tips.length)]

                if (this.tip) {
                    setTimeout(() => {
                        this.tipVisible = true
                    }, 100)
                }
            },
            async reloadSummary() {
                let today = moment()

                this.summary = (await api.get('robotax/전표/annotate/', {
                    params: {
                        사업자: this.globals.trader.robotax_id,
                        거래일__gte: this.dateFormat(this.globals.period.begin),
                        거래일__lte: this.dateFormat(this.globals.period.end),
                        groupby_fields: ['증빙종류', '매출매입', '은행입출금'],
                        count_fields: ['id'],
                        sum_fields: ['공급가액'],
                        max_fields: ['거래일'],
                        ordering: '증빙종류'
                    },
                    paramsSerializer: querystring.stringify
                })).data

                this.총수입금액및필요경비명세서 = (await api.get('robotax/사업소득명세/총수입금액및필요경비명세서/', {
                    params: {
                        사업자: this.globals.trader.robotax_id,
                        과세기간시작일: this.dateFormat(today.month(0).date(1)),
                        과세기간종료일: this.dateFormat(today.month(11).date(31)),
                    }
                })).data
            },
            showVat (resource) {
                if (!this.부가가치세now) { return }
                this.$router.push({name: '부가가치세 신고서', params: {id: resource.id}})
            },
            filterRecords: function(records, 서식명) {
                return records.filter(r => r.서식명 == 서식명)
            },
            getRecord: function(records, 서식명) {
                return records.filter(r => r.서식명 == 서식명)
            },
            load종합소득세: function (period) {
                return this.globals.종합소득세.get(`${period}/?사업자=${this.globals.trader.robotax_id}`).then(res => {
                    this[`종합소득세${period}`] = res.object
                    return res
                })
            },
            requestInAppReview() {
                let saved = localStorage.getItem('last-request-review-date')
                if (saved && moment().diff(moment(saved, 'YYYY-MM-DD'), 'days') < 4) {
                    return
                }

                if (this.globals.user && this.globals.user.visit_point >= 100 && this.isApp && window.Android.startInAppReview) {
                    window.Android.startInAppReview()
                    localStorage.setItem('last-request-review-date', moment().format('YYYY-MM-DD'))
                }
            },
            isThisMonth(date) {
                return moment().isSame(moment(date), 'month')
            },
            isNew(date) {
                return moment().diff(moment(date), 'days') <= 2
            },
            showHometaxDocument(doc) {
                if (doc.source == '전자신고결과조회') {
                    this.$router.push({name: '세금신고납부 상세', params: {id: doc.id}})
                } else if (doc.source == '고지내역') {
                    this.$router.push({name: '납부안내', query: {
                        title: doc.title, accountNo: doc.data.전자납부번호, amount: doc.amount, type: '국세', id: doc.id}
                    })
                }
            },
            async transferAccount(account) {
                await this.globals.accountResource.transferAccount(account, this.globals.trader)
                this.bankAccounts = await this.globals.accountResource.bankAccounts()
                this.$bvModal.hide('link-personal-bank-account-help')
            },
            async retryWetax () {
                this.globals.loading = true
                this.$nextTick(async () => {
                    try{
                        await api.post('robotax/scrapingtask/start/', {
                            '사업자': this.globals.trader.robotax_id,
                            'sources': ['Wetax'],
                        })
                        this.related_certs = (await api.get('/robotax/공인인증서/', {
                            params: {사업자_set: this.globals.trader.robotax_id, valid: true},
                            paramsSerializer: querystring.stringify
                        })).data.results
                    } catch (e) {
                        alert('Wetax 업데이트 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.')
                    }
                    this.globals.loading = false
                })
            },
            scrollToPromotion() {
                console.log('scroll')
            },
            async updateUserSettings(settings) {
                Object.assign(this.globals.user.settings, settings)
                saveGlobal('user', (await api.patch(`user/${this.globals.user.id}/`, {settings: this.globals.user.settings})).data)
            },
            async setPeriod($event) {
                this.globals.period = $event
                this.globals.회계연도 = $event.begin.year()
                await this.reloadSummary()
            }
        }
    }
</script>

