<template>
    <div>
        <status-bar v-if="globals.smallScreen"></status-bar>
        <nav class="navbar navbar-light titlebar">
            <back-button></back-button>
            <h5 class="mt-2">
                소상공인 희망회복자금
            </h5>
            <span></span>
        </nav>

        <div class="card m-3">
            <div class="card-header">
                1차 신속지급 <span class="text-info float-right">21.8.17~</span>
            </div>
            <div class="card-body">
                <div v-if="globals.trader.사업자구분 === '개인'">
                    현재 개인이 선택되었습니다. 희망회복자금 신속지급 상태를 확인하시려면 좌측 상단에서 사업자를 선택해주세요.
                </div>
                <div v-else-if="this.result">
                    <div class="text-center" v-if="this.result.value['TGT_YN'] === 'Y'">
                        <h4 class="text-center">{{ this.globals.trader.법인명_상호 }}</h4>
                        <template v-if="this.result.value['DATE_YN'] === 'Y'">
                            <h3 class="text-success">1차 신속지급 대상입니다</h3>
                            <div>아래 버튼을 눌러 희망회복자금을 신청하세요.</div>
                            <a href="https://xn--jj0bj8t5nckzp7hsmgb.kr/" target="_blank"><b-button block class="mt-4" variant="outline-primary">희망회복자금 신청</b-button></a>
                        </template>
                        <template v-else>
                            <h3 class="text-success">1차지급 대상이나<br> 신청일이 아닙니다.</h3>
                            <div><b>* 사업자등록번호 끝자리 기준 홀짝제 실시 중</b></div>
                            <div>
                                8.17(화) : 끝자리 홀수<br> 8.18(수) : 끝자리 짝수<br> 8.19(목) 이후 : 전체
                            </div>
                            <a href="https://xn--jj0bj8t5nckzp7hsmgb.kr/" target="_blank"><b-button block class="mt-4" variant="outline-primary">신속지급대상 자세히 알아보기</b-button></a>
                        </template>
                    </div>
                    <div v-else>
                        <h4 class="text-center">{{ this.globals.trader.법인명_상호 }}</h4>
                        <h3 class="text-center text-danger">1차 신속지급 대상이 아닙니다</h3>
                        <b>1인 다수사업체, 지자체가 추가한 방역조치 이행 사업체, 매출감소 기준 확대로 지원대상에 추가된 사업체, 21.3월 이후 개업 사업체등</b>은 2차 신속지급 대상입니다.
                        대상에 해당하시는 경우 2021년 8월 30일부터 신청가능한 2차 신속지급을 별도 안내 시 까지 기다려주세요.
                        <a href="https://xn--jj0bj8t5nckzp7hsmgb.kr/" target="_blank"><b-button block class="mt-4" variant="outline-primary">2차 신속지급대상 자세히 알아보기</b-button></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import api from "@/util/api";
    import BackButton from "@/components/BackButton";
    import StatusBar from "@/components/StatusBar";

    export default {
        name: "HopeRecovery.vue",
        components: {BackButton, StatusBar},
        data: () => ({
            result: null,
            timer: null,
            checking: false,
        }),
        watch: {
            'globals.trader': function () {
                this.load()
            }
        },
        mounted () {
            this.load()
        },
        beforeDestroy() {
            clearInterval(this.timer)
        },
        methods: {
            load () {
                this.globals.loading = true
                this.globals.loadingMessage = '희망회복자금 대상정보를 불러오는 중 입니다'
                this.$nextTick(async () => {
                    await this.reload()
                    this.globals.loading = false
                })
            },
            async reload () {
                let res = await api.get(`robotax/사업자항목/?사업자=${this.globals.trader.robotax_id}&key=소상공인희망회복자금_2021_1차&ordering=-date`)
                if (res.data.count) {
                    this.result = res.data.results[0]
                    return true
                }
                return false
            },
            check () {
                api.post(`robotax/사업자/${this.globals.trader.robotax_id}/소상공인희망회복자금_2021_1차/`).then(res => {
                    this.checking = true
                    this.timer = setInterval(async () => {
                        if (await this.reload()) {
                            clearInterval(this.timer)
                            this.checking = false
                        }
                    }, 3000)
                })
            }
        }
    }
</script>
