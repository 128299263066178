<template>
    <div class="h-100 d-flex flex-column">
        <nav class="navbar navbar-light titlebar">
            <back-button></back-button>
            <h5 class="mt-2">도움말</h5>
            <button class="btn btn-sm btn-outline-primary" @click="startChat('세무대행')"><i class="fas fa-comment"></i> 채팅상담</button>
        </nav>

        <b-tabs class="flex-grow-1 overflow-auto d-flex flex-column"
                nav-class="bg-white p-2"
                content-class="flex-grow-0 overflow-auto"
                pills
                :value="tab">

            <b-tab title="게시판"
                   class="h-100 overflow-auto"
                   v-infinite-scroll="loadMore"
                   infinite-scroll-distance="30"
                   @click="$router.replace({query: {tab: 0}})">
                <div class="card m-3" v-if="!post">
                    <div class="card-body">
                        <form class="form">
                            <textarea class="form-control" placeholder="키퍼에 대한 질문이나 의견, 제안, 혹은 세무회계에 대한 질문 등 어떤 이야기든 올려보세요." @input="resizeTextarea" v-model="message"></textarea>
                            <div class="text-center">
                                <button class="mt-2 btn btn-primary" @click.prevent="createPost" v-if="message.length">글쓰기</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div v-if="paginator && posts.length">
                    <div class="card m-3" v-for="post in posts" :key="post.id">
                        <div class="card-header border-bottom">
                            <div class="text-muted">
                                <span class="float-right">{{ humanizeDate(post.created) }}</span>
                                <span class="badge badge-dark" v-if="post.writer.is_staff">키퍼 관리자</span>
                                <span v-else>{{ post.writer.last_name }}{{ post.writer.first_name }}</span>
                            </div>
                        </div>
                        <div class="card-body">
                            <div v-html="post.content_rendered"></div>
                        </div>
                        <div class="card-footer border-top">
                            <h6 class="text-center text-underline" v-if="post.comment_set.length">댓글</h6>
                            <div v-for="comment in post.comment_set" :key="comment.id">
                                <div class="text-muted">
                                    <small class="float-right">{{ humanizeDate(comment.created) }}</small>
                                    <small class="badge badge-dark" v-if="comment.writer.is_staff">키퍼 관리자</small>
                                    <small v-else>{{ comment.writer.last_name }}{{ comment.writer.first_name }}</small>
                                </div>
                                <div v-html="comment.content_rendered"></div>
                                <hr>
                            </div>

                            <form class="form" @submit.prevent="createComment(post, $event)">
                                <div class="input-group">
                                    <textarea name="content" class="form-control" style="height: 40px" @input="resizeTextarea" placeholder="댓글을 입력하세요."></textarea>
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary">올리기</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="card m-3" v-for="i in new Array(5)" :key="i">
                        <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px">
                            <b-spinner variant="primary"></b-spinner>
                        </div>
                    </div>
                </div>
            </b-tab>
            <b-tab title="자주묻는질문"
                   class="h-100 overflow-auto"
                   v-infinite-scroll="loadMore"
                   infinite-scroll-distance="30"
                   @click="$router.replace({query: {tab: 1}})">
                <div class="card m-3" v-if="!post">
                    <div class="card-header">
                        자주 묻는 질문
                    </div>
                    <div class="card-body">
                        <div class="" v-for="(faq, index) in faqList.slice(0, 2)" :key="faq[0]">
                            <p class="" v-b-toggle="'accordian-' + index">
                                <span class="text-primary"><i class="fas fa-question-circle"></i> {{ faq[0] }}</span>
                            </p>
                            <b-collapse :id="'accordian-' + index">
                                <i class="fas fa-arrow-circle-right float-left my-1 mr-1"></i><div class="mb-1" v-html="faq[1]"></div>
                            </b-collapse>
                        </div>
                        <b-collapse class="text-center" visible accordion="more-faq">
                            <button class="btn btn-sm btn-outline-primary" v-b-toggle.more-faq>더보기 <i class="fas fa-chevron-down"></i> </button>
                        </b-collapse>
                        <b-collapse id="more-faq" accordion="more-faq" :visible="true">
                            <div class="" v-for="(faq, index) in faqList.slice(2)" :key="faq[0]">
                                <p class="" v-b-toggle="'accordian-' + (index + 2)">
                                    <span class="text-primary"><i class="fas fa-question-circle"></i> {{ faq[0] }}</span>
                                </p>
                                <b-collapse :id="'accordian-' + (index + 2)">
                                    <i class="fas fa-arrow-circle-right float-left my-1 mr-1"></i><div class="mb-1" v-html="faq[1]"></div>
                                </b-collapse>
                            </div>
                        </b-collapse>
                    </div>
                </div>
            </b-tab>

            <b-tab title="도움영상" @click="$router.replace({query: {tab: 2}})">
                <div class="container mt-3">
                    <iframe class="w-100" style="height: 60vw" src="https://www.youtube.com/embed/6UAE7AAS-VU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe class="w-100" style="height: 60vw" src="https://www.youtube.com/embed/8VODqv1F63M" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe class="w-100" style="height: 60vw" src="https://www.youtube.com/embed/iDfHcAEuAdY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </b-tab>
            <b-tab title="팁" @click="$router.replace({query: {tab: 3}})">
                <div class="p-3 bg-white" v-if="!post">
                    <p>키퍼 사용법, 세금 줄이는 법 등에 대한 팁입니다.</p>
                    <b-check switch v-model="globals.user.settings.show_tips" @change="updateUserSettings({show_tips: $event})">현황판에서 팁 띄우기</b-check>
                </div>
                <div class="card m-3">
                    <div class="card-body" v-if="tips.length == 0">공동인증서를 등록하시면 등록된 사업자 정보에 맞게 최적화된 팁을 보실 수 있습니다.</div>
                    <template v-for="(tip, index) in tips">
                        <div class="card-header border-bottom" :key="index" v-b-toggle="'tip-' + index">
                            {{ tip.title }} <collapse-indicator class="float-right"></collapse-indicator>
                        </div>
                        <b-collapse :id="'tip-' + index" :key="'b' + index">
                            <div class="card-body" >
                                <p v-html="tip.description" v-if="tip.description"></p>
                                <router-link class="text-underline" :to="{name: tip.route}" v-if="tip.route">
                                    <strong>{{ tip.route }}</strong> 바로가기 <i class="fas fa-chevron-right"></i>
                                </router-link>
                                <router-link class="text-underline" :to="{name: 'External Link Browser', params: {link: tip.link, title: tip.link_title, transition: 'push'}}"
                                             v-if="tip.link">
                                    <strong>{{ tip.link_title }}</strong> 바로가기 <i class="fas fa-chevron-right"></i>
                                </router-link>
                            </div>
                        </b-collapse>
                    </template>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
    import LoginRequired from "@/components/LoginRequired";
    import Paginator from "@/util/paginator";
    import api from "@/util/api";
    import infiniteScroll from 'vue-infinite-scroll'
    import CollapseIndicator from "@/views/income-tax/CollapseIndicator";
    import {saveGlobal} from "@/globals";
    import BackButton from "@/components/BackButton";

    export default {
        name: "Chat",
        mixins: [LoginRequired],
        components: {BackButton, CollapseIndicator},
        directives: {infiniteScroll},
        props: ['id'],
        data: () => ({
            active: true,
            message: '',
            paginator: null,
            post: null,
            tips: [],
            faqList: [
                ['키퍼로 세금 신고도 가능한가요? 키퍼를 사용하면 세무사에게 기장을 맡기지 않아도 되나요?', `
<p>경우에 따라 다릅니다. 키퍼에서는 <strong>부가가치세</strong>와 <strong>종합소득세</strong> 신고서를 자동으로 작성하고 제출할 수 있는 기능을 제공하고 있으나,
키퍼의 세금 신고서는 <strong>인공지능 세금신고 엔진</strong>을 통해서 100% 자동으로 작성되기 때문에 세무사가 직접 세부사항을 확인하고 작성하는 신고서보다 조금 부족할 수 있습니다.
회계 구조가 단순한 경우는 큰 차이가 없으나, 비즈니스의 규모가 크거나, 복식부기 대상자이거나, 그외에 세법상으로 특수한 경우에 해당한다면 세무사에게 신고를 맡기는 게 좋습니다.
그리고 <strong>직원을 고용하고 있는 경우에는 원천세, 4대보험 신고 등을 해야 하기 때문에 세무사가 필요합니다</strong>.</p>

<p>또, 키퍼의 세금신고는 세무사에게 맡기는 것과 달리 사업자 본인이 자가신고하는 것으로 간주됩니다. 세무사에게 세금 신고를 맡기면 세무사가
그 신고 내용에 대해 책임을 지게 됩니다만, 키퍼는 세금신고에 대해 책임을 지지 않고, 사업자 본인이 책임을 지게 됩니다. 홈택스에서 직접 신고하는 것과 동일합니다.
키퍼가 저렴한 가격으로 서비스를 제공할 수 있는 것도 세금 신고에 대해 세법상의 책임을 지지 않기 때문입니다.</p>

<p>일반적으로 다음과 같은 조건을 만족하면 키퍼로 세금 신고가 가능합니다.</p>
<ol>
    <li>1인 사업자 (직원 없음)</li>
    <li>매출은 세금계산서/계산서, 카드단말기, 현금영수증으로만 발생한다.</li>
    <li>사업자용 통장을 개설했고 그 통장에서 사업과 무관한 거래가 없다.</li>
    <li>사업과 무관한 카드매입, 현금영수증 매입이 없고, 해외 카드사용이 없다.</li>
    <li>세무서에서 미리 고지 받은 업종별 특이사항이 없다.</li>
    <li>연 매출 규모가 1억을 넘지 않는다.</li>
</ol>
<p>위의 조건을 만족하는 경우라면 대개 키퍼로 세금 신고가 가능합니다. 그리고 키퍼에서 세금 신고를 접수하면 저희가 자체적으로 검토를 하는데,
이 때 세무사에게 맡겨야 하는 사항이 발견되면 저희가 홈택스에 신고하지 않고 고객에게 고지해드리고, 필요한 경우 세무사 소개도 해드립니다.</p>
<p><strong>키퍼는 세무사와 상호보완적으로 사용</strong>하는 것도 좋습니다. 세무사를 쓰더라도 매일 거래내역을 취합해주거나 세금을 예상해주거나 하지는 않기 때문에 키퍼를 이용하면
매일매일의 재무상황을 잘 파악할 수 있고, 세금도 미리 예상해서 대비할 수 있습니다. 그리고 키퍼에서 취합한 자료를 손쉽게 세무사에게 전송할 수도 있습니다.</p>
                `],
                ['공인인증서로 은행계좌 연동이 되었는데 내 사업자의 장부에는 나오지 않네요.', `
                개인 공인인증서로 등록하신 경우 대표자 개인으로 키퍼를 이용할 수도 있고 사업자로 이용할 수도 있는데, 현황판 좌상단의 사업자 선택기능으로
                대표자 개인을 선택하시면 개인으로 등록된 은행 계좌를 보실 수 있습니다. 이 중에서 사업용으로 쓰시는 계좌를 선택한 다음에 연결된 사업자를
                실제 사용하는 사업자로 변경하시면 그 뒤부터는 해당 계좌는 사업자에 연결되고 장부에도 나옵니다.
                `],
                ['예상 세금은 어디서 확인하나요?', `
<p>예상 세금은 모두 <strong>현황판</strong>에서 제공합니다. 현황판 제일 상단에는 손익과 증빙별 거래내역 요약 화면이 나오고, 그 아래에 통장이나 카드매출 현황, 그리고 그 다음에 세금이 나옵니다.
개인사업자의 경우 종합소득세와 부가가치세가 표시되는데, 오늘이 포함된 과세기간에 대한 세금이 먼저 나오고, 만약 이전 과세기간의 세금을 신고해야 할 때가 되면 이전 세금내역도 나옵니다.
전체보기를 눌러서 들어가면 그 동안의 세금 내역을 모두 조회할 수 있구요.</p>
                `],
                ['사업자가 여러개인데, 각각 결제해야하나요?', '네, 키퍼는 사업자 단위로 과금합니다. 다만, 사업자를 여러 개 하시는 분들을 위해 3개의 사업자까지는 유료로 결제하시면, 나머지 사업자들은 무료로 정식버전으로 업그레이드하는 혜택을 드리고 있습니다.'],
                ['태그는 뭐하는 건가요? 꼭 태그를 붙여야 하나요?', `
<p>간편장부에서 거래내역에 적절한 태그를 붙이면 손익이 자동으로 계산됩니다. <strong>손익을 정확하게</strong> 알고 싶으시거나, <strong>종합소득세 신고를 키퍼를 통해서</strong> 하시고 싶으시다면 태그를 적절하게 붙여주셔야 하구요.
반대로 키퍼가 자동으로 추천하는 태그가 그럭저럭 괜찮고 손익을 대강 추정만 할 수 있으면 된다면, 그리고 종합소득세 신고를 세무사에게 맡긴다면 굳이 태그를 열심히 붙이지 않아도 됩니다.</p>
                `],
            ]
        }),
        computed: {
            posts() {
                if (this.post) {
                    return [this.post]
                } else {
                    return this.paginator.objects
                }
            },
            tab() {
                return parseInt(this.$route.query.tab || '0')
            }
        },
        async mounted() {
            if (this.globals.trader && this.globals.trader.service_type == '세무대행') {
                this.startChat('세무대행')
            }

            if (this.$route.query.openChat) {
                this.startChat('세무대행')
            }

            if (this.$route.params.id) {
                this.post = (await api.get(`post/${this.$route.params.id}/`)).data
            }
            this.paginator = new Paginator(api.get, 'post/?ordering=-created')
            this.paginator.loadNext()

            if (this.globals.trader) {
                this.tips = (await api.get(`사업자/${this.globals.trader.id}/tips/`)).data
            }

        },
        beforeDestroy () {
            window.ChannelIO('shutdown');
        },
        methods: {
            startChat: function(chatType) {
                this.globals.chatEnabled = true

                let userId = this.globals.trader ? this.globals.trader.id : `user-${this.globals.user.id}`
                let profile = this.globals.trader ? {
                    "name": `${this.globals.trader.법인명_상호}`,
                    "selftax_trader_id": `${this.globals.trader.id}`,
                    "chatType": `${chatType}`,
                    "user_id": this.globals.user.id,
                    "email": this.globals.user.email,
                } : {
                    "name": this.globals.user.username,
                    "user_id": this.globals.user.id,
                    "email": this.globals.user.email,
                }

                window.ChannelIO('boot', {
                    "pluginKey": "2f8e4f55-e199-45ea-94b2-75a70e800e45",
                    "hideDefaultLauncher": true,
                    // openChatDirectlyAsPossible: true,
                    // TODO 바로 열면 항상 새로운 채팅방으로 열리게 바뀜. 채널톡 문의 후 방법이 있으면 수정
                    openChatDirectlyAsPossible: false,
                    memberId: userId,
                    profile,
                    "mobileOpenUI": 'iframe'
                })
                window.ChannelIO('onBoot', () => {
                    window.ChannelIO('show')
                })
                window.ChannelIO('onShow', () => {
                    // var chatContainer = this.$el.querySelector('.ch-mobile-messenger')
                    // chatContainer.style = 'height: calc(100% - 63px) !important; animation: none !important;'
                })
                window.ChannelIO('onHide', () => {
                    window.ChannelIO('shutdown');
                    this.globals.chatEnabled = false
                });
            },
            async createPost() {
                let res = await api.post('post/', {
                    content: this.message
                })
                this.paginator.objects.splice(0, 0, res.data)
                this.message = ''
            },
            async createComment(post, event) {
                let res = await api.post('comment/', {
                    post: post.id,
                    content: event.target.content.value
                })
                post.comment_set.push(res.data)
                event.target.content.value = ''
            },
            loadMore: function(event) {
                if (!this.paginator.canLoadNext()) {
                    return
                }
                this.paginator.loadNext()
            },
            resizeTextarea(event) {
                event.target.style.height = event.target.scrollHeight + 'px'
            },
            async updateUserSettings(settings) {
                Object.assign(this.globals.user.settings, settings)
                saveGlobal('user', (await api.patch(`user/${this.globals.user.id}/`, {settings: this.globals.user.settings})).data)
            }
        }
    }
</script>
