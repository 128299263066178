<template>
    <div>
        <nav class="navbar navbar-light titlebar text-white">
            <back-button :to="globals.pathBackFromPayment"></back-button>
            <h5 class="mt-2">
                정식버전 안내
            </h5>
            <span></span>
        </nav>

        <div class="card mx-3">
            <div class="card-body">
                <table class="table">
                    <tbody>
                        <tr>
                            <th>사업자</th>
                            <td class="text-right">{{ globals.trader.법인명_상호 }}</td>
                        </tr>
                        <tr>
                            <th>현재 요금제</th>
                            <td class="text-right" :class="'text-' + constants.PLAN_COLORS[globals.trader.plan]">
                                <span v-if="globals.trader.plan == '체험기간'">무료</span>
                                {{ globals.trader.plan.replace('_', ' ') }}
                            </td>
                        </tr>
                        <tr>
                            <th v-if="globals.trader.plan != '정식버전'">만료일</th>
                            <th v-else>다음 결제일</th>
                            <td class="text-right text-nowrap">
                                {{ localDateFormat(globals.trader.expiry) }}<br>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                <p v-if="globals.trader.plan == '체험기간'">
                    체험기간 중에 결제수단을 등록하시면 <strong>1개월 이용 쿠폰</strong>을 드려요!
                </p>
                <p class="text-danger" v-else-if="globals.trader.plan == '사용기간_만료'">
                    키퍼 사용기간이 <strong>만료</strong>되어서 홈택스 자료수집 등의 일부기능만 이용하실 수 있습니다.
                </p>
                <p class="text-success" v-else-if="globals.trader.plan == '정식버전'">
                    <i class="fas fa-check"></i> 키퍼의 모든 기능을 자유롭게 이용하실 수 있습니다.
                </p>
                <p class="text-danger" v-if="globals.trader.plan == '정식버전' && payment && !payment.paid">
                    <i class="fas fa-exclamation-triangle"></i> 결제수단이 유효하지 않아 다음 결제일 이후에 사용기간이 만료됩니다.
                </p>
            </div>
        </div>

        <div class="card m-3">
            <div class="card-header border-bottom">
                <span v-if="globals.trader.plan == '정식버전'">현재 </span> 요금제 (월 이용료)

                <router-link :to="{name: '쿠폰', params: {transition: 'push'}}"
                             class="btn btn-sm btn-outline-primary float-right">
                    무료이용 쿠폰
                    <span v-if="coupons.filter(c => !c.redeemed).length > 0">{{ coupons.filter(c => !c.redeemed).length }}장</span>
                    <span v-else>받기</span>

                </router-link>
            </div>
            <div class="card-body">
                <form>
                    <table class="table">
                        <tbody>
                            <tr>
                                <td>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="정식버전_월_이용료" v-model="paymentItems" @click.prevent>
                                        <label class="form-check-label">정식버전</label>
                                    </div>
                                </td>
                                <td class="text-right">
                                    <strong>5,500원</strong>
                                </td>
                            </tr>
                            <tr @click="togglePaymentItem('장부정리_도우미')">
                                <td>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="장부정리_도우미" v-model="paymentItems">
                                        <label class="form-check-label">장부정리 도우미</label>
                                    </div>
                                    <p class="pl-2 text-secondary" style="word-break: break-all">
                                        전산회계사가 장부를 작성해드립니다.
                                        <span class="ml-1 text-underline text-body" v-b-modal.bookkeeper-agent-help @click.stop>자세히보기 <i class="fas fa-question-circle"></i></span>
                                    </p>

                                    <b-modal id="bookkeeper-agent-help" title="장부정리 도우미 안내">
                                        <p>
                                            키퍼의 정식버전을 이용하시면 고객님이 직접 장부를 작성하고 세금신고까지 하실 수 있으나,
                                            그 과정이 번거롭고 어렵기 때문에 키퍼에서 <strong>간편장부</strong>의 장부정리 도우미를 제공합니다.
                                        </p>
                                        <h5>전산회계사의 거래내역 분류</h5>
                                        <p>
                                            태그 기능을 이용하면 고객님이 직접 거래내역을 분류하고 손익을 확인할 수 있지만, 회계 지식 없이 거래내역을 분류하는 것은 어려운 일이죠.
                                            인공지능이 거래내역의 일부를 자동분류해드리고 있으나, 아직 사람에 비해 30~40% 정도 밖에 분류하지 못합니다.
                                            그래서, <strong>전산회계사가 매일매일 직접 거래내역을 분류</strong>해드립니다. 거래내역에 태그가 잘 붙으면 <strong>손익계산도 정확</strong>해집니다.
                                            차량 구매 등 큰 금액의 지출이 있는 경우도 적절하게 감가상각 처리를 해서 비용으로 인정 받아 세금을 줄일 수도 있고, 비용처리 한도 등도 챙겨드립니다.
                                        </p>
                                        <p>
                                            <i class="fas fa-exclamation-triangle"></i> 다만, 거래내역 분류 기준은 <strong>간편장부</strong>이며, <strong>복식부기</strong>는 지원하지 않습니다.
                                            법인 등은 복식부기 의무자는 키퍼의 장부를 기준으로 법인세를 신고하실 수는 없습니다.
                                        </p>
                                        <h5>계정 연동 대행</h5>
                                        <p>
                                            키퍼에서 모든 회계 정보를 조회하려면 여러 가지 웹사이트에 인증서를 등록하고 설정해야 하는데, 이것도 번거로운 일이라 놓치는 경우가 많습니다.
                                            그래서 다음과 같은 사이트의 연동을 대행해드립니다.
                                        </p>
                                        <ul>
                                            <li>홈택스 사업용신용카드, 사업용 계좌 등록</li>
                                            <li>위택스(지방세) 계정 등록</li>
                                            <li>사회보험통합징수포털 계정 등록</li>
                                            <li>기타 필요한 사이트 계정 등록</li>
                                        </ul>
                                    </b-modal>

                                </td>
                                <td class="text-right">
                                    <span :class="{'font-weight-bold': paymentItems.indexOf('장부정리_도우미') >= 0}">5,500원</span>
                                    <br>
                                    <small>선택사항</small>
                                </td>
                            </tr>
                            <tr>
                                <th>월 결제금액 합계</th>
                                <td class="text-right"><strong>{{ moneyFormat(paymentItems.length * 5500) }}</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
            <transition>
                <div class="card-footer" v-if="globals.trader.plan == '정식버전' && paymentItemsChanged">
                    <button class="btn btn-block btn-outline-success" @click="changePlan(true)">요금제 변경</button>
                </div>
            </transition>
            <template>
                <div class="card-footer border-top">
                    <p v-if="globals.trader.plan == '체험기간'">
                        매월 이용료를 결제할 수단을 등록합니다.
                        결제수단이 등록되면 바로 정식버전으로 전환되며, 체험기간 만료일에 이용료가 결제됩니다.
                    </p>
                    <p v-else-if="globals.trader.plan == '사용기간_만료'">
                        매월 이용료를 결제할 수단을 등록합니다.
                        결제수단이 등록되면 이어서 1개월 이용료가 결제된 후 바로 정식버전으로 전환됩니다.
                    </p>
                    <template v-else-if="globals.trader.plan == '정식버전'">
                        <p>
                            <i class="fas fa-check text-success"></i> 결제수단이 정상적으로 등록되었습니다.
                        </p>
                        <p class="text-underline" v-b-toggle.pay-buttons>결제 수단을 변경하고 싶으신가요? <i class="fas fa-chevron-down"></i> </p>
                    </template>
                    <b-collapse id="pay-buttons" :visible="globals.trader.plan != '정식버전'">
                        <button class="btn btn-outline-primary" style="width: 45%" @click="pay('card')">카드결제</button>
                        <button class="float-right btn btn-outline-primary" style="width: 45%" @click="pay('transfer')">계좌이체결제</button>
                        <p class="text-underline mt-2"><i class="fas fa-info-circle"></i> 정식버전 결제는 언제든지 해지하실 수 있습니다.</p>
                    </b-collapse>
                </div>
            </template>
        </div>

        <div class="card border m-3" :class="payment.paid ? 'border-success' : 'border-danger'" v-if="payment">
            <div class="card-header border-bottom" :class="payment.paid ? 'border-success' : 'border-danger'" >
                최근 결제:
                <span v-if="payment.paid">
                    <i class="fas fa-check text-success"></i>&nbsp;
                    <span v-if="payment.amount == 0">정기결제 {{translate_method(payment.method || 'card')}}등록 성공</span>
                    <span v-else>결제 성공</span>
                </span>
                <span v-else><i class="fas fa-times-circle text-danger"></i>&nbsp; {{ payment.message }}</span>
            </div>
            <div class="card-body">
                <table class="table">
                    <tbody>
                        <tr v-if="payment.card_no">
                            <th>결제카드</th>
                            <td class="text-right"><small>{{ payment.card_name }} {{ payment.card_no }}</small></td>
                        </tr>
                        <tr v-if="payment.bank_name && payment.bank_account_no">
                            <th>결제계좌</th>
                            <td class="text-right"><small>{{ payment.bank_name }} {{ payment.bank_account_no }}</small></td>
                        </tr>
                        <tr v-if="payment.amount && payment.method == 'transfer'">
                            <th>현금영수증 발행여부</th>
                            <td class="text-right">
                                <div :class="payment.cash_receipt_result ? '' : 'text-danger'">{{ payment.cash_receipt_result ? '발행됨' : '발행되지 않음' }}</div>
                            </td>
                        </tr>
                        <tr>
                            <th>결제금액</th>
                            <td class="text-right">
                                <small v-if="!payment.amount">(결제수단 등록)</small>
                                {{ moneyFormat(payment.amount) }}
                            </td>
                        </tr>
                        <tr>
                            <th><span>일시</span></th>
                            <td class="text-right">{{ moment(payment.updated).format('LL LT') }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="card m-3" v-if="globals.trader.plan == '정식버전'">
            <div class="card-header">
                정기결제 해지 안내
            </div>
            <div class="card-body">
                <div class="text-danger" v-if="payment && payment.product_name == '키퍼 정식버전 해지'">
                    정식버전 결제가 해지되었습니다. 마지막 결제일 이후 1개월 간은 정식버전으로 사용하실 수 있으며, 그 이후에 만료되어 일부기능만 사용하실 수 있습니다.
                </div>
                <div v-else>
                    <p>정기결제를 해지하면 정식버전 사용기간이 마지막 결제일 이후 한 달간 유지되며, 그 이후에는 정식버전이 만료되어 일부 기능만 사용하실 수 있습니다.</p>
                    <button class="btn btn-outline-danger btn-block" @click="stop_monthly">정기결제 해지</button>
                </div>
            </div>
        </div>

        <div class="card m-3">
            <div class="card-header">
                <strong>하나로비서</strong> - 경리대행 서비스
            </div>
            <div class="card-body">
                사업하면서 회계업무까지 꼼꼼하게 챙기기 힘드시죠? 키퍼에서 사업자의 모든 회계업무를 대행해드리는 경리대행 서비스를 제공합니다.
            </div>
            <div class="card-footer text-right">
                <router-link class="btn btn-sm btn-outline-primary" :to="{name: '경리대행 안내', params: {transition: 'push'}}">자세히보기 <i class="fas fa-chevron-right"></i> </router-link>
            </div>
        </div>


        <div class="card m-3" v-if="!globals.production">
            <div class="card-header">
                테스트용 버튼
            </div>
            <div class="card-body">
                <form ref="fakePaymentResultForm" method="post">
                    <input v-for="(v, k) of fakePaymentResult" :key="k" :name="k" :value="v">
                </form>
                <button class="btn btn-success btn-block" @click="fakePaymentComplete({'PCD_PAY_TYPE': 'card', 'PCD_PAY_RST': 'success'})">신용카드 정기결제 등록완료</button>
                <button class="btn btn-success btn-block" @click="fakePaymentComplete({'PCD_PAY_TYPE': 'transfer', 'PCD_PAY_RST': 'success'})">계좌이체 정기결제 등록완료</button>
                <button class="btn btn-danger btn-block" @click="fakePaymentComplete({'PCD_PAY_TYPE': 'card', 'PCD_PAY_RST': 'error', PCD_PAY_MSG: '카드번호 오류'})">신용카드 정기결제 등록실패</button>
                <button class="btn btn-warning btn-block" @click="fakePaymentComplete(null)">신용카드 정기결제 도중취소</button>
            </div>
        </div>
    </div>
</template>

<script>
    import BackButton from "@/components/BackButton";
    import api, {baseURL, silentApi} from "@/util/api"
    import {globals, saveGlobal, loadGlobal} from "@/globals";
    import {Coupon} from "@/models";

    export default {
        name: "RegularPayment",
        components: {BackButton},
        data() {
            return {
                paymentItems: ['정식버전_월_이용료'],
                payment: null,
                fakePaymentResult: {},
                coupons: [],
            }
        },
        computed: {
            paymentItemsChanged() {
                return !this.paymentItems.every(item => this.globals.trader.payment_items[item])
                    || !Object.keys(this.globals.trader.payment_items).every(key => this.paymentItems.includes(key))
            }
        },
        async mounted() {
            if (this.$route.query.back) {
                saveGlobal('pathBackFromPayment', this.$route.query.back)
            }
            loadGlobal('pathBackFromPayment')

            saveGlobal('trader', (await api.get(`사업자/${this.globals.trader.id}/`)).data)
            this.paymentItems = Object.keys(this.globals.trader.payment_items)

            try {
                this.payment = (await silentApi.get('payment/latest_full_version/', {
                    params: {trader: globals.trader.id}
                })).data;

                if (this.$route.query && this.$route.query.complete) {
                    this.trackEvent('purchase')
                }
            } catch (e) {
                console.log(e)
            }

            this.coupons = await Coupon.objects()
        },
        methods: {
            async changePlan(notify) {
                this.globals.trader = (await api.put(`사업자/${this.globals.trader.id}/payment_items/`, Object.fromEntries(this.paymentItems.map(item => {
                    return [item, 1]
                })))).data
                if (notify) {
                    this.$bvToast.toast('요금제가 변경되었습니다. 다음 결제일부터 반영됩니다.', {variant: 'success'})
                }
            },
            async pay(method='card') {
                this.trackEvent('payment_begin', {method: method})

                await this.changePlan(false)
                this.payment = (await api.post('payment/begin_full_version/', {trader: globals.trader.id})).data
                let completeURL = `${location.origin.replace(':8080', ':8000')}/api/payment/${this.payment.id}/payple_complete/`;
                PaypleCpayAuthCheck({
                    PCD_CPAY_VER: '1.0.1',
                    PCD_PAY_TYPE: method,
                    PCD_PAY_WORK: 'AUTH',
                    PCD_CARD_VER: '01',
                    PCD_SIMPLE_FLAG: 'Y',
                    payple_auth_file: `${baseURL}/payment/payple_auth_key/`,

                    PCD_PAYER_NO: globals.trader.id,
                    PCD_PAYER_NAME: globals.trader.법인명_상호,
                    PCD_PAYER_HP: '',
                    PCD_PAYER_EMAIL: globals.user.email,

                    PCD_PAY_GOODS: this.payment.product_name,
                    PCD_PAY_TOTAL: 0,
                    PCD_PAY_ISTAX: 'Y',
                    PCD_TAXSAVE_FLAG: method === 'card' ? 'N' : 'Y',
                    PCD_PAY_OID: this.payment.id,

                    PCD_RST_URL: completeURL,
                    callbackFunction: this.completePayment
                })
            },
            async stop_monthly() {
                if (this.coupons.find(c => !c.redeemed && c.reason == '정식버전_결제등록') &&
                    !confirm('사용예정인 쿠폰 보상이 있을 경우에는 해당 쿠폰은 회수되며, 체험기간 요금제로 돌아갑니다. 계속 진행하시겠습니까?')){
                    return
                }
                this.payment = (await api.post(`payment/${this.payment.id}/stop_monthly/`)).data
                this.coupons = await Coupon.objects()
            },
            completePayment(res) {
                console.log(res)
            },
            async fakePaymentComplete(data) {
                this.payment = (await api.post('payment/begin_full_version/', {trader: globals.trader.id})).data

                if (!data) return

                if (data['PCD_PAY_TYPE'] == 'card') {
                    this.fakePaymentResult = Object.assign({
                        'PCD_PAY_OID': this.payment.id,
                        'PCD_PAYER_ID': '1234',
                        'PCD_PAY_CARDNAME': 'BC카드',
                        'PCD_PAY_CARDNUM': '1111********2222',
                    }, data)
                } else {
                    this.fakePaymentResult = Object.assign({
                        'PCD_PAY_OID': this.payment.id,
                        'PCD_PAYER_ID': '1234',
                        'PCD_PAY_BANKNAME': '우리은행',
                        'PCD_PAY_BANKNUM': '1111-********-2222',
                        'PCD_TAXSAVE_FLAG': 'Y',
                        'PCD_TAXSAVE_RST': 'Y'
                    }, data)
                }

                this.$refs.fakePaymentResultForm.action = `${baseURL}/payment/${this.payment.id}/payple_complete/`
                this.$nextTick(() => {
                    this.$refs.fakePaymentResultForm.submit()
                })
            },
            translate_method (method) {
                if (method == 'card') { return '카드' }
                else if (method == 'transfer') { return '계좌이체' }
                return ''
            },
            togglePaymentItem(item) {
                let index = this.paymentItems.indexOf(item);
                this.trackEvent('payment_item_toggle', {item: item, add: index < 0})
                if (index >= 0) {
                    this.paymentItems.splice(index, 1)
                } else {
                    this.paymentItems.push(item)
                }
            }
        }
    }
</script>

<style scoped>

</style>
