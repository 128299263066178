<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-5 p-0">

                <nav class="navbar navbar-light titlebar">
                    <back-button></back-button>
                    <h5 class="mt-2">{{ globals.trader.법인명_상호 }} 은행계좌</h5>
                    <span></span>
                </nav>
                <div>
                    <div class="card mx-3" v-if="사업자_set">
                        <div class="card-header" v-if="사업자_set.length >= 2">
                            <div class="btn-group">
                                <button class="btn btn-sm btn-outline-secondary" :class="{'active': !showAllTraders}" @click="toggleShowAllTraders">
                                    현재 사업자 계좌 {{ bankAccounts.filter(a => a.사업자 == globals.trader.robotax_id).length }}개
                                </button>
                                <button class="btn btn-sm btn-outline-secondary" :class="{'active': showAllTraders}" @click="toggleShowAllTraders">
                                    연관된 모든 계좌 {{ bankAccounts.length }}개
                                </button>
                            </div>
                        </div>
                        <div class="card-body px-0">
                            <table class="table">
                                <tbody v-for="trader in 사업자_set.filter(t => showAllTraders || t.id == globals.trader.robotax_id)"
                                       :key="trader.id">
                                    <tr class="" style="background: #eee">
                                        <td class="py-1" colspan="2">
                                            <strong>{{ trader.법인명_상호 }}</strong>
                                            <small>
                                                <span class="ml-2 text-primary">연동 {{ bankAccounts.filter(a => a.사업자 == trader.id && a.valid ).length }}</span>
                                                /
                                                전체 {{ bankAccounts.filter(a => a.사업자 == trader.id).length }}
                                            </small>
                                        </td>
                                        <td class="py-1 text-right">
                                            {{ moneyFormat((latestBankTransactions.filter(lbt => bankAccounts.find(a => lbt.account && a.id == lbt.account.id && a.사업자 == trader.id)).reduce((s, t) => s.add(t.잔고), decimal(0)))) }}
                                        </td>
                                    </tr>
                                    <router-link :to="{name: '은행계좌', params: {id: account.id}}"
                                                 tag="tr"
                                                 active-class="table-active"
                                                 replace
                                                 v-for="account in bankAccounts.filter(a => a.사업자 == trader.id)" :key="account.id">
                                        <td>
                                            <i class="fas fa-check text-primary" v-if="account.valid"></i>
                                            <i class="fas fa-times text-danger" v-else></i>
                                            {{ account.bank_name }}
                                        </td>
                                        <td>{{ account.account_no }}</td>
                                        <td class="text-right">
                                            {{ moneyFormat((latestBankTransactions.find(t => t.account && t.account.id == account.id) || {}).잔고) }}
                                        </td>
                                    </router-link>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer border-top">
                            <router-link class="btn btn-link" :to="{name: '은행계좌 등록'}">
                                <i class="fas fa-plus"></i>
                                은행계좌 연결
                            </router-link>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-7 p-0">
                <div class="alert alert-light m-3" v-if="!selectedAccount">
                    <strong>계좌를 선택해보세요.</strong>
                </div>
                <template v-else>
                    <scraping-account-panel class="m-3" :source="selectedAccount.type" :account="selectedAccount">
                        <tr>
                            <th>연결된 사업자</th>
                            <td colspan="2">
                                <span>{{ 사업자_set.find(t => t.id == selectedAccount.사업자).법인명_상호 }}</span>
                                <template v-if="owner사업자">
                                    <button class="ml-2 btn btn-sm btn-warning" v-b-modal.remap-bankaccount-modal>계좌 이동</button>
                                    <b-modal id="remap-bankaccount-modal" title="은행계좌와 사업자 연결 안내" ok-only ok-title="닫기">
                                        <p>
                                            개인용 공인인증서로 조회된 은행계좌는 기본적으로 개인 계좌로 인식되지만, 개인 계좌를 사업용 계좌로 쓰는 경우
                                            본인 소유의 다른 사업자용 계좌로 연결해둘 수 있습니다.
                                            이렇게 다른 사업자에 연결된 계좌는 그 거래내역도 해당 사업자의 거래내역으로 인식됩니다.
                                            이렇게 변경한 것을 다시 개인 계좌로 되돌리는 것도 가능합니다.
                                        </p>
                                        <p>
                                            이렇게 계좌를 다른 사업자에 연결하더라도 키퍼 내에서 장부를 쓸 때만 적용되며 실제 은행의 계좌나
                                            국세청에 등록된 사업용 계좌에는 영향을 미치지 않습니다.
                                        </p>
                                        <h5>연결할 사업자 선택</h5>
                                        <b-list-group>
                                            <b-list-group-item
                                                :active="selectedAccount.사업자 == t.id"
                                                v-for="t in 사업자_set" :key="t.id"
                                                @click="remapTrader(t)">
                                                <component :is="t.폐업일 ? 'del' : 'span'" :class="t.폐업일 ? 'text-muted': ''">{{ t.법인명_상호 }} ({{ t.납세자ID }})</component>
                                            </b-list-group-item>
                                        </b-list-group>
                                    </b-modal>
                                </template>
                            </td>
                        </tr>
                        <tr v-if="!selectedAccount.valid && selectedAccount.error.indexOf('비밀번호가 필요한 계좌는 현재 조회가 불가합니다') >= 0">
                            <th>계좌 비밀번호</th>
                            <td>
                                <span class="input-group">
                                    <input type="password" class="form-control" v-model="selectedAccount.account_password">
                                    <span class="input-group-append">
                                        <button class="btn btn-outline-warning" @click="setBankAccountPassword(selectedAccount, selectedAccount.account_password, selectedAccount.password)">변경</button>
                                    </span>
                                </span>
                            </td>
                        </tr>
                        <tr v-if="selectedAccount.type == 'Codef'">
                            <th>계좌전환</th>
                            <td colspan="2">
                                공동인증서 은행 자료수집은 곧 지원이 중단됩니다. 빠른조회로 전환해주시기 바랍니다.
                                <br>
                                <button class="btn btn-warning" v-b-modal.convert-bank-account>전환 시작하기</button>
                                <b-modal id="convert-bank-account" title="빠른조회 계좌 전환 안내"
                                         cancel-title="닫기" ok-title="빠른조회 계좌 등록"
                                         @ok="$router.push({name: '은행계좌 등록', query: {type: '은행빠른조회', bankCode: selectedAccount.bank_code, accountNo: selectedAccount.account_no}})">
                                    <p>
                                        공동인증서 은행 자료수집 기능은 앞으로 지원이 중단될 예정이고, 그 대신 각 은행의 빠른조회 기능을 이용해서
                                        자료수집 기능을 제공하려고 합니다. 공동인증서로 자료수집을 하게 되면 여러 은행의 계좌를 자동으로 찾아서
                                        등록할 수 있다는 장점이 있지만, 자료수집 비용이 너무 비싸서 저희가 감당하기 어려웠습니다. 그래서,
                                        이전보다 좀 불편하시겠지만 빠른조회 기능을 이용해서 연동해주시길 부탁드립니다.
                                    </p>
                                    <p>
                                        빠른조회로 전환하더라도 기존 계좌의 거래내역은 유지됩니다. 다만, 인터넷 뱅킹에서 빠른조회를 한 번
                                        신청해야 하는 번거로움이 있습니다. 다음 안내에 따라 빠른조회 계좌 등록을 진행해주시기 부탁드립니다.
                                    </p>
                                </b-modal>

                            </td>
                        </tr>
                    </scraping-account-panel>

                    <div class="card m-3">
                        <div class="card-header">
                            <div class="btn-toolbar float-right">
                                <div class="btn-group btn-group-sm">
                                    <button class="btn btn-outline-secondary"
                                            :class="{active: unit == u}"
                                            @click="setUnit(u)"
                                            v-for="u in ['주', '월', '연']"
                                            :key="u">{{ u }}</button>
                                </div>
                            </div>
                            <div>
                                <i class="fas fa-chevron-left" @click="moveStep(-1)"></i>
                                <span class="px-4">{{ periodLabel() }}</span>
                                <i class="fas fa-chevron-right" @click="moveStep(1)"></i>
                            </div>
                        </div>
                        <div class="card-body">
                            <canvas id="chart-bank" style="width: 100%; height: 200px"></canvas>
                        </div>
                    </div>

                    <div class="m-3">
                        <b-dropdown block variant="outline-success" class="text-green" menu-class="w-100">
                            <template #button-content>
                                <span><i class="fas fa-file-excel" style="color: green"></i> 거래내역 엑셀로 다운로드 </span>
                            </template>

                            <b-dropdown-item v-for="year in [globals.회계연도, globals.회계연도 - 1]" :key="year" @click="downloadExcel(year)">
                                <i class="fas fa-download"></i> {{ year }}년 거래내역
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>

                    <div class="card m-3" v-if="transactions">
                        <div class="card-header">
                            최근 거래내역
                            <router-link class="float-right btn btn-sm btn-outline-secondary" :to="{name: '증빙종류별 거래', query: {증빙종류: '은행'}}">간편장부에서 보기 <i class="fas fa-chevron-right"></i></router-link>
                        </div>
                        <div class="card-body px-0">
                            <table class="table">
                                <tbody>
                                    <tr v-for="transaction in transactions.objects" :key="transaction.id">
                                        <td>
                                            <div class="text-muted">{{ localDateFormat(transaction.거래일시) }}</div>
                                            <div><strong>{{ transaction.적요 }}</strong></div>
                                            <div class="">{{ transaction.거래지점 }} {{ transaction.거래수단 }}</div>
                                            <div class="">{{ transaction.비고 }} {{ transaction.메모 }}</div>
                                        </td>
                                        <td class="text-right">
                                            <div>
                                                <small>{{ parseInt(transaction.입금액) > 0 ? '입금' : '출금' }}</small>
                                                <strong class="ml-1" :class="{'text-primary': parseInt(transaction.입금액) > 0}">{{ moneyFormat(parseInt(transaction.입금액) || parseInt(transaction.출금액)) }}</strong>
                                            </div>
                                            <span class="text-nowrap"><small>잔액</small> {{ moneyFormat(transaction.잔고) }}</span><br>
                                            <div>
                                                <small>{{ transaction.account.bank_name }}</small>
                                                <small>{{ transaction.account.account_no }}</small>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import BackButton from "@/components/BackButton";
    import LoginRequired from "@/components/LoginRequired";
    import api from "@/util/api";
    import querystring from "querystring"
    import Chart from "chart.js"
    import moment from "moment"
    import {getCssVar, humanizeNumberScale} from "@/util/vue-commons";
    import Paginator from "@/util/paginator";
    import AccountMixin from "@/util/AccountMixin";
    import {robotaxapi} from "../../../operation/src/drfapi";
    import ScrapingAccountPanel from "@/views/connect/ScrapingAccountPanel";

    export default {
        name: "BankReport",
        mixins: [LoginRequired, AccountMixin],
        components: {ScrapingAccountPanel, BackButton},
        props: ['id'],
        data() {
            return {
                사업자_set: [],
                bankAccounts: [],
                latestBankTransactions: [],
                banks: {},
                owner사업자: null,
                cert: null,
                summary: [],
                unit: '주',
                step: 0,
                tasks: [],
                chart: null,
                transactions: null,
                showAllTraders: false,
                공인인증서: null,
            }
        },
        computed: {
            bankDate() {
                return moment.max(this.globals.latestBankTransactions.map(t => moment(t.거래일시)))
            },
            moment: function() {
                if (this.unit == '주') {
                    return moment().add(this.step, 'weeks')
                } else if (this.unit == '월') {
                    return moment().add(this.step, 'months')
                } else if (this.unit == '연') {
                    return moment().add(this.step, 'years')
                }
                return null
            },
            selectedAccount() {
                return this.bankAccounts.find(a => a.id == this.id)
            }
        },
        watch: {
            step: function () {
                this.reload()
            },
            unit: function () {
                this.reload()
            },
            async id() {
                await this.reload()
            }
        },
        async mounted() {
            await this.reload()
            this.pollScraping('은행빠른조회')
        },
        methods: {
            scrapingUpdated() {
                this.reload()
            },
            reloadSelectedAccountDetail: async function () {
                if (this.selectedAccount.공인인증서) {
                    this.공인인증서 = (await api.get(`robotax/공인인증서/${this.selectedAccount.공인인증서.id}/`)).data
                } else {
                    this.공인인증서 = null
                }
                let params = this.queryParams('거래일시__date', ['입금액', '출금액', '잔고']);

                this.summary = (await api.get('robotax/은행거래/periodic_summary/', {
                    params: params,
                    paramsSerializer: querystring.stringify
                })).data

                if (this.chart) {
                    this.chart.destroy()
                }
                this.chart = new Chart('chart-bank', {
                    type: 'bar',
                    data: {
                        labels: Object.keys(this.summary).map(key => {
                            if (this.unit == '주') {
                                return this.localDateFormat(key, true)
                            } else if (this.unit == '월') {
                                return moment(key).format('D일')
                            } else {
                                return key + '월'
                            }
                        }),
                        datasets: [
                            {
                                type: 'line',
                                label: '잔고',
                                borderColor: getCssVar('primary'),
                                yAxisID: '잔고',
                                data: Object.values(this.summary).map(s => s.잔고.total),
                            },
                            {
                                type: 'bar',
                                label: '입금액',
                                borderColor: getCssVar('success'),
                                backgroundColor: getCssVar('success'),
                                yAxisID: '입출금',
                                data: Object.values(this.summary).map(s => s.입금액),
                            },
                            {
                                type: 'bar',
                                label: '출금액',
                                borderColor: getCssVar('danger'),
                                backgroundColor: getCssVar('danger'),
                                yAxisID: '입출금',
                                data: Object.values(this.summary).map(s => s.출금액),
                            },
                        ],
                    },
                    options: {
                        legend: {
                            position: 'top'
                        },
                        scales: {
                            yAxes: [
                                {
                                    id: '입출금',
                                    display: true,
                                    position: 'right',
                                    ticks: {
                                        callback: function (value, index, values) {
                                            return humanizeNumberScale(value)
                                        }
                                    }

                                },
                                {
                                    id: '잔고',
                                    display: true,
                                    position: 'left',
                                    ticks: {
                                        fontColor: getCssVar('primary'),
                                        callback: function (value, index, values) {
                                            return humanizeNumberScale(value)
                                        }
                                    }
                                },
                            ]
                        },
                    }
                })

                let transactionParams = {
                    사업자: this.globals.trader.robotax_id,
                    ordering: '-거래일시',
                    page_size: 5
                };
                if (this.selectedAccount) {
                    transactionParams.account = this.selectedAccount.id
                }
                this.transactions = new Paginator(api.get, 'robotax/은행거래/?' + querystring.stringify(transactionParams))
                this.transactions.loadNext()
            },
            async reload() {
                this.banks = await this.globals.codes.get('bank/')
                this.사업자_set = await this.globals.robotaxTrader.대표자_사업자_set()

                if (this.bankAccounts.length == 0) {
                    for (let a사업자 of this.사업자_set) {
                        for (let account of (await this.globals.accountResource.bankAccountResource.get('', {params: {사업자: a사업자.id}})).data.results) {
                            this.bankAccounts.push(account)
                        }
                    }
                }

                if (this.latestBankTransactions.length == 0) {
                    for (let t of this.사업자_set) {
                        this.latestBankTransactions = this.latestBankTransactions.concat((await api.get('robotax/은행거래/latest/', {
                            params: {사업자: t.id}
                        })).data)
                    }
                }
                if (this.id) {
                    if (this.globals.robotaxTrader.owner) {
                        this.owner사업자 = await this.globals.사업자.getObject(this.globals.robotaxTrader.owner.robotax_id)
                    }
                    await this.reloadSelectedAccountDetail();
                } else {
                    this.owner사업자 = null
                }
            },
            queryParams(groupby, fields) {
                let params = {
                    사업자: this.selectedAccount.사업자,
                    groupby: groupby,
                    fields: fields,
                }
                if (this.selectedAccount) {
                    params.account = this.selectedAccount.id
                }

                if (this.unit == '주') {
                    params[groupby + '__week'] = this.moment.isoWeek()
                    params[groupby + '__year'] = this.moment.isoWeekYear()
                } else if (this.unit == '월') {
                    params[groupby + '__month'] = this.moment.month() + 1
                    params[groupby + '__year'] = this.moment.year()
                } else if (this.unit == '연') {
                    params['groupby'] = groupby + '__month'
                    params[groupby + '__year'] = this.moment.year()
                }
                return params
            },
            moveStep(diff) {
                if (this.step == 0 && diff > 0) {
                    return
                }
                this.step += diff
            },
            setUnit(unit) {
                this.unit = unit
                this.step = 0
            },
            periodLabel() {
                if (this.unit == '주') {
                    if (this.step == 0) {
                        return '이번 주'
                    } else {
                        return (- this.step) + '주 전'
                    }
                } else if (this.unit == '월') {
                    return (this.moment.month() + 1) + '월'
                } else if (this.unit == '연') {
                    return this.moment.year() + '년'
                }
            },
            async remapTrader(trader) {
                this.selectedAccount.사업자 = trader.id
                Object.assign(this.selectedAccount, (await api.put(`robotax/bankaccount/${this.selectedAccount.id}/transfer/`, {
                    사업자: trader.id
                })).data)
                this.$bvModal.hide('remap-bankaccount-modal')

                this.사업자_set = this.$set(this, '사업자_set', Array.from(this.사업자_set))
            },
            toggleShowAllTraders() {
                this.showAllTraders = !this.showAllTraders
                if (this.selectedAccount) {
                    this.$router.replace({params: {id: undefined}})
                }
            },
            downloadExcel(year) {
                location.href = robotaxapi.defaults.baseURL + '/은행거래/excel/?' + querystring.stringify({
                    account: this.selectedAccount.id,
                    거래일시__date__year: year,
                })
            }
        }
    }
</script>

<style scoped>

</style>
