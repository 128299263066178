<template>
    <div class="h-100 d-flex flex-column" v-if="report">
        <nav class="navbar navbar-light titlebar pt-0">
            <back-button v-if="globals.smallScreen"></back-button>
            <span v-else></span>
            <h5 class="mt-2">부가가치세 {{ report.title() }} 신고</h5>
            <span></span>
        </nav>
        <b-nav class="bg-white p-2 shadow" pills style="z-index: 1">
            <b-nav-item :to="{name: '부가가치세 신고서'}" exact exact-active-class="active" replace><strong>내역보기</strong></b-nav-item>
            <b-nav-item :to="{name: '부가가치세 신고'}" exact exact-active-class="active" replace v-if="true"><strong>신고하기</strong></b-nav-item>
            <b-nav-item :to="{name: '부가가치세 증빙자료'}" exact exact-active-class="active" replace><strong>증빙자료</strong></b-nav-item>
        </b-nav>
        <induce-cert class="p-3" v-if="!globals.trader">
            <p>
                <strong>홈택스</strong>에 접속 가능한 <strong>공인인증서</strong>를 등록하시면 홈택스에 신고한 <strong>세금내역, 납부해야 할 내역, 환급 결정된 내역</strong> 등을 조회하실 수 있어요.
            </p>
        </induce-cert>
        <router-view v-else></router-view>
    </div>
</template>

<script>
    import LoginRequired from "../../components/LoginRequired";
    import AppUpdateMixin from "../../components/AppUpdateMixin";
    import api from "@/util/api";
    import InduceCert from "@/components/InduceCert";
    import BackButton from "@/components/BackButton";
    import moment from "moment";

    export default {
        name: "VatTabs",
        components: {BackButton, InduceCert},
        mixins: [LoginRequired, AppUpdateMixin],
        props: ['id'],
        data: function() {
            return {
                report: null,
                hometaxDocument: null,
            }
        },
        computed: {
            canReport() {
                if (!this.report) { return false }
                return moment().isAfter(moment(this.report.과세기간종료일자), 'day')
            }
        },
        watch: {
            id() {
                this.reload()
            }
        },
        async mounted() {
            // TODO refactor 코드 관리
            await this.globals.codes.getObject('공제감면코드')
            await this.globals.codes.getObject('부가가치세_가산세코드')
            await this.globals.codes.getObject('부가가치세_신고구분상세코드')
            await this.reload()
        },
        methods: {
            async reload() {
                this.report = (await this.globals.부가가치세.get(this.id + '/')).object
                this.hometaxDocument = (await api.get(`robotax/hometaxdocument/?report_type=부가가치세&report_id=${this.report.id}`)).data.results[0]

                // TODO 테스트 계정 - 신고하기 활성화
                if (this.globals.user.username == 'test_payment') {
                    this.report.status = true
                }
            }
        }
    }
</script>

<style scoped>

</style>
