<template>
    <div class="h-100" v-if="!globals.trader">
        <status-bar v-if="globals.smallScreen"></status-bar>
        <induce-cert></induce-cert>
    </div>
    <div v-else>
        <status-bar v-if="globals.smallScreen"></status-bar>
        <nav class="navbar navbar-light titlebar">
            <span></span>
            <h5 class="">
                세무회계 자료수집
            </h5>
            <span></span>
        </nav>
        <full-version-banner></full-version-banner>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6 p-0">

                    <div class="card mx-3 mb-3">
                        <div class="card-header border-bottom">
                            홈택스
                            <router-link :to="{name: '공인인증서 목록'}" class="btn btn-sm btn-outline-info float-right">공동인증서 관리</router-link>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <table class="table" style="" >
                                <thead>
                                    <tr>
                                        <th>자료종류</th>
                                        <th>최근 자료수집</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <router-link tag="tr" :to="{name: '세금 신고내역'}">
                                        <th class="">세금신고,고지,납부,환급</th>
                                        <td class="text-center">
                                            <status-cell tag="span"
                                                         class="pl-2"
                                                         :task="{status: Object.values(scrapingSources).filter(t => constants.HOMETAX_TAX_SOURCES.indexOf(t.source) >= 0 && t.status != 'success').map(t => t.status)[0] || 'success', error: Object.values(scrapingSources).filter(t => t.status != 'success').map(t => t.error)[0]}"/>
                                            <span class="ml-1" v-if="scrapingSources.전자신고결과조회">{{ humanizeDate(scrapingSources.전자신고결과조회.updated) }}</span>
                                        </td>
                                    </router-link>
                                    <router-link tag="tr" :to="constants.DATA_SOURCES[source].route"
                                                 v-for="source of constants.HOMETAX_VOUCHER_SOURCES" :key="source">
                                        <th class="">
                                            {{ constants.DATA_SOURCES[source] && constants.DATA_SOURCES[source].label || source }}
                                            <span class="text-info" v-if="constants.DATA_SOURCES[source] && constants.DATA_SOURCES[source].description">
                                                <i class="fas fa-info-circle" v-b-popover.top.hover.blur="constants.DATA_SOURCES[source].description"></i>
                                            </span>
                                        </th>
                                        <status-cell :task="scrapingSources[source]"></status-cell>
                                    </router-link>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card mx-3 mb-3">
                        <div class="card-header">
                            은행
                            <div class="btn-group float-right">
                                <button class="btn btn-sm btn-outline-secondary" :class="{'active': !showAllTraders}" @click="showAllTraders = !showAllTraders">
                                    현재 사업자 계좌 {{ bankAccounts.filter(a => a.사업자 == globals.trader.robotax_id).length }}개
                                </button>
                                <button class="btn btn-sm btn-outline-secondary" :class="{'active': showAllTraders}" @click="showAllTraders = !showAllTraders">
                                    연관된 모든 계좌 {{ bankAccounts.length }}개
                                </button>
                            </div>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <table class="table">
                                <tbody v-for="trader in 사업자_set.filter(t => showAllTraders || t.id == globals.trader.robotax_id)"
                                       :key="trader.id">
                                    <tr class="" style="background: #eee">
                                        <td class="" colspan="2">
                                            <strong>{{ trader.법인명_상호 }}</strong>
                                            <small>
                                                <span class="ml-2 text-primary">연동 {{ bankAccounts.filter(a => a.사업자 == trader.id && a.valid ).length }}</span>
                                                /
                                                전체 {{ bankAccounts.filter(a => a.사업자 == trader.id).length }}
                                            </small>
                                        </td>
                                        <td class="text-right">
                                            {{ moneyFormat((latestBankTransactions.filter(lbt => lbt.account && bankAccounts.find(a => a.id == lbt.account.id && a.사업자 == trader.id)).reduce((s, t) => s.add(t.잔고), decimal(0)))) }}
                                        </td>
                                    </tr>
                                    <router-link :to="{name: '은행계좌', params: {id: account.id}}"
                                                 tag="tr"
                                                 active-class="table-active"
                                                 v-for="account in bankAccounts.filter(a => a.사업자 == trader.id)" :key="`bank-${account.id}`">
                                        <td class="">
                                            <strong>{{ account.bank_name }}</strong>
                                            <br>
                                            {{ account.account_no }}
                                        </td>
                                        <StatusCell :task="{status: account.valid, error: account.error}"></StatusCell>
                                        <td class="text-right">
                                            {{ moneyFormat((latestBankTransactions.find(t => t.account && t.account.id == account.id) || {}).잔고) }}
                                        </td>
                                    </router-link>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 p-0">
                    <div class="card mx-3 mb-3">
                        <div class="card-header">
                            공공기관
                        </div>
                        <div class="card-body p-0 pb-1">
                            <table class="table" style="" v-if="accountTypes">
                                <tbody>
                                    <router-link tag="tr" :to="{name: '사회보험 납부내역'}">
                                        <th class="" style="">
                                            사회보험통합징수포털
                                        </th>
                                        <StatusCell :task="scrapingSources.사회보험"></StatusCell>
                                    </router-link>

                                    <router-link tag="tr" :to="{name: '지방세 신고납부내역'}">
                                        <th class="" style="">
                                            Wetax <small class="text-muted">(지방세)</small>
                                        </th>
                                        <status-cell :task="scrapingSources.Wetax"></status-cell>
                                    </router-link>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="card m-3" v-if="globals.trader.사업자구분 != '개인'">
                        <div class="card-header">매출전표 연동</div>
                        <div class="card-body p-0 pb-1">
                            <table class="table" style="">
                                <tbody>
                                    <router-link tag="tr" :to="{name: '카드매출내역'}">
                                        <th class="" style="">
                                            여신금융협회 <small class="text-muted">(카드매출)</small>
                                        </th>
                                        <status-cell :task="scrapingSources.여신금융협회" v-if="scrapingSources"></status-cell>
                                    </router-link>

                                    <router-link tag="tr" :to="{name: '배달의민족매출집계'}">
                                        <th class="" style="">
                                            배달의민족<small class="text-info ml-1" style="font-family: cursive">Beta</small>
                                        </th>
                                        <status-cell :task="scrapingSources.배달의민족" v-if="scrapingSources"></status-cell>
                                    </router-link>

                                    <router-link tag="tr" :to="{name: '스마트스토어'}">
                                        <th class="" style="">
                                            <del style="">스마트스토어</del><small class="text-info ml-1" style="font-family: cursive"></small>
                                        </th>
                                        <status-cell :task="scrapingSources.스마트스토어" v-if="scrapingSources"></status-cell>
                                    </router-link>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LoginRequired from "@/components/LoginRequired";
    import AccountMixin from "@/util/AccountMixin";
    import StatusBar from "@/components/StatusBar";
    import api from "@/util/api";
    import {saveGlobal} from "@/globals";
    import {validate} from "@/util/res-number";
    import querystring from "querystring";
    import moment from "moment";
    import StatusCell from "@/views/connect/StatusCell";
    import InduceCert from "@/components/InduceCert";
    import FullVersionBanner from "@/views/FullVersionBanner";

    export default {
        name: "DataSources",
        mixins: [LoginRequired, AccountMixin],
        components: {FullVersionBanner, InduceCert, StatusCell, StatusBar},
        data: () => ({
            사업자: null,
            selectedBankAccount: null,
            backNumber: null,
            alert: {title: '', content: ''},
            certs: [],
            certFilter: 'trader',
            coupons: [],
            hometaxDocuments: 0,
            사업자_set: [],
            showAllTraders: false,
            latestBankTransactions: [],
        }),
        computed: {
            needEditResNumber () {
                return this.globals.trader.대표자주민등록번호.includes('*')
            },
            version () {
                return process.env.VUE_APP_VERSION
            },
            traderCerts() {
                return this.certs.filter(cert => cert.사업자_set.filter(t => this.globals.trader.robotax_id === t.id).length)
            },
            businessCert() {
                return this.traderCerts.find(cert => cert.subject_type != '개인' && cert.valid)
            },
        },
        async mounted () {
            await this.reload()
        },
        watch: {
            'globals.trader.대표자주민등록번호': function (to, from_) {
                if (!to) {
                    return
                }
                this.backNumber = to.substring(6, 13).replace(/\*/g, '')
            },
            'globals.trader': function(newTrader, oldTrader) {
                if (!oldTrader || oldTrader.id !== newTrader.id) {
                    this.reload()
                }
            },
            'globals.회계연도': function(to, from_) {
                this.reload()
            }
        },
        methods: {
            async scrapingUpdated() {
                await this.reload(false)
            },
            async reload(reloadScraping=true) {
                if (!this.globals.trader) {
                    return
                }
                this.hometaxDocuments = (await api.get(`robotax/hometaxdocument/annotate/`, {
                    params: {
                        사업자: this.globals.trader.robotax_id,
                        issued__year: this.globals.회계연도,
                        groupby_fields: ['사업자'],
                        count_fields: ['id'],
                        max_fields: ['issued'],
                        ordering: null
                    },
                    paramsSerializer: querystring.stringify
                })).data

                this.사업자_set = await this.globals.robotaxTrader.대표자_사업자_set()

                await this.globals.codes.get('bank/')
                if (this.bankAccounts.length == 0) {
                    for (let a사업자 of this.사업자_set) {
                        let results = (await this.globals.accountResource.bankAccountResource.get('', {params: {사업자: a사업자.id}})).data.results;
                        for (let account of results) {
                            this.bankAccounts.push(account)
                        }
                    }
                }

                if (this.latestBankTransactions.length == 0) {
                    for (let t of this.사업자_set) {
                        this.latestBankTransactions = this.latestBankTransactions.concat((await api.get('robotax/은행거래/latest/', {
                            params: {사업자: t.id}
                        })).data)
                    }
                }
                if (reloadScraping) {
                    this.pollScraping()
                }
            },
            logout () {
                api.post('user/logout/').then(() => {
                    saveGlobal('user', null)
                    saveGlobal('trader', null)
                    saveGlobal('device', null)
                    this.globals.transactions.paginator = null
                    this.globals.transactions.syncing = false
                    this.$router.push({name: '랜딩'})
                })
            },
            updateTrader () {
                if (validate(this.globals.trader.대표자주민등록번호.substring(0, 6), this.backNumber) === false) {
                    return
                }

                this.globals.trader.대표자주민등록번호 = this.globals.trader.대표자주민등록번호.substring(0, 6) + '' + this.backNumber
                api.patch(`robotax/사업자/${this.globals.trader.id}/`, {대표자주민등록번호: this.globals.trader.대표자주민등록번호})
            },
            editNotification (active) {
                api.patch(`device/${this.globals.device.id}/`, {active}).then(res => {
                    this.$set(this.globals, 'device', res.data)
                })
            },
            async reloadCert() {
                this.certs = (await api.get('/robotax/공인인증서/', {
                    params: {client_key: this.globals.user.id},
                    paramsSerializer: querystring.stringify
                })).data.results
            },
            async retryScraping(source, certInfoKey) {
                // 사회보험과 Wetax는 재시도 상황을 보여주기 위해 여기서 프로퍼티를 삭제하지만 서버에는 반영하진 않는다.
                // 서버는 아래의 start api에서 반영한다.
                this.$set(this.businessCert, certInfoKey, undefined)

                api.post('robotax/scrapingtask/start/', {
                    '사업자': this.globals.trader.robotax_id,
                    'sources': [source],
                })

            },
            isNew(date) {
                return moment().diff(moment(date), 'days') <= 2
            }
        }
    }
</script>

