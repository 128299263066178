<template>
    <div class="h-100" v-if="!globals.trader">
        <status-bar v-if="globals.smallScreen"></status-bar>
        <induce-cert></induce-cert>
    </div>
    <split-view v-else>
        <div class="h-100 d-flex flex-column">
            <status-bar v-if="globals.smallScreen"></status-bar>

            <div class="bg-white flex-shrink-0 shadow" style="overflow-x: auto; z-index: 1">
                <b-nav class="bg-white p-2" style="width: max-content;" pills>
                    <b-nav-item :to="{name: groupName}"
                                replace
                                active-class="active"
                                v-for="groupName in ['손익', '증빙', '자산', '태그'].filter(g => g != '태그' || globals.trader.사업자구분 != '개인')"
                                :key="groupName">{{ groupName }}</b-nav-item>
                    <b-nav-item class="ml-auto" :to="{name: '거래내역'}"
                                active-class="active">전체</b-nav-item>
                </b-nav>
            </div>

            <div class="flex-grow-1 overflow-auto">
                <full-version-banner class=""></full-version-banner>
                <div class="card m-3">
                    <div class="card-body">
                        <period-selector :initial-unit="globals.period.unit || 'month'" :initial-step="globals.period.step" @change="setPeriod($event)"></period-selector>
                    </div>
                </div>

                <router-view name="sidebar" :period="globals.period"></router-view>
            </div>
        </div>
    </split-view>
</template>

<script>
    import SplitView from "@/components/SplitView";
    import PeriodSelector from "@/components/PeriodSelector";
    import InduceCert from "@/components/InduceCert";
    import FullVersionBanner from "@/views/FullVersionBanner";
    import StatusBar from "@/components/StatusBar";
    export default {
        name: "Ledgers",
        components: {StatusBar, FullVersionBanner, InduceCert, PeriodSelector, SplitView},
        data() {
            return {
            }
        },
        async mounted() {
            if (!this.globals.trader) {
                return
            }
        },
        methods: {
            setPeriod($event) {
                this.globals.period = $event
                this.globals.회계연도 = $event.begin.year()
            }
        }
    }
</script>

<style scoped>

</style>