<template>
    <div class="px-3">
        <div class="card">
            <div class="card-header">
                고정자산 내역
            </div>
            <div class="card-body px-0">
                <table class="table" v-if="paginator">
                    <thead>
                        <tr>
                            <th>자산명</th>
                            <th>취득일</th>
                            <th>가액</th>
                        </tr>
                    </thead>
                    <tbody>
                        <router-link tag="tr" :to="{name: '고정자산 감가상각', params: {id: asset.id}}"
                                     v-for="asset in paginator.objects" :key="asset.id">
                            <td>{{ asset.자산명 }}</td>
                            <td>{{ localDateFormat(asset.취득일) }}</td>
                            <td class="text-right">{{ moneyFormat(asset.기초가액) }} <i class="fas fa-chevron-right"></i></td>
                        </router-link>
                        <tr v-if="paginator.objects.length == 0">
                            <td colspan="3">
                                등록된 고정자산이 없습니다.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import api from "@/util/api";
    import Paginator from "@/util/paginator";

    export default {
        name: "FixedAssets",
        data() {
            return {
                paginator: null,
            }
        },
        mounted() {
            this.paginator = new Paginator(api.get, 'robotax/고정자산/?사업자=' + this.globals.trader.robotax_id)
            this.paginator.loadNext()
        }
    }
</script>

<style scoped>

</style>