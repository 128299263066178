<template>
    <b-modal id="환급계좌-입력-modal" title="환급계좌 입력" ok-only ok-title="닫기" centered>
        <p>환급세액이 있을 때 환급 받으실 계좌를 선택해주세요. 국세청에서 환급계좌로 지정가능한 은행의 계좌만 선택가능합니다.</p>
        <b-list-group>
            <template v-for="(traderAccounts, 사업자명) in bankAccounts">
                <b-list-group-item v-if="traderAccounts.length === 0" :key="사업자명">
                    선택 가능한 환급계좌가 없습니다.<br>
                    설정 > 은행계좌연동 에서 공인인증서나 빠른조회를 등록하시면, 해당 계좌들 중 하나를 선택하실 수 있습니다.<br>
                    부가세 환급대상이 아니거나, 국세청에서 직접 입금받으시는 경우에는 선택하지 않으셔도 됩니다.
                    <div><small class="text-danger">* 케이뱅크는 부가세 환급계좌로 등록하실 수 없습니다.</small></div>
                </b-list-group-item>
                <template v-else>
                    <b-list-group-item variant="secondary" :key="사업자명">{{ 사업자명 }} 계좌</b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between"
                                       :active="account.금융기관코드 == 금융기관코드 && account.account_no == 계좌번호"
                                       v-for="account in traderAccounts" :key="account.id"
                                       @click="handleSelect(account)">
                    <span>
                        <strong class="mr-2">{{ account.bank_name }}</strong> {{ account.account_no }}
                        <span class="text-danger" v-if="!isRefundable(account.금융기관코드)">(선택불가)</span>
                    </span>
                        <span v-if="isSelected(account)">
                        <i class="fas fa-check text-success"></i>
                    </span>
                    </b-list-group-item>
                </template>
            </template>
        </b-list-group>
    </b-modal>
</template>
<script>
    import {api} from "@/util/api";

    export default {
        name: 'BankAccountSelectorModal',
        props: {
            금융기관코드: {},
            계좌번호: {},
            select: {}
        },
        data() {
            return {
                bankAccounts: {},
            }
        },
        async mounted() {
            await this.globals.codes.get('공통은행코드/')
            this.$set(this.bankAccounts, this.globals.trader.법인명_상호,
                      await this.globals.accountResource.bankAccounts(this.globals.trader.robotax_id))
            let 사업자_set = (await api.get(`robotax/사업자/?대표자_set=${this.globals.trader.robotax_id}`)).data.results
            for (let trader of 사업자_set) {
                let accounts = await this.globals.accountResource.bankAccounts(trader.id);
                if (accounts.length) {
                    this.$set(this.bankAccounts, trader.법인명_상호, accounts)
                }
            }
        },
        methods: {
            isSelected(account) {
                return account.금융기관코드 == this.금융기관코드 && account.account_no == this.계좌번호
            },
            isRefundable(bank_code) {
                return Object.keys(this.globals.codes.cache['공통은행코드/']).includes(bank_code)
            },
            handleSelect(account) {
                if (!this.isSelected(account) && !this.isRefundable(account.금융기관코드)) {
                    alert('선택가능한 환급계좌를 선택주세요.')
                    return
                }
                this.$emit('select', account)
            }
        }
    }
</script>
