<template>
    <div class="" v-if="asset">
        <div class="card mx-3">
            <div class="card-header">
                고정자산 정보
            </div>
            <div class="card-body">
                <table class="table">
                    <tbody>
                        <tr>
                            <th>자산명</th>
                            <td class="text-right">{{ asset.자산명 }}</td>
                        </tr>
                        <tr>
                            <th>종류</th>
                            <td class="text-right">{{ asset.종류 }}</td>
                        </tr>
                        <tr>
                            <th>취득일</th>
                            <td class="text-right">{{ localDateFormat(asset.취득일) }}</td>
                        </tr>
                        <tr>
                            <th>감가상각방법</th>
                            <td class="text-right">{{ asset.감가상각방법_display }}</td>
                        </tr>
                        <tr>
                            <th>내용연수</th>
                            <td class="text-right">{{ asset.내용연수 }}년</td>
                        </tr>
                        <tr>
                            <th>기초가액</th>
                            <td class="text-right">{{ moneyFormat(asset.기초가액) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="card m-3">
            <div class="card-header">
                감가상각비 내역
                <button class="float-right btn btn-sm btn-outline-primary" @click="depreciate"><i class="fas fa-sync"></i> 자동등록</button>
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr class="text-center">
                            <th>연도</th>
                            <th>감가상각금액</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="transaction in asset.감가상각비_set" :key="transaction.id">
                            <td>{{ transaction.거래일.slice(0, 4) }}년</td>
                            <td class="text-right">{{ moneyFormat(transaction.공급가액) }}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>

    </div>
</template>

<script>
    import api from "@/util/api";

    export default {
        name: "FixedAssetDepreciation",
        data() {
            return {
                asset: null
            }
        },
        async mounted() {
            this.asset = (await api.get(`robotax/고정자산/${this.$route.params.id}/`)).data
        },
        methods: {
            async depreciate() {
                await api.post(`robotax/고정자산/${this.$route.params.id}/감가상각비_전표등록/`)
                this.asset = (await api.get(`robotax/고정자산/${this.$route.params.id}/`)).data
            }
        }
    }
</script>
