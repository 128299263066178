<template>
    <div>
        <nav class="navbar navbar-light titlebar">
            <back-button></back-button>
            <h5>소셜 미디어 공유하기</h5>
            <span></span>
        </nav>
        <div>
            <div class="card mx-3">
                <div class="card-body">
                    <p>소셜 미디어에 키퍼 간편장부를 공유해주시면 1개월 무료이용 쿠폰을 드립니다.</p>
                    <p>
                        다음 링크를 복사해서 인스타그램, 페이스북, 트위터 등에 공유해주세요.
                    </p>
                    <div>
                        <strong>공유할 링크</strong>
                        <b-button variant="outline-secondary" size="sm"
                                  @click="copyToClipboard($refs.promotionLink, '키퍼 간편장부 링크가 복사되었습니다.')">
                            <i class="fas fa-copy"></i> 링크복사
                        </b-button>
                    </div>
                    <div class="alert-light p-1">
                        <span class="text-primary text-underline" ref="promotionLink">https://keeper.business/app/landing?utm_campaign=social_share_promotion</span>
                    </div>
                </div>
            </div>

            <div class="card m-3">
                <div class="card-header">
                    소셜 미디어에 올린 게시물 링크
                </div>
                <div class="card-body">
                    <div v-if="referral">
                        <p>게시물의 링크가 등록되었습니다. 담당자가 확인한 후에 쿠폰을 발급해드리겠습니다.</p>
                        <label>등록한 링크</label>
                        <div class="alert-success p-1"><a :href="referral.link">{{ referral.link }}</a></div>
                    </div>
                    <form v-else @submit.prevent="submit">
                        <b-form-group>
                            <label>소셜 미디어에 위의 링크를 공유하셨으면 <strong>게시물의 링크를 복사</strong>해서 아래에 등록해주세요.</label>
                            <b-input type="url" v-model="postedLink"></b-input>
                        </b-form-group>
                        <button class="btn btn-outline-primary">등록</button>
                        <p>게시물이 등록되면 담당자가 확인한 후 쿠폰을 발급해드립니다.</p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BackButton from "@/components/BackButton";
    import {api} from "@/util/api";
    export default {
        name: "SocialShare",
        components: {BackButton},
        data() {
            return {
                referral: null,
                postedLink: null
            }
        },
        async mounted() {
            await this.reload()
        },
        methods: {
            async reload() {
                this.referral = (await api.get(`referral/?referrer=${this.globals.user.id}&link__isnull=False`)).data.results[0]
            },
            async submit() {
                api.post('referral/', {
                    referrer: this.globals.user.id,
                    link: this.postedLink,
                    type: 'social'
                }).then(res => {
                    this.referral = res.data
                }).catch(err => {
                    alert(err.response.data)
                })
            }
        }
    }
</script>

<style scoped>

</style>
