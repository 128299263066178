<template>
    <div v-if="report">
        <div class="card m-3">
            <div class="card-body">
                <table class="table bg-white">
                    <tbody>
                        <tr>
                            <th style="min-width: 80px">귀속년도</th>
                            <td class="text-right">{{ report.귀속년도 }}년</td>
                        </tr>
                        <tr>
                            <th>매출수입금액합계</th>
                            <td class="text-right text-primary">
                                <div><strong>{{ moneyFormat(report.records[0].매출수입금액합계) }}</strong></div>
                            </td>
                        </tr>
                        <tr>
                            <th>매입금액합계</th>
                            <td class="text-right text-primary">
                                <div><strong>{{ moneyFormat(report.records[0].매입금액합계) }}</strong></div>
                            </td>
                        </tr>
                        <tr>
                            <th>신고기한</th>
                            <td class="text-right">
                                <div><strong>{{ localDateFormat(report.납기일) }}</strong></div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" class="text-center">
                                <div :class="`text-` + report.status.color">
                                    <strong>{{ report.status.description || report.status.label }}</strong>
                                </div>

                                <div class="mt-2" v-if="report.finalized && (report.status.color == 'danger' || report.status.color == 'warning')">
                                    <b-button variant="outline-secondary" @click="refresh('전자신고결과조회')" :disabled="refreshing">
                                        <span v-if="!refreshing">홈택스 신고현황 다시 확인하기</span>
                                        <span v-else>홈택스 신고납부현황 다시 확인 중...</span>
                                    </b-button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>

        <div class="card m-3">
            <div class="card-header">신고서 내역</div>
            <div class="card-body">
                <TaxReportBrowser resource-name="사업장현황신고" :id="report.id"></TaxReportBrowser>
            </div>
        </div>
    </div>
</template>

<script>
    import TaxReportBrowser from "@/components/TaxReportBrowser";
    import ScrapingTaskPollMixin from "@/util/ScrapingTaskPollMixin";
    import Decimal from "decimal.js";

    export default {
        name: "BusinessPlaceReport",
        components: {TaxReportBrowser},
        mixins: [ScrapingTaskPollMixin],
        props: ['id'],
        watch: {
            refreshing () {
                if (this.refreshing === false) {
                    this.reload()
                }
            }
        },
        data() {
            return {
                report: null,
            }
        },
        computed: {
            canRefund: function() {
                return new Decimal(this.report.records[1].차감납부할세액 || 0).isNegative()
            },
        },
        async mounted() {
            await this.reload()
        },
        methods: {
            async reload () {
                // TODO refactor 코드 관리
                this.report = (await this.globals.사업장현황신고.get(this.id + '/')).object

                // TODO 테스트 계정 - 신고하기 활성화
                if (this.globals.user.username == 'test_payment') {
                    this.report.status = true
                }
            },
            toggle: function(record) {
                this.$set(record, '$expanded', !record.$expanded)
            },
            getRecord: function(name) {
                return this.report.records.filter(r => r.서식명 == name)[0]
            }
        }
    }
</script>

<style scoped>

</style>