<template>
    <split-view :root-route="{name: '알림'}">
        <template v-slot:no-child-route-view>
            <div class="card m-3">
                <div class="card-body">
                    <p>내용을 보고 싶은 알림을 선택하세요.</p>
                </div>
            </div>
        </template>

        <nav class="navbar navbar-light titlebar">
            <back-button></back-button>
            <h5 class="mt-2">알림</h5>
            <span></span>
        </nav>
        <b-form class="px-3 pb-2 text-white" inline>
            <b-form-checkbox @change="$router.push({query: {...$route.query, status__in: $event ? ['new,read,waiting'] : undefined}})">미완료</b-form-checkbox>
            <b-dropdown id="trader-dropdown" class="ml-auto" variant="outline-secondary" size="sm" toggle-class="border-0"
                        :text="$route.query.사업자명 || '사업자 전체'">
                <b-dropdown-item :to="{query: {...$route.query, 사업자: undefined, 사업자명: undefined}}">
                    사업자 전체
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item v-for="trader in globals.user.사업자_set.filter(t => !t.폐업일)" :key="trader.id" :to="{query: {...$route.query, 사업자: trader.id, 사업자명: trader.법인명_상호}}">
                    {{ 법인명_상호_display(trader) }} <small>({{ trader.사업자구분 }})</small>
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item v-for="trader in globals.user.사업자_set.filter(t => t.폐업일)" :key="trader.id" :to="{query: {...$route.query, 사업자: trader.id, 사업자명: trader.법인명_상호}}">
                    {{ 법인명_상호_display(trader) }} <small>({{ trader.사업자구분 }})</small>
                </b-dropdown-item>
            </b-dropdown>
        </b-form>
        <div class="bg-white"
            v-infiniteScroll="loadNext"
            infinite-scroll-disabled="false"
            infinite-scroll-distance="30">
            <table class="table" v-if="taskPaginator">
                <tbody>
                    <tr v-if="taskPaginator.loading"></tr>
                    <tr v-else-if="!visibleTasks || !visibleTasks.length">
                        <td class="text-center">아직 알림이 없어요.</td>
                    </tr>
                    <tr v-for="task in visibleTasks" :key="task.id" @click="show(task)">
                        <td>
                            <span class="text-muted">{{ localDateFormat(task.updated) }}</span>
                            <span class="float-right text-secondary" v-if="task.사업자">{{ task.사업자.법인명_상호 }}</span>
                            <div>
                                <strong v-if="task.status != 'done'">{{ task.title }}</strong>
                                <span v-else>{{ task.title }}</span>
                            </div>
                            <div class="" v-if="task.is_confirmed != null">
                                <span class="badge badge-success" v-if="task.is_confirmed">
                                    <i class="fas fa-check"></i> 동의
                                </span>
                                <span class="badge badge-secondary" v-else>
                                    <i class="fas fa-thumbs-down"></i> 거절
                                </span>
                            </div>
                            <div v-if="task.comment_set.length">
                                <span class="badge" :class="task.status == 'done' ? 'badge-secondary' : 'badge-light'">
                                    댓글 <strong>{{ task.comment_set.length }}</strong>개
                                    <span class="ml-2 text-secondary">{{ humanizeDate(task.comment_set[task.comment_set.length - 1].created) }}</span>
                                </span>
                            </div>
                        </td>
                        <td class="align-middle">
                           <i class="fas fa-chevron-right text-muted" v-if="task.content"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </split-view>
</template>

<script>
    import axios from 'axios';
    import infiniteScroll from 'vue-infinite-scroll'
    import Paginator from "@/util/paginator";
    import {api, silentApi} from "@/util/api";
    import SplitView from "@/components/SplitView";
    import LoginRequired from "@/components/LoginRequired";
    import BackButton from "@/components/BackButton";
    import querystring from "querystring";

    export default {
        name: "Tasks",
        mixins: [LoginRequired],
        components: {BackButton, SplitView},
        directives: { infiniteScroll },
        data() {
            return {
                taskPaginator: null
            }
        },
        computed: {
            visibleTasks() {
                if (!this.taskPaginator) { return null }
                return this.taskPaginator.objects.filter(t => t.task_type != 'invisible')
            }
        },
        watch: {
            $route() {
                this.reload()
            }
        },
        async mounted() {
            await this.reload()
            setTimeout(this.read, 5000)
        },
        methods: {
            async reload() {
                this.globals.loadingMessage = '알림을 불러오는 중 입니다..'
                this.taskPaginator = new Paginator(api.get, 'task/?' + querystring.stringify(this.$route.query))
                this.taskPaginator.loadNext(async res => {
                    this.globals.unfinishedTaskCount = (await silentApi.get('task/unfinished_count/')).data
                })
            },
            show(task) {
                if (task.task_type == 'HometaxDocument' && task.data.source === '전자신고조회결과') {
                    this.$router.push({name: '세금신고납부 상세', params: {id: task.robotax_id, transition: 'push'}})
                } else if (task.task_type == 'HometaxDocument' && task.data.source === '고지내역') {
                    this.$router.push({name: '납부안내', query: {
                        title: task.data.title, accountNo: task.data.data.전자납부번호, amount: task.data.amount, type: '국세', id: task.data.id}
                    })
                } else if (task.task_type == '체납내역') {
                    this.$router.push({name: '세금 체납내역', params: {transition: 'push'}})
                } else if (task.content) {
                    this.$router.push({name: '알림 상세', params: {id: task.id, transition: 'push'}})
                } else if (task.task_type && task.task_type.includes('희망회복자금')) {
                    this.$router.push({name: '희망회복자금', params: {transition: 'push'}})
                } else {
                    return
                }
            },
            read() {
                let readableTasks = this.visibleTasks.filter(t => {
                    if (t.user && this.globals.user.id !== t.user) {
                        return false
                    } else if (t.사업자 && this.globals.user.is_staff && !t.사업자.is_staff_trader) {
                        return false
                    }
                    return t.status === 'new'
                })

                axios.all(
                    readableTasks.map(task => api.put(`task/${task.id}/read/`))
                ).then(async res => this.globals.unfinishedTaskCount = (await silentApi.get('task/unfinished_count/')).data)
            },
            loadNext () {
                if (this.taskPaginator.next)
                    this.globals.loadingMessage = '알림을 불러오는 중 입니다..'
                this.taskPaginator.loadNext(() => {
                    setTimeout(this.read, 5000)
                })
            }
        }
    }
</script>

<style scoped>

</style>
