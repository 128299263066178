<template>
    <form class="form" v-if="!task.is_confirmed">
        <b-form-group>
            <label>메일주소</label>
            <b-form-input type="email" v-model="email"></b-form-input>
        </b-form-group>
        <button class="btn btn-primary" @click.prevent="send" :disabled="sent">
            {{ message ? '다시 보내기' : '인증메일 발송' }}
        </button>
        <div class="alert alert-success mt-3" v-if="message">{{ message }}</div>
    </form>
    <div class="alert alert-success" v-else>
        <i class="fas fa-check"></i> 이메일 주소가 확인되었습니다.
    </div>
</template>

<script>
    import api from "@/util/api";

    export default {
        name: "EmailVerification",
        props: ['task'],
        data() {
            return {
                email: null,
                message: null,
                verified: false,
                debounce: null,
                sent: false,
            }
        },
        mounted() {
            this.email = this.globals.user.email
        },
        beforeDestroy() {
            if (this.debounce) {
                clearInterval(this.debounce)
            }
        },
        methods: {
            async send() {
                if (this.sent) { return }

                await api.post('emailverification/', {
                    user: this.globals.user.id,
                    email: this.email
                })
                this.message = '이메일이 발송되었습니다. 메일 확인 부탁드립니다.'
                this.$emit('sent')
                this.sent = true
                this.debounce = setTimeout(() => this.sent = false, 5000)
            }
        }

    }
</script>

<style scoped>

</style>
