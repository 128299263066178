<template>
    <div>
        <nav class="navbar navbar-light titlebar text-white">
            <back-button></back-button>
            <h5 class="mt-2">
                경리대행 안내
            </h5>
            <span>&nbsp;&nbsp;&nbsp;</span>
        </nav>
        <div class="card mx-3">
            <div class="card-body">
                <p>
                    사업하느라 바쁜데 매일매일 잡무는 쏟아지고 그렇다고 경리 한 명 채용할 정도는 아니고, 고민되시죠?
                    경리 급여보다 훨씬 저렴한 비용으로 경리대행 서비스를 이용해보세요. 전산회계 자격증의 전문가가 꼼꼼하게 챙겨드립니다.
                </p>
                <p>지금 바로 상담해보세요.</p>
                <div class="text-center">
                    <div class="kakao-talk-channel-chat-button" onmousedown="trackEvent('click_talk')" data-channel-public-id="_Fxkhtj" data-title="consult" data-size="small" data-color="yellow" data-shape="pc" data-support-multiple-densities="true"></div>
                </div>
            </div>
        </div>
        <div class="card m-3">
            <div class="card-header">
                서비스 상세내역
            </div>
            <div class="card-body px-0">
                <ul>
                    <li>키퍼 장부정리 도우미</li>
                    <li>
                        급여관리
                        <ul>
                            <li>직원별 정보 관리</li>
                            <li>매월 급여 이체</li>
                            <li>4대보험 관리</li>
                            <li>원천세 신고 및 납부</li>
                            <li>연말정산</li>
                            <li>원천징수영수증 등 각종 증명서 발급</li>
                            <li>각종 세제 혜택, 지원금</li>
                        </ul>
                    </li>
                    <li>부가가치세 신고 및 납부</li>
                    <li>법인세, 종합소득세 신고 및 납부</li>
                    <li>
                        거래 관리
                        <ul>
                            <li>세금계산서 발행</li>
                            <li>미수금 관리</li>
                            <li>미지급금 확인 및 입금 처리</li>
                        </ul>
                    </li>
                    <li>각종 공과금, 관리비, 소모품비 등 입금 대행</li>
                    <li>기타 고객의 요청에 따른 세무회계 업무</li>
                </ul>
            </div>
        </div>

        <div class="card m-3">
            <div class="card-header">
                이용료
            </div>
            <div class="card-body px-0">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th style="min-width: 100px">기준</th>
                            <th style="min-width: 90px">이용료 (매월)</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>3인 이하 사업장, <br>매출액 1억 이하</td>
                        <td>10만원</td>
                    </tr>
                    <tr>
                        <td>5인 이하 사업장, <br>매출액 2억 이하</td>
                        <td>20만원</td>
                    </tr>
                    <tr>
                        <td>10인 이하 사업장, <br>매출액 4억 이하</td>
                        <td>30만원</td>
                    </tr>
                    <tr>
                        <td>10인 이상 사업장 또는 <br>매출액 4억 이상</td>
                        <td>별도 협의</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import BackButton from "@/components/BackButton";
    export default {
        name: "HanaroIntro",
        components: {BackButton},
        mounted() {
            this.$nextTick(() => {
                window.kakaoAsyncInit = function () {
                    window.Kakao.Channel.createChatButton({
                        container: '.kakao-talk-channel-chat-button'
                    });
                };

                (function (d, s, id) {
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) return;
                    js = d.createElement(s);
                    js.id = id;
                    js.src = "//developers.kakao.com/sdk/js/kakao.plusfriend.min.js";
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'kakao-js-sdk'));
            })
        }
    }
</script>

<style scoped>

</style>