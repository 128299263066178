<template>
    <b-modal id="tagging-modal" ok-only ok-title="닫기" centered>
        <template v-slot:modal-title v-if="selected">
            <small class="">
                {{ localDateFormat(selected.거래일) }}
                -
                {{selected.증빙종류}} {{selected.은행입출금}} {{selected.매출매입}}
            </small>
        </template>
        <template v-if="selected">
            <h5 class="text-center"><small class="text-secondary">적요:</small> {{ selected.적요 }}</h5>
            <table class="table table-sm">
                <tbody>
                    <tr>
                        <th>거래처명</th>
                        <td class="text-right">{{ selected.거래처명 }}</td>
                    </tr>
                    <tr>
                        <th>총금액</th>
                        <td class="text-right"
                            :class="{'text-success': selected.매출매입 === '매출', 'text-primary': selected.은행입출금 == '입금'}">
                            <span style="">{{ moneyFormat(Number(selected.공급가액) + Number(selected.부가세)) }}</span>
                        </td>
                    </tr>
                    <tr>
                        <th>공급가액</th>
                        <td class="text-right">{{ moneyFormat(selected.공급가액) }}</td>
                    </tr>
                    <tr>
                        <th>부가세</th>
                        <td class="text-right">{{ moneyFormat(selected.부가세) }}</td>
                    </tr>
                    <tr v-for="field in ['품명', '비고']" :key="field" v-show="selected[field]">
                        <th>{{ field }}</th>
                        <td class="text-right">{{ selected[field] }}</td>
                    </tr>
                    <tr v-if="selected.증빙종류 == '은행'">
                        <th>증빙</th>
                        <td class="text-right">
                                <span class="text-primary text-underline" @click="$emit('select-other', selected.source)"
                                      v-if="selected.source">
                                    <i class="fas fa-link"></i> 연결된 증빙보기
                                    <i class="fas fa-times text-secondary ml-2" @click="unrelate"></i>
                                </span>
                            <span class="text-underline" v-b-modal:relation-help-modal v-else>연결된 증빙없음</span>
                        </td>
                    </tr>
                    <tr v-if="selected.증빙종류 == '세금계산서'">
                        <th>실제 입출금</th>
                        <td class="text-right">
                            <div class="text-primary text-underline"
                                 v-for="relatedId in selected.related_set"
                                 :key="relatedId">
                                    <span @click="$emit('select-other', relatedId)">
                                        <i class="fas fa-link"></i> 연결된 은행거래 보기
                                    </span>
                                <i class="fas fa-times text-secondary ml-2" @click="unrelate"></i>
                            </div>
                            <div class="text-underline" v-b-modal:relation-help-modal
                                 v-if="selected.related_set.length == 0">
                                연결된 은행입출금 없음
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <b-modal id="relation-help-modal" title="전표 연결 안내" ok-title="연결할 전표 선택" cancel-title="닫기"
                     @ok="$emit('begin-relating')">
                <p>
                    세금계산서에 해당하는 은행 입출금 내역을 찾아서 연결해두면 미수금, 미지급금 관리를 할 수 있고
                    결산하는데도 도움이 됩니다. 관련된 세금계산서를 찾아서 연결해보세요.
                </p>
            </b-modal>

            <template v-if="globals.trader.사업자구분 != '개인'">
                <transaction-tagging class="mt-2"
                                     :transaction="selected"
                                     @tag="$bvModal.hide('tagging-modal'); $emit('tag', $event)"
                                     @untag="$bvModal.hide('tagging-modal')"
                                     @remove-recommend="$bvModal.hide('tagging-modal')"></transaction-tagging>
                <tagging-help class="mt-1" :transaction="selected"></tagging-help>
            </template>
            <p class="alert alert-light">
                사업자가 아닌 개인은 태그로 거래내역을 분류하실 수 없습니다. 은행계좌 등을 사업자에 연결해주시면 사업자를 위한 각종 간편장부 기능이 지원됩니다.
            </p>

            <form class="form">
                <b-input-group prepend="메모">
                    <b-textarea v-model="selected.메모" style="height: 50px" @input="memoDirty = true"></b-textarea>
                    <b-input-group-append>
                        <button class="btn btn-outline-primary" :disabled="!memoDirty" @click.prevent="update전표()">저장
                        </button>
                    </b-input-group-append>
                </b-input-group>
            </form>

            <b-button variant="outline-danger" class="w-100" v-if="!selected.전자세금 || selected.증빙종류 === '증빙없음'"
                      @click="deleteTransaction(selected)">삭제
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import TaggingHelp from "@/views/transaction/TaggingHelp"
    import TransactionTagging from "@/views/transaction/TransactionTagging"
    import api, {silentApi} from "@/util/api";

    export default {
        name: 'transaction-detail',
        components: {TaggingHelp, TransactionTagging},
        props: {
            selectTransactionById: {},
            selected: {},
        },
        data() {
            return {
                memoDirty: false,
            }
        },
        methods: {
            async deleteTransaction(transaction) {
                try {
                    await silentApi.delete(`robotax/전표/${transaction.id}/`)
                    this.globals.transactions.paginator.objects = this.globals.transactions.paginator.objects.filter(t => t != transaction)
                    this.$bvModal.hide('tagging-modal')
                } catch (e) {
                    alert(JSON.stringify(e.response.data))
                }
            },
            async update전표() {
                await api.put(`robotax/전표/${this.selected.id}/`, this.selected)
                this.memoDirty = false
            },
            async unrelate() {
                if (confirm('두 전표의 연관 관계를 끊으시겠습니까?')) {
                    Object.assign(this.selected, (await api.delete(`robotax/전표/${this.selected.id}/unrelate/`)).data)
                }
            },
        }
    }
</script>