<template>
    <div class="h-100 d-flex flex-column">
        <nav class="navbar navbar-light titlebar">
            <back-button></back-button>
            <h5 class="mt-2">
                세금계산서/계산서
            </h5>
            <router-link class="btn btn-sm btn-link" :to="{name: '세금계산서 상세', params: {id: '0'}, query: {mode: 'issue'}}">신규발급</router-link>
        </nav>
        <scraping-account-panel class="mx-3 mb-3" source="세금계산서" scraping-only></scraping-account-panel>
        <div class="h-100 bg-white overflow-auto" v-infinite-scroll="loadNext" infinite-scroll-distance="30">
            <table class="table" v-if="paginator">
                <thead>
                    <tr>
                        <th class="text-danger">공급자</th>
                        <th class="text-primary text-right">공급받는자</th>
                    </tr>
                </thead>
                <tbody>
                    <router-link tag="tr" :to="{name: '세금계산서 상세', params: {id: invoice.id}}"
                                 v-for="invoice in paginator.objects" :key="invoice.id">
                        <td colspan="2">
                            <div>
                                <div class="text-danger float-left"
                                     :class="{'font-weight-bold': invoice.data.TaxInvoiceTradeSettlement.InvoicerParty.ID == globals.trader.사업자등록번호}">
                                    {{ invoice.data.TaxInvoiceTradeSettlement.InvoicerParty.NameText }}
                                </div>
                                <div class="text-primary text-right"
                                     :class="{'font-weight-bold': invoice.data.TaxInvoiceTradeSettlement.InvoiceeParty.ID == globals.trader.사업자등록번호}">
                                    {{ invoice.data.TaxInvoiceTradeSettlement.InvoiceeParty.NameText }}
                                </div>
                            </div>
                            <div><span class="text-underline">[{{invoice.매출매입}}]</span> {{ invoice.data.TaxInvoiceTradeLineItem[0].NameText }}</div>
                            <div class="float-left">
                                <div><small class="text-muted">작성일자</small> {{ invoice.data.TaxInvoiceDocument.IssueDateTime }}</div>
                                <div><small class="text-muted">전송일자</small> {{ invoice.전송일자 }}</div>
                            </div>
                            <div class="text-right">
                                <div class="text-monospace">{{ moneyFormat(invoice.data.TaxInvoiceTradeSettlement.SpecifiedMonetarySummation.ChargeTotalAmount) }}</div>
                                <div class="text-monospace">{{ moneyFormat(invoice.data.TaxInvoiceTradeSettlement.SpecifiedMonetarySummation.TaxTotalAmount) }}</div>
                                <div class="text-monospace"><strong>{{ moneyFormat(invoice.data.TaxInvoiceTradeSettlement.SpecifiedMonetarySummation.GrandTotalAmount) }}</strong></div>
                            </div>
                        </td>
                    </router-link>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import {api} from "@/util/api";
    import Paginator from "@/util/paginator";
    import BackButton from "@/components/BackButton";
    import infiniteScroll from "vue-infinite-scroll";
    import ScrapingAccountPanel from "@/views/connect/ScrapingAccountPanel";
    import AccountMixin from "@/util/AccountMixin";

    export default {
        name: "Invoices",
        mixins: [AccountMixin],
        components: {ScrapingAccountPanel, BackButton},
        directives: { infiniteScroll },
        data() {
            return {
                paginator: null
            }
        },
        computed: {
        },
        watch: {
            'scrapingSources.세금계산서.status'(newValue) {
                if (newValue == 'success') {
                    this.reload()
                }
            }
        },
        async mounted() {
            this.reload()
            this.pollScraping('세금계산서')
        },
        methods: {
            reload() {
                this.paginator = new Paginator(api.get, `robotax/세금계산서/?사업자=${this.globals.trader.robotax_id}&ordering=-작성일자`)
                this.paginator.loadNext()
            },
            loadNext () {
                this.paginator.loadNext()
            }
        }
    }
</script>

<style scoped>

</style>