<template>
    <div>
        <nav class="navbar navbar-light titlebar pt-0">
            <back-button></back-button>
            <h5 class="mt-2">{{ $route.query.model }}</h5>
            <span></span>
        </nav>
        <div class="bg-white" v-if="paginator">
            <table class="table">
                <tbody>
                    <tr v-for="item in paginator.objects" :key="item.id">
                        <td>{{ localDateFormat(item[Object.keys(sourceInfo.fields)[0]]) }}</td>
                        <td>
                            <table class="table table-sm table-striped">
                                <tbody>
                                    <tr v-for="field in Object.keys(sourceInfo.fields)" :key="field">
                                        <th>{{ field }}</th>
                                        <td>{{ item[sourceInfo.fields[field]] }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr v-if="paginator.count == 0">
                        <td>자료가 없습니다.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import querystring from "querystring"
    import api from "@/util/api"
    import Paginator from "@/util/paginator";
    import BackButton from "@/components/BackButton";

    export default {
        name: "TransactionSources",
        components: {BackButton},
        data() {
            return {
                paginator: null,
                sourceInfo: {}
            }
        },
        async mounted() {
            this.sourceInfo = (await this.globals.consts.get('transaction_sources_field_map/')).data[this.$route.query.model]

            let params = {사업자: this.globals.trader.robotax_id}
            params[this.sourceInfo.date_field + '__gte'] = this.$route.query.begin
            params[this.sourceInfo.date_field + '__lte'] = this.$route.query.end
            params['ordering'] = '-' + this.sourceInfo.date_field.replace('__date', '')
            this.paginator = new Paginator(api.get, `robotax/${this.$route.query.model}/?` + querystring.stringify(params))
            this.paginator.loadNext()
        }
    }
</script>

<style scoped>

</style>
