import Vue from 'vue'
// @ts-ignore
import mixpanel from 'mixpanel-browser';
import moment from "moment";
import BootstrapVue from 'bootstrap-vue'
import * as Sentry from "@sentry/vue";
import {Integrations} from "@sentry/tracing";
// @ts-ignore
import VueCarousel from 'vue-carousel'
import '@fortawesome/fontawesome-free/css/all.css'

import App from './App.vue'
import './registerServiceWorker'
import {globals} from '@/globals'
// @ts-ignore
import {VueCommonUtils, trackPageView} from "@/util/vue-commons";

import VueRouter from "vue-router";
import Terms from "@/views/Terms.vue";
import RegisterDelegateTerms from "@/views/RegisterDelegateTerms.vue";
import Dashboard from "@/views/Dashboard.vue";
import Landing from "@/views/Landing.vue";
import Transaction from "@/views/transaction/Transaction.vue";
import Ledgers from "@/views/transaction/Ledgers.vue";
import TransactionsByTypes from "@/views/transaction/TransactionsByTypes.vue";
import Tagging from "@/views/transaction/Tagging.vue";
import PartyList from "@/views/transaction/PartyList.vue";
import PartyDetail from "@/views/transaction/PartyDetail.vue";
import FixedAssets from "@/views/transaction/FixedAssets.vue";
import FixedAssetDepreciation from "@/views/transaction/FixedAssetDepreciation.vue";
import DataSources from "@/views/connect/DataSources.vue";
import SmartStoreReport from "@/views/connect/SmartStoreReport.vue";
import YoutubeReport from "@/views/connect/YoutubeReport.vue";
import Setting from "@/views/Setting.vue";
import Pricing from "@/views/payment/Pricing.vue";
import RegularPayment from "@/views/payment/RegularPayment.vue";
import TaxMenu from "@/views/taxreport/TaxMenu.vue";
import TaxList from "@/views/taxreport/TaxList.vue";
import HometaxDocuments from "@/views/hometax/HometaxDocuments.vue";
import HometaxReports from "@/views/hometax/HometaxReports.vue";
import HometaxReportDetail from "@/views/hometax/HometaxReportDetail.vue";
import HometaxPayments from "@/views/hometax/HometaxPayments.vue";
import HometaxRefunds from "@/views/hometax/HometaxRefunds.vue";
import HometaxNonpayments from "@/views/hometax/HometaxNonpayments.vue";
import HometaxNotices from "@/views/hometax/HometaxNotices.vue";
import SI4NPayments from "@/views/SI4NPayments.vue";
import LocalTax from "@/views/LocalTax.vue";
import VatTabs from "@/views/vat/VatTabs.vue";
import VatReport from "@/views/vat/VatReport.vue";
import VatReportConfirm from "@/views/vat/VatReportConfirm.vue";
import VatTransactions from "@/views/vat/VatTransactions.vue";
import BusinessPlaceReportMenu from "@/views/vat-free/BusinessPlaceReportMenu.vue";
import BusinessPlaceReport from "@/views/vat-free/BusinessPlaceReport.vue";
import BusinessPlaceReportConfirm from "@/views/vat-free/BusinessPlaceReportConfirm.vue";
import IncomeTaxTabs from "@/views/income-tax/IncomeTaxTabs.vue";
import IncomeTaxGuide from "@/views/income-tax/IncomeTaxGuide.vue";
import IncomeTaxReport from "@/views/income-tax/IncomeTaxReport.vue";
import IncomeTaxReportConfirm from "@/views/income-tax/IncomeTaxReportConfirm.vue";
import IncomeRecords from "@/views/income-tax/IncomeRecords.vue";
import IncomeStatement from "@/views/income-tax/IncomeStatement.vue";
import IncomeTaxTransactions from "@/views/income-tax/IncomeTaxTransactions.vue";
import TaxPayment from "@/views/TaxPayment.vue";
import Chat from "@/views/Chat.vue";
import Tasks from "@/views/Tasks.vue";
import TaskDetail from "@/views/TaskDetail.vue";
import WeeklyReport from "@/views/WeeklyReport.vue";
import InvoiceDetail from "@/views/invoice/InvoiceDetail.vue";
import Invoices from "@/views/invoice/Invoices.vue";
import CashReceipts from "@/views/hometax/CashReceipts.vue";
import CardPurchases from "@/views/hometax/CardPurchases.vue";
import CardRevenueMonthlySum from "@/views/hometax/CardRevenueMonthlySum.vue";
import GovernmentSupport from "@/views/GovernmentSupport.vue";
import TaxAgentIntro from "@/views/TaxAgentIntro.vue";
import HanaroIntro from "@/views/HanaroIntro.vue";
import OpenbankingAuth from "@/views/openbanking/Auth.vue";
import OpenbankingManage from "@/views/openbanking/Manage.vue";
import ConnectHometax from "@/views/connect/Hometax.vue";
import Account from "@/views/connect/Account.vue";
import BankAccount from "@/views/connect/BankAccount.vue";
import CardSalesReport from "@/views/CardSalesReport.vue";
import CardSalesMonthlyReport from "@/views/CardSalesMonthlyReport.vue";
import BaeminReport from "@/views/BaeminReport.vue";
import BankReport from "@/views/BankReport.vue";
import Privacy from "@/views/Privacy.vue";
import TaxSchedule from "@/views/TaxSchedule.vue";
import YearEndTaxAdjustment from "@/views/YearEndTaxAdjustment.vue";
import TransactionSources from "@/views/transaction/TransactionSources.vue";
import NewTransaction from "@/views/transaction/NewTransaction.vue";
import TaxReportBrowser from "@/components/TaxReportBrowser.vue";
import HtmlViewer from "@/views/HtmlViewer.vue";
import ExternalLinkBrowser from "@/views/ExternalLinkBrowser.vue";
import CertList from "@/views/connect/CertList.vue";
import CertManager from "@/components/CertManager.vue";
import CertDetail from "@/views/CertDetail.vue";
import Rents from "@/views/rent/Rents.vue";
import RentDetail from "@/views/rent/RentDetail.vue";
import Point from "@/views/Point.vue";
import Referral from "@/views/Referral.vue";
import Coupon from "@/views/Coupon.vue";
import SmallBizSupport from "@/views/SmallBizSupport.vue";
import SocialShare from "@/views/SocialShare.vue";
import HopeRecovery from "@/views/HopeRecovery.vue";


moment.locale('ko')


if (globals.production) {
    mixpanel.init('c1d2b8dd9d00fd834e6235c8c54caafa')
} else {
    mixpanel.init('c18f2ec2dfe5cb674cc0552d16a602f0')
}


if (globals.production) {
    Sentry.init({
        dsn: "https://1922f571f31a46f892605e4c396225ed@o525418.ingest.sentry.io/5640591",
        integrations: [new Integrations.BrowserTracing()],
        release: require('../package.json').version,
        Vue: Vue,
        logErrors: true,
    } as Sentry.BrowserOptions);
}


Vue.config.productionTip = false
Vue.prototype.moment = moment
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(VueCarousel)
Vue.mixin(VueCommonUtils)
Vue.mixin({
    data: () => ({
        globals: globals,
        enabledFeatures: process.env.NODE_ENV === 'production' ? [] : ['openbanking']
    }),
    computed: {
        isApp () {
            // @ts-ignore
            if (window.Android) {
                return true
            }
            return false
        },
        isMobileBrowser () {
            // @ts-ignore
            if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
                return 'iOS'
            }
            if (/(android)/i.test(navigator.userAgent)) {
                return 'Android'
            }
            return null
        },
    }
})


/*
https://router.vuejs.org/guide/advanced/navigation-failures.html#detecting-navigation-failures
https://github.com/vuejs/vue-router/issues/2881
Vue Router에서 코드로 route push할 때 다양한 이유로 catch를 해주어야함.
하지만 현재 그냥 push를 사용하는 코드가 많고, 
대부분 navigation guard로 인한 실패나 NavigationDuplicated 오류를 내고 있으므로
이 두 케이스만 잡아 글로벌로 처리하고 나머지는 그대로 오류를 내서 추적가능하게 한다.
*/
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location: any) {
   return (originalPush as any).call(this, location).catch((err: Error) => {
       if (VueRouter.isNavigationFailure(err)) {
           return
       }
       Promise.reject(err)
 })
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        { path: '/', redirect: {name: '현황판'}},
        { path: '/landing', name: '랜딩', component: Landing, meta: {title: '키퍼 - 가장 쉬운 간편장부'}},
        { path: '/terms', name: '약관', component: Terms},
        { path: '/dashboard', name: '현황판', component: Dashboard, meta: {icon: 'fas fa-tachometer-alt'} },
        { path: '/dashboard/aitguide/:id', name: '종합소득세 신고가이드', component: IncomeTaxGuide, props: true },
        { path: '/dashboard/incomestatement/', name: '손익계산서', component: IncomeStatement, props: true, children: [
            { path: 'transaction', name: '손익계산서 거래내역', component: Transaction },
        ] },
        { path: '/transactionsources', name: '증빙자료', component: TransactionSources, meta: {transition: 'push'}},
        { path: '/taxpayment', name: '납부안내', component: TaxPayment, meta: {transition: 'push'}},
        { path: '/dashboard/cardsales', name: '카드매출내역', component: CardSalesReport},
        { path: '/dashboard/cardsalesmonthly', name: '카드매출집계', component: CardSalesMonthlyReport},
        { path: '/dashboard/baeminsummary', name: '배달의민족매출집계', component: BaeminReport},
        { path: '/dashboard/bank/:id?', name: '은행계좌', component: BankReport, props: true},
        { path: '/ledger', name: '간편장부', redirect: {name: '손익'}, component: Ledgers, meta: {icon: 'fas fa-table', label: '간편장부'}, children: [
            { path: 'types', name: '증빙', components: {sidebar: TransactionsByTypes}, meta: {root: true} },
            { path: 'types/transactions/:id?', name: '증빙종류별 거래', components: {sidebar: TransactionsByTypes, default: Transaction} },
            { path: 'incomestatement', name: '손익', components: {sidebar: IncomeStatement}, meta: {root: true} },
            { path: 'incomestatement/transactions/:id?', name: '손익거래', components: {sidebar: IncomeStatement, default: Transaction} },
            { path: 'assets', name: '자산', components: {sidebar: FixedAssets}, meta: {root: true} },
            { path: 'assets/:id', name: '고정자산 감가상각', components: {sidebar: FixedAssetDepreciation} },
            { path: 'tagging', name: '태그', components: {sidebar: Tagging}, meta: {root: true} },
            { path: 'tagging/transactions/:id?', name: '태그 붙이기', components: {sidebar: Tagging, default: Transaction} },
        ]},
        { path: '/party', name: '거래처', component: PartyList, meta: {icon: 'far fa-handshake'} },
        { path: '/party/detail/:id?', name: '거래처 상세보기', component: PartyDetail, props: true},
        { path: '/party/transactions/:id?', name: '거래처별 거래', component: Transaction },
        { path: '/transaction', name: '거래내역', component: Transaction },
        { path: '/transaction/new', name: '거래내역 수동입력', component: NewTransaction, meta: {transition: 'push'} },
        { path: '/dashboard/taxes', name: '내 세금 내역', component: TaxMenu, children: [
            { path: 'taxlist/:type', name: '세금 내역', components: {sidebar: TaxList}, props: {sidebar: true}, meta: {root: true}},
            { path: 'taxlist/vat/:id', name: '부가가치세', components: {sidebar: TaxList, default: VatTabs},
                props: {sidebar: {type: 'vat'}, default: true}, children: [
                { path: 'confirm', name: '부가가치세 신고', component: VatReportConfirm, props: true },
                { path: 'detail', name: '부가가치세 신고서', component: VatReport, props: true },
                { path: 'transactions', name: '부가가치세 증빙자료', component: VatTransactions, props: true },
                { path: 'detail/:screenName', name: '부가가치세 신고서 상세', component: TaxReportBrowser,
                    props: (route) => {
                        return {id: route.params.id, resourceName: '부가가치세', screenName: route.params.screenName}
                    },
                    meta: {transition: 'push'}
                },
            ]},
            { path: 'taxlist/bpreport/:id', name: '사업장현황', components: {sidebar: TaxList, default: BusinessPlaceReportMenu},
                props: {sidebar: {type: 'bpreport'}, default: true}, children: [
                { path: 'confirm', name: '사업장현황 신고', component: BusinessPlaceReportConfirm, props: true },
                { path: 'detail', name: '사업장현황 신고서', component: BusinessPlaceReport, props: true },
                // { path: 'transactions', name: '부가가치세 증빙자료', component: VatTransactions, props: true },
                { path: 'detail/:screenName', name: '사업장현황 신고서 상세', component: TaxReportBrowser,
                    props: (route) => {
                        return {id: route.params.id, resourceName: '사업장현황신고', screenName: route.params.screenName}
                    },
                    meta: {transition: 'push'}
                },
            ]},
            { path: 'taxlist/ait/:id', name: '종합소득세', components: {sidebar: TaxList, default: IncomeTaxTabs},
                props: {sidebar: {type: 'ait'}, default: true}, children: [
                { path: 'confirm', name: '종합소득세 신고하기', component: IncomeTaxReportConfirm, props: true },
                { path: 'detail', name: '종합소득세 신고서', component: IncomeTaxReport, props: true },
                { path: 'transactions', name: '종합소득세 증빙자료', component: IncomeTaxTransactions, props: true },
                { path: 'detail/incomerecords', name: '사업소득명세서', component: IncomeRecords, meta: {transition: 'push'}, props: true},
                { path: 'detail/:screenName', name: '종합소득세 신고서 상세', component: TaxReportBrowser, meta: {transition: 'push'},
                    props: (route) => {
                        return {id: route.params.id, resourceName: '종합소득세', screenName: route.params.screenName}
                    },
                },
            ]},
            { path: 'reports', name: '세금 신고내역', components: {sidebar: HometaxReports}, meta: {root: true}},
            { path: 'reports/:id', name: '세금신고납부 상세', components: {sidebar: HometaxReports, default: HometaxReportDetail} },
            { path: 'reports/taxpayment', name: '고지내역 납부안내', components: {sidebar: HometaxReports, default: TaxPayment}, meta: {transition: 'push'}},
            { path: 'payments', name: '세금 납부내역', components: {sidebar: HometaxPayments}, meta: {root: true}},
            { path: 'refunds', name: '세금 환급내역', components: {sidebar: HometaxRefunds}, meta: {root: true}},
            { path: 'nonpayments', name: '세금 체납내역', components: {sidebar: HometaxNonpayments}, meta: {root: true}},
            { path: 'nonpayments/taxpayment', name: '체납내역 납부안내', components: {sidebar: HometaxNonpayments, default: TaxPayment}, meta: {transition: 'push'}},
            { path: 'notices', name: '세금 고지내역', components: {sidebar: HometaxNotices}, meta: {root: true}},
        ]},
        { path: '/dashboard/hometax', name: '세금신고납부', component: HometaxDocuments, children: [
        ]},
        { path: '/dashboard/si4n', name: '사회보험 납부내역', component: SI4NPayments },
        { path: '/dashboard/localtax', name: '지방세 신고납부내역', component: LocalTax },
        { path: '/datasources', name: '자료수집', component: DataSources, meta: {icon: 'fas fa-file-import', 'class': function() {
            // @ts-ignore
            return globals.scraping.some(t => t.status == 'running' || t.status == 'ready') ? 'animate__animated animate__flash animate__slower animate__infinite' : ''
        } } },
        { path: '/datasources/smartstore', name: '스마트스토어', component: SmartStoreReport},
        { path: '/datasources/youtube', name: '유튜브', component: YoutubeReport},
        { path: '/tasks', name: '알림', component: Tasks, meta: {transition: 'modal', root: true}, children: [
            { path: ':id', name: '알림 상세', component: TaskDetail},
        ]},
        { path: '/regularreport', name: '정기 보고서', component: WeeklyReport},
        { path: '/invoices', name: '세금계산서', component: Invoices},
        { path: '/invoices/:id?', name: '세금계산서 상세', component: InvoiceDetail, props: true},
        { path: '/cashreceipts', name: '현금영수증', component: CashReceipts},
        { path: '/cardpurchases', name: '카드매입', component: CardPurchases},
        { path: '/chat/:id?', name: '도움말', component: Chat},
        { path: '/chat/governmentsupport', name: '정부 지원금', component: GovernmentSupport},
        { path: '/chat/taxagentintro', name: '세무대행 안내', component: TaxAgentIntro },
        { path: '/hanarointro', name: '경리대행 안내', component: HanaroIntro },
        { path: '/chat/taxschedule', name: '세무일정 안내', component: TaxSchedule },
        { path: '/pricing', name: '이용요금', component: Pricing, meta: {transition: 'push'}},
        { path: '/regularpayment', name: '정식버전 결제', component: RegularPayment, meta: {transition: 'push'}},
        { path: '/setting', name: '설정', component: Setting, meta: {icon: 'fas fa-cog'}},
        { path: '/setting/openbankingauth/', name: '오픈뱅킹인증', component: OpenbankingAuth },
        { path: '/setting/manage/', name: '오픈뱅킹연동관리', component: OpenbankingManage },
        { path: '/setting/connect/hometax', name: '홈택스연동', component: ConnectHometax },
        { path: '/setting/connect/certlist', name: '공인인증서 목록', component: CertList },
        { path: '/setting/connect/cert', name: '공인인증서 등록', component: CertManager },
        { path: '/setting/connect/cert/:id', name: '공인인증서 상세', component: CertDetail, props: true},
        { path: '/setting/connect/account/:id?', name: '스크래핑 계정 등록', component: Account },
        { path: '/setting/connect/bank', name: '은행계좌 등록', component: BankAccount },
        { path: '/privacy', name: '개인정보처리방침', component: Privacy, meta: {transition: 'push', public: true}},
        { path: '/htmlviewer', name: 'HTML Viewer', component: HtmlViewer, props: true, meta: {transition: 'push'}},
        { path: '/external', name: 'External Link Browser', component: ExternalLinkBrowser, props: true, meta: {transition: 'push'}},
        { path: '/yearendtaxadjustment', name: '연말정산', component: YearEndTaxAdjustment},
        { path: '/registerdelegateterms', name: '빠른조회신청대행 동의', component: RegisterDelegateTerms},
        { path: '/rent/rents', name: '부동산임대', component: Rents},
        { path: '/rent/rents/detail', name: '부동산임대 편집', component: RentDetail},
        { path: '/point', name: '포인트', component: Point},
        { path: '/referral', name: '친구 추천', component: Referral},
        { path: '/coupon', name: '쿠폰', component: Coupon},
        { path: '/smallbizsupport', name: '영세사업자 지원 쿠폰 신청', component: SmallBizSupport},
        { path: '/socialshare', name: '소셜 미디어 공유', component: SocialShare},
        { path: '/hoperecovery', name: '희망회복자금', component: HopeRecovery},
    ]
})


declare let gtag: Function


router.afterEach(( to, from ) => {
    globals.smallScreen = window.innerWidth < parseInt(getComputedStyle(document.body).getPropertyValue('--breakpoint-md'))

    if (to.path == '/') {
        return
    }
    document.title = to.meta && to.meta.title || `${to.name} | 키퍼 간편장부`

    let params = {
        page_path: to.path,
        page_title: to.name
    }
    if (globals.user) {
        // @ts-ignore
        params.user_id = globals.user.id
    }
    trackPageView(params)
})


new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
