<template>
    <div class="h-100 d-flex flex-column">
        <nav class="navbar navbar-light titlebar">
            <button class="btn btn-outline-primary"
                    @click="$router.push($route.query.next ? {name: $route.query.next} : '/')">
                {{ $route.query.next ? '뒤로' : '키퍼' }}
            </button>
        </nav>
        <div class="h-100 p-3 overflow-auto bg-white">
            <div v-html="privacyHTML"></div>
        </div>
    </div>
</template>

<script>
    import api from "@/util/api";

    export default {
        name: "Privacy",
        data: function() {
            return {
                privacyHTML: '',
            }
        },
        mounted: async function() {
            this.privacyHTML = (await api.get('user/privacy/')).data
        },
    }
</script>

<style scoped>

</style>
