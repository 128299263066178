<template>
    <div v-if="task">
        <nav class="navbar navbar-light titlebar pt-0">
            <back-button :to="{name: '알림'}"></back-button>
            <h5 class="mt-2">알림 상세보기</h5>
            <span></span>
        </nav>
        <div class="card mx-3">
            <div class="card-header">
                <strong>{{ task.title }}</strong>
            </div>
            <div class="card-body">
                <div v-html="task.content_rendered"></div>
                <br>
                <div v-if="task.clear_condition == 'ON_CONFIRM'">
                    <div v-if="task.status != 'done'">
                        <button class="btn btn-block btn-primary" @click="confirm">동의</button>
                        <br>
                        <button class="btn btn-block btn-outline-secondary" @click="reject">거부</button>
                    </div>
                    <div class="text-center" v-else>
                        <strong class="text-success" v-if="task.is_confirmed">
                            <i class="fas fa-check"></i> 동의하셨습니다.
                        </strong>
                        <strong class="text-secondary" v-else>
                            <i class="fas fa-thumbs-down"></i> 거부하셨습니다.
                        </strong>
                        <br>
                        <br>
                        <span class="text-secondary text-underline" @click="cancel">
                            <span v-if="task.is_confirmed">동의</span>
                            <span v-else>거부</span>
                            취소하기
                        </span>
                    </div>
                </div>

                <div class="text-center" v-if="task.task_type == 'comment'">
                    <router-link :to="{name: '도움말', params: {id: task.key.split('-').pop(), transition: 'push'}}" class="btn btn-outline-primary">댓글 읽기 <i class="fas fa-chevron-right"></i></router-link>
                </div>

                <email-verification :task="task" v-if="task.task_type == 'email-verification'" @sent="startPolling"></email-verification>

                <my-referral v-if="task.task_type == 'referral'"></my-referral>
                <b-button variant="outline-primary" class="mt-2" block @click="$router.push({name: '친구 추천', params: {transition: 'push'}})" v-if="task.task_type == 'referral'">추천 현황 확인하기</b-button>

                <div v-if="task.task_type == 'require-transaction-memo'">
                    <table class="table">
                        <thead>
                            <tr>
                                <th colspan="3">거래내역</th>
                            </tr>
                        </thead>
                        <tbody>
                            <transaction-item :object="object"
                                              @select="selectedTransaction = object; $bvModal.show('tagging-modal')"
                                              v-for="object in transactions"
                                              :key="object.id" />
                        </tbody>
                    </table>
                    <transaction-detail :selected="selectedTransaction"
                                        @begin-relating="$bvToast.toast('현재 페이지에서는 전표 연결 기능을 사용할 수 없습니다. 간편장부 메뉴에서 이용해주세요.', {variant: 'warning'})"
                                        @select-other="$bvToast.toast('현재 페이지에서는 전표 연결 기능을 사용할 수 없습니다. 간편장부 메뉴에서 이용해주세요.', {variant: 'warning'})"
                    />

                </div>

                <div v-if="task.data.link && task.data.link.href">
<!--                    <a :href="intentLink(task.data.link.href)" class="btn btn-outline-primary" v-if="isAndroidApp()">-->
<!--                        {{ task.data.link.title }}-->
<!--                    </a>-->
                    <router-link :to="{name: 'External Link Browser', params: {link: task.data.link.href, title: task.title, transition: 'push'}}"
                                 class="btn btn-block btn-outline-primary">
                        {{ task.data.link.title }} <i class="fas fa-chevron-right"></i>
                    </router-link>
                </div>
            </div>
        </div>

        <div class="card m-3">
            <div class="card-header">
                댓글
            </div>
            <div class="card-body">
                <div v-for="comment in task.comment_set" :key="comment.id">
                    <div class="text-muted">
                        <small class="float-right">{{ humanizeDate(comment.created) }}</small>
                        <small class="badge badge-dark" v-if="comment.writer.is_staff">키퍼 관리자</small>
                        <small v-else>{{ comment.writer.last_name }}{{ comment.writer.first_name }}</small>
                    </div>
                    <div v-html="comment.content_rendered"></div>
                    <hr>
                </div>

                <form class="form" @submit.prevent="createComment(task, $event)">
                    <div class="input-group">
                        <textarea name="content" class="form-control" style="height: 120px" placeholder="댓글을 입력하세요."></textarea>
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary">올리기</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</template>

<script>
    import BackButton from "@/components/BackButton";
    import MyReferral from "@/components/MyReferral";
    import api from "@/util/api";
    import EmailVerification from "@/views/EmailVerification";
    import TransactionDetail from "@/views/transaction/TransactionDetail";
    import TransactionItem from "@/views/transaction/TransactionItem";
    export default {
        name: "TaskDetail.vue",
        components: {TransactionItem, TransactionDetail, EmailVerification, BackButton, MyReferral},
        data() {
            return {
                task: null,
                poller: null,
                transactions: [],
                selectedTransaction: null
            }
        },
        watch: {
            '$route.params.id': function() {
                this.reload()
            },
            'task.is_confirmed': function() {
                if (this.task.is_confirmed && this.poller) {
                    clearInterval(this.poller)
                }
            }
        },
        async mounted() {
            await this.reload()
        },
        beforeDestroy() {
            if (this.poller) {
                clearInterval(this.poller)
            }
        },
        methods: {
            async reload() {
                this.task = (await api.get(`task/${this.$route.params.id}/`)).data
                if (this.task.data.transactions) {
                    for (let t of this.task.data.transactions) {
                        this.transactions.push((await api.get(`robotax/전표/${t}/`)).data)
                    }
                }
            },
            async confirm() {
                this.task = (await api.post(`task/${this.$route.params.id}/confirm/`)).data
            },
            async reject() {
                this.task = (await api.post(`task/${this.$route.params.id}/reject/`)).data
            },
            async cancel() {
                this.task = (await api.patch(`task/${this.$route.params.id}/`, {
                    is_confirmed: null,
                    status: 'read',
                })).data
            },
            intentLink(href) {
                let parts = href.split('://')
                return `intent://${parts[1]}#Intent;scheme=${parts[0]};action=android.intent.action.VIEW;end;`
            },
            isAndroidApp() {
                return new Boolean(window.Android)
            },
            startPolling() {
                if (this.poller) { return }
                this.poller = setInterval(this.reload, 3000)
            },
            async createComment(task, event) {
                let res = await api.post('taskcomment/', {
                    task: task.id,
                    content: event.target.content.value
                })
                task.comment_set.push(res.data)
                event.target.content.value = ''
            },
        }
    }
</script>

<style scoped>

</style>
