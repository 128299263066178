<template>
    <div>
        <div class="mx-3" v-if="shouldTag">
            <button class="btn btn-block btn-warning" @click="untaggedHelpVisible = true">
                미확인, 미분류 거래내역 안내
            </button>
            <b-modal title="미확인, 미분류 거래내역 안내" ok-only ok-title="닫기" v-model="untaggedHelpVisible">
                <p>
                    <strong>미분류</strong> 거래내역이 있으면 손익계산이 제대로 안 나올 수 있습니다. 다음과 같이 태그를 붙여주세요.
                </p>
                <ul>
                    <li><strong>매출</strong>은 <strong>매출액</strong>이나 <strong>영업외수익</strong>> 태그를 붙여주세요.</li>
                    <li><strong>매입</strong>은 <strong>매출원가</strong>, <strong>판매비와관리비</strong>, <strong>영업외비용</strong> 태그를 붙여주세요.</li>
                    <li><strong>은행 입금</strong>은 대응되는 세금계산서나 카드매출이 있으면 태깅하지 않아도 되며, 그 외의 경우에 <strong>매출액</strong>이나 <strong>영업외수익</strong> 태그를 붙이면 됩니다.</li>
                    <li><strong>은행 출금</strong>은 대응되는 세금계산서나 카드매출이 있으면 태깅하지 않아도 되며, 그 외의 경우에 <strong>매출원가</strong>, <strong>판매비와관리비</strong>, <strong>영업외비용</strong> 중 하나의 태그를 붙이면 됩니다.</li>
                    <li>사업과 무관한 수입과 지출의 경우에는 손익과 관련된 태그를 붙이지 않아도 됩니다.</li>
                </ul>
                <p>
                    <strong>미확인</strong> 거래내역은 키퍼가 태그를 추천했으나 사장님이 확인하지 않으신 내역입니다.
                    미확인 거래내역도 손익계산서에는 포함이 되지만 종합소득세 신고에는 사장님이 직접 확인하신 내역만 신고되므로 종합소득세 신고하기 전에는
                    모두 확인 부탁드립니다. 태그를 누르고 추천된 항목을 다시 누르면 확인처리됩니다.
                </p>
            </b-modal>
        </div>
        <div class="m-3 alert alert-danger" v-if="induce.cardSalesAccount">
            <strong>카드매출</strong>이 있습니다. 여신금융협회 계정을 등록해 태그를 붙여주세요.
            <b-button block variant="link" size="sm" @click="$router.push({name: '카드매출내역'})">
                여신금융협회 연동하기
                <i class="fas fa-chevron-right"></i>
            </b-button>
        </div>
        <div class="m-3 alert alert-danger" v-if="induce.bankAccount">
            <strong>은행계좌</strong>연동이 되어있지 않습니다. 은행 조회가 가능한 사업용 공인인증서를 등록하거나, 빠른조회로 계좌를 등록해주세요.
            <b-button block variant="link" size="sm" @click="$router.push({name: '은행계좌'})">
                은행계좌 연동하기 <i class="fas fa-chevron-right"></i>
            </b-button>
        </div>
    </div>
</template>

<script>
    import {api} from "@/util/api";
    import querystring from "querystring";

    export default {
        name: "TagGuide",
        props: ['shouldTag'],
        data () {
            return {
                untaggedHelpVisible: false,
                induce: {
                    cardSalesAccount: false,
                    bankAccount: false
                }
            }
        },
        async mounted() {
            if (!await this.globals.accountResource.cardSalesAccount()) {
                let cardSales = (await api.get('robotax/카드매출집계/', {
                    params: {
                        사업자: this.globals.trader.robotax_id,
                        거래기간시작일__year: this.year,
                    },
                    paramsSerializer: querystring.stringify
                })).data
                this.induce.cardSalesAccount = !!cardSales.count
            }

            this.induce.bankAccount = (await this.globals.accountResource.bankAccounts()).length === 0
        }
    }
</script>

<style scoped>

</style>
