<template>
    <div class="h-100 overflow-auto" @scroll="loadMore($event)">
        <div class="alert alert-light rounded-0 mb-0">
            <strong>홈택스</strong>에 실제로 <strong>신고</strong>하거나, 홈택스로부터 <strong>고지</strong> 받은 세금 내역입니다.
        </div>

        <scraping-account-panel class="m-3" source="전자신고결과조회" scraping-only></scraping-account-panel>

        <div class="bg-white">
            <table class="table" v-if="taskPaginator">
                <tbody>
                    <router-link tag="tr" :to="detailRoute(task)" exact-active-class="table-active" v-for="task in taskPaginator.objects" :key="task.id">
                        <td class="">
                            <span class="mr-1" :class="'text-' + globals.TAX_STATUSES[task.status].color">
                                <i class="fas fa-exclamation-triangle" v-if="globals.TAX_STATUSES[task.status].color == 'danger'"></i>
                                <i class="fas fa-check" v-else></i>
                            </span>
                            <span>{{ task.title }}</span>
                            <div v-if="task.status == 'reported'">
                            <span class="text-danger" v-if="task.data.납부서 && task.data.납부서.length > 0">
                                납기일: {{ localDateFormat(task.data.납부서[0].납부기한) }}
                            </span>
                            </div>
                            <div v-else-if="task.status == 'paid'">
<!--                                <span :class="'text-' + globals.TAX_STATUSES[task.status].color">-->
<!--                                    {{ globals.TAX_STATUSES[task.status].label }}: {{localDateFormat(task.related_document_set[0].issued) }}-->
<!--                                </span>-->
                            </div>
                            <div v-else-if="task.status">
                            <span :class="'text-' + globals.TAX_STATUSES[task.status].color">
                                {{ globals.TAX_STATUSES[task.status].label }}
                            </span>
                            </div>
                        </td>
                        <td class="text-right" style="min-width: 150px">
                            <span>{{ moneyFormat(task.amount) }}</span>
                            <div class="text-muted">
                                <small>
                                    {{ localDateFormat(task.issued) }}
                                    <strong v-if="task.source == '전자신고결과조회'">신고</strong>
                                    <strong v-else-if="task.source == '고지내역'">고지</strong>
                                </small>
                            </div>
                        </td>
                    </router-link>
                    <tr class="text-center text-white" v-if="taskPaginator.loading || globals.transactions.syncing">
                            <p>
                            <b-spinner variant="light"></b-spinner>
                        </p>
                        <p>불러오는 중...</p>
                    </tr>
                    <tr class="text-white" v-else-if="taskPaginator.objects.length == 0">
                        <p>세금 신고 내역을 찾을 수 없습니다. 홈택스의 세금 신고 내역은 야간에 조회가 불가능하므로 내일 오전에 다시 확인해보세요.</p>
                        <p>홈택스의 세금계산서, 카드매입 등의 거래내역 자료는 정상적으로 업데이트 가능합니다.</p>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import LoginRequired from "../../components/LoginRequired";
    import AppUpdateMixin from "../../components/AppUpdateMixin";
    import Paginator from "@/util/paginator";
    import api from "@/util/api";
    import InduceCert from "@/components/InduceCert";
    import BackButton from "@/components/BackButton";
    import ScrapingAccountPanel from "@/views/connect/ScrapingAccountPanel";
    import AccountMixin from "@/util/AccountMixin";

    export default {
        name: "HometaxReports",
        components: {ScrapingAccountPanel},
        mixins: [LoginRequired, AppUpdateMixin, AccountMixin],
        data: function() {
            return {
                taskPaginator: null,
            }
        },
        watch: {
            'globals.transactions.syncing': function() {
                this.reload()
            },
            'globals.trader': function() {
                this.reload()
            },
        },
        mounted() {
            this.reload()
            this.pollScraping('전자신고결과조회', '고지내역')
        },
        methods: {
            async reload () {
                if (!this.globals.trader) { return }

                this.taskPaginator = new Paginator(api.get, 'robotax/hometaxdocument/?page_size=10&source__in=전자신고결과조회,고지내역&사업자=' + this.globals.trader.robotax_id)
                this.taskPaginator.loadNext()
            },
            scrapingUpdated() {
                this.reload()
            },
            loadMore: function(event) {
                if (!this.globals.trader) { return }

                if (!this.taskPaginator.canLoadNext()) {
                    return
                }
                if (event.target.scrollTop + event.target.clientHeight + 100 > event.target.scrollHeight) {
                    this.taskPaginator.loadNext()
                }
            },
            detailRoute(doc) {
                if (doc.source == '전자신고결과조회') {
                    return {name: '세금신고납부 상세', params: {id: doc.id}}
                } else if (doc.source == '고지내역') {
                    return {name: '고지내역 납부안내', query: {
                        title: doc.title, accountNo: doc.data.전자납부번호, amount: doc.amount, type: '국세', id: doc.id}
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>