<template>
    <div>
        <div class="card m-3">
            <div class="card-header">
                <span v-b-modal.tagrule-help>
                    태그 규칙
                    <i class="fas fa-question-circle"></i>
                </span>
                <b-modal id="tagrule-help" title="태그 규칙 안내" ok-only ok-title="닫기">
                    거래내역에서 태그를 붙이면 자동으로 비슷한 거래를 모아서 동일한 태그를 붙일 수 있습니다. 태그를 붙일 때 선택하면 태그 규칙이 자동으로 만들어지고, 새로운 거래에도 그 규칙이 적용됩니다.
                </b-modal>
            </div>
            <div class="card-body" v-if="tagrulePaginator">
                <table class="table">
                    <tr v-for="tagrule in tagrulePaginator.objects" :key="tagrule.id">
                        <td>
                            <div><strong>{{ tagrule.tag_path }}</strong></div>
                            <div>{{ tagrule.거래처명 }} {{ tagrule.증빙종류 }} {{ tagrule.은행입출금 }} {{ tagrule.매출매입 }}</div>
                            <div class="text-muted">적요: {{ tagrule.적요 }}</div>
                        </td>
                        <td>
                            <button class="btn btn-sm btn-outline-secondary" @click="deleteTagRule(tagrule)">
                                <i class="fas fa-times"></i>
                            </button>
                        </td>
                    </tr>
                    <tr v-if="tagrulePaginator.loading">
                        <td colspan="2" class="text-center">
                            <b-spinner class="ml-3 align-middle" variant="primary"></b-spinner>
                        </td>
                    </tr>
                    <tr v-else-if="tagrulePaginator.objects == 0">
                        <td colspan="2" class="text-center">
                            아직 태그 규칙이 없습니다.
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="card m-3">
            <div class="card-header">
                태그 전체보기
            </div>
            <div class="card-body p-0 pt-2">
                <tag-selector extend-all @select="selectedTag = $event">
                    <template v-slot:tag-submenu="slotProps">
                        <span class="ml-2" v-if="selectedTag == slotProps.tagPath">
                            <router-link class="btn btn-sm btn-outline-primary"
                                         :to="{name: '태그 붙이기', query: {tag__path__contains: selectedTag}}"
                                         @click.native="selectedTag = null"
                                         :replace="!globals.smallScreen">
                                <i class="fas fa-table"></i>
                                보기
                            </router-link>
                            <router-link class="ml-1 btn btn-sm btn-primary"
                                         :to="{name: '태그 붙이기', query: {select_tag: selectedTag}}"
                                         @click.native="selectedTag = null"
                                         :replace="!globals.smallScreen">
                                <i class="fas fa-tag"></i>
                                붙이기
                            </router-link>
                        </span>
                    </template>
                </tag-selector>
            </div>
        </div>
    </div>
</template>

<script>
    import {api} from "@/util/api";
    import Paginator from "@/util/paginator";
    import querystring from "querystring";
    import {loadTags} from "@/models";
    import IncomeTaxMixin from "@/util/IncomeTaxMixin";
    import TagSelector from "@/views/transaction/TagSelector";

    export default {
        name: "Tagging",
        components: {TagSelector},
        props: ['period'],
        mixins: [IncomeTaxMixin],
        data() {
            return {
                사업자: null,
                종합소득세: null,
                사업소득명세: null,
                전표Summary: [],
                untagged전표Summary: [],
                unconfirmed전표Summary: [],
                tags: null,
                tagrulePaginator: null,
                selectedTag: null
            }
        },
        watch: {
        },
        async mounted() {
            // this.period = Object.assign({}, this.$route.query)
            // this.사업소득명세 = (await this.globals.사업소득명세.get(this.id + '/')).object
            await this.reloadSummary()
        },
        methods: {
            async reloadSummary(period) {
                if (period) {
                    this.period = period
                }

                this.전표Summary = (await api.get('robotax/전표/annotate/', {
                    params: {
                        사업자: this.globals.trader.robotax_id,
                        거래일__gte: this.dateFormat(this.period.begin),
                        거래일__lte: this.dateFormat(this.period.end),
                        groupby_fields: ['매출매입', '은행입출금'],
                        count_fields: ['id'],
                        sum_fields: ['공급가액'],
                        max_fields: ['거래일'],
                    },
                    paramsSerializer: querystring.stringify
                })).data
                this.untagged전표Summary = (await api.get('robotax/전표/annotate/', {
                    params: {
                        사업자: this.globals.trader.robotax_id,
                        거래일__gte: this.dateFormat(this.period.begin),
                        거래일__lte: this.dateFormat(this.period.end),
                        tag__isnull: true,
                        groupby_fields: ['매출매입', '은행입출금'],
                        count_fields: ['id'],
                        sum_fields: ['공급가액'],
                        max_fields: ['거래일'],
                    },
                    paramsSerializer: querystring.stringify
                })).data

                this.unconfirmed전표Summary = (await api.get('robotax/전표/annotate/', {
                    params: {
                        사업자: this.globals.trader.robotax_id,
                        거래일__gte: this.dateFormat(this.period.begin),
                        거래일__lte: this.dateFormat(this.period.end),
                        tag__isnull: false,
                        tag__confirmed: false,
                        groupby_fields: ['매출매입', '은행입출금'],
                        count_fields: ['id'],
                        sum_fields: ['공급가액'],
                        max_fields: ['거래일'],
                    },
                    paramsSerializer: querystring.stringify
                })).data

                this.tagrulePaginator = new Paginator(api.get, `robotax/tagrule/?사업자=${this.globals.trader.robotax_id}`)
                this.tagrulePaginator.loadNext()

                this.tags = await loadTags()

                this.사업자 = await this.globals.사업자.getObject(this.globals.trader.robotax_id)
                if (this.사업자.owner) {
                    this.종합소득세 = (await this.globals.종합소득세.get(`now/?사업자=${this.사업자.owner.robotax_id}&today=${this.dateFormat(this.period.begin)}`)).object
                }
            },
            showTagModal() {
                this.$bvModal.show('tag-modal')
            },
            async deleteTagRule(tagrule) {
                if (confirm(`${tagrule.tag_path}의 태그 규칙을 삭제하시겠습니까? 태그 규칙을 삭제하면 앞으로 해당 규칙의 전표는 자동으로 태그가 붙지 않습니다.`)) {
                    await api.delete(`robotax/tagrule/${tagrule.id}/?사업자=${this.globals.trader.robotax_id}`)
                    this.tagrulePaginator.objects = this.tagrulePaginator.objects.filter(t => t.id != tagrule.id)
                }
            }
        }
    }
</script>
