<template>
    <div class="h-100 overflow-auto" v-if="task">
        <nav class="navbar navbar-light titlebar">
            <back-button v-if="globals.smallScreen"/>
            <span v-else></span>
            <h5 class="mt-2">
                {{ task.title }}
            </h5>
            <span></span>
        </nav>
        <div class="h-100 mx-3">
            <div class="card mb-3" @click="$router.push({name: '세금신고납부 상세', params: {id: task.id}})">
                <div class="card-header">
                    <h5 class="m-0">{{ task.title }}</h5>
                </div>
                <div class="card-body">
                    <table class="table table-sm">
                        <tbody class="table-borderless">
                            <tr>
                                <td>납부(환급) 금액 합계</td>
                                <td class="text-right"><strong style="font-size: 1.5em">{{ moneyFormat(task.amount) }}</strong></td>
                            </tr>
                            <tr>
                                <td class="">
                                    <strong :class="'text-' + globals.TAX_STATUSES[task.tax_status].color">
                                        <i class="fas fa-check" v-if="globals.TAX_STATUSES[task.tax_status].color == 'success'"></i>
                                        {{ globals.TAX_STATUSES[task.tax_status].label }}
                                    </strong>
                                </td>
                                <td class="text-right">
                                    <strong v-if="task.tax_status == 'paid-more' || task.tax_status == 'paid-less'">{{ moneyFormat(차액) }}</strong>
                                </td>

                            </tr>
                        </tbody>
                        <tbody>
                            <tr v-if="task.data.납부서[0]">
                                <td>납부기한</td>
                                <td class="text-right">{{ localDateFormat(task.data.납부서[0].납부기한) }}</td>
                            </tr>
                            <tr v-if="task.data.접수일시">
                                <td>접수일시</td>
                                <td class="text-right">{{ localDateFormat(task.data.접수일시.slice(0, 8) + 'T' + task.data.접수일시.slice(8)) }}</td>
                            </tr>
                            <tr v-for="field in 신고서_FIELDS" :key="field">
                                <td>{{ field }}</td>
                                <td class="text-right">{{ task.data[field] }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <h5 class="text-white" v-if="task.data['납부서'].length">납부 안내</h5>
            <div class="card mb-3" v-for="납부서 in task.data['납부서']" :key="납부서.전자납부번호">
                <div class="card-header">
                    <h6 class="m-0">
                        <a v-b-modal.tax-payment-help-modal class="text-primary float-right"><i class="fas fa-info-circle"></i> 납부 안내</a>
                        {{ 납부서.세목 }}
                    </h6>
                </div>
                <div class="card-body">
                    <div>
                        <table class="table table-sm table-borderless">
                            <tbody>
                                <tr v-if="parseInt(납부서.신고납부차액) == 0">
                                    <th>납부할 금액</th>
                                    <th class="text-right">{{ moneyFormat(납부서.총납부금액) }}</th>
                                </tr>
                                <tr v-else-if="parseInt(납부서.신고납부차액) < 0">
                                    <th>환급될 금액</th>
                                    <th class="text-right">{{ moneyFormat(납부서.신고납부차액) }}</th>
                                </tr>
                                <tr v-else-if="parseInt(납부서.신고납부차액) > 0">
                                    <th>납부할 금액</th>
                                    <th class="text-right">{{ moneyFormat(납부서.신고납부차액) }}</th>
                                </tr>
                                <tr>
                                    <td>전자납부번호</td>
                                    <td class="text-right" :id="`tooltip-${납부서.전자납부번호}`" @click="copyToClipboard(`tooltip-${납부서.전자납부번호}`, 납부서.전자납부번호)">{{ 납부서.전자납부번호 }}</td>
                                    <b-tooltip :target="`tooltip-${납부서.전자납부번호}`" triggers="hover" delay="50">복사됨</b-tooltip>
                                    <i class="far fa-copy" v-if="clipboard != 납부서.전자납부번호" @click="copyToClipboard(`tooltip-${납부서.전자납부번호}`, 납부서.전자납부번호)"></i>
                                    <i class="text-success text-sm fas fa-check" style="font-size: 0.9em" v-else></i>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-sm" v-if="납부서.신고납부차액 != '0'">
                            <tbody>
                                <tr>
                                    <td>처음 신고한 금액</td>
                                    <td class="text-right">{{ moneyFormat(납부서.최초신고금액) }}</td>
                                </tr>
                                <tr>
                                    <td>납부해야할 금액 합계</td>
                                    <td class="text-right">{{ moneyFormat(납부서.총납부금액) }}</td>
                                </tr>
                                <tr>
                                    <td>신고납부 차액</td>
                                    <td class="text-right">{{ moneyFormat(납부서.신고납부차액) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <h5 class="text-white" v-if="납부내역.length">실제 납부 내역</h5>
            <div class="card mb-3" v-for="doc in 납부내역" :key="doc.id">
                <div class="card-header">
                    <h6 class="m-0">{{ doc.data.세목 }}</h6>
                </div>
                <div class="card-body">
                    <table class="table table-sm">
                        <tbody>
                            <tr>
                                <td>납부금액</td>
                                <td class="text-right">{{ moneyFormat(doc.amount) }}</td>
                            </tr>
                            <tr>
                                <td>전자납부번호</td>
                                <td class="text-right">{{ doc.data.전자납부번호 }}</td>
                            </tr>
                            <tr>
                                <td>납부일</td>
                                <td class="text-right">{{ localDateFormat(doc.issued) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card mb-3" v-for="doc in task.related_document_set.filter(doc => doc.source == '환급금조회')" :key="doc.id">
                <div class="card-header">
                    <h6 class="m-0">환급내역: {{ doc.data.세목 }}</h6>
                </div>
                <div class="card-body">
                    <table class="table table-sm">
                        <tbody>
                            <tr>
                                <td>환급금</td>
                                <td class="text-right">{{ moneyFormat(doc.amount) }}</td>
                            </tr>
                            <tr>
                                <td>환급결정일</td>
                                <td class="text-right">{{ localDateFormat(doc.issued) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <b-modal id="tax-payment-help-modal" ok-only ok-title="닫기" title="납부 안내" scrollable>
            <h5>국세 납부</h5>
            <p>국세는 전자납부번호만 알면 <strong>국세 계좌</strong>를 통해 손쉽게 납부할 수 있습니다. 거래하시는 <strong>은행 앱</strong>을 열고 계좌이체에서 다음과 같이 입력하시면 됩니다. </p>
            <table class="table table-sm">
                <tbody>
                    <tr><th>입금은행</th> <td>국세</td></tr>
                    <tr><th>입금계좌</th> <td>전자납부번호</td></tr>
                    <tr><th>입금액</th> <td>납부할 금액</td></tr>
                </tbody>
            </table>
            <p>
                <strong>전자납부번호</strong>와 <strong>납부할 금액</strong>은 납부 안내에서 숫자를 클릭하면 자동으로 복사되어, 은행 앱에서 붙여넣기 할 수 있습니다.
                국세 계좌를 이용해서 납부하면 수수료가 무료입니다.
            </p>
            <h5>지방세 납부</h5>
            <p>지방세는 <a href="https://www.wetax.go.kr/main/">위택스</a>로 접속하신 후, 스마트 위택스 앱을 설치해서 사용하시면 쉽게 납부하실 수 있습니다.</p>
            <p>스마트 위택스 앱에서 공인인증서로 로그인하신 후 우상단 메뉴 버튼을 눌러서 <strong>나의 위택스</strong>로 들어가면 납부해야 할 내역을 조회할 수 있습니다.</p>
            <p>원천세, 종합소득세, 법인세는 모두 지방소득세를 별도로 납부해야 하니, 국세 납부를 완료하신 후에 위택스에서 지방소득세도 빠뜨리지 말고 확인하시기 바랍니다. </p>
        </b-modal>

    </div>
</template>

<script>
    import api from "@/util/api";
    import BackButton from "@/components/BackButton";

    export default {
        name: "HometaxReportDetail",
        components: {BackButton},
        data() {
            return {
                신고서_FIELDS: ['과세연월', '신고구분', '신고유형', ],
                납부서_FIELDS: ['납부기한', '총납부금액', '최초신고금액', '신고납부차액',  ],
                task: null,
                clipboard: null,
            }
        },
        computed: {
            납부내역: function() {
                return this.task.related_document_set.filter(doc => doc.source == '납부내역')
            },
            차액: function () {
                return this.task.data.납부서.reduce((acc, 납부서) => {
                    return acc + parseInt(납부서['신고납부차액'])
                }, 0)
            }
        },
        watch: {
            '$route.params'() {
                this.reload()
            }
        },
        async mounted() {
            await this.reload()
        },
        methods: {
            async reload() {
                this.task = (await api.get(`robotax/hometaxdocument/${this.$route.params.id}/`)).data
            },
            copyToClipboard (containerId,  value) {
                let range = document.createRange();
                range.selectNode(document.getElementById(containerId));
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
                document.execCommand("copy");
                this.clipboard = value
            }
        }
    }
</script>

<style scoped>

</style>