<template>
    <div>
        <nav class="navbar navbar-light titlebar pt-0">
            <back-button></back-button>
            <h5 class="mt-2">
                장부 수동입력
            </h5>
            <span style="width: 20px"></span>
        </nav>
        <b-card class="m-3" no-body>
            <b-tabs card>
                <b-tab title="현금매출" active>
                    <b-form>
                        <b-form-group label="매출 연월">
                            <b-input type="date" v-model="현금매출입력.거래일"></b-input>
                        </b-form-group>
                        <b-form-group label="공급가액">
                            <b-input type="number" v-model="현금매출입력.공급가액"></b-input>
                        </b-form-group>
                        <b-form-group label="부가세">
                            <b-input type="number" v-model="현금매출입력.부가세"></b-input>
                        </b-form-group>
                        <b-button class="btn-block" variant="primary" @click.prevent="appendTransaction(현금매출입력)">입력</b-button>
                    </b-form>
                </b-tab>
                <b-tab title="종이세금계산서">
                    <b-form inline>
                        <b-form-group class="">
                            <b-dropdown class="ml-2"
                                        :text="종이세금계산서.부가세유형 || '부가세유형 선택'"
                                        variant="outline-primary"
                                        size="sm"
                                        style="font-size: 0.5em">
                                <b-dropdown-item @click="종이세금계산서.부가세유형 = '과세'">과세</b-dropdown-item>
                                <b-dropdown-item @click="종이세금계산서.부가세유형 = '면세'">면세</b-dropdown-item>
                                <b-dropdown-item @click="종이세금계산서.부가세유형 = '영세'">영세</b-dropdown-item>
                            </b-dropdown>
                        </b-form-group>
                        <b-form-group>
                            <b-dropdown class="ml-2"
                                        :text="종이세금계산서.매출매입 || '세금계산서 매출매입 선택'"
                                        variant="outline-primary"
                                        size="sm"
                                        style="font-size: 0.5em">
                                <b-dropdown-item @click="종이세금계산서.매출매입 = '매출'">매출</b-dropdown-item>
                                <b-dropdown-item @click="종이세금계산서.매출매입 = '매입'">매입</b-dropdown-item>
                            </b-dropdown>
                        </b-form-group>
                        <b-form-group class="" v-if="종이세금계산서.매출매입">
                            <b-input type="text" v-model="종이세금계산서.거래처사업자주민번호" placeholder="거래처 사업자등록번호"></b-input>
                        </b-form-group>
                        <b-form-group class="" v-if="종이세금계산서.매출매입">
                            <b-input type="text" v-model="종이세금계산서.거래처명" placeholder="거래처 법인명/상호"></b-input>
                        </b-form-group>
                        <b-form-group class="" v-if="종이세금계산서.매출매입">
                            <b-input type="text" v-model="종이세금계산서.거래처대표자명" placeholder="거래처 대표자명"></b-input>
                        </b-form-group>
                        <b-form-group class="" v-if="종이세금계산서.매출매입">
                            <b-input type="date" v-model="종이세금계산서.거래일" placeholder="작성일자"></b-input>
                        </b-form-group>
                        <b-form-group class="" v-if="종이세금계산서.매출매입">
                            <b-input type="text" v-model="종이세금계산서.품명" placeholder="품명"></b-input>
                        </b-form-group>
                        <b-form-group class="" v-if="종이세금계산서.매출매입">
                            <b-input type="number" v-model="종이세금계산서.공급가액" placeholder="공급가액"></b-input>
                        </b-form-group>
                        <b-form-group class="" v-if="종이세금계산서.매출매입">
                            <b-input type="number" v-model="종이세금계산서.부가세" placeholder="부가세"></b-input>
                        </b-form-group>

                        <b-button class="btn-block" variant="primary" @click.prevent="appendTransaction(종이세금계산서)">입력</b-button>
                    </b-form>

                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>
<script>
    import {silentApi} from "@/util/api";
    import moment from "moment";
    import BackButton from "@/components/BackButton";

    export default {
        name: 'NewTransaction',
        components: {BackButton},
        props: {
        },
        data() {
            return {
                현금매출입력: {
                    증빙종류: '증빙없음',
                    매출매입: '매출',
                    거래일: null,
                    공급가액: 0,
                    부가세: 0,
                },
                종이세금계산서: {
                    부가세유형: null,
                    매출매입: null,
                    증빙종류: '세금계산서',
                    거래처명: null,
                    거래처사업자주민번호: null,
                    거래처대표자명: null,
                    품명: null,
                    거래일: null,
                    공급가액: null,
                    부가세: 0,
                },
            }
        },
        methods: {
            transactionError(data) {
                if (data.증빙종류 == '증빙없음') {
                    if (data.공급가액 <= 0) {
                        return '공급가액을 정확하게 입력해주세요.'
                    }
                } else if (data.증빙종류 == '세금계산서') {
                    if (!(data.부가세유형 && data.매출매입)) {
                        return '부가세유형과 매출매입을 선택해주세요.'
                    }
                    if (!(data.거래처사업자주민번호 || data.거래처명 || data.거래처대표자명)) {
                        return '거래처 정보를 입력해주세요.'
                    }
                    if (!data.공급가액) {
                        return '공급가액을 정확하게 입력해주세요.'
                    }
                }

                let 거래일 = moment(data.거래일);
                if (!거래일.isValid()) {
                    return '날짜를 정확하게 입력해주세요.'
                }
                return null
            },
            fields(data) {
                if (data.증빙종류 == '증빙없음') {
                    let 거래일 = moment(data.거래일);
                    return {
                        품명: `${거래일.get('month') + 1}월 현금매출`,
                        매입매출: '매출'
                    }
                } else if (data.증빙종류 == '세금계산서') {
                    return {
                        전자세금: false
                    }
                }
            },
            async appendTransaction(data) {
                if (this.transactionError(data)) {
                    alert(this.transactionError(data))
                    return
                }

                try {
                    let row = (await silentApi.post('robotax/전표/', {
                        사업자: this.globals.trader.robotax_id,
                        ...this.fields(data),
                        ...data
                    })).data

                    Object.assign(this.$data, {
                        현금매출입력: {
                            증빙종류: '증빙없음',
                            거래일: null,
                            공급가액: 0,
                            부가세: 0,
                        },
                        종이세금계산서: {
                            부가세유형: null,
                            매출매입: null,
                            증빙종류: '세금계산서',
                            거래처명: null,
                            거래처사업자주민번호: null,
                            거래처대표자명: null,
                            품명: null,
                            거래일: null,
                            공급가액: null,
                            부가세: null,
                        },
                    })

                    history.back()
                } catch (e) {
                    alert(JSON.stringify(e.response.data))
                }
            },
        }
    }
</script>
