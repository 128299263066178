<template>
    <div class="vh-100 text-white">
        <h1 class="text-center mt-5 mx-5">이용요금 안내</h1>
        <div class="card m-3">
            <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th>서비스</th>
                            <th>가격</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>거래내역 수집</th>
                            <td class="text-right">무료</td>
                        </tr>
                        <tr>
                            <th>예상 세금 계산</th>
                            <td class="text-right">무료</td>
                        </tr>
                        <tr>
                            <th>회계 처리</th>
                            <td class="text-right">무료</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>홈택스 세금신고 (1건당)</strong><br>
                            </td>
                            <td class="text-right">1만원</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                <p class="text-muted"><i class="fas fa-info-circle"></i><strong> 환불규정:</strong><br>세금신고한 내역에 대해 환불요청을 하면 홈택스에 등록된 세금신고가 취소됩니다. 해당 세금신고 기간이 지난 후에는 환불되지 않습니다.</p>
            </div>
        </div>
        <div class="text-center">
            <button class="btn btn-secondary" @click="$router.go(-1)">확인</button>
        </div>
        <div class="mt-auto mx-4">
            <p class="">
                <small>
                주식회사 파인코드
                (813-81-00856)
                |
                대표 박영록
                <br>
                <a class="text-white" href="tel:031-717-5643">031-717-5643</a>
                |
                경기도 성남시 분당구 백현로 97, 12층 1215호
                |
                통신판매업신고: 2020-성남분당B-0350
                </small>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Pricing",
    }
</script>

<style scoped>

</style>