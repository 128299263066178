<template>
    <div>
        <a :class="'text-' + color" @click="go()">
            <i class="fas fa-chevron-left mr-1" style="font-size: 1.4em"></i>
            <slot></slot>
        </a>
    </div>
</template>
<script>
    export default {
        name: 'BackButton',
        props: {
            to: {},
            color: {default: 'white'}
        },
        computed: {
            target: function() {
                if (this.to) return this.to

                for (var i = this.$route.path.split('/').length; i > 0; i--) {
                    var result = this.$router.options.routes.filter(function(e) {
                        return (e.path == this.$route.path.split('/').slice(0, i - 1).join('/')) |
                            (e.path == this.$route.path.split('/').slice(0, i - 2).join('/') + '/:id')
                    }.bind(this))

                    if (result.length && !result[0].children) {
                        return result[0]
                    }
                }

                return {path: '/'}
            }
        },
        methods: {
            go: function() {
                this.$emit('back')
                if (this.to) {
                    this.$router.replace(this.target)
                } else {
                    this.$router.go(-1)
                }
            }
        }
    }
</script>
